import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { patientPropType, patientSubscriptionPropType } from 'Lib/types';
import OneTimeTextMessageModal from '../PatientMessagingEnrollmentModal/OneTimeTextMessageModal';
import PatientMessagingEnrollmentModal from '../PatientMessagingEnrollmentModal/PatientMessagingEnrollmentModal';
import PatientMessagingHistoryModal from '../PatientMessagingEnrollmentModal/PatientMessagingHistoryModal';
import PatientMessagingUnenrollmentModal from '../PatientMessagingEnrollmentModal/PatientMessagingUnenrollmentModal';
import SendOneTimeTextModal from '../PatientMessagingEnrollmentModal/SendOneTimeTextModal';
import MessagingStatus from './MessagingStatus';

const PatientEngagement = ({
    patientDemographicsData: patient,
    patientMessagingSubscription: subscription,
    refetchMessagingSubscriptions: refetch,
}) => {
    const [showPatientMessagingModal, setShowPatientMessagingModal] = useState(false);
    const [showPatientMessagingUnenrollmentModal, setShowPatientMessagingUnenrollmentModal] = useState(false);

    const [showMessagingHistoryModal, setShowMessagingHistoryModal] = useState(false);
    const [showOneTimeMessage, setShowOneTimeMessage] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const { mobilePhone, id: patientId, divisionId } = patient;

    const tooltip = (
        <Tooltip id="actions-tooltip">
            Educational content and digital communication services available for the patient
        </Tooltip>
    );

    return (
        <div data-testid="patient-engagement">
            <span className="engage-label">Patient Engagement</span>
            <OverlayTrigger trigger={['hover', 'focus']} overlay={tooltip}>
                <i className="fas fa-fw fa-question-circle" />
            </OverlayTrigger>

            <div>
                <MessagingStatus
                    subscription={subscription}
                    showOneTimeModal={() => setShowOneTimeMessage(true)}
                    showMessagingModal={() => setShowPatientMessagingModal(true)}
                    showHistoryModal={() => setShowMessagingHistoryModal(true)}
                    showUnenrollmentModal={() => setShowPatientMessagingUnenrollmentModal(true)}
                />
            </div>

            {showPatientMessagingUnenrollmentModal && (
                <PatientMessagingUnenrollmentModal
                    mobilePhone={mobilePhone}
                    subscriptionValue={subscription}
                    patientId={patientId}
                    show={showPatientMessagingUnenrollmentModal}
                    onHide={(wasSuccess = false) => {
                        setShowPatientMessagingUnenrollmentModal(false);
                        if (wasSuccess) {
                            refetch();
                        }
                    }}
                />
            )}
            {showPatientMessagingModal && (
                <PatientMessagingEnrollmentModal
                    mobilePhone={mobilePhone}
                    subscriptionValue={subscription}
                    patientId={patientId}
                    show={showPatientMessagingModal}
                    onHide={(wasSuccess = false) => {
                        setShowPatientMessagingModal(false);
                        if (wasSuccess) {
                            refetch();
                        }
                    }}
                />
            )}
            {showMessagingHistoryModal && (
                <PatientMessagingHistoryModal
                    show={showMessagingHistoryModal}
                    onHide={() => setShowMessagingHistoryModal(false)}
                    patientId={patientId}
                    divisionId={divisionId}
                />
            )}
            {showOneTimeMessage && (
                <OneTimeTextMessageModal
                    onClose={(template) => {
                        setShowOneTimeMessage(false);
                        if (template) {
                            setSelectedTemplate(template);
                        }
                    }}
                />
            )}
            {patientId && (
                <SendOneTimeTextModal
                    patientId={patientId}
                    onClose={() => {
                        setSelectedTemplate(null);
                    }}
                    selectedTemplate={selectedTemplate}
                />
            )}
        </div>
    );
};

PatientEngagement.propTypes = {
    patientDemographicsData: patientPropType.isRequired,
    patientMessagingSubscription: patientSubscriptionPropType,
    refetchMessagingSubscriptions: PropTypes.func.isRequired,
};

PatientEngagement.defaultProps = {
    patientMessagingSubscription: null,
};

export default PatientEngagement;
