import { useMemo } from 'react';
import * as R from 'ramda';
import { useUserContext } from 'App/contexts/UserContext';
import {
    ActivityDueDateCell,
    ActivityNameCell,
    ActivityStatusCell,
    LastUpdatedCell,
    PatientIdCell,
    PatientNameCell,
    MedicationAssociationInsuranceCell,
} from '../components/Cell';
import propOrDash from 'App/utils/propOrDash';

const displayIdMap = {
    external_id: 'externalId',
    other_id: 'otherId',
    ehr_id: 'ehrId',
};

export const useGetHeaderMapping = () => {
    const user = useUserContext();
    const displayedId = R.path(['company_permissions', 'DisplayedId'], user);

    const headerMapping = useMemo(
        () => ({
            patient_id: {
                sortName: Object.keys(displayIdMap).includes(displayedId) ? displayedId : 'human_id',
                accessor: propOrDash(displayIdMap[displayedId] || 'humanId'),
                canSort: true,
                filterName: displayIdMap[displayedId] || 'humanId',
                Cell: PatientIdCell,
            },
            patient_name: {
                canSort: true,
                Cell: PatientNameCell,
                filterName: 'patientName',
                sortName: 'patient_name',
            },
            medication: {
                canSort: false,
                filterName: 'medication',
            },
            activity_due_date: {
                accessor: propOrDash('dateDueSearch'),
                sortName: 'date_due',
                canSort: true,
                Cell: ActivityDueDateCell,
                filterName: 'dateDueSearch',
            },
            activity_name: {
                accessor: propOrDash('activityName'),
                canSort: true,
                Cell: ActivityNameCell,
                filterName: 'activityName',
            },
            patient_dob: {
                accessor: propOrDash('patientDob'),
                canSort: true,
                filterName: 'patientDob',
            },
            activity_status: {
                accessor: propOrDash('activityStatus'),
                canSort: true,
                Cell: ActivityStatusCell,
                filterName: 'activityStatus',
            },
            last_updated: {
                accessor: propOrDash('updatedOnDisplay'),
                canSort: true,
                sortName: 'updated_on',
                filterName: 'updatedName',
                Cell: LastUpdatedCell,
            },
            patient_labels: {
                accessor: propOrDash('patientLabels'),
                canSort: true,
                sortName: 'patient_labels',
                filterName: 'patientLabels',
            },
            activity_needs_by_date: {
                accessor: propOrDash('activity_needs_by_date'),
                canSort: true,
                sortName: 'needs_by',
                filterName: 'needsBySearch',
            },
            assigned_to: {
                /**
                 * The assignedTo is the ID, but the assignedToName is the display value
                 */
                accessor: propOrDash('assignedToName'),
                canSort: true,
                filterName: 'assignedTo',
            },
            branch_name: {
                accessor: propOrDash('branchName'),
                canSort: true,
                filterName: 'branchName',
            },
            patient_state: {
                accessor: propOrDash('patientState'),
                canSort: true,
                filterName: 'patientState',
            },
            patient_status: {
                accessor: propOrDash('patientStatus'),
                canSort: true,
                filterName: 'patientStatus',
            },
            insurance_plan_name: {
                accessor: propOrDash('insurancePlanName'),
                canSort: true,
                Cell: MedicationAssociationInsuranceCell,
                filterName: 'insurancePlanName',
            },
            activity_status_date: {
                accessor: propOrDash('activityStatusDate'),
                canSort: true,
                filterName: 'activityStatusDate',
            },
            medication_association: {
                accessor: propOrDash('medicationAssociation'),
                canSort: true,
                Cell: MedicationAssociationInsuranceCell,
                filterName: 'medicationAssociation',
            },
        }),
        [displayedId]
    );

    return headerMapping;
};
