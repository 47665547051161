import getBlockedDays from 'App/components/Assessments/utils/getBlockedDays';

/** @ngInject */
function AssessmentQuestionDateController(moment) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.doChangeAnswer = doChangeAnswer;
    ctrl.blockedDays = [];

    ctrl.dateOptions = {
        dateDisabled(data) {
            return data.mode === 'day' && ctrl.blockedDays.includes(data.date.getDay());
        },
    };

    function $onChanges(changes) {
        if (changes.fullyScriptedMode && ctrl.fullyScriptedMode !== undefined) {
            ctrl.fullyScriptedMode = angular.copy(ctrl.fullyScriptedMode);
        }

        if (changes.question && ctrl.question) {
            ctrl.question = angular.copy(ctrl.question);

            if (ctrl.question.response !== undefined) {
                ctrl.response = moment(ctrl.question.response, 'YYYY-MM-DDTHH:mm:ss').toDate();
                ctrl.dateValue = moment(ctrl.question.response, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
            }

            if (ctrl.question.attributes?.blockedDays) {
                ctrl.blockedDays = getBlockedDays(ctrl.question.attributes.blockedDays);
            }
        }
    }

    function doChangeAnswer() {
        const response = ctrl.response ? ctrl.response.toISOString() : '';
        ctrl.dateValue = ctrl.response ? moment(ctrl.response, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD') : '';
        const minResponseDate =
            moment(ctrl.response, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD') <
            moment('1900/01/01', 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
        if (ctrl.response && minResponseDate) {
            ctrl.model.$setValidity('date', !minResponseDate);
            ctrl.form.$setValidity('date', !minResponseDate);
            ctrl.onChangeAnswer({
                answer: '',
            });
        } else {
            ctrl.model.$setValidity('date', true);
            ctrl.form.$setValidity('date', true);
            ctrl.onChangeAnswer({
                answer: response,
            });
        }
    }
}

export default AssessmentQuestionDateController;
