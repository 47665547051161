import toast from 'Lib/toast';

/** @ngInject */
function PatientProfileEditDueDateModalController($http, $httpParamSerializerJQLike, moment, rmsService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.submit = submit;

    function $onChanges(changes) {
        if (changes.resolve && ctrl.resolve.activity) {
            ctrl.activity = angular.copy(ctrl.resolve.activity);
        }
    }

    function submit() {
        ctrl.saving = true;

        // https://bugs.mysql.com/bug.php?id=68760 need to zero out milliseconds so mysql doesn't round up :)
        ctrl.activity.date_due = moment.utc(ctrl.activity.date_due).endOf('day').millisecond(0).toDate();

        if (ctrl.activity.type_label === 'Referral') {
            ctrl.activity.due_on = ctrl.activity.date_due;

            return rmsService
                .updateActivity(ctrl.activity.patient_id, ctrl.activity.referral_id, ctrl.activity.id, ctrl.activity)
                .then((res) => {
                    toast.success('Activity due date successfully updated');
                    ctrl.close({ $value: res });
                    return res;
                })
                .catch((err) => {
                    toast.error('Activity due date did not update successfully. Please try again.');
                    ctrl.dismiss({ $value: null });
                    return err;
                });
        }
        // The activity_type does not have to be dynamic since this modal is only available to non-referral
        // activities
        return $http
            .post(
                '/stm/patient_activity.php',
                $httpParamSerializerJQLike({
                    new_date_due: ctrl.activity.date_due,
                    patient_id: ctrl.activity.patient_id,
                    activity_id: ctrl.activity.id,
                    activity_type: 0,
                    status: 1,
                    submit: 'Submit',
                }),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            )
            .then((res) => {
                toast.success('Activity due date successfully updated');
                ctrl.close({ $value: res });
                return res;
            })
            .catch((err) => {
                toast.error('Activity due date did not update successfully. Please try again.');
                ctrl.dismiss({ $value: null });
                return err;
            });
    }
}

export default PatientProfileEditDueDateModalController;
