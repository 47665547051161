import React, { useMemo, useState } from 'react';
import { useParams, generatePath, useHistory } from 'react-router-dom';
import Page from 'App/components/Page/Page';
import { Button, Row, Col } from 'react-bootstrap';
import PatientHeader from 'App/components/PatientHeader';
import { referralDetailRoute } from 'App/common/routeLookup';
import useFetchPatient from 'App/hooks/useFetchPatient';
import { PERMISSION_TYPES } from 'App/utils/isPermitted/isPermitted';
import { Formik } from 'formik';
import useFetchActivityDetails from 'App/hooks/useFetchActivityDetails';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import DeleteModal from 'App/components/DeleteModal';
import FAInformationForm from './components/FAInformationForm';
import { convertFAtoSave, convertFAtoView } from '../referral.utils';
import useUpdateReferralActivityMutation from 'App/hooks/useUpdateReferralActivityMutation';
import useCreateReferralActivityMutation from 'App/hooks/useCreateReferralActivityMutation';
import toast from 'Lib/toast';
import useDeleteReferralActivity from 'App/hooks/useDeleteReferralActivity';
import * as yup from 'yup';
import getFeatureFlagStatus from 'App/utils/getFeatureFlagStatus';
import BackToLinks from 'App/features/ReferralManager/Referral/BackToLinks';
import SubmitButton from 'Lib/form/SubmitButton';
import useCreateReferralActivityNoteMutation from 'App/hooks/useCreateReferralActivityNoteMutation';
import { STATUS_TYPE, disableActivityStatusList } from './constants';

const propTypes = {};

const defaultProps = {};

const validationSchema = yup.object().shape({
    details: yup.object().shape({
        followUpScheduleOption: yup.string().nullable(),
        effectiveEndDate: yup
            .date()
            .nullable()
            .when('followUpScheduleOption', {
                is: '14 Days Prior to Effective End Date',
                then: yup.date().when('fortnight', {
                    is: (fortnight) => !!fortnight,
                    then: yup
                        .date()
                        .min(
                            yup.ref('fortnight'),
                            'Effective End Date must be at least 14 days after today when 14 Days Prior to Effective End Date is selected'
                        ),
                    otherwise: yup.date().nullable(),
                }),
                otherwise: yup.date().when('followUpScheduleOption', {
                    is: (option) => option === 'Specific Date' || option === '30 Days After Completion Date',
                    then: yup
                        .date()
                        .required(
                            'Effective End Date must be at least 14 days after today when 14 Days Prior to Effective End Date is selected'
                        )
                        .nullable(),
                    otherwise: yup.date().nullable(),
                }),
            }),
        followUpDueDate: yup.date().nullable(),
    }),
});

function FinancialAssistance() {
    const { patientId, referralId, financialAssistanceId, typeId } = useParams();
    const { isLoading, data: patient } = useFetchPatient(patientId);
    const { data: activityDetails, isLoading: isActivityDetailsLoading } = useFetchActivityDetails(
        patientId,
        referralId,
        financialAssistanceId,
        typeId
    );
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const isReferralPMEnabled = getFeatureFlagStatus(PERMISSION_TYPES.FEATURE_REFERRAL_PROTOCOL_MANAGER);
    const updateReferralActivity = useUpdateReferralActivityMutation();
    const deleteReferralActivity = useDeleteReferralActivity();
    const createReferralActivityMutation = useCreateReferralActivityMutation();
    const createReferralActivityNoteMutation = useCreateReferralActivityNoteMutation(financialAssistanceId);
    const history = useHistory();

    const initialValues = useMemo(() => {
        if (activityDetails) {
            const FAtoView = convertFAtoView(activityDetails);
            const computedDisableActivity = disableActivityStatusList.includes(FAtoView?.status?.id);
            const computedDisableInputs = FAtoView?.status?.id === STATUS_TYPE.deleted;
            FAtoView.disableActivity = computedDisableActivity;
            FAtoView.disableInputs = computedDisableInputs;
            return FAtoView;
        }
        return {};
    }, [activityDetails]);

    const navigateToReferralDetailPage = () => {
        history.push(generatePath(referralDetailRoute, { patientId, referralId }));
    };

    const deleteActivity = () => {
        deleteReferralActivity
            .mutateAsync({
                patientId,
                referralId,
                activityId: financialAssistanceId,
            })
            .then(() => {
                navigateToReferralDetailPage();
            });
    };

    return (
        <Page
            title={typeId === '5' ? 'Financial Assistance Renewal' : 'Financial Assistance'}
            permissionType={PERMISSION_TYPES.ENABLE_REACT_FINANCIAL_ASSISTANCE}
            isLoading={isLoading || isActivityDetailsLoading}
        >
            {isLoading ? null : (
                <>
                    <PatientHeader patient={patient} showEhrStatus={false} />
                    <BackToLinks patientId={patientId} referralId={referralId} />
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        validateOnChange
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(true);
                            const { activityPayload, followUpActivityPayload } = convertFAtoSave(values);
                            const promiseArray = [
                                updateReferralActivity.mutateAsync({
                                    patientId,
                                    referralId,
                                    activityId: financialAssistanceId,
                                    data: activityPayload,
                                }),
                            ];
                            if (!isReferralPMEnabled && followUpActivityPayload) {
                                promiseArray.push(
                                    createReferralActivityMutation.mutateAsync({
                                        patientId,
                                        referralId,
                                        body: followUpActivityPayload,
                                    })
                                );
                            }

                            promiseArray.push(
                                createReferralActivityNoteMutation.mutateAsync({
                                    note: null,
                                    status: values.status.id,
                                })
                            );

                            return Promise.all(promiseArray).then(() => {
                                toast.success('Activity Information has been updated successfully');
                                navigateToReferralDetailPage();
                            });
                        }}
                    >
                        {({ isSubmitting, handleSubmit, isValid, values }) => {
                            const isFormReadOnly = values.disableActivity || values.disableInputs || isSubmitting;

                            return (
                                <form onSubmit={handleSubmit} noValidate>
                                    <FAInformationForm isFormReadOnly={isFormReadOnly} />
                                    <Row>
                                        <Col md={9}>
                                            <FormButtonGroup>
                                                <Button
                                                    bsStyle="danger"
                                                    disabled={isFormReadOnly}
                                                    onClick={() => setShowDeleteModal(true)}
                                                >
                                                    Delete
                                                </Button>
                                                <Button onClick={navigateToReferralDetailPage}>Cancel</Button>
                                                <SubmitButton disabled={!isValid || isFormReadOnly}>
                                                    Save Activity
                                                </SubmitButton>
                                            </FormButtonGroup>
                                        </Col>
                                    </Row>
                                </form>
                            );
                        }}
                    </Formik>

                    {showDeleteModal && (
                        <DeleteModal
                            onHide={() => setShowDeleteModal(false)}
                            show={showDeleteModal}
                            onDelete={deleteActivity}
                            title="Delete Activity"
                            message="Are you sure you want to delete this activity?"
                        />
                    )}
                </>
            )}
        </Page>
    );
}

FinancialAssistance.propTypes = propTypes;
FinancialAssistance.defaultProps = defaultProps;

export default FinancialAssistance;
