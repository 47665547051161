import { queryClient } from 'Lib/queryClient';
import { addPageAction } from 'App/services/NewRelicService';
import * as R from 'ramda';
import { isProd } from 'App/components/config/config';

/**
 * Fetches a dev feature flag.
 * @param flagName - The dev feature flag name.
 * @returns {boolean} - true or false.
 */
function getFeatureFlagStatus(flagName) {
    const flags = queryClient.getQueryData(['featureFlags']);

    if (flags === undefined) {
        console.error('Feature Flags were queried, but flags was not set.');
        addPageAction('feature-flag-queried-before-set');
        return false;
    }

    if (flags[flagName] === undefined) {
        addPageAction('feature-flag-queried-none-exist', { flagName });
        console.error(`Feature flags were queried for a flag ${isProd ? '' : `(${flagName})`} that does not exist.`);
        return false;
    }

    return R.compose(Boolean, R.propOr(false, flagName))(flags);
}

export default getFeatureFlagStatus;
