import angular from 'angular';

import ReferralManagerController from './referral.controller';
import ReferralConfig from './referral.config';
import ReferralActivitiesController from './activities/activities.controller';
import ReferralActivitiesEditDueDateModalController from './activities-edit-due-date-modal/activities-edit-due-date-modal.controller';
import ReferralAddActivityModalController from './activity-add-modal/activity-add-modal.controller';
import ReferralAppealActivityConfig from './activity-appeal/activity-appeal.config';
import ReferralAppealActivityController from './activity-appeal/activity-appeal.controller';
import ReferralActivityBenefitsInvestigationController from './activity-benefits-investigation/activity-benefits-investigation.controller';
import ReferralActivityBenefitsInvestigationConfig from './activity-benefits-investigation/activity-benefits-investigation.config';
import ReferralActivityFinancialAssistanceController from './activity-financial-assistance/activity-financial-assistance.controller';
import ReferralActivityFinancialAssistanceProgramTableController from './activity-financial-assistance/program-table/program-table.controller';
import ReferralActivityFinancialAssistanceConfig from './activity-financial-assistance/activity-financial-assistance.config';
import ProgramDetailModal from './activity-financial-assistance/program-detail-modal/program-detail-modal.controller';
import ReferralActivityHistoryController from './activity-history/activity-history.controller';
import ReferralActivityPriorAuthorizationController from './activity-prior-authorization/activity-prior-authorization.controller';
import ReferralActivityPriorAuthorizationConfig from './activity-prior-authorization/activity-prior-authorization.config';
import ReferralAddCommunicationModalController from './communication-add-modal/communication-add-modal.controller';
import ReferralCommunicationsController from './communications/communications.controller';
import ReferralHistoryController from './history/history.controller';
import ReferralInformationController from './information/information.controller';
import BenefitsInvestigationAddInsurancePlanModalController from './insurance-add-modal/insurance-add-modal.controller';
import ReferralAddMedicationModalController from './medication-add-modal/medication-add-modal.controller';
import ReferralMedicationsController from './medications/medications.controller';
import ReferralEditMedicationsModalController from './medications-edit-modal/medications-edit-modal.controller';

export default angular
    .module('components.referral', ['ngAnimate', 'ngTable', 'ui.router'])
    .component('referral', {
        bindings: {
            patient: '<',
            patientMedications: '<',
            patientRxs: '<',
            user: '<',
        },
        controller: ReferralManagerController,
        template: require('./referral.html'),
    })
    .config(ReferralConfig)
    .component('referralActivities', {
        bindings: {
            activities: '<',
            patient: '<',
            user: '<',
        },
        controller: ReferralActivitiesController,
        template: require('./activities/activities.html'),
    })
    .component('referralActivitiesEditDueDateModal', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: ReferralActivitiesEditDueDateModalController,
        template: require('./activities-edit-due-date-modal/activities-edit-due-date-modal.html'),
    })
    .component('referralAddActivityModal', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
            user: '<',
        },
        controller: ReferralAddActivityModalController,
        template: require('./activity-add-modal/activity-add-modal.html'),
    })
    .component('referralActivityAppeal', {
        bindings: {
            activity: '<',
            activities: '<',
            appealDocuments: '<',
            medications: '<',
            patient: '<',
            user: '<',
        },
        controller: ReferralAppealActivityController,
        template: require('./activity-appeal/activity-appeal.html'),
    })
    .config(ReferralAppealActivityConfig)
    .component('referralActivityBenefitsInvestigation', {
        bindings: {
            activity: '<',
            insurancePlans: '<',
            patient: '<',
            user: '<',
        },
        controller: ReferralActivityBenefitsInvestigationController,
        template: require('./activity-benefits-investigation/activity-benefits-investigation.html'),
    })
    .config(ReferralActivityBenefitsInvestigationConfig)
    .component('referralActivityFinancialAssistance', {
        bindings: {
            activity: '<',
            patient: '<',
            user: '<',
        },
        controller: ReferralActivityFinancialAssistanceController,
        template: require('./activity-financial-assistance/activity-financial-assistance.html'),
    })
    .config(ReferralActivityFinancialAssistanceConfig)
    .component('referralActivityHistory', {
        bindings: {
            activityId: '<',
            patientId: '<',
            referralId: '<',
        },
        controller: ReferralActivityHistoryController,
        template: require('./activity-history/activity-history.html'),
    })
    .component('programTable', {
        bindings: {
            programs: '<',
            headingText: '<',
            showRemove: '<',
            noRowsText: '<',
            selectProgram: '<',
            removeProgram: '<',
            showDetail: '<',
            isLoading: '<',
            shouldDisableAction: '<',
        },
        controller: ReferralActivityFinancialAssistanceProgramTableController,
        template: require('./activity-financial-assistance/program-table/program-table.html'),
    })
    .component('programDetailModal', {
        bindings: {
            close: '&',
            resolve: '<',
        },
        controller: ProgramDetailModal,
        template: require('./activity-financial-assistance/program-detail-modal/program-detail-modal.html'),
    })
    .component('referralActivityHistoryModal', {
        bindings: {
            close: '&',
            resolve: '<',
        },
        template: require('./activity-history-modal/activity-history-modal.html'),
    })
    .component('referralActivityPriorAuthorization', {
        bindings: {
            activity: '<',
            medications: '<',
            patient: '<',
            user: '<',
        },
        controller: ReferralActivityPriorAuthorizationController,
        template: require('./activity-prior-authorization/activity-prior-authorization.html'),
    })
    .config(ReferralActivityPriorAuthorizationConfig)
    .component('referralAddCommunicationModal', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: ReferralAddCommunicationModalController,
        template: require('./communication-add-modal/communication-add-modal.html'),
    })
    .component('referralCommunications', {
        bindings: {
            communications: '<',
        },
        controller: ReferralCommunicationsController,
        template: require('./communications/communications.html'),
    })
    .component('referralHistory', {
        bindings: {
            patientId: '<',
            referralId: '<',
        },
        controller: ReferralHistoryController,
        template: require('./history/history.html'),
    })
    .component('referralHistoryModal', {
        bindings: {
            close: '&',
            resolve: '<',
        },
        template: require('./history-modal/history-modal.html'),
    })
    .component('referralInformation', {
        bindings: {
            onChangeValue: '&',
            activities: '<',
            referral: '<',
            user: '<',
        },
        controller: ReferralInformationController,
        template: require('./information/information.html'),
    })
    .component('referralInsurance', {
        bindings: {
            disabled: '<',
            insurance: '=',
        },
        template: require('./insurance/insurance.html'),
    })
    .component('referralInsuranceAddModal', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: BenefitsInvestigationAddInsurancePlanModalController,
        template: require('./insurance-add-modal/insurance-add-modal.html'),
    })
    .component('referralAddMedicationModal', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: ReferralAddMedicationModalController,
        template: require('./medication-add-modal/medication-add-modal.html'),
    })
    .component('referralMedications', {
        bindings: {
            medications: '<',
            statusReasons: '<',
            user: '<',
        },
        controller: ReferralMedicationsController,
        template: require('./medications/medications.html'),
    })
    .component('referralEditMedicationsModal', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
            user: '<',
        },
        controller: ReferralEditMedicationsModalController,
        template: require('./medications-edit-modal/medications-edit-modal.html'),
    }).name;
