import * as R from 'ramda';
import { getData, stripAllHtml } from '../utils';
import * as PatientService from 'App/services/PatientService';

/** @ngInject */
function PesService(_, $http, $q, $window, CacheFactory, moment, __env, sessionStorageService, dateService) {
    const endpoint = `${__env.pesEndpoint}:${__env.pesPort}/pes/${__env.pesVersion}`;

    // Only create Cache for 'pesCache' if it has expired or was deleted
    if (!CacheFactory.get('pesCache')) {
        CacheFactory('pesCache', {
            maxAge: 15 * 60 * 1000, // Items added to this cache expire after 15 minutes
            cacheFlushInterval: 60 * 60 * 1000, // This cache will clear itself every hour
            deleteOnExpire: 'aggressive', // Items will be deleted from this cache when they expire
        });
    }

    return {
        associatedPatientAction,
        createSummaryNote,
        formatMedications,
        formatPatient,
        get,
        getAge,
        getAllergy,
        getAssociationQueue,
        getAssociationQueueEntry,
        getAssociations,
        getHighRiskReasons,
        getICD10,
        getMedication,
        getOtherCondition,
        getOtherMedication,
        getPatient,
        getPatientMessages,
        getPatientSubscriptions,
        getPrescriptions,
        getSummaryNotes,
        getTherapies,
        getTherapyMedications,
        update,
    };

    function _authorization() {
        return {
            headers: {
                Authorization: `Bearer ${sessionStorageService.getJwt()}`,
            },
        };
    }

    function associatedPatientAction(action, associationQueueId, patientId) {
        const url = `${endpoint}/associationQueue/${associationQueueId}/${action}/${patientId}`;
        const config = _authorization();
        return $http.patch(url, {}, config).then((res) => {
            return res.data;
        });
    }

    function createSummaryNote(
        patientId,
        activityId,
        clinicalIssues,
        sendToEmr,
        noteBody,
        noteSubject,
        noteType,
        noteParent
    ) {
        const config = _authorization();
        const url = `${endpoint}/patient/${patientId}/summaryNotes`;
        return $http
            .post(
                url,
                {
                    activityId,
                    body: stripAllHtml(noteBody),
                    clinicalIssues,
                    parent: _.isEmpty(noteParent) ? null : noteParent,
                    subject: noteSubject,
                    sendToEmr,
                    type: noteType,
                },
                config
            )
            .then((res) => {
                return res.data;
            });
    }

    function formatMedications(medications) {
        return _.reduce(
            medications,
            (result, value) => {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-plusplus
                for (let i = 0, len = value.length; i < len; i++) {
                    if (!Object.prototype.hasOwnProperty.call(result, value[i].medication.name)) {
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line no-param-reassign
                        result[value[i].medication.name] = [];
                    }
                    Array.prototype.push.apply(result[value[i].medication.name], value[i].rxs);
                }
                return result;
            },
            {}
        );
    }

    function formatPatient(patient) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        patient.birthDate = moment.utc(patient.birthDate).format('YYYY/MM/DD');
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        patient.welcomePacketReturned = moment.utc(patient.welcomePacketReturned).format('YYYY/MM/DD');
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        patient.welcomePacketSent = moment.utc(patient.welcomePacketSent).format('YYYY/MM/DD');
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        patient.updatedOn = dateService.localize(patient.updatedOn, 'YYYY/MM/DD h:mm a');
        return patient;
    }

    function get(patientId) {
        const url = `${endpoint}/patient/${patientId}`;
        const config = _authorization();
        return $http.get(url, config).then((res) => {
            return formatPatient(res.data);
        });
    }

    function getAge(birthDate, format) {
        if (!format) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            format = 'YYYY/MM/DD';
        }
        return moment().diff(moment(birthDate, format), 'years');
    }

    function getAllergy(patientId) {
        const url = `${endpoint}/patient/${patientId}/allergy`;
        const config = _authorization();
        return $http.get(url, config).then((res) => {
            return res.data;
        });
    }

    function getAssociationQueue() {
        const url = `${endpoint}/associationQueue`;
        const config = _authorization();
        return $http.get(url, config).then((res) => {
            return res.data;
        });
    }

    function getAssociationQueueEntry(id) {
        const url = `${endpoint}/associationQueue/${id}`;
        const config = _authorization();
        return $http.get(url, config).then((res) => {
            return res.data;
        });
    }

    function getAssociations(associationQueueId) {
        const url = `${endpoint}/associationQueue/${associationQueueId}/associations`;
        const config = _authorization();
        return $http.get(url, config).then((res) => {
            return res.data;
        });
    }

    function getHighRiskReasons(patientId) {
        return PatientService.getHighRiskReasons(patientId);
    }

    function getICD10(patientId, therapyId) {
        const url = `${endpoint}/patient/${patientId}/therapy/${therapyId}/icd10`;
        const config = _authorization();
        return $http.get(url, config).then((res) => {
            return res.data;
        });
    }

    function getMedication(patientId, queryParams) {
        const url = `${endpoint}/patient/${patientId}/medication`;
        const config = _authorization();
        if (queryParams) {
            config.params = queryParams;
        }
        return $http.get(url, config).then((res) => {
            return res.data;
        });
    }

    function getOtherCondition(patientId) {
        const url = `${endpoint}/patient/${patientId}/otherCondition`;
        const config = _authorization();
        return $http.get(url, config).then((res) => {
            return res.data;
        });
    }

    function getOtherMedication(patientId) {
        const url = `${endpoint}/patient/${patientId}/otherMedication`;
        const config = _authorization();
        return $http.get(url, config).then((res) => {
            return res.data;
        });
    }

    function getPatient(params) {
        const url = `${endpoint}/patient?`;
        const config = _authorization();
        config.params = params;

        return $http.get(url, config).then((res) => {
            return res.data;
        });
    }

    function getPatientMessages(patientId) {
        const url = `${endpoint}/patient/${patientId}/messages`;
        const config = _authorization();

        return $http.get(url, config).then((res) => {
            return res.data;
        });
    }

    function getPatientSubscriptions(patientId, subscriptionIds, activeOnly) {
        const url = `${endpoint}/patient/${patientId}/subscriptions`;
        const config = _authorization();
        config.params = {
            subscriptionIds,
            activeOnly,
        };
        return $http.get(url, config).then((res) => {
            return res.data;
        });
    }

    function getPrescriptions(patientId, includeEnded) {
        const url = `${endpoint}/patient/${patientId}/prescriptions`;
        const config = _authorization();
        config.params = {
            includeEnded,
        };
        return $http.get(url, config).then((res) => {
            return res.data;
        });
    }

    function getSummaryNotes(patientId, type) {
        /**
         * This is called on the preview page, and so will not have a patientId on those occasions
         */
        if (!patientId) {
            return Promise.resolve({});
        }
        const url = `${endpoint}/patient/${patientId}/summaryNotes`;
        const config = _authorization();
        if (type === 0) {
            config.params = {
                type: 'PCC',
            };
        } else if (type === 1) {
            config.params = {
                type: 'CLINICAL',
            };
        }
        return $http.get(url, config).then((res) => {
            return R.compose(R.map(R.over(R.lensProp('body'), R.compose(stripAllHtml))), getData)(res);
        });
    }

    function getTherapies(patientId) {
        const url = `${endpoint}/patient/${patientId}/therapy`;
        const config = _authorization();
        return $http.get(url, config).then((res) => {
            return res.data;
        });
    }

    function getTherapyMedications(patientId, therapyId, includeRx) {
        const url = `${endpoint}/patient/${patientId}/therapy/${therapyId}/medication`;
        const config = _authorization();
        config.params = {
            includeRx,
        };
        return $http.get(url, config).then((res) => {
            return res.data;
        });
    }

    function update(patientId, body) {
        const url = `${endpoint}/patient/${patientId}`;
        const config = _authorization();
        return $http.patch(url, body, config).then((res) => {
            return res.data;
        });
    }
}

export default PesService;
