import { isPermitted } from 'App/utils';
import { PERMISSION_TYPES } from 'App/utils/isPermitted/isPermitted';
import toast from 'Lib/toast';

/** @ngInject */
function ReferralEditMedicationsModalController($q, NgTableParams, patientService, rmsService, moment) {
    const ctrl = this;

    ctrl.$onChanges = $onChanges;
    ctrl.submit = submit;
    ctrl.$onInit = $onInit;
    ctrl.referralProtocolManagerEnabled = false;
    ctrl.getIcd10 = getIcd10;
    ctrl.getRxNumbers = getRxNumbers;
    ctrl.updateRxNumberId = updateRxNumberId;
    ctrl.doChangeAnswer = doChangeAnswer;

    function $onChanges(changes) {
        if (changes.resolve) {
            if (ctrl.resolve.user) {
                ctrl.user = angular.copy(ctrl.resolve.user);
            }
            if (ctrl.resolve.medications) {
                ctrl.medications = angular.copy(ctrl.resolve.medications);
                ctrl.originalMedications = angular.copy(ctrl.resolve.medications);

                ctrl.tableParams = new NgTableParams(
                    {
                        count: 5,
                        sorting: {
                            name: 'asc',
                        },
                    },
                    {
                        counts: [],
                        dataset: ctrl.medications,
                    }
                );

                if (ctrl.tableParams.total() > ctrl.tableParams.count()) {
                    ctrl.tableParams.settings({ counts: [5, 10, 25] });
                }
            }

            if (ctrl.resolve.statusReasons) {
                ctrl.statusReasons = angular.copy(ctrl.resolve.statusReasons);
            }
        }
    }

    function $onInit() {
        ctrl.referralProtocolManagerEnabled = isPermitted(
            PERMISSION_TYPES.FEATURE_REFERRAL_PROTOCOL_MANAGER,
            ctrl.user
        );
    }

    function submit() {
        ctrl.saving = true;
        const promises = [];

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = ctrl.medications.length; i < len; i++) {
            if (
                ctrl.originalMedications[i].status !== ctrl.medications[i].status ||
                ctrl.originalMedications[i].status_date.getTime() !== ctrl.medications[i].status_date.getTime() ||
                ctrl.originalMedications[i].icd_10 !== ctrl.medications[i].medication.icd10?.icd10Details?.DxCode ||
                ctrl.originalMedications[i].rx_number_id !== ctrl.medications[i].rx_number_id
            ) {
                promises.push(
                    rmsService.updateMedication(
                        ctrl.medications[i].patient_id,
                        ctrl.medications[i].referral_id,
                        ctrl.medications[i].id,
                        {
                            icd_10: ctrl.medications[i].medication.icd10?.icd10Details?.DxCode,
                            medication_id: ctrl.medications[i].medication_id,
                            rx_number_id: ctrl.medications[i].rx_number_id,
                            status: ctrl.medications[i].status,
                            status_date: ctrl.medications[i].status_date,
                        }
                    )
                );
            }

            if (
                ctrl.originalMedications[i].rx_number_id &&
                ctrl.originalMedications[i].rx &&
                ctrl.originalMedications[i].rx.rx_number !== ctrl.medications[i].rx.rx_number
            ) {
                promises.push(
                    patientService.updateRx(ctrl.medications[i].patient_id, ctrl.medications[i].rx_number_id, {
                        rx_number: ctrl.medications[i].rx.rx_number,
                    })
                );
            }
        }

        return $q
            .all(promises)
            .then((res) => {
                toast.success('The referral medications have been saved successfully.');
                ctrl.close({ $value: res });
                return res;
            })
            .catch((res) => {
                if (res.status === 404 || res.status === 403) {
                    toast.error(
                        'The referral was not found or you do not have permission to access it. ' +
                            'Please contact your STM administrator.'
                    );
                } else if (!res.status) {
                    toast.error(res);
                } else {
                    toast.error('Referral medications were not updated successfully. Please try again.');
                }
                ctrl.dismiss({ $value: ctrl.resolve });
                return res;
            })
            .finally(() => {
                ctrl.saving = false;
            });
    }
    function getIcd10(row) {
        const icd10Details = row.medication?.icd10?.icd10Details;
        if (icd10Details?.DxCode) {
            return `${icd10Details.DxCode} ${icd10Details.DxDescShort}`;
        }
        return row.icd_10 || '--';
    }
    function getRxNumbers(row) {
        const rxNumbers = row.medication?.rxNumbers;
        return rxNumbers?.length ? rxNumbers : [];
    }
    function updateRxNumberId(row) {
        const rxData = row.medication.rxNumbers[0];
        if (rxData) {
            // eslint-disable-next-line no-param-reassign
            row.rx_number_id = rxData.id;
            // Display the Rx Number in the span
            return rxData.rxNumber;
        }
        return null;
    }

    function doChangeAnswer(row) {
        const minStatusDate =
            moment(row.status_date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD') <
            moment('1900/01/01', 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
        if (row.status_date && minStatusDate) {
            ctrl.editMedicationsForm[`status_date_${row.id}`].$setValidity('date', !minStatusDate);
        } else {
            return row.status_date;
        }
    }
}

export default ReferralEditMedicationsModalController;
