import React, { useMemo } from 'react';
import { Row, Col, Form, Button, FormGroup, ControlLabel, FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap';
import BranchSelect from '../FindPatient/BranchSelect';
import CategorySelect from '../CategorySelect/CategorySelect';
import MedicationSelect from 'App/components/MedicationSelect';
import PatientStatus from '../AddPatient/Components/PatientStatus';
import PatientLabels from '../AddPatient/Components/PatientLabels';
import { Formik } from 'formik';
import styled from '@emotion/styled';
import GenderSelect from '../FindPatient/GenderSelect';
import SelectField from 'Lib/form/SelectField';
import * as R from 'ramda';
import HighRisk from './HighRisk';
import HighRiskReasons from './HighRiskReasons';
import { errorHandler } from 'App/utils';
import FindPatientTable from 'App/components/FindPatient/FindPatientTable';
import propOrDash from 'App/utils/propOrDash';
import PESService from 'App/services/PESService';
import delay from 'App/utils/delay';
import AccordionWrapper from '../AccordionWrapper/AccordionWrapper';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { sortBy } from 'lodash';
import LaddaButton from '../LaddaButton';

const propTypes = {
    addPatients: PropTypes.func,
};
const defaultProps = {
    addPatients: Function,
};

const propTypes2 = {
    cell: PropTypes.shape({
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line react/forbid-prop-types
        value: PropTypes.any,
    }),
};
const defaultProps2 = {
    cell: {
        value: null,
    },
};

const PaddedRow = styled(Row)`
    padding: 0.5% 0;
`;

const initialValues = {
    searchParams: {
        branch: null,
        category: null,
        medication: null,
        patientStatus: null,
        patientLabels: null,
        showDisabled: false,
        city: '',
        state: '',
        zip: '',
        gender: null,
        insurancePlanName: '',
        insurancePayerSegment: null,
        highRiskvalue: null,
        highRiskReasonvalue: null,
        sortKey: 'lastName',
        sortOrder: 'asc',
        count: 10,
        page: 0,
        isPatientSubscribed: true,
        additionalDisplayFields: [
            'therapeuticCategory',
            'branch',
            'medication',
            'insurancePayorType',
            'insurancePlanName',
            'patientLabels',
            'highRiskReasons',
        ],
    },

    insurancePayerSegmentOptions: [
        { id: 'Auto Insurance', value: 'Auto Insurance' },
        { id: 'Charity/Indigent Program', value: 'Charity/Indigent Program' },
        { id: 'Government', value: 'Government' },
        { id: 'HMO/Commercial', value: 'HMO/Commercial' },
        { id: 'Integrated PBM', value: 'Integrated PBM' },
        { id: 'Managed Medicaid', value: 'Managed Medicaid' },
        { id: 'Medicaid', value: 'Medicaid' },
        { id: 'Medicare', value: 'Medicare' },
        { id: 'Medicare A', value: 'Medicare A' },
        { id: 'Medicare Advantage (Part C)', value: 'Medicare Advantage (Part C)' },
        { id: 'Medicare B', value: 'Medicare B' },
        { id: 'Medicare D', value: 'Medicare D' },
        { id: 'None', value: 'None' },
        { id: 'Other', value: 'Other' },
        { id: 'PPO/Commercial', value: 'PPO/Commercial' },
        { id: 'Prisoners', value: 'Prisoners' },
        { id: 'Self-Pay', value: 'Self-Pay' },
        { id: 'Tri-Care', value: 'Tri-Care' },
        { id: 'Unknown', value: 'Unknown' },
        { id: 'Workers Comp', value: 'Workers Comp' },
    ],
    isAddPatientsDisabled: false,
    isAddPatientsLoading: false,
    lastSubmitSearchParam: {}, //store last submit param to use in case of pagination or sorting
    isSortOrPageChanged: false,
    data: {
        results: [],
        total: 0,
    },
};

const CustomCellDisplay = ({ cell }) => {
    let cellValue = !R.isNil(cell.value) ? cell.value : '--';
    cellValue = cell.value.length === 0 ? '--' : cellValue;
    cellValue = Array.isArray(cellValue) ? cellValue.join(', ') : cellValue;

    const tooltip = <Tooltip id="actions-tooltip">{cellValue}</Tooltip>;
    return cellValue.length > 25 ? (
        <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={tooltip}>
            <span>{`${cellValue.slice(0, 22)}...`}</span>
        </OverlayTrigger>
    ) : (
        cellValue
    );
};

const massTextSubscribedPatientColumns = [
    {
        Header: 'Last Name',
        accessor: propOrDash('lastName'),
        id: 'lastName',
        canSort: true,
    },
    {
        Header: 'First Name',
        accessor: propOrDash('firstName'),
        id: 'firstName',
        canSort: true,
    },
    {
        Header: 'City, State',
        accessor: propOrDash('city'),
        id: 'city',
        canSort: true,
    },
    {
        Header: 'Zip',
        accessor: propOrDash('zip'),
        id: 'zip',
        canSort: true,
    },
    {
        Header: 'Branch',
        accessor: propOrDash('branch'),
        id: 'branch',
        canSort: true,
    },
    {
        Header: 'Therapeutic Category',
        accessor: propOrDash('therapeuticCategory'),
        id: 'therapeuticCategory',
        canSort: true,
        Cell: CustomCellDisplay,
    },
    {
        Header: 'Medication',
        accessor: propOrDash('medication'),
        id: 'medication',
        canSort: true,
        Cell: CustomCellDisplay,
    },
    {
        Header: 'Patient Labels',
        accessor: propOrDash('patientLabels'),
        id: 'patientLabels',
        canSort: true,
        Cell: CustomCellDisplay,
    },
    {
        Header: 'High Risk Reasons',
        accessor: propOrDash('highRiskReasons'),
        id: 'highRiskReasons',
        canSort: true,
        Cell: CustomCellDisplay,
    },
    {
        Header: 'Insurance Plan Name',
        accessor: propOrDash('insurancePlanName'),
        id: 'insurancePlanName',
        canSort: true,
        Cell: CustomCellDisplay,
    },
    {
        Header: 'Insurance Payor Type',
        accessor: propOrDash('insurancePayorType'),
        id: 'insurancePayorType',
        canSort: true,
        Cell: CustomCellDisplay,
    },
    {
        Header: 'Actions',
        id: 'actions',
        canSort: false,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => {
            const actualId = R.path(['original', 'id'], row);
            return (
                <span>
                    <a href={`#!/patient/${actualId}`}>Profile</a>
                    <span style={{ margin: '2%' }}>|</span>
                    <a href={`#!/editPatient/${actualId}`}>Edit</a>
                </span>
            );
        },
    },
];
function SearchSubscribedPatients({ addPatients }) {
    const data = initialValues.insurancePayerSegmentOptions;
    const sortedInsurancePayerSegmentOptions = useMemo(() => {
        return sortBy(data, 'value');
    }, [data]);
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting, setFieldValue }) => {
                let params = { ...values.lastSubmitSearchParam };
                if (!values.isSortOrPageChanged) {
                    params = R.compose(
                        R.over(R.lensProp('branch'), R.propOr(null, 'id')),
                        R.over(R.lensProp('gender'), R.propOr(null, 'id')),
                        R.over(R.lensProp('category'), R.propOr(null, 'id')),
                        R.over(R.lensProp('condition'), R.propOr(null, 'id')),
                        R.over(R.lensProp('medication'), R.propOr(null, 'id')),
                        R.over(R.lensProp('page'), () => 0),
                        R.over(
                            R.lensProp('birthDate'),
                            R.ifElse(R.isNil, R.identity, (date) => dayjs(date).format('YYYY-MM-DD'))
                        )
                    )(values.searchParams);
                    setFieldValue('lastSubmitSearchParam', params);
                    setFieldValue('searchParams.page', 0);
                    setFieldValue('lastSubmitSearchParam.page', 0);
                }
                setFieldValue('isSortOrPageChanged', false);
                Promise.all([
                    PESService.findPatients(params),
                    /**
                     * Build in a small amount of delay so if
                     * the response is really fast, we don't get a flicker
                     * from the load spinner being added and removed really fast.
                     */
                    delay(300),
                ])
                    .then(([results]) => {
                        setFieldValue('data', results);
                    })
                    .catch(errorHandler)
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
        >
            {({
                handleChange,
                isSubmitting,
                setFieldValue,
                submitForm,
                handleSubmit,
                values,
                resetForm,
                // submitCount
            }) => {
                const handleSortChange = (sort) => {
                    setFieldValue('searchParams.sortKey', sort.sortKey);
                    setFieldValue('searchParams.sortOrder', sort.sortOrder);
                    setFieldValue('lastSubmitSearchParam.sortKey', sort.sortKey);
                    setFieldValue('lastSubmitSearchParam.sortOrder', sort.sortOrder);
                    setFieldValue('isSortOrPageChanged', true);

                    submitForm().then().catch(errorHandler);
                };

                const handlePageChange = (page) => {
                    setFieldValue('searchParams.count', page.pageSize);
                    setFieldValue('searchParams.page', page.pageIndex - 1);
                    setFieldValue('lastSubmitSearchParam.count', page.pageSize);
                    setFieldValue('lastSubmitSearchParam.page', page.pageIndex - 1);
                    setFieldValue('isSortOrPageChanged', true);

                    submitForm().then().catch(errorHandler);
                };

                const addAllPatients = async () => {
                    setFieldValue('isAddPatientsLoading', true);
                    setFieldValue('isAddPatientsDisabled', true);
                    const params = {
                        ...values.lastSubmitSearchParam,
                        count: values.data.total,
                        additionalDisplayFields: [],
                    };
                    const { results } = await PESService.findPatients(params);
                    addPatients(results.map((patient) => R.prop('id', patient)));
                    setFieldValue('isAddPatientsLoading', false);
                    setFieldValue('isAddPatientsDisabled', false);
                };

                return (
                    <>
                        <AccordionWrapper title="Search Filters">
                            <Form onSubmit={handleSubmit} onReset={resetForm}>
                                <PaddedRow>
                                    <Col md={2}>
                                        <BranchSelect
                                            onChange={(option) => {
                                                setFieldValue('searchParams.branch', option);
                                            }}
                                            value={values.searchParams.branch}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <CategorySelect
                                            fieldName="searchParams.category"
                                            onChange={(option) => {
                                                setFieldValue('searchParams.medication', null);
                                                setFieldValue('searchParams.category', option);
                                            }}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <MedicationSelect
                                            fieldName="searchParams.medication"
                                            therapyId={values.searchParams.category?.id || null}
                                            onChange={(medication) => {
                                                setFieldValue('searchParams.medication', medication);
                                            }}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <PatientStatus
                                            value={values.searchParams.patientStatus}
                                            onChange={(patientStatus) => {
                                                setFieldValue('searchParams.patientStatus', patientStatus);
                                            }}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <PatientLabels
                                            value={values.searchParams.patientLabels}
                                            onChange={(patientLabels) => {
                                                setFieldValue('searchParams.patientLabels', patientLabels);
                                            }}
                                        />
                                    </Col>
                                </PaddedRow>
                                <PaddedRow>
                                    <Col md={2}>
                                        <FormGroup>
                                            <ControlLabel htmlFor="searchParams.city">City</ControlLabel>
                                            <FormControl
                                                id="searchParams.city"
                                                name="searchParams.city"
                                                type="text"
                                                label="City"
                                                placeholder="City"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                value={values.searchParams.city}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                            <ControlLabel htmlFor="searchParams.state">State</ControlLabel>
                                            <FormControl
                                                id="searchParams.state"
                                                name="searchParams.state"
                                                type="text"
                                                label="State"
                                                placeholder="State"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                value={values.searchParams.state}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                            <ControlLabel htmlFor="searchParams.zip">Zip</ControlLabel>
                                            <FormControl
                                                id="searchParams.zip"
                                                name="searchParams.zip"
                                                type="text"
                                                label="Zip"
                                                placeholder="Zip"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                value={values.searchParams.zip}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <GenderSelect label="Sex" />
                                    </Col>
                                    <Col md={2} />
                                </PaddedRow>
                                <PaddedRow>
                                    <Col md={2}>
                                        <FormGroup>
                                            <ControlLabel htmlFor="searchParams.insurancePlanName">
                                                Insurance Plan Name
                                            </ControlLabel>
                                            <FormControl
                                                id="searchParams.insurancePlanName"
                                                name="searchParams.insurancePlanName"
                                                type="text"
                                                label="Insurance Plan Name"
                                                placeholder="Insurance Plan Name"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                value={values.searchParams.insurancePlanName}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <SelectField
                                            fieldName="searchParams.insurancePayerSegment"
                                            options={sortedInsurancePayerSegmentOptions}
                                            label="Insurance Payor Type"
                                            getOptionValue={R.prop('id')}
                                            getOptionLabel={R.prop('value')}
                                            isClearable
                                            onChange={(option) => {
                                                setFieldValue('searchParams.insurancePayerSegment', option);
                                            }}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <HighRisk
                                            highRiskvalue={values.searchParams.highRiskvalue}
                                            onChange={(value) => {
                                                setFieldValue('searchParams.highRiskvalue', value);
                                            }}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <HighRiskReasons
                                            highRiskvalue={values.searchParams.highRiskvalue}
                                            highRiskReasonvalue={values.searchParams.highRiskReasonvalue}
                                            onChange={(value) => {
                                                setFieldValue('searchParams.highRiskReasonvalue', value);
                                            }}
                                        />
                                    </Col>
                                </PaddedRow>

                                <PaddedRow>
                                    <Col mdOffset={6} md={6}>
                                        <div className="form-button-group">
                                            <Button
                                                type="button"
                                                disabled={isSubmitting}
                                                onClick={() => {
                                                    setFieldValue('searchParams', initialValues.searchParams);
                                                }}
                                                aria-label="reset-get-subscribed-patients"
                                            >
                                                Reset
                                            </Button>
                                            <Button
                                                type="submit"
                                                bsStyle="primary"
                                                disabled={isSubmitting}
                                                aria-label="search-subscribed-patients"
                                            >
                                                Search
                                            </Button>
                                        </div>
                                    </Col>
                                </PaddedRow>
                            </Form>
                        </AccordionWrapper>
                        <AccordionWrapper title="Search Results">
                            <>
                                <FindPatientTable
                                    data={values.data.results}
                                    totalRows={values.data.total}
                                    onPageChange={handlePageChange}
                                    onSortChange={handleSortChange}
                                    currentPage={values.searchParams.page + 1}
                                    columns={massTextSubscribedPatientColumns}
                                />
                                <PaddedRow id="TableAction">
                                    <Col mdOffset={6} md={6}>
                                        <div className="form-button-group">
                                            <LaddaButton
                                                bsStyle="primary"
                                                name="send"
                                                className="float-right"
                                                disabled={
                                                    values.isAddPatientsDisabled || values.data.results.length === 0
                                                }
                                                isLoading={values.isAddPatientsLoading}
                                                onClick={addAllPatients}
                                            >
                                                Add
                                            </LaddaButton>
                                        </div>
                                    </Col>
                                </PaddedRow>
                            </>
                        </AccordionWrapper>
                    </>
                );
            }}
        </Formik>
    );
}

SearchSubscribedPatients.propTypes = propTypes;
SearchSubscribedPatients.defaultProps = defaultProps;
CustomCellDisplay.propTypes = propTypes2;
CustomCellDisplay.defaultProps = defaultProps2;
export default SearchSubscribedPatients;
