import { isPermitted } from 'App/utils';
import CompanyService from '../../services/CompanyService';
import PESService from 'App/services/PESService';
import { setPatientIdentifiers } from 'App/utils/setPatientIdentifiers';
import { PERMISSION_TYPES } from 'App/utils/isPermitted/isPermitted';
import * as R from 'ramda';
import hasLength from 'App/utils/hasLength';

/** @ngInject */
function ReferralManagerController(_, $scope, $state, $uibModal, rmsService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.addActivity = addActivity;
    ctrl.addCommunication = addCommunication;
    ctrl.addMedication = addMedication;
    ctrl.editMedications = editMedications;
    ctrl.viewReferralHistory = viewReferralHistory;
    ctrl.referralProtocolManagerEnabled = false;

    $scope.$on('updateReferralActivities', (event, data) => {
        rmsService.getActivities(data.patient_id, data.referral_id).then((res) => {
            ctrl.activities = angular.copy(res);
            return res;
        });
    });

    function $onChanges(changes) {
        if (changes.patientMedications && ctrl.patientMedications) {
            ctrl.patientMedications = angular.copy(ctrl.patientMedications);
        }

        if (changes.patientRxs && ctrl.patientRxs) {
            ctrl.patientRxs = angular.copy(ctrl.patientRxs);
        }

        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
        }
    }

    function $onInit() {
        ctrl.patientId = $state.params.patientId;
        ctrl.referralId = $state.params.referralId;
        ctrl.referralProtocolManagerEnabled = isPermitted(
            PERMISSION_TYPES.FEATURE_REFERRAL_PROTOCOL_MANAGER,
            ctrl.user
        );

        rmsService
            .get(ctrl.patientId, ctrl.referralId)
            .then((res) => {
                ctrl.referral = res;

                _getActivities();

                rmsService
                    .getCommunications(ctrl.patientId, ctrl.referralId)
                    .then((comms) => {
                        ctrl.communications = comms;
                    })
                    .catch(() => {
                        ctrl.communications = false;
                    });

                rmsService
                    .getMedications(ctrl.patientId, ctrl.referralId)
                    .then((meds) => {
                        ctrl.medications = _.uniqBy(meds, (m) => {
                            return m.medication_id + m.rx_number_id;
                        });
                    })
                    .catch(() => {
                        ctrl.medications = false;
                    });

                ctrl.statusReasons = CompanyService.getCustomMenu('referral_medication_status_reason');

                return ctrl.referral;
            })
            .catch(() => {
                ctrl.referral = false;
                return ctrl.referral;
            });

        PESService.getPatientReferral(ctrl.patientId)
            .then((res) => {
                ctrl.patientReferral = !R.isEmpty(res);
                return res;
            })
            .catch((err) => {
                ctrl.patientReferral = false;
                return err;
            });

        return setPatientIdentifiers(ctrl).catch((err) => {
            ctrl.displayedId = '';
            return err;
        });
    }

    $scope.$on('patientActivitiesUpdated', () => {
        _getActivities();
    });

    function _getActivities() {
        return rmsService
            .getActivities(ctrl.patientId, ctrl.referralId)
            .then((activities) => {
                ctrl.activities = R.map((activity) => {
                    const { notes } = activity;
                    if (hasLength(notes)) {
                        const latestNoteStatus = R.compose(R.prop('status'), R.last)(notes);
                        return R.assoc('note_status', latestNoteStatus, activity);
                    }
                    return R.assoc('note_status', activity.status, activity);
                })(activities);
            })
            .catch(() => {
                ctrl.activities = false;
            });
    }

    function addActivity() {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'referralAddActivityModal',
            windowClass: 'referral-add-activity-modal',
            keyboard: false,
            resolve: {
                user() {
                    return ctrl.user;
                },
                rawActivities() {
                    return ctrl.activities;
                },
                activities() {
                    return _.reduce(
                        ctrl.activities,
                        (result, value) => {
                            const next = result;
                            if (['Pending', 'New'].includes(value.status)) {
                                next[value.type] = next[value.type].concat(_.map(value.medications, 'medication_id'));
                                // For appeals, get the Prior Auth activity ids
                                if (value.type === 3) {
                                    next[value.type].push(value.details.pa_activity_id);
                                }
                                if (value.type === 5) {
                                    next[value.type].push(value.details.fa_activity_id);
                                }
                            }
                            return next;
                        },
                        { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [] }
                    );
                },
                deniedPriorAuthorizations() {
                    //prettier-ignore
                    return R.filter(
                        R.allPass([
                                R.propSatisfies(R.includes(R.__, [1,4]), 'type'),
                                R.anyPass([
                                    R.propEq('Denied', 'status'),
                                    R.propEq('Appealed - Upheld', 'status'),
                                ])
                            ]
                        )
                    )(ctrl.activities);
                },
                approvedFinancialAssistances() {
                    //prettier-ignore
                    return R.filter(
                        R.allPass([
                                R.propSatisfies(R.includes(R.__, [2,5]), 'type'),
                                R.anyPass([
                                    R.propEq('Approved', 'status'),
                                ])
                            ]
                        )
                    )(ctrl.activities);
                },
                approvedPriorAuthorizations() {
                    return R.filter(
                        R.allPass([
                            R.propSatisfies(R.includes(R.__, [1, 4]), 'type'),
                            R.anyPass([R.propEq('Approved', 'status')]),
                        ])
                    )(ctrl.activities);
                },
                medications() {
                    return _.uniqBy(ctrl.medications, 'medication_id');
                },
                referral() {
                    return ctrl.referral;
                },
            },
        });

        modalInstance.result
            .then((res) => {
                if (res) {
                    return _getActivities();
                }

                return res;
            })
            .catch((err) => {
                return err;
            });
    }

    function addCommunication() {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            size: 'lg',
            component: 'referralAddCommunicationModal',
            keyboard: false,
            resolve: {
                patientId() {
                    return ctrl.patientId;
                },
                referralId() {
                    return ctrl.referralId;
                },
            },
        });

        modalInstance.result
            // eslint-disable-next-line consistent-return
            .then((res) => {
                if (res) {
                    return rmsService.getCommunications(ctrl.patientId, ctrl.referralId).then((communications) => {
                        ctrl.communications = angular.copy(communications);
                        return ctrl.communications;
                    });
                }
            })
            .catch((err) => {
                return err;
            });
    }

    function addMedication() {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'referralAddMedicationModal',
            size: 'lg',
            keyboard: false,
            resolve: {
                modalData() {
                    const patientMedicationRxs = R.reduce(
                        (result, patientMedications) => {
                            return result.concat(patientMedications.prescriptions);
                        },
                        [],
                        ctrl.patientMedications
                    );

                    return {
                        patientMedicationRxs: _.keyBy(patientMedicationRxs, 'id'),
                        patientRxs: ctrl.patientRxs,
                        medications: ctrl.medications,
                    };
                },
            },
        });

        modalInstance.result
            .then((res) => {
                if (res) {
                    return rmsService.getMedications(ctrl.patientId, ctrl.referralId).then((medications) => {
                        ctrl.medications = angular.copy(medications);
                    });
                }

                return res;
            })
            .catch((err) => {
                return err;
            });
    }

    function editMedications() {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            size: 'lg',
            component: 'referralEditMedicationsModal',
            keyboard: false,
            resolve: {
                medications() {
                    return ctrl.medications;
                },
                user() {
                    return ctrl.user;
                },
                statusReasons() {
                    return CompanyService.getCustomMenu('referral_medication_status_reason');
                },
            },
        });

        modalInstance.result
            // eslint-disable-next-line consistent-return
            .then((res) => {
                if (res) {
                    return rmsService.getMedications(ctrl.patientId, ctrl.referralId).then((medications) => {
                        ctrl.medications = angular.copy(medications);
                        return ctrl.medications;
                    });
                }
            })
            .catch((err) => {
                return err;
            });
    }

    function viewReferralHistory() {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            size: 'lg',
            component: 'referralHistoryModal',
            keyboard: false,
            resolve: {
                patientId() {
                    return ctrl.patientId;
                },
                referralId() {
                    return ctrl.referralId;
                },
            },
        });

        modalInstance.result.then((value) => {
            return value;
        });
    }
}

export default ReferralManagerController;
