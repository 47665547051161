import React from 'react';
import rhToast, { ToastBar as RHToastBar, Toaster as RHToaster } from 'react-hot-toast';
import styleVars from '@/sass/base/_vars.scss';
import styled from '@emotion/styled';

const commonConfig = {
    duration: 10000,
    className: 'react-hot-toast-toast',
    style: {
        border: `2px solid ${styleVars.brandPrimary}`,
    },
    iconTheme: {
        primary: styleVars.brandPrimary,
        secondary: styleVars.snow,
    },
    success: {
        style: {
            border: `2px solid ${styleVars.brandSuccess}`,
        },
        iconTheme: {
            primary: styleVars.brandSuccess,
            secondary: styleVars.snow,
        },
    },
    error: {
        style: {
            border: `2px solid ${styleVars.brandDanger}`,
        },
        iconTheme: {
            primary: styleVars.brandDanger,
            secondary: styleVars.snow,
        },
    },
};

const StyledToast = styled('div')`
    margin: 0;
    padding: 0;
    max-width: 28rem;
    width: 100%;
    background-color: rgb(255 255 255 / 100%);
    display: flex;
    animation: enter 0.2s ease-out;
    animation: leave 0.15s ease-in forwards;

    .message-container {
        flex: 1 1 0%;
        width: 0;
        padding: 1rem;
    }

    .icon-container-outer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .icon-container-inner {
        flex-shrink: 0;
        padding-top: 0.125rem;
    }

    .message {
        margin-left: 0.75rem;
        align-items: center;
        display: flex;
        flex: 1;
    }

    .button-container {
        display: flex;
        border-left: 1px solid var(--dove);
    }

    button {
        display: flex;
        width: 100%;
        height: 100%;
        border-width: 1px;
        border-color: transparent;
        border-radius: 0;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        align-items: center;
        padding: 1rem;
        margin: 0;
        margin-left: 0.5rem;
        justify-content: center;
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: 500;
        outline: 2px solid transparent;
        outline-offset: 2px;
        background-color: transparent;

        &:hover {
            color: rgb(107 114 128 100%);
        }
    }
`;

const toast = {
    success(message) {
        rhToast.success(message);
    },

    error(message) {
        return rhToast.error(message);
    },
};

function Toaster() {
    return (
        <RHToaster position="top-right" gutter={8} containerClassName="react-hot-toast" toastOptions={commonConfig}>
            {(t) => {
                return (
                    <RHToastBar toast={t}>
                        {({ icon, message }) => {
                            return (
                                <StyledToast>
                                    <div className="icon-container-outer">
                                        <div className="icon-container-inner">{icon}</div>
                                    </div>
                                    <div className="message">{message}</div>
                                    <div className="button-container">
                                        <button type="button" onClick={() => rhToast.dismiss(t.id)}>
                                            X
                                        </button>
                                    </div>
                                </StyledToast>
                            );
                        }}
                    </RHToastBar>
                );
            }}
        </RHToaster>
    );
}

export { Toaster };

export default toast;
