import React from 'react';
import useIsPermitted from 'App/utils/hooks/useIsPermitted';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import NotFound from 'App/components/errors/NotFound';
import { HashRouter, Route, Switch } from 'react-router-dom';
import RouteWithHeader from 'App/components/RouteWithHeader';
import { Helmet } from 'react-helmet';
import { catchPhrase } from 'App/components/config/config';
import AngularRootReactComponent from '@/angular-root';
import ReferralManagerHome from '../ReferralManagerHome';
import Page from 'App/components/Page';
import WithHeader from 'App/components/WithHeader';
import {
    referralAppealRoute,
    referralBenefitsInvestigation,
    referralDetailRoute,
    referralManagerRoute,
    referralPriorAuthorization,
    referralFinancialAssistance,
} from 'App/common/routeLookup';
import ReferralDetail from '../Referral/ReferralDetail';
import PriorAuthorization from '../Referral/PriorAuthorization';
import AppealPage from '../Referral/AppealPage';
import FinancialAssistance from '../Referral/FinancialAssistance/FinancialAssistance';
import BenefitsInvestigation from '../Referral/BenefitsInvestigation';

const propTypes = {};

const defaultProps = {};

function Router() {
    const hasReferralMgr = useIsPermitted(PERMISSION_TYPES.REFERRAL_MANAGER);
    const hasReactReferralDetailPage = useIsPermitted(PERMISSION_TYPES.ENABLE_REACT_REFERRAL_DETAIL);
    const hasReactPriorAuthorizationPage = useIsPermitted(PERMISSION_TYPES.ENABLE_REACT_REFERRAL_PRIOR_AUTHORIZATION);
    const isReactAppealEnabled = useIsPermitted(PERMISSION_TYPES.ENABLE_REACT_REFERRAL_APPEAL);
    const hasReactFinancialAssistancePage = useIsPermitted(PERMISSION_TYPES.ENABLE_REACT_FINANCIAL_ASSISTANCE);
    const hasReactBenefitsInvestigationPage = useIsPermitted(
        PERMISSION_TYPES.ENABLE_REACT_REFERRAL_BENEFITS_INVESTIGATION
    );

    if (hasReferralMgr) {
        return (
            <HashRouter hashType="hashbang">
                <Switch>
                    <RouteWithHeader path={`${referralManagerRoute}`} component={ReferralManagerHome} exact />
                    {hasReactReferralDetailPage && (
                        <RouteWithHeader path={`${referralDetailRoute}`} component={ReferralDetail} exact />
                    )}
                    {hasReactPriorAuthorizationPage && (
                        <RouteWithHeader path={`${referralPriorAuthorization}`} component={PriorAuthorization} exact />
                    )}

                    {isReactAppealEnabled && (
                        <RouteWithHeader path={`${referralAppealRoute}`} component={AppealPage} exact />
                    )}

                    {hasReactFinancialAssistancePage && (
                        <RouteWithHeader
                            path={`${referralFinancialAssistance}`}
                            component={FinancialAssistance}
                            />
                    )}
                    {hasReactBenefitsInvestigationPage && (
                        <RouteWithHeader
                            path={`${referralBenefitsInvestigation}`}
                            component={BenefitsInvestigation}
                            exact
                        />
                    )}
                    <Route>
                        <Helmet>
                            <title>{catchPhrase}</title>
                        </Helmet>

                        <AngularRootReactComponent />
                    </Route>
                </Switch>
            </HashRouter>
        );
    }

    return (
        <WithHeader>
            <Page title="Not Found" permissionType={PERMISSION_TYPES.EVERYONE} showPageTitle={false}>
                <NotFound />
            </Page>
        </WithHeader>
    );
}

Router.propTypes = propTypes;
Router.defaultProps = defaultProps;

export default Router;
