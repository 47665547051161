import angular from 'angular';

import UserManagerController from './user-manager.controller';
import UserManagerConfig from './user-manager.config';

export default angular
    .module('components.userManager', ['ngAnimate', 'ui.router'])
    .component('userManager', {
        bindings: {
            user: '<',
            users: '<',
        },
        controller: UserManagerController,
        template: require('./user-manager.html'),
    })
    .config(UserManagerConfig).name;
