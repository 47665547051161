import angular from 'angular';

import QueueManagerController from './queue-manager.controller';
import QueueManagerConfig from './queue-manager.config';

export default angular
    .module('components.queueManager', ['ngMessages', 'ngTable'])
    .component('queueManager', {
        bindings: {
            branches: '<',
            user: '<',
        },
        controller: QueueManagerController,
        template: require('./queue-manager.html'),
    })
    .config(QueueManagerConfig).name;
