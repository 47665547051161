import { useFormikContext } from 'formik';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import createLabelValueOption from 'App/utils/createLabelValueOption';
import useFetchRxnconsoAutocomplete from '../hooks/useFetchRxnconsoAutocomplete';
import PatientFormTypeaheadField from './PatientFormTypeaheadField';

const OtherMedicationTypeaheadField = ({ fieldName, ...rest }) => {
    const { values } = useFormikContext();
    const fieldValue = get(values, fieldName);
    const { data: options = {}, isLoading } = useFetchRxnconsoAutocomplete(fieldValue);
    const optionsList = Object.values(options).map((o) => createLabelValueOption(o.Str));

    return (
        <PatientFormTypeaheadField
            {...rest}
            fieldName={fieldName}
            options={optionsList}
            isLoading={isLoading}
            label="Other Medication"
            placeholder="Other Medication"
        />
    );
};

OtherMedicationTypeaheadField.propTypes = {
    fieldName: PropTypes.string.isRequired,
};

export default OtherMedicationTypeaheadField;
