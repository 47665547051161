import { useQuery } from 'react-query';

import { search } from 'App/services/RxnconsoService';

function useFetchRxnconsoAutocomplete(inputValue) {
    return useQuery(['rxnconsoAutocomplete', inputValue], () => search(inputValue), {
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
        staleTime: 60 * 1000,
    });
}

export default useFetchRxnconsoAutocomplete;
