import { useMutation, useQueryClient } from 'react-query';
import { updateReferralActivity } from '@/app/services/RMSService';

function useUpdateReferralActivityMutation() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ patientId, referralId, activityId, data }) => {
            return updateReferralActivity(patientId, referralId, activityId, data);
        },
        {
            onSuccess: async (referralActivity) => {
                /**
                 * Delete the cache for this so it will repull on its own.
                 */
                queryClient.removeQueries([
                    'patient',
                    referralActivity.patientId,
                    'referral',
                    String(referralActivity.referralId),
                    'activity',
                    String(referralActivity.id),
                ]);
            },
        }
    );
}

export default useUpdateReferralActivityMutation;
