import PropTypes from 'prop-types';
import React from 'react';
import { Link, generatePath } from 'react-router-dom';

import { unassignedPatientRoute } from 'App/common/routeLookup';
import AccordionWrapper from 'App/components/AccordionWrapper/AccordionWrapper';
import ManualPageTable from 'App/components/ManualPageTable';
import { formatUtcDate, isoDateOnlyFormat } from 'App/services/DateService';
import SessionStorageService from 'App/services/SessionStorageService';

import useFetchAssociationQueue from '../../hooks/useFetchAssociationQueue';

const PatientIDLinkCell = ({ value, row }) => {
    return (
        <Link to={generatePath(unassignedPatientRoute, { associationQueueId: value })}>
            {row.original.message.ExternalId || '--'}
        </Link>
    );
};
PatientIDLinkCell.propTypes = {
    value: PropTypes.string.isRequired,
    row: PropTypes.shape({
        original: PropTypes.shape({
            message: PropTypes.shape({
                ExternalId: PropTypes.string,
            }),
        }),
    }).isRequired,
};

const BirthDateCell = ({ value }) => {
    return formatUtcDate({ date: value, format: isoDateOnlyFormat });
};

const columns = [
    {
        Header: 'Patient ID',
        accessor: 'uuid',
        Cell: PatientIDLinkCell,
    },
    {
        Header: 'Last Name',
        accessor: 'message.LastName',
    },
    {
        Header: 'First Name',
        accessor: 'message.FirstName',
    },
    {
        Header: 'Patient DOB',
        accessor: 'message.BirthDate',
        Cell: BirthDateCell,
    },
    {
        Header: 'Patient Zip',
        accessor: 'message.Location.Zip',
    },
    {
        Header: 'Status',
        accessor: () => 'Pending',
    },
];

const UnassignedPatientTable = () => {
    const { data } = useFetchAssociationQueue();
    const dataList = Object.values(data || {});

    if (dataList.length === 0) {
        return null;
    }

    return (
        <AccordionWrapper
            title="Unassigned Patients"
            initialVisible={SessionStorageService.getOnUser('unassignedPatientActivity-toggle', true)}
            onClick={(newValue) => SessionStorageService.setOnUser('unassignedPatientActivity-toggle', newValue)}
        >
            <ManualPageTable columns={columns} data={dataList} pageSizeOptions={[]} />
        </AccordionWrapper>
    );
};

export default UnassignedPatientTable;
