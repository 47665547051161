import React from 'react';
import mountReactComponent from 'App/utils/mountReactComponent';
import ReferralInformationForm from '@/app/components/ReferralInformationForm';
import CompanyService from '../../../services/CompanyService';
import { isPermitted } from 'App/utils';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import toast from 'Lib/toast';

/** @ngInject */
function ReferralInformationController(_, $scope, $state, rmsService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.doChangeValue = doChangeValue;
    ctrl.resetForm = resetForm;
    ctrl.updateReferral = updateReferral;

    function $onChanges(changes) {
        if (changes.referral && ctrl.referral) {
            ctrl.original = angular.copy(ctrl.referral);
            ctrl.referral = angular.copy(ctrl.referral);

            if (!ctrl.referral.status) {
                ctrl.referral.status = 'Pending';
            }

            if (!ctrl.referral.status_reason) {
                ctrl.referral.status_reason = 'New Referral';
            }

            if (!ctrl.referral.updated_on) {
                ctrl.referral.updated_on = new Date();
            }
        }

        if (changes.activities && ctrl.activities) {
            ctrl.activities = angular.copy(ctrl.activities);
        }

        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
        }
    }

    function $onInit() {
        ctrl.isFeatureNoGoEnabled = isPermitted(PERMISSION_TYPES.FEATURE_REFERRAL_NO_GO, {});

        ctrl.referral_dispense_status = null;
        ctrl.referral_dispense_status_reason = null;
        ctrl.referral_received_from = null;
        ctrl.referral_status_reasons = {};

        const referralStatusReasonsComplete = CompanyService.getCustomMenu('referral_status_reasons_complete');
        ctrl.referral_status_reasons.Complete = _.filter(referralStatusReasonsComplete, 'valueName');

        const referralStatusReasonsHold = CompanyService.getCustomMenu('referral_status_reasons_hold');
        ctrl.referral_status_reasons.Hold = _.filter(referralStatusReasonsHold, 'valueName');

        const referralStatusReasonsNoGo = CompanyService.getCustomMenu('referral_status_reasons_no_go');
        ctrl.referral_status_reasons['No Go'] = _.filter(referralStatusReasonsNoGo, 'valueName');

        const referralStatusReasonsPending = CompanyService.getCustomMenu('referral_status_reasons_pending');
        ctrl.referral_status_reasons.Pending = _.filter(referralStatusReasonsPending, 'valueName');

        const referralDispenseStatus = CompanyService.getCustomMenu('referral_dispense_status');
        ctrl.referral_dispense_status = _.filter(referralDispenseStatus, 'valueName');

        const referralDispenseStatusReason = CompanyService.getCustomMenu('referral_dispense_status_reason');
        ctrl.referral_dispense_status_reason = _.filter(referralDispenseStatusReason, 'valueName');

        const referralReceivedFrom = CompanyService.getCustomMenu('referral_received_from');
        ctrl.referral_received_from = _.filter(referralReceivedFrom, 'valueName');

        const referralStatus = CompanyService.getCustomMenu('referral_status');
        ctrl.referral_status = _.filter(referralStatus, 'valueName');

        if (ctrl.isFeatureNoGoEnabled) {
            mountReactComponent('#react-referral-mount', <ReferralInformationForm />);
        }
    }

    function doChangeValue(value, status) {
        if (value === 'status' || value === 'status_reason') {
            ctrl.referral.updated_on = new Date();
        }

        if (status) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line default-case
            switch (status) {
                case 'Pending':
                    ctrl.referral.status_reason = 'New';
                    break;
                case 'Complete':
                    ctrl.referral.status_reason = 'Ready for Shipment';
                    break;
            }
        }
    }

    function resetForm() {
        ctrl.referral = angular.copy(ctrl.original);
    }

    function updateReferral() {
        ctrl.saving = true;

        const openActivities = [];

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = ctrl.activities.length; i < len; i++) {
            if (['Created', 'Pending', 'Pending Submission', 'Submitted'].indexOf(ctrl.activities[i].status) !== -1) {
                openActivities.push(ctrl.activities[i]);
            }
        }

        return rmsService
            .update(ctrl.referral.patient_id, ctrl.referral.id, ctrl.referral)
            .then((res) => {
                ctrl.referral = res;
                toast.success('Referral successfully updated.');

                $scope.$emit('updateReferrals', {
                    patient_id: ctrl.referral.patient_id,
                    referral_id: ctrl.referral.id,
                });

                if (ctrl.referral.status === 'Complete') {
                    $state.go('app.referralManager', {
                        patientId: ctrl.referral.patient_id,
                    });
                }

                return res;
            })
            .catch(() => {
                toast.error('Referral was not updated successfully. Please try again.', {
                    closeButton: true,
                    closeHtml: '<button><i class="fa fa-fw fa-times"></i></button>',
                    tapToDismiss: false,
                    timeOut: 25000,
                });
            })
            .finally(() => {
                ctrl.saving = false;
            });
    }
}

export default ReferralInformationController;
