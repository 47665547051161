import { useQuery } from 'react-query';
import { getReferralActivity } from 'App/services/RMSService';

function useFetchAppealQuery(patientId, referralId, activityId) {
    return useQuery(
        ['patient', patientId, 'referral', referralId, 'activity', activityId],
        () => {
            return getReferralActivity(patientId, referralId, activityId, 3);
        },
        {
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useFetchAppealQuery;
