import styled from '@emotion/styled';
import { cx } from '@emotion/css';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState, useRef, useMemo, memo, useEffect } from 'react';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import { useFilters, usePagination, useTable } from 'react-table';
import hasLength from 'App/utils/hasLength';
import LoadSpinnerOverlay from './LoadSpinnerOverlay';

import TableMessage from 'App/components/Table/TableMessage';
import { some } from 'lodash';
import TablePagination from '../Table/TablePagination';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.arrayOf(PropTypes.object),
    stateReducer: PropTypes.func,
    isLoading: PropTypes.bool,
    placeholderText: PropTypes.string,
    getKey: PropTypes.func,
    onPageChange: PropTypes.func,
    onSortChange: PropTypes.func,
    totalRows: PropTypes.number,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
    canSort: PropTypes.bool,
    initialSortKey: PropTypes.string,
    initialSortOrder: PropTypes.oneOf(['asc', 'desc']),
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    currentPage: PropTypes.number.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    currentPageIndex: PropTypes.number,
};

const defaultProps = {
    data: [],
    stateReducer: R.identity,
    isLoading: false,
    onSortChange: () => {},
    getKey: () => {},
    onPageChange: () => {},
    totalRows: 0,
    placeholderText: 'There are no rows that match the criteria.',
    pageSizeOptions: [10, 20, 50],
    canSort: true,
    initialSortKey: '',
    initialSortOrder: 'asc',
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    currentPage: 1,
};

const RelativeDiv = styled('div')`
    position: relative;
`;

const FlexHeaderContents = styled('div')`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .header-text {
        margin-right: 0.5rem;
    }

    .sort-indicator-container {
        display: flex;
        align-items: center;
    }

    .sort-indicator {
        opacity: 0.4;

        &:hover {
            opacity: 1;
        }
    }
`;

const FilterRowStyle = styled('tr')`
    background-color: #fff;
`;

const ManualPageTable = ({
    columns,
    data,
    stateReducer,
    isLoading,
    placeholderText,
    getKey,
    onPageChange,
    onSortChange,
    totalRows,
    pageSizeOptions,
    canSort,
    initialSortKey,
    initialSortOrder,
    currentPage: pageIndex,
    currentPageIndex,
}) => {
    const [sortKey, setSortKey] = useState(initialSortKey);
    const [sortOrder, setSortOrder] = useState(initialSortOrder);
    const [pageSize, setPageSize] = useState(currentPageIndex || R.head(pageSizeOptions));
    const hasFilter = useMemo(() => some(columns, { canFilter: true }), [columns]);
    // Calculate pageCount
    const pageCount = useMemo(() => Math.ceil(totalRows / pageSize), [totalRows, pageSize]);
    // Calculate canPreviousPage and canNextPage
    const canPreviousPage = useMemo(() => pageIndex - 1 > 0, [pageIndex]);
    const canNextPage = useMemo(() => pageIndex - 1 < pageCount - 1, [pageIndex, pageCount]);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        // The rest of these things are super handy, too.
        pageOptions,
    } = useTable(
        {
            columns,
            data,
            stateReducer,
            manualFilter: true,
            manualPagination: true,
            initialState: { pageIndex: 0 },
            pageCount,
        },
        useFilters,
        usePagination
    );

    const tbody = useRef();

    const toggleSort = (columnId) => {
        if (sortKey === columnId) {
            if (sortOrder === 'asc') {
                setSortOrder('desc');
                onSortChange({
                    sortKey,
                    sortOrder: 'desc',
                });
            } else {
                setSortOrder('asc');
                onSortChange({
                    sortKey,
                    sortOrder: 'asc',
                });
            }
        } else {
            setSortKey(columnId);
            setSortOrder('asc');
            onSortChange({
                sortKey: columnId,
                sortOrder: 'asc',
            });
        }
    };

    const onPageSizeChange = (page) => {
        onPageChange({ pageIndex: 1, pageSize: page });
        setPageSize(page);
    };

    const handleNextPage = () => {
        if (canNextPage) {
            onPageChange({ pageIndex: pageIndex + 1, pageSize });
        }
    };

    const handlePreviosPage = () => {
        if (canPreviousPage) {
            onPageChange({ pageIndex: pageIndex - 1, pageSize });
        }
    };

    const jumpToPage = (pageNo) => {
        onPageChange({ pageIndex: pageNo + 1, pageSize });
    };

    useEffect(() => {
        if (currentPageIndex) {
            setPageSize(currentPageIndex);
        }
    }, [currentPageIndex]);

    return (
        <RelativeDiv>
            {isLoading ? (
                <LoadSpinnerOverlay width={tbody.current ? tbody.current.getBoundingClientRect().width : '100%'} />
            ) : null}
            <table {...getTableProps()} className="therigy-table">
                <thead>
                    {headerGroups.map((headerGroup) => {
                        return (
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line react/jsx-key
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => {
                                    return (
                                        // TODO: Fix this the next time the file is edited.
                                        // eslint-disable-next-line react/jsx-key
                                        <th
                                            {...column.getHeaderProps()}
                                            onClick={() => {
                                                if (canSort && column.canSort) {
                                                    toggleSort(column.id);
                                                }
                                            }}
                                            style={column.style}
                                            aria-label={
                                                canSort && column.canSort ? `Sort by ${column.Header}` : column.Header
                                            }
                                            className={cx({
                                                sortable: column.canSort,
                                                'sort-asc': sortKey === column.id && sortOrder === 'asc',
                                                'sort-desc': sortKey === column.id && sortOrder === 'desc',
                                            })}
                                        >
                                            <FlexHeaderContents>
                                                <span className="header-text">{column.render('Header')}</span>
                                                {column.canSort && (
                                                    <span className="sort-indicator-container">
                                                        {/* eslint-disable-next-line no-nested-ternary */}
                                                        {sortKey === column.id && sortOrder === 'asc' ? (
                                                            <FaSortUp className="sort-indicator" />
                                                        ) : sortKey === column.id && sortOrder === 'desc' ? (
                                                            <FaSortDown className="sort-indicator" />
                                                        ) : (
                                                            <FaSort className="sort-indicator" />
                                                        )}
                                                    </span>
                                                )}
                                            </FlexHeaderContents>
                                            {column.SubHeader && (
                                                <small className="text-muted">{column.render('SubHeader')}</small>
                                            )}
                                        </th>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    {hasFilter &&
                        headerGroups.map((headerGroup) => (
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line react/jsx-key
                            <FilterRowStyle {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    // TODO: Fix this the next time the file is edited.
                                    // eslint-disable-next-line react/jsx-key
                                    <th {...column.getHeaderProps()}>
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                    </th>
                                ))}
                            </FilterRowStyle>
                        ))}
                </thead>

                <tbody {...getTableBodyProps()} ref={tbody}>
                    {hasLength(rows) ? (
                        rows.map((row) => {
                            prepareRow(row);
                            const props = row.getRowProps();
                            if (getKey && getKey(row.original)) {
                                props.key = getKey(row.original);
                            }
                            return (
                                // TODO: Fix this the next time the file is edited.
                                // eslint-disable-next-line react/jsx-key
                                <tr {...props}>
                                    {row.cells.map((cell) => {
                                        return (
                                            // TODO: Fix this the next time the file is edited.
                                            // eslint-disable-next-line react/jsx-key
                                            <td
                                                {...cell.getCellProps()}
                                                data-testid={
                                                    typeof cell.column.Header === 'function'
                                                        ? `cell-${cell.column.id}`
                                                        : `cell-${cell.column.Header}`
                                                }
                                            >
                                                {R.isNil(cell.column.accessor) || !R.isNil(cell.value)
                                                    ? cell.render('Cell')
                                                    : '--'}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })
                    ) : (
                        <TableMessage message={placeholderText} />
                    )}
                </tbody>
            </table>
            {pageSize && (
                <TablePagination
                    pageSize={pageSize}
                    setPageSize={onPageSizeChange}
                    previousPage={handlePreviosPage}
                    nextPage={handleNextPage}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    gotoPage={jumpToPage}
                    pageIndex={pageIndex - 1}
                    pageOptions={pageOptions}
                    pageSizeOptions={pageSizeOptions}
                />
            )}
        </RelativeDiv>
    );
};

ManualPageTable.propTypes = propTypes;
ManualPageTable.defaultProps = defaultProps;

export default memo(ManualPageTable);
