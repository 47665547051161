import React from 'react';
import { Formik } from 'formik';
import { Col, Modal, Row } from 'react-bootstrap';
import { Button } from 'react-bootstrap/lib/InputGroup';
import { errorHandler } from 'App/utils';
import * as yup from 'yup';
import * as R from 'ramda';
import SelectField from 'Lib/form/SelectField';
import toast from 'Lib/toast';
import { TextField } from 'Lib/form/TextField';
import { DatePickerField } from 'Lib/form/DatePickerField';
import { TextareaField } from 'Lib/form/TextareaField';
import useCreateReferralCommunicationMutation from 'App/hooks/useCreateReferralCommunicationMutation';
import { identity, pickBy } from 'lodash';
import CustomMenuField from 'Lib/form/CustomMenu';
import { referralModalPropType } from '../AddReferralActivity/types';
import FormButtonGroup from '../styles/FormButtonGroup';
import SubmitButton from 'Lib/form/SubmitButton';

const validationSchema = yup.object().shape({
    communication: yup.object().shape({
        number: yup.string().required('Please enter a call #').nullable(),
        dateTime: yup.date().required('Please enter a date').nullable(),
    }),
});

const communicationMadeOptions = [
    {
        name: 'Yes',
        id: 1,
    },
    {
        name: 'No',
        id: 0,
    },
];

const defaultProps = {};

const AddCommunicationModal = ({ show, onHide, patientId, referralId }) => {
    const initialValues = {
        communication: {
            number: null,
            contact: null,
            reason: null,
            dateTime: null,
            made: null,
            outcome: null,
            notes: null,
        },
    };
    const createReferralCommunicationMutation = useCreateReferralCommunicationMutation();

    return (
        <Modal
            bsSize="lg"
            show={show}
            onHide={() => onHide(false)}
            backdrop="static"
            className="referral-add-communication"
        >
            <Modal.Header>
                <Modal.Title>Add Communication</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnBlur={false}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    const { communication } = values;
                    const body = {
                        number: communication.number,
                        contact: communication?.contact?.valueName,
                        reason: communication?.reason?.valueName,
                        made: communication?.made?.id,
                        outcome: communication?.outcome?.valueName,
                        notes: communication?.notes,
                        date_time: values.communication.dateTime,
                    };
                    return createReferralCommunicationMutation
                        .mutateAsync({
                            patientId,
                            referralId,
                            body: pickBy(body, identity),
                        })
                        .then(() => {
                            toast.success('Referral communication successfully added');
                        })
                        .catch(errorHandler)
                        .finally(() => {
                            setSubmitting(false);
                            onHide(true);
                        });
                }}
            >
                {({ isSubmitting, handleSubmit, values }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <Modal.Body>
                                <Row>
                                    <Col md={3}>
                                        <TextField fieldName="communication.number" label="Call #" required />
                                    </Col>
                                    <Col md={3}>
                                        <CustomMenuField
                                            menuName="referral_communication_person_contacted"
                                            isClearable
                                            label="Person Contacted"
                                            fieldName="communication.contact"
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <CustomMenuField
                                            menuName="referral_communication_contact_reason"
                                            isClearable
                                            label="Contacted Reason"
                                            fieldName="communication.reason"
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <DatePickerField
                                            fieldName="communication.dateTime"
                                            label="Date"
                                            required
                                            placeholder="YYYY/MM/DD"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <SelectField
                                            options={communicationMadeOptions}
                                            isClearable
                                            label="Contact Made"
                                            fieldName="communication.made"
                                            getOptionValue={R.prop('id')}
                                            getOptionLabel={R.prop('name')}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <CustomMenuField
                                            menuName="referral_communication_call_outcome"
                                            isClearable
                                            label="Call Outcome"
                                            fieldName="communication.outcome"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <TextareaField
                                            fieldName="communication.notes"
                                            label={`Notes ${values?.communication?.notes?.length || 0}/2500`}
                                            placeholder="Notes"
                                            maxLength={2500}
                                            rows={3}
                                        />
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <FormButtonGroup>
                                    <Button
                                        disabled={isSubmitting}
                                        bsClass="btn btn-default"
                                        onClick={() => onHide(false)}
                                    >
                                        Cancel
                                    </Button>
                                    <SubmitButton id="add-referral-communication-modal-submit" ignoreDirty>
                                        Save
                                    </SubmitButton>
                                </FormButtonGroup>
                            </Modal.Footer>
                        </form>
                    );
                }}
            </Formik>
        </Modal>
    );
};

AddCommunicationModal.propTypes = referralModalPropType;
AddCommunicationModal.defaultProps = defaultProps;

export default AddCommunicationModal;
