import PropTypes from 'prop-types';
import React from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';

import asFormField from './asFormField';

const NumberInput = ({
    name,
    value,
    onChange,
    onBlur,
    className,
    style,

    placeholder,
    disabled,
    min,
    max,
    prefixAddon,
    ...otherProps
}) => {
    const formControlContent = (
        <FormControl
            aria-label={otherProps['aria-label']}
            aria-labelledby={otherProps['aria-labelledby']}
            id={name}
            value={value ?? ''}
            onChange={onChange}
            onBlur={onBlur}
            className={className}
            style={style}
            type="number"
            placeholder={placeholder}
            disabled={disabled}
            min={min}
            max={max}
        />
    );

    if (prefixAddon) {
        return (
            <InputGroup>
                <InputGroup.Addon>{prefixAddon}</InputGroup.Addon>
                {formControlContent}
            </InputGroup>
        );
    }
    return formControlContent;
};

NumberInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    className: PropTypes.string,
    style: PropTypes.shape({}),

    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    prefixAddon: PropTypes.node,
};

NumberInput.defaultProps = {
    value: '',
    className: '',
    style: null,

    placeholder: '',
    disabled: false,
    min: null,
    max: null,
    prefixAddon: null,
};

export const NumberField = asFormField({ WrappedComponent: NumberInput });
