/**
 * Import vendor files
 */
import './vendor';

/**
 * Import the global styles
 */
import './sass/main.sass';
import './css/ui-select.css';
import './css/ng-table.css';

// Import React
import React, { useEffect, useRef } from 'react';

// Import angular and angular packages
import angular from 'angular';
import uiBootstrap from 'angular-ui-bootstrap';
import { ngTableModule } from 'ng-table/bundles/ng-table';
import SessionStorageService from 'App/services/SessionStorageService';
import * as R from 'ramda';

// Import Services
import Services from './app/services/services.module';

// Import Module Names
import Config from './app/components/config/config.module';
import Components from './app/components/components.module';
import Common from './app/common/common.module';
import Directives from './app/directives/directives.module';
import Filters from './app/filters/filters.module';

// Import Factories
import HttpCacheFactory from './app/services/http-cache.service';

// Declares $injector with singleton pattern
// eslint-disable-next-line no-unused-vars
let $injector;

/**
 *
 * @ngdoc module
 * @name root
 **
 * @description
 *
 * This is the root module. It is the root of our single page application.
 *
 **/
const AngularRoot = angular
    .module('root', [uiBootstrap, ngTableModule.name, Config, Components, Services, Common, Directives, Filters])
    .component('root', {
        template: '<div class="root" ui-view></div>',
    })
    .factory('httpCache', HttpCacheFactory)
    // Sets _paq constant for Piwik
    .constant('_paq', window._paq || [])
    .run([
        '$injector',
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line func-names
        function (_$injector) {
            $injector = _$injector;
        },
    ]);

/**
 * Root React component for Angular.
 * Bootstraps Angular to a HTML <div> element on component mount.
 */
const AngularRootReactComponent = () => {
    // Contains the container ref for Angular
    const angularContainerRef = useRef(null);

    /**
     * The HTML body for Angular.
     * We have to dangerouslySetInnerHTML in order to prevent React from complaining about
     * the element names like "<root/>"
     */
    const html = `<div><root class="root"><span class="loader">Loading...</span></root></div>`;

    useEffect(() => {
        // Store the bootstrap instance in a variable
        const bootstrap = angular.bootstrap(angularContainerRef.current, [AngularRoot.name]);

        /**
         * Fix SSO logout bug.
         */
        const rootScope = bootstrap.get('$rootScope');
        const jwt = SessionStorageService.getDecodedJwt();
        rootScope.token = R.path(['data', 'Token'], jwt);

        // When component unmounts...
        return () => {
            // Remove any leftover ng modal elements
            setImmediate(() => {
                const isStillAngular = !!document.querySelector('#app > .ng-scope');
                if (!isStillAngular) {
                    const modalElements = document.getElementsByClassName('modal fade ng-scope ng-isolate-scope');
                    const modalBackdropElements = document.getElementsByClassName('modal-backdrop fade ng-scope');
                    [...modalElements, ...modalBackdropElements].forEach((elem) => elem.parentNode.removeChild(elem));
                    document.body.classList.remove('modal-open');
                }
            });

            // Destroy the instance to make sure it gets cleaned from the DOM
            bootstrap.get('$rootScope').$destroy();
        };
    }, []);

    return (
        <div
            ref={angularContainerRef}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
                __html: html,
            }}
        />
    );
};

export default AngularRootReactComponent;
