import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import SelectField from 'Lib/form/SelectField';
import { followupAppealActivityOptions } from './constants';

const propTypes = {
    isFormDisabled: PropTypes.bool.isRequired,
};

const defaultProps = {};

function UpheldForm({ isFormDisabled }) {
    return (
        <Row>
            <Col md={2}>
                <SelectField
                    isDisabled={isFormDisabled}
                    isClearable
                    label="Create a follow-up Appeal activity?"
                    options={followupAppealActivityOptions}
                    fieldName="details.scheduleFollowupAppeal"
                />
            </Col>
        </Row>
    );
}

UpheldForm.propTypes = propTypes;
UpheldForm.defaultProps = defaultProps;

export default UpheldForm;
