import { Risk } from '@stm/arm';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { saveResponse } from 'App/services/AMSService';

import { rapid3FunctionalScoringIds, rapid3TotalScoringIds } from '../constants';
import { activityPropType, questionPropType } from '../types';
import AssessmentQuestionText from './AssessmentQuestionText';

const AssessmentRapid3ScoreQuestion = ({ activity, question, isFunctionalScore }) => {
    const { values } = useFormikContext();
    const { scoring } = activity.assessment;
    const modifiedValues = Object.values(values).map((questionValue) => ({
        ...questionValue,
        response: questionValue.response?.id || questionValue.response,
    }));
    const scores = Risk.calculate(scoring, modifiedValues);
    const relevantScoringIds = isFunctionalScore ? rapid3FunctionalScoringIds : rapid3TotalScoringIds;
    const theScore = Object.entries(scores).find(([id]) => relevantScoringIds.includes(id))[1];

    // When the score value changes, save the response to the API
    useEffect(() => {
        if (!activity.id || theScore.value === null) {
            return;
        }
        saveResponse(activity.id, question.questionId, theScore.value);
    }, [activity.id, question.questionId, theScore.value]);

    return <AssessmentQuestionText question={question} viewMode={values.viewMode} score={theScore} />;
};

AssessmentRapid3ScoreQuestion.propTypes = {
    activity: activityPropType.isRequired,
    question: questionPropType.isRequired,
    isFunctionalScore: PropTypes.bool,
};
AssessmentRapid3ScoreQuestion.defaultProps = {
    isFunctionalScore: false,
};

export default AssessmentRapid3ScoreQuestion;
