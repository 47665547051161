import React from 'react';
import ClickLink from 'App/components/styles/ClickLink';
import PropTypes from 'prop-types';

const propTypes = {
    showMessagingModal: PropTypes.func.isRequired,
    showHistoryModal: PropTypes.func.isRequired,
};

const defaultProps = {};

function Unsubscribed({ showMessagingModal, showHistoryModal }) {
    return (
        <>
            <div style={{ flexGrow: 1, flexBasis: '50%' }}>
                <i className="fas fa-fw fa-times text-danger" />
                <span>
                    Patient Messaging: <span id="engage-patient-messaging-enrollment-status">Opted-out</span>
                    {' | '}
                </span>
                <ClickLink onClick={showMessagingModal}>Edit</ClickLink>
            </div>
            <div>
                <ClickLink onClick={showHistoryModal}>
                    <i className="fa fa-fw fa-history" id="view-engage-patient-messaging-history-link" /> View Patient
                    Messaging History
                </ClickLink>
            </div>

            <div style={{ flexGrow: 1, flexBasis: '50%' }} />
        </>
    );
}

Unsubscribed.propTypes = propTypes;
Unsubscribed.defaultProps = defaultProps;

export default Unsubscribed;
