import React, { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import AssessmentDisplay from 'App/components/Assessments/AssessmentDisplay';
import useFetchAssessment from '../../hooks/useFetchAssessment';
import useFetchAssessmentQuestions from '../../hooks/useFetchAssessmentQuestions';

const AssessmentPreview = () => {
    const { assessmentId } = useParams();

    const { search } = useLocation();

    const allQuestions = useMemo(() => {
        return new URLSearchParams(search).get('expanded') === 'true';
    }, [search]);

    const assessmentQuery = useFetchAssessment(assessmentId);
    const questionsQuery = useFetchAssessmentQuestions(assessmentId, { allQuestions });

    return (
        <AssessmentDisplay
            isPreview
            activityQuery={{
                isLoading: assessmentQuery.isLoading,
                data: {
                    name: assessmentQuery.data?.name,
                    assessment: assessmentQuery.data,
                },
            }}
            patientQuery={{
                // Static dummy data - always set to this because there is no patient for assessment preview
                isLoading: false,
                data: {
                    city: 'Orlando',
                    firstName: 'patient',
                    lastName: 'preview',
                    state: 'FL',
                    zip: '12345',
                },
            }}
            questionsQuery={questionsQuery}
        />
    );
};

export default AssessmentPreview;
