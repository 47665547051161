import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import useGetPatientData from 'App/components/PatientProfile/hooks/useGetPatientData';

import FlexCenter from 'App/components/styles/FlexCenter';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner/TherigyLoadingSpinner';
import Page from 'App/components/Page';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import ErrorMessage from 'App/components/errors/ErrorMessage';
import { supportEmail } from 'App/components/config/config';
import UserBranchSwitcher from 'App/components/UserBranchSwitcher/UserBranchSwitcher';

const propTypes = {
    children: PropTypes.element,
};
const defaultProps = {
    children: null,
};

const PatientProfileLoadingWrapper = ({ children }) => {
    const { patientId } = useParams();
    const { patientData, isPatientDataLoading, isError } = useGetPatientData(patientId);

    if (isError) {
        return (
            <Page title="Not Found" permissionType={PERMISSION_TYPES.WORK_QUEUE} showPageTitle={false}>
                <ErrorMessage>
                    Error: You do not have permission to view/edit patients in this branch.
                    <br />
                    <br />
                    If you believe that this has been done in error, please contact Therigy at{' '}
                    <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
                </ErrorMessage>
            </Page>
        );
    }

    if (isPatientDataLoading) {
        return (
            <FlexCenter>
                <TherigyLoadingSpinner />
            </FlexCenter>
        );
    }

    return (
        <>
            <UserBranchSwitcher patient={patientData} />
            {children}
        </>
    );
};

PatientProfileLoadingWrapper.defaultProps = defaultProps;
PatientProfileLoadingWrapper.propTypes = propTypes;

export default PatientProfileLoadingWrapper;
