import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import useFetchActivityCount from './useFetchActivityCount';
import useFetchWorkQueueSavedFilters from './useFetchWorkQueueSavedFilters';
import useFetchWorkQueueTableHeaders from './useFetchWorkQueueTableHeaders';
import useFetchWorkQueueResult from './useFetchWorkQueueResult';
import { processWorkQueueResult, transformReactFilterToLS, updateWorkQueueFilters } from '../workQueue.utils';
import SessionStorageService from 'App/services/SessionStorageService';

function useWorkQueue() {
    const { data: activityCount, isLoading: isActivityCountLoading } = useFetchActivityCount();
    const { data: workQueueTableHeaders, isLoading: isTableHeadersLoading } = useFetchWorkQueueTableHeaders();
    const { data: savedFilter, isLoading: isSavedFiltersLoading } = useFetchWorkQueueSavedFilters();
    const {
        values: { filterParams },
        setFieldValue,
        setSubmitting,
    } = useFormikContext();

    //prettier-ignore
    const {isLoading, data: workQueueResult} = useFetchWorkQueueResult(updateWorkQueueFilters(filterParams));

    useEffect(() => {
        if (!isActivityCountLoading) {
            setFieldValue('result.activityCountResult', activityCount);
        }
    }, [activityCount, isActivityCountLoading, setFieldValue]);

    useEffect(() => setSubmitting(isLoading), [isLoading, setSubmitting]);

    useEffect(() => {
        if (!isTableHeadersLoading) {
            setFieldValue('result.workQueueTableHeaders', workQueueTableHeaders);
        }
    }, [workQueueTableHeaders, isTableHeadersLoading, setFieldValue]);

    useEffect(() => {
        if (!isSavedFiltersLoading && savedFilter) {
            setFieldValue('result.savedFilterResult', Object.values(savedFilter));
        }
    }, [savedFilter, isSavedFiltersLoading, setFieldValue]);

    useEffect(() => {
        if (workQueueResult) {
            setFieldValue('result.workQueueActivityCount', workQueueResult.count);
            setFieldValue('result.workQueueResult', processWorkQueueResult(workQueueResult.data));
            SessionStorageService.setOnUser(`workQueue_selectedFilter`, transformReactFilterToLS(filterParams));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workQueueResult]);
}

export default useWorkQueue;
