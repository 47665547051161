import React from 'react';
import './WorkQueue.scss';
import { Helmet } from 'react-helmet';
import { catchPhrase } from '../../components/config/config';
import ActivityCount from './components/ActivityCount';
import MyFilters from './components/MyFilters';
import WorkQueueFilters from './components/WorkQueueFilters';
import { WorkQueueProvider } from './WorkQueueProvider';
import UnassignedPatientTable from './components/Tables/UnassignedPatientTable';
import { WorkQueueTable } from './components/Tables/WorkQueueTable';
import UnassignedMedicationTable from './components/Tables/UnassignedMedicationTable';
import { useUserContext } from 'App/contexts/UserContext';
import useIsPermitted from 'App/utils/hooks/useIsPermitted';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import MobileCompletedActivitiesTable from './components/Tables/MobileCompletedActivitiesTable';

const propTypes = {};

const defaultProps = {};

const WorkQueue = () => {
    const hasMobileAnswersCompleteQueueEnabled = useIsPermitted(PERMISSION_TYPES.FEATURE_MOBILE_ANSWERS_COMPLETE_QUEUE);
    const user = useUserContext();
    const { company_permissions: { MckessonCpsIntegration: isMcKessonEnabled = false } = {} } = user;

    return (
        <>
            <Helmet>
                <title>Work Queue - {catchPhrase}</title>
            </Helmet>

            <div className="work-queue">
                <WorkQueueProvider>
                    <aside>
                        <ActivityCount />
                        <MyFilters />
                        <WorkQueueFilters />
                    </aside>
                    <article>
                        {hasMobileAnswersCompleteQueueEnabled && <MobileCompletedActivitiesTable />}
                        <UnassignedPatientTable />
                        {isMcKessonEnabled && <UnassignedMedicationTable />}
                        <WorkQueueTable />
                    </article>
                </WorkQueueProvider>
            </div>
        </>
    );
};

WorkQueue.propTypes = propTypes;
WorkQueue.defaultProps = defaultProps;

export default WorkQueue;
