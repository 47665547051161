import angular from 'angular';

import ReferralManagerConfig from './referral-manager.config';
import ReferralManagerController from './referral-manager.controller';

export default angular
    .module('components.referralManager', ['ngAnimate', 'ngTable', 'ui.router'])
    .component('referralManager', {
        bindings: {
            patient: '<',
            referrals: '<',
            rxs: '<',
            user: '<',
        },
        controller: ReferralManagerController,
        template: require('./referral-manager.html'),
    })
    .config(ReferralManagerConfig).name;
