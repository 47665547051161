import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import camelCase from 'lodash.camelcase';
import { TextInput } from 'Lib/form/TextField';
import * as R from 'ramda';

export const useGetWorkQueueColumns = ({ headerMapping, onFilterChange, searchState }) => {
    const {
        values: {
            result: { workQueueTableHeaders },
        },
    } = useFormikContext();

    const columns = useMemo(() => {
        if (!workQueueTableHeaders?.headers) {
            return [];
        }
        const headers = R.compose(
            R.sortBy(R.prop('fieldOrder')),
            /**
             * Double-reverse allows for the right-most duplicate to be retained and the left-most be dropped
             */
            R.reverse,
            R.uniqBy(R.prop('fieldSelected')),
            R.reverse
        )(workQueueTableHeaders?.headers || []);

        return headers?.map((header) => {
            const mapping = headerMapping[header.fieldSelected];

            const accessor = mapping.accessor || camelCase(header.fieldSelected);

            return {
                Header: header.nameOfHeader,
                accessor,
                sortName: mapping.sortName || header.fieldSelected,
                id: mapping.sortName || header.fieldSelected,
                canSort: mapping.canSort || false,
                Filter: (
                    <TextInput
                        name={mapping.filterName || header.fieldSelected}
                        placeholder={`Filter by ${header.nameOfHeader}`}
                        onChange={onFilterChange}
                        value={searchState[mapping.filterName || header.fieldSelected] || ''}
                    />
                ),
                canFilter: true,
                ...(mapping.Cell ? { Cell: mapping.Cell } : {}),
            };
        });
    }, [workQueueTableHeaders?.headers, headerMapping, onFilterChange, searchState]);

    return columns;
};
