import { useMemo } from 'react';
import * as R from 'ramda';
import { useUserContext } from 'App/contexts/UserContext';
import {
    ActivityDueDateMobileAnswersCell,
    ActivityNameCell,
    ActivityStatusMobileAnswersCell,
    LastUpdatedCell,
    PatientIdCell,
    PatientNameCell,
    MedicationAssociationInsuranceCell,
} from '../components/Cell';

const displayIdMap = {
    external_id: 'externalId',
    other_id: 'otherId',
    ehr_id: 'ehrId',
};

export const useGetHeaderMappingForMobileAnswersQueue = () => {
    const user = useUserContext();
    const displayedId = R.path(['company_permissions', 'DisplayedId'], user);

    const headerMapping = useMemo(
        () => ({
            patient_id: {
                sortName: Object.keys(displayIdMap).includes(displayedId) ? displayedId : 'human_id',
                accessor: displayIdMap[displayedId] || 'humanId',
                canSort: true,
                filterName: Object.keys(displayIdMap).includes(displayedId) ? displayedId : 'human_id',
                Cell: PatientIdCell,
            },
            patient_name: {
                canSort: true,
                Cell: PatientNameCell,
            },
            medication: {
                canSort: false,
            },
            activity_due_date: {
                accessor: 'dateDueSearch',
                sortName: 'date_due',
                canSort: true,
                Cell: ActivityDueDateMobileAnswersCell,
                filterName: 'date_due_search',
            },
            activity_name: {
                accessor: 'activityName',
                canSort: true,
                Cell: ActivityNameCell,
            },
            patient_dob: {
                accessor: 'patientDob',
                canSort: true,
            },
            activity_status: {
                accessor: 'activityStatus',
                canSort: true,
                Cell: ActivityStatusMobileAnswersCell,
            },
            last_updated: {
                accessor: 'updatedOnDisplay',
                canSort: true,
                sortName: 'updated_on',
                filterName: 'updated_name',
                Cell: LastUpdatedCell,
            },
            patient_labels: {
                accessor: 'patientLabels',
                canSort: true,
                sortName: 'patient_labels',
            },
            activity_needs_by_date: {
                accessor: 'activity_needs_by_date',
                canSort: true,
                sortName: 'needs_by',
            }, // need to figure out the value
            assigned_to: {
                accessor: 'assignedToName',
                canSort: true,
            },
            branch_name: {
                accessor: 'branchName',
                canSort: true,
            },
            patient_state: {
                accessor: 'patientState',
                canSort: true,
            },
            patient_status: {
                accessor: 'patientStatus',
                canSort: true,
            },
            insurance_plan_name: {
                accessor: 'insurancePlanName',
                canSort: true,
                Cell: MedicationAssociationInsuranceCell,
            },
            activity_status_date: {
                accessor: 'activityStatusDate',
                canSort: true,
            },
            medication_association: {
                accessor: 'medicationAssociation',
                canSort: true,
                Cell: MedicationAssociationInsuranceCell,
            },
        }),
        [displayedId]
    );

    return headerMapping;
};
