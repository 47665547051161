import { getReferralActivities } from 'App/services/RMSService';
import { useQuery } from 'react-query';

function useFetchReferralActivitiesQuery(patientId, referralId, type = undefined, status = undefined) {
    const typeKey = type || 'all';
    const statusKey = status || 'all';
    return useQuery(
        ['patient', patientId, 'referral', referralId, 'activity', typeKey, statusKey],
        () => getReferralActivities(patientId, referralId, { type, status }),
        {
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useFetchReferralActivitiesQuery;
