import toast from 'Lib/toast';

/** @ngInject */
function AssessmentCreateController($scope, $state, assessmentService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.createAssessment = createAssessment;
    ctrl.selectEhrNote = selectEhrNote;

    function $onChanges(changes) {
        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
        }
    }

    function $onInit() {
        const { categoryId } = $state.params;

        return assessmentService
            .getAssessment(categoryId, null)
            .then((res) => {
                res.category_id = categoryId;
                ctrl.assessment = res;
                return res;
            })
            .catch((err) => {
                let error =
                    'The assessment therapeutic category was not found or you do not have permission to view it.';
                if ((err.status === 403 || err.status === 404) && err.data && err.data.message) {
                    error = err.data.message;
                }
                return error;
            });
    }

    function createAssessment(categoryId, assessment) {
        ctrl.busy = true;

        return assessmentService
            .createAssessment(categoryId, assessment)
            .then((res) => {
                toast.success(res.data.message);

                $state.go('app.assessmentManager', {
                    categoryId,
                    pollId: res.data.poll_id,
                });

                return res;
            })
            .catch((err) => {
                toast.error(err.data.message);
                return err.data;
            })
            .finally(() => {
                ctrl.busy = false;
            });
    }

    function selectEhrNote() {
        if (ctrl.assessment.ehr_encounter_note === true) {
            ctrl.assessment.enhanced_summary_note = true;
            ctrl.assessment.require_medication_association = true;
        }
    }
}

export default AssessmentCreateController;
