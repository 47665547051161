import angular from 'angular';

import AccessViewController from './access-view.controller';
import AccessViewConfig from './access-view.config';

export default angular
    .module('components.accessView', [])
    .component('accessView', {
        bindings: {
            user: '<',
        },
        controller: AccessViewController,
        template: require('./access-view.html'),
    })
    .config(AccessViewConfig).name;
