import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { FaWindowClose } from 'react-icons/fa';

import NavTabs from 'App/components/NavTabs/NavTabs';
import InsuranceFormArea from 'App/components/PatientInsurance/InsuranceFormArea';
import TherigyInfoMessage from 'App/components/TherigyInfoMessage/TherigyInfoMessage';

import AddInsuranceDetachModal from 'App/components/PatientInsurance/AddInsuranceDetachModal';
import SectionTitleStyle from 'App/components/PatientForm/components/SectionTitleStyle';
import AttachInsurancePlan from './AttachInsurancePlan';

const BenefitsInvestigationInsuranceForm = () => {
    const { values, setFieldValue, validateForm } = useFormikContext();
    const [removeIndex, setRemoveIndex] = useState();
    const [showDetachModal, setShowDetachModal] = useState(false);

    const removeInsurancePlan = () => {
        values.patientInsurance.splice(removeIndex, 1);
        setFieldValue('patientInsurance', [...values.patientInsurance]);
        setRemoveIndex(undefined);
        setImmediate(() => validateForm());
    };

    const onRemoveInsurancePlanClick = (index) => {
        setRemoveIndex(index);
        setShowDetachModal(true);
    };

    return (
        <>
            <SectionTitleStyle>
                <h2>Insurance Plans</h2>
            </SectionTitleStyle>

            <AttachInsurancePlan />
            {!values.patientInsurance.length ? (
                <TherigyInfoMessage message="There are no insurance plans attached to this patient." />
            ) : (
                <NavTabs
                    items={values.patientInsurance}
                    renderNavTab={(item, index) => (
                        <>
                            <span>{item.name || `Plan ${item.position}`} </span>
                            <FaWindowClose
                                onClick={() => {
                                    onRemoveInsurancePlanClick(index);
                                }}
                            />
                        </>
                    )}
                    renderPaneContent={(item, index) => (
                        <InsuranceFormArea fieldPrefix={`patientInsurance[${index}]`} />
                    )}
                />
            )}
            {showDetachModal && (
                <AddInsuranceDetachModal
                    detach={showDetachModal}
                    setDetach={setShowDetachModal}
                    removePlan={removeInsurancePlan}
                />
            )}
        </>
    );
};

export default BenefitsInvestigationInsuranceForm;
