import { useUserContext } from 'App/contexts/UserContext';
import useFetchPatientIdentifiers from 'App/hooks/useFetchPatientIdentifiers';
import useFetchPatient from 'App/hooks/useFetchPatient';
import useFetchEhrBranchSettings from 'App/components/Assessments/hooks/useFetchEhrBranchSettings';

const getIdentifier = (ids, type) => Object.values(ids).find((id) => id.identifierType === type)?.identifier;

const PatientDisplayId = ({ patientId, fallback = '' }) => {
    const { isLoading: isLoadingPatient, ...patientResponse } = useFetchPatient(patientId);
    const { isLoading: isLoadingIds, ...identifiersResponse } = useFetchPatientIdentifiers(patientId);
    const { isLoading: isLoadingEhr, ...ehrResponse } = useFetchEhrBranchSettings();
    const { DisplayedId: displayedId } = useUserContext().company_permissions;

    if (isLoadingPatient || isLoadingIds || isLoadingEhr) {
        return '';
    }

    const { humanId, externalId } = patientResponse.data || {};
    const { ehrPrimaryIdType } = ehrResponse.data || {};
    const identifiers = identifiersResponse?.data;

    const additionalIdentifiers = {
        ...(identifiers && ehrPrimaryIdType && { ehr_id: getIdentifier(identifiers, ehrPrimaryIdType) }),
        ...(identifiers && { other_id: getIdentifier(identifiers, 'stm_other_id') }),
    };

    const idDisplayMap = {
        external_id: externalId,
        human_id: humanId,
        ...additionalIdentifiers,
        ...(displayedId === 'ehr_id' && { ehrFallback: externalId }),
    };

    return idDisplayMap[displayedId] || fallback;
};

export default PatientDisplayId;
