import React, { useState } from 'react';
import { OverlayTrigger, Popover, Button } from 'react-bootstrap';
import TasService from 'App/services/TasService';
import LaddaButton from 'App/components/LaddaButton';
import getCssVariable from 'App/utils/getCssVariable';
import { supportWebsite } from 'App/components/config/config';

const tknClickHandler = () => {
    return TasService.getTknLogin().then((loginUrl) => {
        window.open(loginUrl, '_blank');
    });
};

const HelpIcon = () => {
    const [isLoading, setIsLoading] = useState(false);
    const HelpPopover = (
        <Popover id="help-popover">
            <div id="help-popover-text">Help and Training</div>
            <br />
            <ul className="list-inline">
                <li className="list-inline-item">
                    <a data-testid="help-support-link" href={supportWebsite} target="_blank" rel="noopener noreferrer">
                        <Button
                            id="help-popover-button-support"
                            data-testid="help-support-button"
                            bsSize="small"
                            bsStyle="link"
                        >
                            Contact Support
                            <i className="fas fa-fw fa-external-link-alt" />
                        </Button>
                    </a>
                </li>
                <li className="list-inline-item">
                    <LaddaButton
                        color={getCssVariable('--brandPrimary')}
                        data-testid="help-tkn-button"
                        isLoading={isLoading}
                        disabled={isLoading}
                        id="help-popover-button-tkn"
                        bsSize="small"
                        bsStyle="link"
                        onClick={() => {
                            setIsLoading(true);
                            return tknClickHandler().finally(() => {
                                setIsLoading(false);
                            });
                        }}
                    >
                        TherigySTM Knowledge Network
                        <i className="fas fa-fw fa-external-link-alt" />
                    </LaddaButton>
                </li>
            </ul>
        </Popover>
    );

    return (
        <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={HelpPopover}>
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a id="help-icon" data-testid="header-help-icon" className="icon">
                <i className="fas fa-fw fa-question-circle" />
            </a>
        </OverlayTrigger>
    );
};

export default HelpIcon;
