import { flatten, isNil, path, propEq } from 'ramda';
import ActivityMedService from '../../../services/ActivityMedicationService';
import PESService from '../../../services/PESService';
import { stripCurlyTags } from 'App/utils';
import toast from 'Lib/toast';

/** @ngInject */
function EhrEncounterNoteModalController(
    _,
    $filter,
    $q,
    amsService,
    assessmentService,
    jwtHelper,
    moment,
    pesService,
    sessionStorageService,
    urlBuilderService
) {
    const ctrl = this;

    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.submitNote = submitNote;
    ctrl.goBack = goBack;
    ctrl.stripCurlyTags = stripCurlyTags;
    ctrl.requirePrescriber = false;
    ctrl.prescriberSelectionList = [];
    ctrl.ctaText = 'Send to EHR';

    function _formatList(list, property) {
        if (typeof list === 'object') {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            list = Object.values(list);
        }

        if (property) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            list = _.map(list, property);
        }

        return _.uniq(list).sort();
    }

    function _flattenQuestions(questions) {
        let result = [];

        // Attach the info needed to sort this level
        _.forEach(questions, (q) => {
            if (q.attributes.group_id && ctrl.questionGroups[q.attributes.group_id]) {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                q.group = ctrl.questionGroups[q.attributes.group_id];
            } else {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                q.group = null;
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                q.attributes.group_id = null;
            }
        });

        // Sort and then process
        const sortedQuestions = Object.values(questions).sort(amsService.sortQuestions);
        _.forEach(sortedQuestions, (q) => {
            result.push(q);
            if (q.children) {
                result = result.concat(_flattenQuestions(q.children));
            }
        });

        return result;
    }

    function $onChanges(changes) {
        if (changes.resolve && ctrl.resolve) {
            if (ctrl.resolve.modalData) {
                if (ctrl.resolve.modalData.activityDate) {
                    ctrl.activityDate = angular.copy(ctrl.resolve.modalData.activityDate);
                }

                if (ctrl.resolve.modalData.branchSettings) {
                    ctrl.branchSettings = angular.copy(ctrl.resolve.modalData.branchSettings);
                    let prescriberType = 'user';
                    if (typeof ctrl.branchSettings.redoxNotePrescriberType === 'string') {
                        prescriberType = ctrl.branchSettings.redoxNotePrescriberType;
                    }
                    ctrl.requirePrescriber =
                        ctrl.branchSettings.redoxNoteRequiresPrescriber && prescriberType === 'prescriber';
                }

                if (ctrl.resolve.modalData.user) {
                    ctrl.user = angular.copy(ctrl.resolve.modalData.user);
                    ctrl.requireVisit = ctrl.branchSettings.ehrNoteRequiresVisit;
                }

                if (ctrl.resolve.modalData.patientIdentifiers) {
                    ctrl.patientIdentifiers = angular.copy(ctrl.resolve.modalData.patientIdentifiers);
                }

                if (ctrl.resolve.modalData.clinicalIssues) {
                    ctrl.clinicalIssues = _formatList(ctrl.resolve.modalData.clinicalIssues);
                }

                if (ctrl.resolve.modalData.patient) {
                    ctrl.patient = angular.copy(ctrl.resolve.modalData.patient);

                    if (ctrl.patient.middleName) {
                        ctrl.patient.middleInitial = ctrl.patient.middleName.charAt(0).toUpperCase();
                    }

                    pesService.getAllergy(ctrl.patient.id).then((res) => {
                        ctrl.allergies = _formatList(res);
                    });

                    pesService.getOtherMedication(ctrl.patient.id).then((res) => {
                        const otherMedications = _.values(res);
                        ctrl.otherMedications = _formatList(otherMedications, 'text');
                    });
                }

                if (ctrl.patientIdentifiers && ctrl.branchSettings.ehrPrimaryIdType) {
                    const primaryIdentifier = ctrl.patientIdentifiers.find(
                        propEq(ctrl.branchSettings.ehrPrimaryIdType, 'identifierType')
                    );

                    if (primaryIdentifier) {
                        ctrl.patientPrimaryIdentifier = primaryIdentifier.identifier;
                        ctrl.showEhrId = true;
                    }
                }

                if (ctrl.resolve.modalData.activity && ctrl.resolve.modalData.patient) {
                    ctrl.activity = angular.copy(ctrl.resolve.modalData.activity);
                    ctrl.modalTitle = ctrl.activity.name;
                    ctrl.modalAction = 'review';

                    ctrl.patient = angular.copy(ctrl.resolve.modalData.patient);
                    if (ctrl.patient.middleName) {
                        ctrl.patient.middleInitial = ctrl.patient.middleName.charAt(0).toUpperCase();
                    }
                    $q.all({
                        medAssociations: assessmentService.getMedAssociation(ctrl.patient.id, ctrl.activity.id),
                        prescriptions: pesService.getPrescriptions(ctrl.patient.id),
                    }).then((res) => {
                        ctrl.prescriptions = Object.values(res.prescriptions);
                        ctrl.therapies = _formatList(res.medAssociations, 'TherapyName').join(', ');
                        ctrl.medAssociations = res.medAssociations;
                        ctrl.medicationAssociations = _formatList(res.medAssociations, 'Name');
                        ctrl.ctaText = ctrl.requireVisit || ctrl.requirePrescriber ? 'Next' : 'Send to EHR';

                        const medAssociationMapping = _.map(res.medAssociations, (r) => {
                            return {
                                id: r.ID,
                                medicationId: r.MedicationId,
                            };
                        });

                        const providers = [];
                        _.forEach(res.prescriptions, (p) => {
                            if (p.physician) {
                                // If the medication has an Rx number, look up association by Rx
                                // Otherwise, look up by medication id
                                if (medAssociationMapping.map((m) => m.id).indexOf(p.id) !== -1) {
                                    providers.push(`${p.physician.firstName} ${p.physician.lastName}`);
                                } else if (
                                    medAssociationMapping.map((m) => m.medicationId).indexOf(p.medication.id) !== -1
                                ) {
                                    providers.push(`${p.physician.firstName} ${p.physician.lastName}`);
                                }
                            }
                        });
                        ctrl.providers = _formatList(providers);
                    });
                }

                if (ctrl.resolve.modalData.questions && ctrl.resolve.modalData.questionGroups) {
                    ctrl.questionGroups = _.keyBy(ctrl.resolve.modalData.questionGroups, 'id');
                    let questions = _flattenQuestions(ctrl.resolve.modalData.questions);
                    questions = questions.map((q) => _.omit(q, 'children'));
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line consistent-return
                    ctrl.questions = _.remove(questions, (q) => {
                        if (q.attributes && q.attributes.ehr_encounter_note) {
                            if (q.answers && q.response) {
                                switch (q.type) {
                                    case 'date':
                                        // TODO: Fix this the next time the file is edited.
                                        // eslint-disable-next-line no-param-reassign
                                        q.answer = $filter('date')(q.response, 'yyyy/MM/dd');
                                        break;
                                    case 'number':
                                    case 'textarea':
                                    case 'textbox':
                                        // TODO: Fix this the next time the file is edited.
                                        // eslint-disable-next-line no-param-reassign
                                        q.answer = q.response;
                                        break;
                                    case 'checkbox':
                                        // eslint-disable-next-line no-var,vars-on-top
                                        var answers = [];
                                        // TODO: Fix this the next time the file is edited.
                                        // eslint-disable-next-line no-plusplus
                                        for (let i = 0, len = q.response.length; i < len; i++) {
                                            answers.push(q.answers[q.response[i]].option);
                                        }
                                        // TODO: Fix this the next time the file is edited.
                                        // eslint-disable-next-line no-param-reassign
                                        q.answer = _formatList(answers).join(', ');
                                        break;
                                    case 'radio':
                                    case 'select':
                                        // TODO: Fix this the next time the file is edited.
                                        // eslint-disable-next-line no-param-reassign
                                        q.answer = q.answers[q.response].option;
                                        break;
                                    case 'message':
                                        break;
                                    default:
                                        break;
                                }
                            } else {
                                // TODO: Fix this the next time the file is edited.
                                // eslint-disable-next-line no-param-reassign
                                q.answer = '<unanswered>';
                            }
                            return q.attributes.ehr_encounter_note;
                        }
                    });

                    const { ehrNoteHideUnanswered } = ctrl.branchSettings;
                    if (ehrNoteHideUnanswered) {
                        ctrl.questions = ctrl.questions.filter((q) => q.answer !== '<unanswered>');
                    }

                    ctrl.questionTree = _.keyBy(ctrl.questions, 'questionId');
                    for (const question of Object.values(ctrl.questions)) {
                        ctrl.questionTree = getParent(question, questions, ctrl.questionTree);
                    }
                    ctrl.questionTree = amsService.attachChildren(ctrl.questionTree);
                    ctrl.questionTree = Object.values(ctrl.questionTree).sort(amsService.sortQuestions);
                }

                if (ctrl.resolve.modalData.summaryNote) {
                    ctrl.summaryNote = angular.copy(ctrl.resolve.modalData.summaryNote);
                }

                if (ctrl.resolve.modalData.user) {
                    ctrl.user = angular.copy(ctrl.resolve.modalData.user);
                }
            }
        }
    }

    function getParent(question, allQuestions, tree) {
        if (question.parentQuestionId) {
            const parent = _.find(allQuestions, { questionId: question.parentQuestionId });
            if (parent) {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                tree[parent.questionId] = parent;
                if (parent.parentQuestionId && !tree[parent.parentQuestionId]) {
                    getParent(parent, allQuestions, tree);
                }
            }
        }
        return tree;
    }

    function $onInit() {
        ctrl.now = moment.utc().format('YYYY/MM/DD HH:mm:ss z');
        const jwt = sessionStorageService.getJwt();
        const token = jwtHelper.decodeToken(jwt);
        const activityUrl = `${urlBuilderService.getHost()}/#!/patient/${ctrl.patient.id}/activity/${ctrl.activity.id}`;
        const ssoEnabled = token.data.CompanyPermissions.SsoEnabled === true;
        ctrl.activityUrl = activityUrl;
        if (ssoEnabled === true) {
            ctrl.activityUrl = `${token.data.CompanyPermissions.SsoLogin}?redirect=${encodeURIComponent(activityUrl)}`;
        }
    }

    function selectPrescriber() {
        ctrl.ctaText = 'Send to EHR';
        ctrl.modalTitle = 'Provider';
        ctrl.modalAction = 'associatePrescriber';
        buildPrescriberList(ctrl.patient.id, ctrl.activity.id);
    }

    function selectVisit() {
        ctrl.ctaText = ctrl.requirePrescriber && !ctrl.associatedPrescriberId ? 'Select Prescriber' : 'Send to EHR';
        ctrl.modalTitle = 'Associate Visit';
        ctrl.modalAction = 'associateVisit';
        ctrl.patientVisits = null;

        PESService.getVisits(ctrl.patient.id)
            .then((res) => {
                ctrl.patientVisits = Object.values(res).map((v) => {
                    const visitDate = moment.utc(v.visitDateTime);
                    let providerName = '--';
                    if (!!v.providerFirstName || !!v.providerLastName) {
                        providerName = `${v.providerFirstName} ${v.providerLastName}`;
                    }
                    return {
                        visitId: v.visitId,
                        facilityId: v.facilityId,
                        label: `${v.visitId}, ${visitDate.isValid() ? visitDate.format('YYYY/MM/DD') : '--'}, ${
                            v.department || '--'
                        }, ${providerName}`,
                    };
                });
            })
            .catch(() => {
                toast.error('Unable to load patient visits.');
            });
    }

    function goBack() {
        if (ctrl.modalAction === 'associateVisit') {
            ctrl.ctaText = 'Next';
            ctrl.associatedVisitId = null;
            ctrl.modalAction = 'review';
        } else if (ctrl.modalAction === 'associatePrescriber') {
            ctrl.ctaText = 'Next';
            ctrl.associatedPrescriberId = null;
            ctrl.modalAction = ctrl.requireVisit ? 'associateVisit' : 'review';
        } else {
            ctrl.dismiss();
        }
    }

    function submitNote({ sendToEhr }) {
        if (ctrl.modalAction === 'review') {
            let ehrMessage = '<div>';
            ehrMessage += angular.element('ehr-encounter-note-modal .modal-header')[0].outerHTML;
            ehrMessage += angular.element('ehr-encounter-note-modal .modal-body')[0].outerHTML;
            ehrMessage += '</div>';
            ehrMessage = ehrMessage
                .replaceAll('<!-- ngIf: $ctrl.questions.length > 0 -->', '')
                .replaceAll('<!-- ngRepeat: q in $ctrl.questions -->', '')
                .replaceAll('<!-- end ngRepeat: q in $ctrl.questions -->', '')
                .replaceAll('<!-- end ngIf: $ctrl.questions.length > 0 -->', '');
            // RTF needs single carriage return
            if (ctrl.branchSettings.ehrNoteType === 'RTF') {
                ehrMessage = ehrMessage.replaceAll('<br><br>', '<br>');
            }
            ctrl.ehrMessage = ehrMessage;
        }

        let facilityId = null;
        if (ctrl.associatedVisitId) {
            const visit = _.find(ctrl.patientVisits, { visitId: ctrl.associatedVisitId });
            facilityId = visit ? visit.facilityId : null;
        }

        if (ctrl.requireVisit && !!ctrl.associatedVisitId === false) {
            ctrl.visitValidation = false;
            selectVisit();
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-bitwise
        } else if (ctrl.requirePrescriber & (!!ctrl.associatedPrescriberId === false)) {
            ctrl.prescriberValidation = false;
            selectPrescriber();
        } else {
            ctrl.close({
                $value: {
                    message: ctrl.ehrMessage,
                    visitId: ctrl.associatedVisitId,
                    ehrProviderId: ctrl.user.user_attributes.ehrProviderId,
                    facilityId,
                    prescriberId: ctrl.associatedPrescriberId,
                    sendToEhr,
                },
            });
        }
    }

    function buildPrescriberList(patientId, activityId) {
        ActivityMedService.getMedicationAssociations(patientId, activityId).then((res) => {
            ctrl.activityMedicationAssociations = res;
            const medications = Object.values(ctrl.activityMedicationAssociations);
            if (medications && medications.length > 0) {
                const prescribers = medications.filter((med) => {
                    const physician = path(['rx', 'physician'], med);
                    return physician && physician.npi && physician.npi.length;
                });
                ctrl.prescriberSelectionList = prescribers.map((med) => {
                    const { id, firstName, lastName, npi } = med.rx.physician;
                    return {
                        prescriberId: id,
                        label: `${firstName}, ${lastName}, ${npi}, ${med.medication.medicationName}`,
                    };
                });

                // Add prescribers for associated medications with no rx number
                const medicationsWithoutRx = medications.filter((med) => isNil(med.rx));
                ctrl.prescriberSelectionList = ctrl.prescriberSelectionList.concat(
                    flatten(medicationsWithoutRx.map((med) => getPrescribersForMedWithNoRx(med)))
                );
            }
        });
    }

    function getPrescribersForMedWithNoRx(med) {
        return ctrl.prescriptions
            .filter((p) => p.medication.id === med.medication.medicationId && !isNil(p.physician))
            .map((p) => p.physician)
            .map((p) => {
                return {
                    prescriberId: p.id,
                    label: `${p.firstName}, ${p.lastName}, ${p.npi}, ${med.medication.medicationName}`,
                };
            });
    }
}

export default EhrEncounterNoteModalController;
