import {useMutation, useQueryClient} from 'react-query';
import {createAppealDocuments} from 'App/services/RMSService';

function useCreateAppealDocumentMutation(patientId, referralId, activityId) {
    const queryClient = useQueryClient();

    return useMutation(
        (payload) => {
            return createAppealDocuments(patientId, referralId, activityId, payload);
        },
        {
            onSuccess() {
                const key = [
                    'patient',
                    patientId,
                    'referral',
                    referralId,
                    'appeal',
                    String(activityId),
                    'appealDocuments',
                ];

                queryClient.refetchQueries(key);
            },
        }
    );
}

export default useCreateAppealDocumentMutation;
