import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import Scrollspy from 'react-scrollspy';

import ScrollToElementLink from './ScrollToElementLink';
import { scrollContainerId } from './constants';
import { questionGroupPropType } from './types';
import getGroupScrollId from './utils/getGroupScrollId';

const ScrollspyStyle = styled(Scrollspy)`
    background-color: #f1f6f9;
    padding: 10px;
    max-width: 200px;
    flex-shrink: 0;
    overflow: auto;
`;

const ScrollspyItemStyle = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 1.2rem;
    color: var(--matisse);

    &.active {
        color: var(--biscay);
    }

    & a {
        color: inherit;

        &:focus {
            text-decoration: none;
        }
    }
`;

const ScrollspyItemIcon = styled.div`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    background-color: var(--matisse);
    color: white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    margin-right: 5px;

    .active & {
        background-color: var(--biscay);
    }
`;

const AssessmentScrollspy = ({ questionGroups, rootElementSelector }) => {
    return (
        <ScrollspyStyle
            items={questionGroups.map(getGroupScrollId)}
            currentClassName="active"
            componentTag="div"
            rootEl={rootElementSelector}
            className="assessment-scrollspy"
        >
            {questionGroups.map((group, index) => (
                <ScrollspyItemStyle key={group.id}>
                    <ScrollspyItemIcon>{index + 1}</ScrollspyItemIcon>
                    <ScrollToElementLink elementId={getGroupScrollId(group)}>
                        {group.name || 'Questions'}
                    </ScrollToElementLink>
                </ScrollspyItemStyle>
            ))}
        </ScrollspyStyle>
    );
};

AssessmentScrollspy.propTypes = {
    questionGroups: PropTypes.arrayOf(questionGroupPropType).isRequired,
    rootElementSelector: PropTypes.string,
};

AssessmentScrollspy.defaultProps = {
    rootElementSelector: `#${scrollContainerId}`,
};

export default AssessmentScrollspy;
