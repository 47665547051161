import createAxios from 'App/utils/createAxios';

const { protocol, host, port } = window.location;
const therigyURL = `${protocol}//${host}${port ? `:${port}` : ''}`;
const phpRequest = createAxios({
    baseURL: `${therigyURL}/app.api.php/api`,
});

export function search(inputText) {
    if (!inputText) return {};
    return phpRequest.get(`/resource/rxnconso?CODE=${inputText}&STR=${inputText}`);
}
