import React, { useMemo } from 'react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SelectField from 'Lib/form/SelectField';
import { TextField } from 'Lib/form/TextField';
import { CheckboxField } from 'Lib/form/CheckboxField';
import { useFormikContext } from 'formik';
import * as R from 'ramda';
import { STATUS_TYPE, booleanOptions, faSourceOptions, denialReasonOptions } from '../constants';
import { DatePickerField } from 'Lib/form/DatePickerField';
import { FaExclamationCircle } from 'react-icons/fa';
import FAMedicationTable from './FAMedicationTable';
import { NumberField } from 'Lib/form/NumberField';
import PropTypes from 'prop-types';
import { PhoneField } from 'App/components/PatientForm/components/PhoneField';

const propTypes = {
    isFormReadOnly: PropTypes.bool.isRequired,
};

const defaultProps = {};

const tooltip = <Tooltip id="actions-tooltip"> Must have an applicable protocol enabled. </Tooltip>;

function setInitialStatus(statusNames) {
    return R.compose(
        R.map(
            R.applySpec({
                id: R.identity,
                name: R.identity,
                disabled: R.compose(R.ifElse(R.equals(STATUS_TYPE.new), R.T, R.F)),
            })
        ),
        R.values,
        R.pick(statusNames)
    )(STATUS_TYPE);
}

function FAInformationForm({ isFormReadOnly }) {
    const { initialValues, values, setTouched, touched, setValues } = useFormikContext();

    const originalStatus = R.path(['status', 'id'], initialValues);

    const statusOptions = useMemo(() => {
        if (originalStatus === STATUS_TYPE.new) {
            return setInitialStatus(['new', 'pendingSubmission', 'submitted']);
        }

        if (originalStatus === STATUS_TYPE.pendingSubmission) {
            return setInitialStatus(['pendingSubmission', 'submitted']);
        }

        if (originalStatus === STATUS_TYPE.submitted) {
            return setInitialStatus(['pendingSubmission', 'submitted', 'partiallyApproved', 'approved', 'denied']);
        }

        return R.compose(
            R.map(R.applySpec({ id: R.identity, name: R.identity, disabled: R.F })),
            R.values
        )(STATUS_TYPE);
    }, [originalStatus]);

    return (
        <>
            <Row>
                <Col md={3}>
                    <SelectField
                        options={statusOptions}
                        label="Status"
                        fieldName="status"
                        getOptionValue={R.prop('id')}
                        getOptionLabel={R.prop('name')}
                        isDisabled={isFormReadOnly}
                        onChange={(option) => {
                            let nextValues = values;
                            let nextTouched = touched;

                            if (
                                STATUS_TYPE.pendingSubmission === option?.id ||
                                STATUS_TYPE.submitted === option?.id ||
                                STATUS_TYPE.new === option?.id ||
                                STATUS_TYPE.denied === option?.id
                            ) {
                                nextValues = R.compose(
                                    R.set(R.lensPath(['details', 'effectiveStartDate']), null),
                                    R.set(R.lensPath(['details', 'effectiveEndDate']), null),
                                    R.set(R.lensPath(['details', 'amountFunded']), null),
                                    R.set(R.lensPath(['details', 'otherAssistance']), null),
                                    R.set(R.lensPath(['details', 'fundingSource']), null),
                                    R.set(R.lensPath(['details', 'isCoveringMedicationCost']), null),
                                    R.set(R.lensPath(['details', 'isReEnrollmentRequired']), null),
                                    R.set(R.lensPath(['details', 'fundingSourceOther']), null)
                                )(nextValues);

                                nextTouched = R.compose(
                                    R.set(R.lensPath(['details', 'applicationSubmission']), null),
                                    R.set(R.lensPath(['details', 'effectiveStartDate']), null),
                                    R.set(R.lensPath(['details', 'effectiveEndDate']), null),
                                    R.set(R.lensPath(['details', 'amountFunded']), null),
                                    R.set(R.lensPath(['details', 'otherAssistance']), null),
                                    R.set(R.lensPath(['details', 'fundingSource']), null),
                                    R.set(R.lensPath(['details', 'isCoveringMedicationCost']), null),
                                    R.set(R.lensPath(['details', 'isReEnrollmentRequired']), null),
                                    R.set(R.lensPath(['details', 'fundingSourceOther']), null)
                                )(touched);
                            }

                            if (STATUS_TYPE.denied !== option?.id) {
                                nextValues = R.compose(R.set(R.lensPath(['details', 'denialReason']), null))(
                                    nextValues
                                );

                                nextTouched = R.compose(R.set(R.lensPath(['details', 'denialReason']), false))(
                                    nextTouched
                                );
                            }

                            if (STATUS_TYPE.denied === option?.id) {
                                nextValues = R.compose(
                                    R.set(R.lensPath(['details', 'fileApplication']), { id: 'No', name: 'No' })
                                )(nextValues);

                                nextTouched = R.compose(R.set(R.lensPath(['details', 'fileApplication']), false))(
                                    nextTouched
                                );
                            }

                            if (
                                STATUS_TYPE.pendingSubmission === option?.id ||
                                STATUS_TYPE.new === option?.id ||
                                STATUS_TYPE.denied === option?.id
                            ) {
                                nextValues = R.compose(R.set(R.lensPath(['details', 'applicationSubmission']), null))(
                                    nextValues
                                );

                                nextTouched = R.compose(R.set(R.lensPath(['details', 'applicationSubmission']), false))(
                                    nextTouched
                                );
                            }

                            R.compose(setValues, R.set(R.lensProp('status'), option))(nextValues);

                            setTouched(nextTouched);
                        }}
                    />
                </Col>
                {values?.status?.id !== STATUS_TYPE.denied && (
                    <Col md={3}>
                        <SelectField
                            options={booleanOptions.map((it) => ({ id: it, name: it }))}
                            label="Did the patient file an application?"
                            fieldName="details.fileApplication"
                            getOptionValue={R.prop('id')}
                            getOptionLabel={R.prop('name')}
                            isDisabled={isFormReadOnly}
                        />
                    </Col>
                )}
                {[
                    STATUS_TYPE.submitted,
                    STATUS_TYPE.deleted,
                    STATUS_TYPE.partiallyApproved,
                    STATUS_TYPE.approved,
                ].includes(values?.status?.id) && (
                    <Col md={3}>
                        <DatePickerField
                            fieldName="details.applicationSubmission"
                            label="Application Submission Date"
                            disabled={isFormReadOnly}
                            placeholder="YYYY/MM/DD"
                        />
                    </Col>
                )}
            </Row>

            <FAMedicationTable />
            <h3> Available Program </h3>
            <div>
                <FaExclamationCircle /> There is no program selected.
            </div>

            <h3> Selected Program </h3>
            <div>
                <FaExclamationCircle /> There is no program selected.
            </div>

            <div style={{ marginTop: '20px' }}>
                <Row>
                    <Col md={3}>
                        <PhoneField
                            disabled={isFormReadOnly}
                            fieldName="details.followUpPhone"
                            label="Follow Up Phone Number"
                            placeholder="(###) ###-####"
                        />
                    </Col>
                </Row>
            </div>

            {(values?.status?.id === STATUS_TYPE.partiallyApproved || values?.status?.id === STATUS_TYPE.approved) && (
                <>
                    <Row>
                        <Col md={3}>
                            <DatePickerField
                                fieldName="details.effectiveStartDate"
                                label="Effective Start Date"
                                disabled={isFormReadOnly}
                                placeholder="YYYY/MM/DD"
                            />
                        </Col>

                        <Col md={3}>
                            <DatePickerField
                                fieldName="details.effectiveEndDate"
                                label="Effective End Date"
                                disabled={isFormReadOnly}
                                placeholder="YYYY/MM/DD"
                            />
                        </Col>

                        <Col md={3}>
                            <NumberField
                                label="Amount Funded"
                                placeholder="Amount Funded"
                                disabled={isFormReadOnly}
                                fieldName="details.amountFunded"
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={3}>
                            <NumberField
                                label="Other Assistance Approved"
                                placeholder="Other Assistance Approved"
                                disabled={isFormReadOnly}
                                fieldName="details.otherAssistance"
                            />
                        </Col>
                        <Col md={3}>
                            <SelectField
                                options={faSourceOptions.map((it) => ({ id: it, name: it }))}
                                label="Financial Assistance Source?"
                                fieldName="details.fundingSource"
                                getOptionValue={R.prop('id')}
                                getOptionLabel={R.prop('name')}
                                isDisabled={isFormReadOnly}
                            />
                        </Col>

                        <Col md={3}>
                            <SelectField
                                options={booleanOptions.map((it) => ({ id: it, name: it }))}
                                label="Is it covering part/all of medication cost?"
                                fieldName="details.isCoveringMedicationCost"
                                getOptionValue={R.prop('id')}
                                getOptionLabel={R.prop('name')}
                                isDisabled={isFormReadOnly}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <SelectField
                                options={booleanOptions.map((it) => ({ id: it, name: it }))}
                                label="Is re-enrollment to the program required?"
                                fieldName="details.isReEnrollmentRequired"
                                getOptionValue={R.prop('id')}
                                getOptionLabel={R.prop('name')}
                                isDisabled={isFormReadOnly}
                            />
                        </Col>
                        {values?.details?.fundingSource?.id === 'Other' && (
                            <Col md={3}>
                                <TextField
                                    label="Other Source?"
                                    fieldName="details.fundingSourceOther"
                                    disabled={isFormReadOnly}
                                />
                            </Col>
                        )}
                    </Row>

                    <Row>
                        <Col md={4}>
                            <CheckboxField
                                disabled={isFormReadOnly}
                                fieldName="details.scheduleFollowUpActivity"
                                label={
                                    <>
                                        Create a Financial Assistance Renewal activity{' '}
                                        <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={tooltip}>
                                            <FaExclamationCircle data-testid="icon" />
                                        </OverlayTrigger>
                                    </>
                                }
                            />
                        </Col>
                    </Row>
                </>
            )}

            {values?.status?.id === STATUS_TYPE.denied && (
                <Row>
                    <Col md={3}>
                        <SelectField
                            options={denialReasonOptions.map((it) => ({ id: it, name: it }))}
                            label="Denial Reason"
                            fieldName="details.denialReason"
                            getOptionValue={R.prop('id')}
                            getOptionLabel={R.prop('name')}
                            isDisabled={isFormReadOnly}
                        />
                    </Col>
                </Row>
            )}
        </>
    );
}

FAInformationForm.propTypes = propTypes;
FAInformationForm.defaultProps = defaultProps;

export default FAInformationForm;
