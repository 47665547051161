import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { generateReferralLink } from 'App/components/PatientProfile/PatientProfile.utils.js';

const defaultProps = {};

const ActivityNameCell = ({ row, value }) => {
    if (row.original.type === 'REF') {
        const params = {
            patientId: row.original.patientId,
            referralId: row.original.referralId,
            activityId: row.original.activityId,
            typeId: row.original.referralType,
        };
        return (
            <Link id={`pending-activity-${row.id}`} to={generateReferralLink(params)}>
                {value}
            </Link>
        );
    }
    const { patientId, activityId, divisionId } = row.original;
    return (
        <Link
            id={`pending-activity-${row.id}`}
            to={`/patient/${patientId}/activity/${activityId}?branchId=${divisionId}`}
        >
            {value}
        </Link>
    );
};

ActivityNameCell.propTypes = {
    row: PropTypes.shape({
        id: PropTypes.string,
        original: PropTypes.shape({
            patientId: PropTypes.string,
            referralId: PropTypes.number,
            type: PropTypes.string,
            referralType: PropTypes.number,
            activityId: PropTypes.string,
            divisionId: PropTypes.string,
        }),
    }).isRequired,
    value: PropTypes.string.isRequired,
};
ActivityNameCell.defaultProps = defaultProps;

export default ActivityNameCell;
