import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const propTypes = {
    ehr: PropTypes.shape({
        branchSettings: PropTypes.shape({
            ehrNoteType: PropTypes.string,
            ehrType: PropTypes.string,
            ehrPrimaryIdType: PropTypes.string,
        }),
        patientSynced: PropTypes.bool,
        syncingWithEhr: PropTypes.bool,
    }).isRequired,
    status: PropTypes.string,
};

const MarginSpan = styled('span')`
    margin-left: 4%;
`;
const defaultProps = {
    status: '',
};
const PatientEhrTile = (props) => {
    const { ehr, status } = props;

    return (
        <div>
            Patient Status: <strong>{status}</strong>
            {ehr?.branchSettings?.ehrType && ehr?.branchSettings?.ehrNoteType && (
                <div data-testid="patientEhr">
                    {ehr?.syncingWithEhr ? (
                        <div data-testid="syncingWithEhr">
                            <i className="fa fa-spinner fa-pulse fa-fw" />
                            Syncing with EHR
                        </div>
                    ) : (
                        <div data-testid="syncedToEhr">
                            <i
                                data-testid="synced-to-ehr-icon"
                                className={
                                    ehr?.patientSynced
                                        ? 'fa fa-check-circle text-success'
                                        : 'fa fa-times-circle text-danger'
                                }
                            />
                            <MarginSpan data-testid="syncedToEhrLabel">Synced to EHR</MarginSpan>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

PatientEhrTile.propTypes = propTypes;
PatientEhrTile.defaultProps = defaultProps;

export default PatientEhrTile;
