import React from 'react';
import { cellPropsType } from '../../workQueue.constant';

const MedicationAssociationInsuranceCell = ({ value }) => {
    if (value !== '--') {
        const data = JSON.parse(value);
        return <span>{data.length > 0 ? data.join(', ') : '--'}</span>;
    }
    return <span>{value}</span>;
};

MedicationAssociationInsuranceCell.propTypes = cellPropsType;

export default MedicationAssociationInsuranceCell;
