import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';

const propTypes = {};

const defaultProps = {};

function NoPreviousEnrollmentMessage() {
    return (
        <>
            <Row className="patienteducation-message">
                <Col sm={12}>
                    <p>
                        <span>
                            <FaInfoCircle className="info-icon" /> Submitting this form will send an OPT IN text message
                            to the patient.{' '}
                        </span>
                        <span>
                            <b>Once the patient texts back YES, they will be enrolled in mobile messaging.</b>{' '}
                        </span>
                        <span>
                            Following enrollment, the patient will receive text message(s) with a hyperlink to the
                            mobile-enabled assessment(s) based on the assessment&apos;s due date.{' '}
                        </span>
                    </p>
                </Col>
            </Row>
            <Row className="patienteducation-message">
                <Col sm={12}>
                    <span>
                        <b>
                            The patient may opt out of text communications anytime by replying &quot;UNSUBSCRIBE&quot;
                            to 833-354-1731{' '}
                        </b>
                    </span>
                    <span>(the number from which texts will be sent).</span>
                </Col>
            </Row>
            <Row className="patienteducation-message">
                <Col sm={12}>
                    <span>
                        The patient must be enrolled prior to creating the activity for the activity to send via text
                        message.
                    </span>
                </Col>
            </Row>
            <Row className="patienteducation-message">
                <Col sm={12}>
                    <span>
                        Disclaimer: Text messages are <strong>unsecure and unencrypted</strong> (unless the patient has
                        taken additional security precautions). These text communications are governed by your
                        pharmacy&apos;s Notice of Privacy Practices.
                    </span>
                </Col>
            </Row>
        </>
    );
}

NoPreviousEnrollmentMessage.propTypes = propTypes;
NoPreviousEnrollmentMessage.defaultProps = defaultProps;

export default NoPreviousEnrollmentMessage;
