import { useFormikContext } from 'formik';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import createLabelValueOption from 'App/utils/createLabelValueOption';
import useFetchICD10Autocomplete from '../hooks/useFetchICD10Autocomplete';
import PatientFormTypeaheadField from './PatientFormTypeaheadField';

const formatOption = (option) => {
    return `${option.code} ${option.shortDescription}`;
};

const OtherDiagnosisTypeaheadField = ({ fieldName, ...rest }) => {
    const { values } = useFormikContext();
    const fieldValue = get(values, fieldName);
    const { data: options = {}, isLoading } = useFetchICD10Autocomplete(fieldValue, 5);
    const optionsList = Object.values(options).map((o) => createLabelValueOption(formatOption(o)));

    return (
        <PatientFormTypeaheadField
            {...rest}
            fieldName={fieldName}
            options={optionsList}
            isLoading={isLoading}
            label="Other Diagnosis"
            placeholder="Enter an Additional Diagnosis"
        />
    );
};

OtherDiagnosisTypeaheadField.propTypes = {
    fieldName: PropTypes.string.isRequired,
};

export default OtherDiagnosisTypeaheadField;
