import angular from 'angular';

import AssessmentController from './assessment.controller.js';
import AssessmentConfig from './assessment.config.js';
import AssessmentGroupController from './group/group.controller';
import ConfirmDeleteModalController from './confirm-delete-modal/confirm-delete-modal.controller';
import ConfirmDeleteChildrenModalController from './confirm-delete-children-modal/confirm-delete-children-modal.controller';
import LockAssessmentModalController from './lock-assessment-modal/lock-assessment-modal.controller';
import MedicationAssociationController from './medication-association/medication-association.controller';
import AssessmentProgressBarController from './progress-bar/progress-bar.controller';
import AssessmentContentModalController from '../content-display/content-modal/content-modal.controller';
import MonographLinkController from '../content-display/monograph-link/monograph-link.controller';

// Assessment Questions controllers
import AssessmentQuestionActivityDateController from './question/activity-date/activity-date.controller';
import AssessmentQuestionCheckboxController from './question/checkbox/checkbox.controller';
import AssessmentQuestionDateController from './question/date/date.controller';
import AssessmentQuestionMessageController from './question/message/message.controller';
import AssessmentQuestionNeedByDateController from './question/need-by-date/need-by-date.controller';
import AssessmentQuestionNumberController from './question/number/number.controller';
import AssessmentQuestionRadioController from './question/radio/radio.controller';
import AssessmentQuestionRapid3FunctionalScoreController from './question/rapid3/functional-score/functional-score.controller';
import AssessmentQuestionRapid3TotalScoreController from './question/rapid3/total-score/total-score.controller';
import AssessmentQuestionSelectController from './question/select/select.controller';
import AssessmentQuestionTextareaController from './question/textarea/textarea.controller';
import AssessmentQuestionTextboxController from './question/textbox/textbox.controller';
import AssessmentQuestionController from './question/question.controller';
import AssessmentScoringController from './scoring/scoring.controller.js';
import AssessmentScrollSpyController from './scrollspy/scrollspy.controller.js';
import EhrEncounterNoteModalController from './ehr-encounter-note-modal/ehr-encounter-note-modal.controller';
import EhrEncounterNoteQuestionController from './ehr-encounter-note-modal/question/ehr-encounter-note-question.controller';
import SendEmrSummaryNoteModalController from './send-emr-summary-note-modal/send-emr-summary-note-modal.compotent.js';

export default angular
    .module('components.assessment', ['angular-ladda', 'duScroll', 'ui.router'])
    .value('duScrollOffset', 45)
    .component('assessment', {
        bindings: {
            activity: '<',
            patient: '<',
            groups: '<',
            user: '<',
            branchSettings: '<',
            transitionParams: '<',
        },
        controller: AssessmentController,
        template: require('./assessment.html'),
    })
    .config(AssessmentConfig)
    .component('confirmDeleteModal', {
        bindings: {
            close: '&',
            dismiss: '&',
            modalInstance: '<',
            resolve: '<',
            response: '<',
        },
        controller: ConfirmDeleteModalController,
        template: require('./confirm-delete-modal/confirm-delete-modal.html'),
    })
    .component('confirmDeleteChildrenModal', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
            response: '<',
        },
        controller: ConfirmDeleteChildrenModalController,
        template: require('./confirm-delete-children-modal/confirm-delete-children-modal.html'),
    })
    .component('assessmentGroup', {
        bindings: {
            activity: '<',
            fullyScriptedMode: '<',
            group: '<',
            questions: '<',
            patientTherapies: '<',
            patientMedications: '<',
        },
        controller: AssessmentGroupController,
        template: require('./group/group.html'),
    })
    .component('lockAssessmentModal', {
        bindings: {
            close: '&',
            modalInstance: '<',
            resolve: '<',
        },
        controller: LockAssessmentModalController,
        template: require('./lock-assessment-modal/lock-assessment-modal.html'),
    })
    .component('medicationAssociation', {
        bindings: {
            activity: '<',
            patient: '<',
        },
        controller: MedicationAssociationController,
        require: {
            form: '^form',
        },
        template: require('./medication-association/medication-association.html'),
    })
    .component('assessmentProgressBar', {
        bindings: {
            questionGroups: '<',
            visibleQuestionGroupIds: '<',
        },
        controller: AssessmentProgressBarController,
        template: require('./progress-bar/progress-bar.html'),
    })
    .component('assessmentQuestionActivityDate', {
        bindings: {
            activity: '<',
            fullyScriptedMode: '<',
            onChangeAnswer: '&',
            question: '<',
        },
        controller: AssessmentQuestionActivityDateController,
        template: require('./question/activity-date/activity-date.html'),
    })
    .component('assessmentQuestionCheckbox', {
        bindings: {
            activity: '<',
            fullyScriptedMode: '<',
            getNumberOfAnswers: '&',
            onChangeAnswer: '&',
            question: '<',
            patientTherapies: '<',
            patientMedications: '<',
        },
        controller: AssessmentQuestionCheckboxController,
        template: require('./question/checkbox/checkbox.html'),
    })
    .component('assessmentQuestionDate', {
        bindings: {
            activity: '<',
            fullyScriptedMode: '<',
            onChangeAnswer: '&',
            question: '<',
        },
        controller: AssessmentQuestionDateController,
        require: {
            form: '^form',
            model: 'ngModel',
        },
        template: require('./question/date/date.html'),
    })
    .component('assessmentQuestionMessage', {
        bindings: {
            fullyScriptedMode: '<',
            question: '<',
        },
        controller: AssessmentQuestionMessageController,
        require: {
            form: '^form',
        },
        template: require('./question/message/message.html'),
    })
    .component('assessmentQuestionNeedByDate', {
        bindings: {
            activity: '<',
            fullyScriptedMode: '<',
            onChangeAnswer: '&',
            question: '<',
        },
        controller: AssessmentQuestionNeedByDateController,
        template: require('./question/need-by-date/need-by-date.html'),
    })
    .component('assessmentQuestionNumber', {
        bindings: {
            activity: '<',
            fullyScriptedMode: '<',
            onChangeAnswer: '&',
            question: '<',
        },
        controller: AssessmentQuestionNumberController,
        template: require('./question/number/number.html'),
    })
    .component('assessmentQuestionRadio', {
        bindings: {
            activity: '<',
            fullyScriptedMode: '<',
            getNumberOfAnswers: '&',
            onChangeAnswer: '&',
            question: '<',
            patientTherapies: '<',
            patientMedications: '<',
        },
        controller: AssessmentQuestionRadioController,
        template: require('./question/radio/radio.html'),
    })
    .component('assessmentQuestionRapid3FunctionalScore', {
        bindings: {
            activity: '<',
            fullyScriptedMode: '<',
            question: '<',
        },
        controller: AssessmentQuestionRapid3FunctionalScoreController,
        template: require('./question/rapid3/functional-score/functional-score.html'),
    })
    .component('assessmentQuestionRapid3TotalScore', {
        bindings: {
            activity: '<',
            fullyScriptedMode: '<',
            question: '<',
        },
        controller: AssessmentQuestionRapid3TotalScoreController,
        template: require('./question/rapid3/total-score/total-score.html'),
    })
    .component('assessmentQuestionSelect', {
        bindings: {
            activity: '<',
            fullyScriptedMode: '<',
            getNumberOfAnswers: '&',
            onChangeAnswer: '&',
            question: '<',
            patientTherapies: '<',
            patientMedications: '<',
        },
        controller: AssessmentQuestionSelectController,
        template: require('./question/select/select.html'),
    })
    .component('assessmentQuestionTextarea', {
        bindings: {
            activity: '<',
            fullyScriptedMode: '<',
            onChangeAnswer: '&',
            question: '<',
        },
        controller: AssessmentQuestionTextareaController,
        template: require('./question/textarea/textarea.html'),
    })
    .component('assessmentQuestionTextbox', {
        bindings: {
            activity: '<',
            fullyScriptedMode: '<',
            onChangeAnswer: '&',
            question: '<',
        },
        controller: AssessmentQuestionTextboxController,
        template: require('./question/textbox/textbox.html'),
    })
    .component('assessmentQuestion', {
        bindings: {
            activity: '<',
            fullyScriptedMode: '<',
            question: '<',
            patientTherapies: '<',
            patientMedications: '<',
        },
        controller: AssessmentQuestionController,
        require: {
            form: '^form',
        },
        template: require('./question/question.html'),
    })
    .component('saveResponseRetryModal', {
        bindings: {
            close: '&',
        },
        template: require('./save-response-retry-modal/save-response-retry-modal.html'),
    })
    .component('assessmentScoring', {
        bindings: {
            scores: '<',
            scoring: '<',
        },
        controller: AssessmentScoringController,
        template: require('./scoring/scoring.html'),
    })
    .component('assessmentScrollspy', {
        bindings: {
            questionGroups: '<',
            visibleQuestionGroupIds: '<',
        },
        controller: AssessmentScrollSpyController,
        template: require('./scrollspy/scrollspy.html'),
    })
    .component('sendEmrSummaryNoteModal', {
        bindings: {
            close: '&',
            modalInstance: '<',
        },
        controller: SendEmrSummaryNoteModalController,
        template: require('./send-emr-summary-note-modal/send-emr-summary-note-modal.html'),
    })
    .component('assessmentContentModal', {
        bindings: {
            close: '&',
            resolve: '<',
        },
        controller: AssessmentContentModalController,
        template: require('../content-display/content-modal/content-modal.html'),
    })
    .component('contentLibraryResourceLink', {
        bindings: {
            contentResourceLabel: '@',
            contentResourceName: '@',
            contentResourceValue: '@',
        },
        controller: MonographLinkController,
        template: require('../content-display/monograph-link/monograph-link.html'),
    })
    .component('ehrEncounterNoteModal', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: EhrEncounterNoteModalController,
        template: require('./ehr-encounter-note-modal/ehr-encounter-note-modal.html'),
    })
    .component('ehrEncounterNoteQuestion', {
        bindings: {
            question: '<',
            hasParent: '<',
        },
        controller: EhrEncounterNoteQuestionController,
        template: require('./ehr-encounter-note-modal/question/ehr-encounter-note-question.html'),
    }).name;
