import aetnaActivityHTML from './aetna-activity.html';

import ReferralActivityAetnaAppealController from './aetna-activity-appeal/aetna-activity-appeal.controller';
import ReferralActivityAetnaAppealConfig from './aetna-activity-appeal/aetna-activity-appeal.config';

import ReferralActivityAetnaBenefitsInvestigationController from './aetna-activity-benefits/aetna-activity-benefits-investigation.controller';
import ReferralActivityAetnaBenefitsInvestigationConfig from './aetna-activity-benefits/aetna-activity-benefits-investigation.config';

import ReferralActivityFinancialAssistanceController from './aetna-activity-financial-assistance/aetna-activity-financial-assistance.controller';
import ReferralActivityFinancialAssistanceConfig from './aetna-activity-financial-assistance/aetna-activity-financial-assistance.config';

import ReferralAetnaActivityPriorAuthorizationController from './aetna-activity-prior-authorization/aetna-activity-prior-authorization.controller';
import ReferralAetnaActivityPriorAuthorizationConfig from './aetna-activity-prior-authorization/aetna-activity-prior-authorization.config';

import AetnaActivityService from './aetna-activity.service';

export default angular
    .module('components.aetnaActivity', ['ngAnimate', 'ngTable', 'ui.router'])
    .component('referralAetnaActivityAppeal', {
        bindings: {
            activity: '<',
            completeStatusReasons: '<',
            patient: '<',
            pendingStatusReasons: '<',
            user: '<',
        },
        controller: ReferralActivityAetnaAppealController,
        template: aetnaActivityHTML,
    })
    .config(ReferralActivityAetnaAppealConfig)
    .component('referralAetnaActivityBenefitsInvestigation', {
        bindings: {
            activity: '<',
            completeStatusReasons: '<',
            patient: '<',
            pendingStatusReasons: '<',
            user: '<',
        },
        controller: ReferralActivityAetnaBenefitsInvestigationController,
        template: aetnaActivityHTML,
    })
    .config(ReferralActivityAetnaBenefitsInvestigationConfig)
    .component('referralAetnaActivityFinancialAssistance', {
        bindings: {
            activity: '<',
            completeStatusReasons: '<',
            patient: '<',
            pendingStatusReasons: '<',
            user: '<',
        },
        controller: ReferralActivityFinancialAssistanceController,
        template: aetnaActivityHTML,
    })
    .config(ReferralActivityFinancialAssistanceConfig)
    .component('referralAetnaActivityPriorAuthorization', {
        bindings: {
            activity: '<',
            completeStatusReasons: '<',
            patient: '<',
            pendingStatusReasons: '<',
            user: '<',
        },
        controller: ReferralAetnaActivityPriorAuthorizationController,
        template: aetnaActivityHTML,
    })
    .config(ReferralAetnaActivityPriorAuthorizationConfig)
    .service('aetnaActivityService', AetnaActivityService).name;
