import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PERMISSION_TYPES } from 'App/utils/isPermitted/isPermitted';
import { Button } from 'react-bootstrap';
import { FaPencilAlt } from 'react-icons/fa';
import FlexContainer from 'App/common/styles/FlexContainer';
import IconWrapper from 'App/common/styles/IconWrapper';
import EditMedicationModal from '../EditMedicationModal/EditMedicationModal';
import useGetReferralMedication from 'App/hooks/useGetReferralMedication';
import * as R from 'ramda';
import MedicationTable from './MedicationTable';
import { queryClient } from 'Lib/queryClient';
import getFeatureFlagStatus from 'App/utils/getFeatureFlagStatus';

const propTypes = {
    patientId: PropTypes.string.isRequired,
    referralId: PropTypes.string.isRequired,
};

const defaultProps = {};

function ReferralMedicationTable({ patientId, referralId }) {
    const isFeatureReferralPMEnabled = getFeatureFlagStatus(PERMISSION_TYPES.FEATURE_REFERRAL_PROTOCOL_MANAGER);
    const { data, isLoading } = useGetReferralMedication(patientId, referralId);
    const [showEditModal, setShowEditModal] = useState(false);
    const handleUpdate = (updatedData) => {
        const updatedDataByMedId = R.indexBy(R.prop('medicationId'), updatedData);
        queryClient.setQueryData(['patient', patientId, 'referral', referralId, 'medication'], (oldData) => {
            return oldData.map((item) => R.mergeDeepRight(item, updatedDataByMedId[item.medicationId] || {}));
        });
    };
    const onHide = () => {
        setShowEditModal(false);
    };

    return (
        <div className="referral-medication">
            <FlexContainer>
                <h3>{isFeatureReferralPMEnabled ? 'Medication' : 'Medications'}</h3>
                <div className="add-create-action">
                    <Button bsStyle="link" onClick={() => setShowEditModal(true)}>
                        <IconWrapper>
                            <FaPencilAlt />
                        </IconWrapper>
                        Edit Medication
                    </Button>
                </div>
            </FlexContainer>
            <MedicationTable data={data} isLoading={isLoading} insideModal={false} />
            {showEditModal && (
                <EditMedicationModal
                    show={showEditModal}
                    onHide={onHide}
                    onUpdate={handleUpdate}
                    patientId={patientId}
                    referralId={referralId}
                />
            )}
        </div>
    );
}

ReferralMedicationTable.propTypes = propTypes;
ReferralMedicationTable.defaultProps = defaultProps;

export default ReferralMedicationTable;
