import * as R from 'ramda';

const DAY_MAP = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
};

function getBlockedDays(blockedDays = []) {
    if (blockedDays) {
        return R.compose(
            R.reduce((result, [day, include]) => {
                const dayNum = DAY_MAP[day];
                if (include && !R.isNil(dayNum)) {
                    return R.append(dayNum, result);
                }
                return result;
            }, []),
            R.toPairs
        )(blockedDays);
    }
    return [];
}

export default getBlockedDays;
