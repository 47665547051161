import toast from 'Lib/toast';

/** @ngInject */
function ReferralAetnaActivityPriorAuthorizationController($state, rmsService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.save = save;

    function $onChanges(changes) {
        if (changes.activity && ctrl.activity) {
            ctrl.activity = angular.copy(ctrl.activity);
            ctrl.original = angular.copy(ctrl.activity);

            ctrl.activity.status = ctrl.activity.status ? ctrl.activity.status : 'Pending';
            ctrl.activity.status_reason = ctrl.activity.status_reason ? ctrl.activity.status_reason : 'New';
        }
    }

    function $onInit() {
        ctrl.activityId = $state.params.activityId;
        ctrl.patientId = $state.params.patientId;
        ctrl.referralId = $state.params.referralId;
    }

    function save() {
        ctrl.saving = true;

        return rmsService
            .updateAetnaActivity(ctrl.patientId, ctrl.referralId, ctrl.activityId, ctrl.activity)
            .then((res) => {
                toast.success('Activity information has been updated successfully.');
                $state.go('app.referral', {
                    patientId: ctrl.patientId,
                    referralId: ctrl.referralId,
                });
                return res;
            })
            .catch(() => {
                toast.error('Activity information did not update successfully. Please try again.');
            })
            .finally(() => {
                ctrl.saving = false;
            });
    }
}

export default ReferralAetnaActivityPriorAuthorizationController;
