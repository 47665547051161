import React, { useState, useEffect, useCallback } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import MedicationManagementTable from 'App/components/MedicationManagement/MedicationManagementTable';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import { errorHandler } from 'App/utils';
import { Formik, Form, Field } from 'formik';
import { getByMedicationIds, getByTherapy, patchSettings } from 'App/services/MedicationManagementService';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner';
import toast from 'Lib/toast';
import PropTypes from 'prop-types';
import PlaceHolderMessage from 'App/components/styles/PlaceholderMessage/PlaceHolderMessage';
import * as R from 'ramda';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
    searchValue: PropTypes.array.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    therapies: PropTypes.array,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    searchType: PropTypes.string.isRequired,
};

const defaultProps = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    searchValue: [],
    therapies: [],
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    searchType: '',
};

function MedicationManagement(props) {
    const [values, setValues] = useState({ medications: [{}], all: { enroll: false, manage: false } });
    const [loadedValues, setLoadedValues] = useState([]);
    const [loading, setLoading] = useState(true);
    const { searchType, searchValue, therapies } = props;

    const loadSettings = useCallback(() => {
        setLoading(true);
        const fetch = searchType === 'medication' ? getByMedicationIds(searchValue) : getByTherapy(searchValue[0]);
        fetch
            .then((res) => {
                const settings = res.medicationSettings || res.therapyMedicationSettings;
                if (searchType === 'medication') {
                    // We want to display the therapy name instead of the medication name for searches by med name.
                    for (const setting of settings) {
                        const therapy = therapies.find((t) => t.id === setting.therapyId);
                        setting.name = therapy ? therapy.name : 'Unknown Therapy';
                    }
                }
                const enroll = settings.every((r) => r.enroll);
                const manage = settings.every((r) => r.manage);
                setLoadedValues(R.clone(settings));
                setValues({ medications: settings, all: { enroll, manage } });
            })
            .catch((error) => {
                errorHandler(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [searchType, searchValue, therapies]);

    useEffect(loadSettings, [loadSettings]);

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-shadow
    const onSubmit = (values) => {
        setLoading(true);
        const postData = values.medications.filter((setting) => {
            const original = loadedValues.find((o) => o.medicationId === setting.medicationId);
            return original.enroll !== setting.enroll || original.manage !== setting.manage;
        });

        // Needs to be able to handle someone selecting/unselecting all on an TC like general.
        const chunks = R.splitEvery(100, postData);
        const promises = [];
        for (const chunk of chunks) {
            promises.push(patchSettings(chunk));
        }
        return Promise.all(promises)
            .then(() => {
                toast('Medications updated.');
            })
            .catch((error) => {
                errorHandler(error);
            })
            .finally(() => {
                return loadSettings();
            });
    };

    const resetForm = () => {
        loadSettings();
    };

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/destructuring-assignment
    if (props.searchValue) {
        return (
            <>
                {loading ? (
                    <PlaceHolderMessage>
                        {' '}
                        <TherigyLoadingSpinner />{' '}
                    </PlaceHolderMessage>
                ) : null}
                <Formik initialValues={values} onSubmit={onSubmit} enableReinitialize>
                    <Form style={{ display: loading ? 'none' : 'block' }}>
                        <Row>
                            <Col md={12} style={{ marginBottom: '2rem' }} />
                        </Row>

                        <Row>
                            <Col md={12}>
                                <Field name="medications" component={MedicationManagementTable} />
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <FormButtonGroup>
                                    <Button
                                        onClick={() => {
                                            resetForm();
                                        }}
                                    >
                                        Reset
                                    </Button>
                                    <Button bsStyle="primary" type="submit" name="submit">
                                        Save
                                    </Button>
                                </FormButtonGroup>
                            </Col>
                        </Row>
                    </Form>
                </Formik>
            </>
        );
    }
    return null;
}

MedicationManagement.propTypes = propTypes;
MedicationManagement.defaultProps = defaultProps;

export default MedicationManagement;
