import { isEmpty, some } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';

import PatientForm from 'App/components/PatientForm/PatientForm';
import TherigyLoadingSpinner from 'App/components/TherigyLoadingSpinner';
import { supportEmail } from 'App/components/config/config';
import { ErrorMessage } from 'App/components/errors';
import FlexCenter from 'App/components/styles/FlexCenter';
import useFetchMultiplePatientMedicationRxNumber from 'App/hooks/useFetchMultiplePatientMedicationRxNumber';
import useFetchPatient from 'App/hooks/useFetchPatient';
import useFetchPatientHighRiskReasons from 'App/hooks/useFetchPatientHighRiskReasons';
import useFetchPatientIdentifiers from 'App/hooks/useFetchPatientIdentifiers';
import useFetchPatientInsurance from 'App/hooks/useFetchPatientInsurance';
import useFetchPatientLabels from 'App/hooks/useFetchPatientLabels';
import useFetchPatientOtherDiagnoses from 'App/hooks/useFetchPatientOtherDiagnoses';
import useFetchPatientOtherMedications from 'App/hooks/useFetchPatientOtherMedications';
import useFetchPatientReferral from 'App/hooks/useFetchPatientReferral';
import useFetchPatientRx from 'App/hooks/useFetchPatientRx';
import useFetchPatientTherapies from 'App/hooks/useFetchPatientTherapies';

const EditPatient = () => {
    const { patientId } = useParams();
    const { data: patient, isLoading: isPatientLoading, isError: isPatientError } = useFetchPatient(patientId);
    const { data: patientLabels, isLoading: isPatientLabelsLoading } = useFetchPatientLabels(patientId);
    const { data: patientHighRiskReasons, isLoading: isPatientHighRiskReasonsLoading } =
        useFetchPatientHighRiskReasons(patientId);
    const { data: patientIdentifiers, isLoading: isPatientIdentifiersLoading } = useFetchPatientIdentifiers(patientId);
    const { data: patientTherapies, isLoading: isPatientTherapiesLoading } = useFetchPatientTherapies(patientId);
    const { data: patientRx, isLoading: isPatientRxLoading } = useFetchPatientRx(patientId);
    const medicationIds = Object.values(patientRx || {}).map((rx) => rx.medicationId);
    const { data: patientRxList, isLoading: isPatientRxListLoading } = useFetchMultiplePatientMedicationRxNumber(
        patientId,
        medicationIds
    );
    const { data: patientReferral, isLoading: isPatientReferralLoading } = useFetchPatientReferral(patientId);
    const { data: patientOtherDiagnoses, isLoading: isPatientOtherDiagnosesLoading } =
        useFetchPatientOtherDiagnoses(patientId);
    const { data: patientOtherMedications, isLoading: isPatientOtherMedicationsLoading } =
        useFetchPatientOtherMedications(patientId);
    const { data: patientInsurance, isLoading: isPatientInsuranceLoading } = useFetchPatientInsurance(patientId);

    if (
        some([
            isPatientLoading,
            isPatientLabelsLoading,
            isPatientHighRiskReasonsLoading,
            isPatientIdentifiersLoading,
            isPatientTherapiesLoading,
            isPatientRxLoading,
            isPatientRxListLoading,
            isPatientReferralLoading,
            isPatientOtherDiagnosesLoading,
            isPatientOtherMedicationsLoading,
            isPatientInsuranceLoading,
        ])
    ) {
        return (
            <FlexCenter>
                <TherigyLoadingSpinner />
            </FlexCenter>
        );
    }

    if (isPatientError) {
        return (
            <ErrorMessage>
                <p>Error: You do not have access to edit this patient.</p>
                If you believe that this has been done in error, please contact Therigy at{' '}
                <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
            </ErrorMessage>
        );
    }

    const patientOtherId = Object.values(patientIdentifiers).find(
        (ident) => ident.identifierType === 'stm_other_id'
    )?.identifier;

    return (
        <PatientForm
            existingPatient={patient}
            existingPatientLabels={Object.values(patientLabels)}
            existingPatientHighRiskReasons={Object.values(patientHighRiskReasons)}
            existingPatientOtherId={patientOtherId}
            existingPatientTherapies={Object.values(patientTherapies)}
            existingPatientMedicationsRx={Object.values(patientRx)}
            existingPatientRxList={patientRxList?.flat().filter((rx) => !!rx)}
            existingPatientReferral={!isEmpty(patientReferral)}
            existingPatientOtherDiagnoses={Object.values(patientOtherDiagnoses)}
            existingPatientOtherMedications={Object.values(patientOtherMedications)}
            existingPatientInsurance={Object.values(patientInsurance)}
        />
    );
};

export default EditPatient;
