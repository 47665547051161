import React from 'react';
import PropTypes from 'prop-types';
import ClickLink from 'App/components/styles/ClickLink';

const propTypes = {
    showMessagingModal: PropTypes.func.isRequired,
};

const defaultProps = {};

function NoSubmission({ showMessagingModal }) {
    return (
        <div style={{ flexGrow: 1, flexBasis: '50%' }}>
            <ClickLink onClick={showMessagingModal}>Enroll Patient</ClickLink>
        </div>
    );
}

NoSubmission.propTypes = propTypes;
NoSubmission.defaultProps = defaultProps;

export default NoSubmission;
