import React from 'react';
import { render } from 'react-dom';
import CompanyService from '../../../services/CompanyService';
import values from 'ramda/src/values';
import map from 'ramda/src/map';
import prop from 'ramda/src/prop';
import * as R from 'ramda';
import Select from 'react-select';
import { hasLength } from 'App/utils';
import styled from '@emotion/styled';

const MultiValueLabelWrapper = styled('div')`
    border-radius: 2px;
    color: hsl(0deg 0% 20%);
    font-size: 85%;
    overflow: hidden;
    padding: 3px 3px 3px 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
`;

function MultiValueLabel(props) {
    // eslint-disable-next-line react/prop-types
    return <MultiValueLabelWrapper {...props} title={props.data.value} />;
}

/** @ngInject */
function PatientLabelsInputController($scope) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.doChangePatientLabels = doChangePatientLabels;
    ctrl.isEmptyPatientLabel = isEmptyPatientLabel;
    ctrl.patientLabelsOptions = [];
    ctrl.patientLabels = [];
    ctrl.emptyPatientLabel = false;
    ctrl.hasError = false;

    function $onChanges(changes) {
        if (changes.patientLabels) {
            ctrl.patientLabels = angular.copy(ctrl.patientLabels);
            if (ctrl.isRequired && !ctrl.patientLabels.length && ctrl.emptyPatientLabel) {
                ctrl.hasError = true;
            } else {
                ctrl.hasError = false;
            }
        }
        mountIt();
    }

    $scope.$watch(
        () => {
            return ctrl.form.$submitted;
        },
        () => {
            if (ctrl.isRequired && !ctrl.patientLabels.length && ctrl.form.$submitted) {
                ctrl.hasError = true;
            } else {
                ctrl.hasError = false;
            }
            mountIt();
        }
    );

    function $onInit() {
        const patientLabelOptions = CompanyService.getCustomMenu('patient_labels') || [];
        ctrl.patientLabelsOptions = values(map(prop('valueName'))(patientLabelOptions));

        mountIt();
    }

    function mountIt() {
        /**
         * Since we're not using something like CSS grid or Flex box for the patient page,
         * there are two "cells" in the grid that will be toggled on/off based on some criteria:
         * ex. high-risk reasons
         *
         * Therefore, we need to mount on _all_ label mounts.
         */

        const dom = document.querySelectorAll('.patient-label-mount');
        dom.forEach((node) => {
            render(
                <Select
                    styles={{
                        control: (baseStyles) => {
                            return {
                                ...baseStyles,
                                borderColor: ctrl.hasError ? '#8d1000' : '#ccc',
                                boxShadow: 'none',
                                '&:hover': {
                                    borderColor: ctrl.hasError ? '#8d1000' : '#ccc',
                                },
                            };
                        },
                        placeholder: (defaultStyles) => {
                            return {
                                ...defaultStyles,
                                color: 'var(--dimgray)',
                            };
                        },
                    }}
                    isMulti
                    tabIndex={32}
                    inputId="patient-labels"
                    name="patient_labels"
                    components={{ MultiValueLabel }}
                    disabled={ctrl.isLocked || !hasLength(ctrl.patientLabelsOptions)}
                    required={ctrl.isRequired}
                    placeholder="Select Labels(s)"
                    isClearable
                    value={R.map(
                        R.applySpec({
                            label: R.identity,
                            value: R.identity,
                        }),
                        ctrl.patientLabels
                    )}
                    options={R.map(
                        R.applySpec({
                            label: R.identity,
                            value: R.identity,
                        }),
                        ctrl.patientLabelsOptions
                    )}
                    onChange={(options) => {
                        ctrl.patientLabels = R.map(R.prop('value'), options);
                        ctrl.emptyPatientLabel = !ctrl.patientLabels.length;
                        doChangePatientLabels();
                        isEmptyPatientLabel();
                    }}
                />,
                node
            );
        });
    }

    function doChangePatientLabels() {
        $scope.$emit('updatePatientLabels', ctrl.patientLabels);
    }

    function isEmptyPatientLabel() {
        $scope.$emit('emptyPatientLabel', ctrl.emptyPatientLabel);
    }
}

export default PatientLabelsInputController;
