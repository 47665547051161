import * as R from 'ramda';
import React, { useState } from 'react';
import { Button, Col, HelpBlock, Row } from 'react-bootstrap';
import PublishProtocolTable from 'App/components/ProtocolPublisher/PublishProtocolTable';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import PublishProtocolModal from 'App/components/ProtocolPublisher/PublishProtocolModal';
import { errorHandler, hasLength } from 'App/utils';
import { publishProtocols } from 'App/services/AdminService';
import toast from 'Lib/toast';

const propTypes = {};

const defaultProps = {};

const ifOptionIsNew = R.ifElse(R.pathEq('New', ['protocolToReplace', 'id']));

function ProtocolPublisher() {
    const [protocolsToPublish, setProtocolsToPublish] = useState([]);

    const [showAddProtocol, setShowAddProtocol] = useState(false);
    const [serverErrors, setServerErrors] = useState(null);

    const onSave = (data) => {
        setProtocolsToPublish(R.append(data));
        setShowAddProtocol(false);
    };

    const onRemove = (index) => {
        setProtocolsToPublish(R.remove(index, 1));
    };

    const onPublish = () => {
        setServerErrors(null);
        const protocols = R.map(
            R.applySpec({
                protocolId: R.path(['protocolToPublish', 'id']),
                targetId: ifOptionIsNew(R.always(null), R.path(['protocolToReplace', 'id'])),
                enableForAll: R.prop('enableForAll'),
            }),
            protocolsToPublish
        );

        publishProtocols(protocols)
            .then(({ message }) => {
                toast.success(message);
                setProtocolsToPublish([]);
            })
            .catch((error) => {
                if (R.pathSatisfies(R.is(String), ['response', 'data', 'message'], error)) {
                    setServerErrors(R.path(['response', 'data', 'message'], error));
                }
                errorHandler(error);
            });
    };
    return (
        <>
            <Row>
                <Col md={12} style={{ marginBottom: '2rem' }}>
                    <Button onClick={() => setShowAddProtocol(true)}>Add a Protocol</Button>
                </Col>
            </Row>

            {serverErrors ? (
                <Row>
                    <Col md={12} style={{ marginBottom: '2rem' }}>
                        <div className="has-error">
                            <HelpBlock>
                                <strong>{serverErrors}</strong>{' '}
                            </HelpBlock>
                            <HelpBlock>
                                To continue, you will need to publish the assessment changes related to the above
                                protocols.
                            </HelpBlock>
                        </div>
                    </Col>
                </Row>
            ) : null}

            <Row>
                <Col md={12}>
                    <PublishProtocolTable data={protocolsToPublish} onRemove={onRemove} />
                </Col>
            </Row>

            <Row>
                <Col md={12}>
                    <FormButtonGroup>
                        <Button bsStyle="primary" disabled={!hasLength(protocolsToPublish)} onClick={onPublish}>
                            Publish Protocols
                        </Button>
                    </FormButtonGroup>
                </Col>
            </Row>

            {showAddProtocol && (
                <PublishProtocolModal
                    onHide={() => setShowAddProtocol(false)}
                    onSave={onSave}
                    show
                    excludeIds={R.map(R.path(['protocolToPublish', 'id']), protocolsToPublish)}
                />
            )}
        </>
    );
}

ProtocolPublisher.propTypes = propTypes;
ProtocolPublisher.defaultProps = defaultProps;

export default ProtocolPublisher;
