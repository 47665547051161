import React from 'react';
import PropTypes from 'prop-types';
import useIsPermitted from 'App/utils/hooks/useIsPermitted';
import Main from 'App/components/styles/Main/Main';
import { Helmet } from 'react-helmet';
import { catchPhrase } from 'App/components/config/config';
import PageTitle from 'App/components/styles/PageTitle/PageTitle';
import PageWrapper from 'App/components/styles/PageWrapper/PageWrapper';
import { NotFound } from 'App/components/errors';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import * as R from 'ramda';
import PageSpinner from 'App/components/PageSpinner';

const propTypes = {
    permissionType: PropTypes.oneOf(R.keys(PERMISSION_TYPES)).isRequired,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
    title: PropTypes.string.isRequired,
    showPageTitle: PropTypes.bool,
    showNotFound: PropTypes.bool,
    isLoading: PropTypes.bool,
};

const defaultProps = {
    showPageTitle: true,
    showNotFound: false,
    isLoading: false,
    children: null,
};

function Page({ permissionType, children, title, showPageTitle, isLoading, showNotFound }) {
    const hasPermission = useIsPermitted(permissionType);

    if (isLoading) {
        return <PageSpinner />;
    }

    return (
        <Main>
            <Helmet>
                <title>
                    {hasPermission && !showNotFound ? title : 'Not Found'} - {catchPhrase}
                </title>
            </Helmet>
            {hasPermission && showPageTitle && !showNotFound && <PageTitle>{title}</PageTitle>}

            <PageWrapper>{hasPermission && !showNotFound ? children : <NotFound />}</PageWrapper>
        </Main>
    );
}

Page.propTypes = propTypes;
Page.defaultProps = defaultProps;

export default Page;
