import { useEffect } from 'react';

import { useUserContext } from 'App/contexts/UserContext';
import useChangeBranch from 'App/hooks/useChangeBranch';
import { patientPropType } from 'Lib/types';

const UserBranchSwitcher = ({ patient }) => {
    const user = useUserContext();
    const { handleChangeBranch, isChanging } = useChangeBranch();

    useEffect(() => {
        if (!isChanging && patient.divisionId !== user.active_branch.ID) {
            handleChangeBranch(patient.divisionId);
        }
    }, [handleChangeBranch, isChanging, patient.divisionId, user.active_branch.ID]);

    return null;
};

UserBranchSwitcher.propTypes = {
    patient: patientPropType.isRequired,
};

export default UserBranchSwitcher;
