import toast from 'Lib/toast';
import { useMutation } from 'react-query';
import { postActivityTextScheduleAssignments } from '@/app/services/PatientMessagingService';
import { errorHandler } from '@/app/utils';

const useActivityTextScheduleAssignmentMutation = () => {
    return useMutation((reqBody) => postActivityTextScheduleAssignments(reqBody), {
        onError: errorHandler,
        onSuccess: () => {
            toast.success('Activity Text Schedule Assignment successfully updated');
        },
    });
};

export default useActivityTextScheduleAssignmentMutation;
