import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import useGetPatientSubscriptions from '../../hooks/useGetPatientSubscriptions';
import dayjs from 'dayjs';
import PatientMessagingViewModal from './PatientMessagingViewModal';
import './PatientMessagingEnrollmentModalStyle.scss';
import PatientMessagingHistoryTable from './PatientMessagingHistoryTable';

const propTypes = {
    patientId: PropTypes.string.isRequired,
    divisionId: PropTypes.string,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
};
const defaultProps = {
    divisionId: '',
};

function PatientMessagingHistoryModal({ show, onHide, patientId, divisionId }) {
    const { patientMessagingSubscription } = useGetPatientSubscriptions(patientId);

    const [showMessageViewModal, setShowMessageViewModal] = useState(false);
    const [patientMessageViewModalProps, setPatientMessageViewModalProps] = useState({});

    const patientSubscriptionUpdated = useMemo(() => {
        if (patientId && patientMessagingSubscription) {
            return {
                status: patientMessagingSubscription.unsubscribedOn ? 'Opted-out' : 'Enrolled',
                date: patientMessagingSubscription.unsubscribedOn || patientMessagingSubscription.subscribedOn,
                reason: patientMessagingSubscription.reason,
            };
        }
        return null;
    }, [patientMessagingSubscription, patientId]);

    const showPatientMessageViewModal = (message) => {
        setPatientMessageViewModalProps(message);
        setShowMessageViewModal(true);
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            backdrop="static"
            className="patientMedicationHistoryModal"
            bsSize="large"
            data-testid="patient-messaging-history-modal"
        >
            <Modal.Header closeButton={false}>
                <Modal.Title className="header">Patient Messaging History</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <h4 className="header-medication-history-modal">Enrollment Status</h4>
                {patientSubscriptionUpdated && (
                    <table className="therigy-table">
                        <colgroup>
                            <col width="60%" />
                            {patientSubscriptionUpdated.reason ? (
                                <>
                                    <col width="20%" />
                                    <col width="20%" />
                                </>
                            ) : (
                                <col width="40%" />
                            )}
                        </colgroup>
                        <thead>
                            <tr>
                                <th aria-label="Enrollment Status Header">Status</th>
                                <th aria-label="Enrollment Date Header">Date</th>
                                {patientSubscriptionUpdated.reason ? (
                                    <th aria-label="Enrollment Reason Header">Reason</th>
                                ) : null}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td data-testid="messaging-enrollment-status">{patientSubscriptionUpdated.status}</td>
                                <td data-testid="messaging-enrollment-date">
                                    {patientSubscriptionUpdated.date
                                        ? dayjs(patientSubscriptionUpdated.date).format('YYYY-MM-DD')
                                        : null}
                                </td>
                                {patientSubscriptionUpdated.reason && (
                                    <td data-testid="messaging-enrollment-reason">
                                        {patientSubscriptionUpdated.reason}
                                    </td>
                                )}
                            </tr>
                        </tbody>
                    </table>
                )}

                <h4 className="header-medication-history-modal">Sent History</h4>

                <PatientMessagingHistoryTable
                    patientId={patientId}
                    divisionId={divisionId}
                    showMessageViewModal={showPatientMessageViewModal}
                />
            </Modal.Body>

            <Modal.Footer>
                <Button
                    id="close"
                    data-automation-id="medication_history_close"
                    bsStyle="default"
                    type="button"
                    onClick={onHide}
                >
                    Close
                </Button>
            </Modal.Footer>
            {showMessageViewModal ? (
                <PatientMessagingViewModal
                    show
                    onHide={() => setShowMessageViewModal(false)}
                    {...patientMessageViewModalProps}
                />
            ) : (
                ''
            )}
        </Modal>
    );
}

PatientMessagingHistoryModal.propTypes = propTypes;
PatientMessagingHistoryModal.defaultProps = defaultProps;

export default PatientMessagingHistoryModal;
