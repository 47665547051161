import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, ControlLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import FlexContainer from 'App/common/styles/FlexContainer';
import useFetchPatientReferral from 'App/hooks/useFetchPatientReferral';
import { PERMISSION_TYPES } from 'App/utils/isPermitted/isPermitted';
import useIsPermitted from 'App/utils/hooks/useIsPermitted';

const propTypes = {
    patientId: PropTypes.string.isRequired,
};
const defaultProps = {};

const tooltip = <Tooltip id="action-tooltip">Changes to this field must be made in Edit Patient page.</Tooltip>;

const PatientReferralFaOptIn = ({ patientId }) => {
    const isAdvancedReferralEnabled = useIsPermitted(PERMISSION_TYPES.ADVANCED_REFERRAL);
    const { data: referral } = useFetchPatientReferral(patientId);

    if (!isAdvancedReferralEnabled) {
        return null;
    }
    return (
        <FlexContainer style={{ margin: 0, marginBottom: 5 }}>
            <Checkbox id="receive_fa_alerts" checked={Boolean(referral?.id)} readOnly disabled />
            <ControlLabel htmlFor="receive_fa_alerts">
                Receive Financial Assistance Alerts for patient &nbsp;
            </ControlLabel>
            <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={tooltip}>
                <FaInfoCircle />
            </OverlayTrigger>
        </FlexContainer>
    );
};

PatientReferralFaOptIn.propTypes = propTypes;
PatientReferralFaOptIn.defaultProps = defaultProps;

export default PatientReferralFaOptIn;
