import React, { useCallback } from 'react';
import * as R from 'ramda';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SafeHtmlOnly from 'App/components/SafeHtmlOnly/SafeHtmlOnly';
import ClickLink from 'App/components/styles/ClickLink';
import useActivityNoteStatusModal from '../../hooks/useActivityNoteStatusModal';
import { useFormikContext } from 'formik';
import { cellPropsType } from '../../workQueue.constant';

const ActivityStatusCell = ({ row, value }) => {
    const { setFieldValue } = useFormikContext();
    const onActivityStatusClick = useCallback(
        (state) => setFieldValue('modalState.activityNoteModal', state),
        [setFieldValue]
    );
    const modalState = useActivityNoteStatusModal(row);
    const notesTooltip = R.pathOr('', ['original', 'notesTooltip'], row);
    const statusToolTip = (
        <Tooltip className="notes-tooltip" id="notes-tooltip">
            <SafeHtmlOnly>{notesTooltip}</SafeHtmlOnly>
        </Tooltip>
    );

    return (
        <span className="activity-status">
            <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={statusToolTip}>
                <ClickLink onClick={() => onActivityStatusClick(modalState)}>{value}</ClickLink>
            </OverlayTrigger>
        </span>
    );
};

ActivityStatusCell.propTypes = cellPropsType;

export default ActivityStatusCell;
