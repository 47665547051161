import { useFormikContext } from 'formik';
import React from 'react';
import { Button } from 'react-bootstrap';
import { FaMinus, FaPlus } from 'react-icons/fa';

import { CheckboxField } from 'Lib/form/CheckboxField';

import CommonPatientFieldWrapper from './CommonPatientFieldWrapper';
import OtherDiagnosisTypeaheadField from './OtherDiagnosisTypeaheadField';
import SectionTitleStyle from './SectionTitleStyle';

const OtherDiagnosesForm = () => {
    const { values, setFieldValue } = useFormikContext();

    return (
        <CommonPatientFieldWrapper fieldName="otherDiagnoses">
            {(commonProps) => (
                <div>
                    <SectionTitleStyle>
                        <h2>Other Diagnoses</h2>
                    </SectionTitleStyle>

                    <CheckboxField
                        fieldName="noKnownMedicalConditions"
                        label="No known other diagnoses"
                        disabled={commonProps.disabled}
                    />

                    {!values.noKnownMedicalConditions && (
                        <>
                            {values.otherCondition.map((otherCondition, index) => (
                                <OtherDiagnosisTypeaheadField
                                    key={otherCondition.id || `index-${index}`}
                                    {...commonProps}
                                    fieldName={`otherCondition[${index}].text`}
                                    addonButton={
                                        <Button
                                            onClick={() => {
                                                values.otherCondition.splice(index, 1);
                                                setFieldValue('otherCondition', values.otherCondition);
                                            }}
                                            aria-label="Remove Other Diagnosis"
                                        >
                                            <FaMinus />
                                        </Button>
                                    }
                                />
                            ))}

                            {!commonProps.disabled && (
                                <Button
                                    bsStyle="link"
                                    onClick={() => {
                                        const newIndex = values.otherCondition.length;
                                        setFieldValue(`otherCondition[${newIndex}]`, {});
                                    }}
                                >
                                    <FaPlus /> Add Other Diagnosis
                                </Button>
                            )}
                        </>
                    )}
                </div>
            )}
        </CommonPatientFieldWrapper>
    );
};

export default OtherDiagnosesForm;
