import mapIdentityToValueLabel from 'App/utils/mapIdentityToValueLabel';
import * as R from 'ramda';

const statuses = Object.freeze({
    COMPLETED: 'Completed',
    DELETED: 'Deleted',
    NEW: 'New',
    OVERTURNED: 'Overturned',
    PENDING_SUBMISSION: 'Pending Submission',
    SUBMITTED: 'Submitted',
    UPHELD: 'Upheld',
});

const authorizedUnitsOfMeasureOptions = [
    'Ampule',
    'Applicator',
    'Bag',
    'Bar',
    'Blister',
    'Bottle',
    'Box',
    'Can',
    'Canister',
    'Caplet',
    'Capsule',
    'Cartridge',
    'Cassette',
    'Container',
    'Disk',
    'Dose Pack',
    'Dual Pack',
    'Each',
    'Fluid Ounce',
    'Gallon',
    'Gram',
    'Implant',
    'Inhalation',
    'Inhaler',
    'Kilogram',
    'Kit',
    'Liter',
    'Metric Drop',
    'Milliequivalent',
    'Milligram',
    'Milliliter',
    'Nebule',
    'Ounce',
    'Package',
    'Packet',
    'Pad',
    'Patch',
    'Pint',
    'Pouch',
    'Pound',
    'Pre-filled Pen Syringe',
    'Quart',
    'Ring',
    'Sachet',
    'Spray',
    'Stick',
    'Strip',
    'Swab',
    'Syringe',
    'Tablespoon',
    'Tablet',
    'Teaspoon',
    'Troche',
    'Tube',
    'Unspecified',
    'Vial',
].map(mapIdentityToValueLabel);

const ndcSpecificApprovalOptions = ['Yes', 'No'].map(mapIdentityToValueLabel);

const followupAppealActivityOptions = ['Yes', 'No'].map(mapIdentityToValueLabel);

const methodApprovalReceivedOptions = [
    'Pharmacy claim',
    'Physician notification',
    'Patient notification',
    'Payor notification',
].map(mapIdentityToValueLabel);

const appealDocumentNames = [
    'Denial Letter',
    'MD Notes',
    'Required clinical information/labs',
    'Appeals Letter with MD signature',
];

const appealDocumentOptions = appealDocumentNames.map(R.applySpec({ id: R.identity, option: R.identity }));

export {
    statuses,
    authorizedUnitsOfMeasureOptions,
    methodApprovalReceivedOptions,
    ndcSpecificApprovalOptions,
    followupAppealActivityOptions,
    appealDocumentNames,
    appealDocumentOptions,
};
