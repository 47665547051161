import toast from 'Lib/toast';

/** @ngInject */
function ReferralManagerSettingsController(_, $uibModal, NgTableParams, divisionService, rmsService, therapyService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;

    ctrl.addMedicationRule = addMedicationRule;
    ctrl.addRxStatusRule = addRxStatusRule;
    ctrl.cancelChanges = cancelChanges;
    ctrl.del = del;
    ctrl.deleteRxStatusRule = deleteRxStatusRule;
    ctrl.hasChanges = hasChanges;
    ctrl.medicationFilter = medicationFilter;
    ctrl.resetTableStatus = resetTableStatus;
    ctrl.saveChanges = saveChanges;
    ctrl.toggleSelection = toggleSelection;
    ctrl.updateDefaultRule = updateDefaultRule;

    function $onChanges(changes) {
        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);

            rmsService.getDefaultRule(ctrl.user.active_branch.ID).then((res) => {
                ctrl.defaultRule = res;
                ctrl.disableOnCreateRule = ctrl.defaultRule.defaults[0] === 'none';
            });

            rmsService.getMedicationRules(ctrl.user.active_branch.ID).then((res) => {
                ctrl.medicationRulesIds = {};
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-plusplus
                for (let i = 0, len = res.length; i < len; i++) {
                    if (!Object.prototype.hasOwnProperty.call(ctrl.medicationRulesIds, res[i].medication.name)) {
                        ctrl.medicationRulesIds[res[i].medication.name] = [];
                    }
                    ctrl.medicationRulesIds[res[i].medication.name].push(res[i].medication_id);
                }

                // Since the rules save differently than they display, we need to maintain two arrays.
                ctrl.medicationRules = angular.copy(res);
                ctrl.originalMedicationRules = angular.copy(res);
                ctrl.medicationRulesDataset = _.uniqBy(ctrl.medicationRules, 'medication.name');

                ctrl.tableParams = new NgTableParams(
                    {
                        count: 10,
                        sorting: {
                            'medication.name': 'asc',
                        },
                    },
                    {
                        counts: [],
                        dataset: angular.copy(ctrl.medicationRulesDataset),
                    }
                );

                if (ctrl.medicationRules.length > ctrl.tableParams.count()) {
                    ctrl.tableParams.settings({ counts: [5, 10, 25] });
                }

                ctrl.deleteCount = 0;
            });

            rmsService.getRxStatusRules(ctrl.user.active_branch.ID).then((res) => {
                ctrl.rxStatusRules = res;

                ctrl.rxStatusRulesTableParams = new NgTableParams(
                    {
                        count: 10,
                        sorting: {
                            'medication.name': 'asc',
                        },
                    },
                    {
                        counts: [],
                        dataset: angular.copy(ctrl.rxStatusRules),
                    }
                );

                if (ctrl.rxStatusRules.length > ctrl.rxStatusRulesTableParams.count()) {
                    ctrl.rxStatusRulesTableParams.settings({
                        counts: [5, 10, 25],
                    });
                }
            });
        }
    }

    function $onInit() {
        ctrl.defaultRule = 'createOnMedication';

        divisionService.getMedications().then((res) => {
            ctrl.medications = _.groupBy(res, 'medication_name');
        });

        therapyService.query().then((res) => {
            ctrl.therapies = _.values(res);
        });
    }

    function addMedicationRule() {
        ctrl.isEditing = true;
        ctrl.isAdding = true;

        // Only update the table display. Since the user can change their selection for the medication, we want to
        // update the rule set on save rather than on add.
        ctrl.tableParams.settings().dataset.unshift({
            medication_id: '',
            trigger_on: [],
            triggers: [],
        });

        // Remove sorting and move them to the first page where we know that our new item was added to
        ctrl.tableParams.sorting({});
        ctrl.tableParams.page(1);
        ctrl.tableParams.reload();

        if (ctrl.tableParams.total() > ctrl.tableParams.count()) {
            ctrl.tableParams.settings({ counts: [5, 10, 25] });
        }
    }

    function addRxStatusRule(row) {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            size: 'lg',
            component: 'referralManagerSettingsAddRxStatusRule',
            keyboard: false,
            resolve: {
                branchId() {
                    return ctrl.user.active_branch.ID;
                },
                therapies() {
                    return ctrl.therapies;
                },
                rule() {
                    return row;
                },
            },
        });

        modalInstance.result
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line consistent-return
            .then((res) => {
                if (res) {
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line no-shadow
                    return rmsService.getRxStatusRules(ctrl.user.active_branch.ID).then((res) => {
                        ctrl.rxStatusRulesTableParams.settings().dataset = angular.copy(res);
                        ctrl.rxStatusRulesTableParams.reload().then((data) => {
                            if (data.length === 0 && ctrl.rxStatusRulesTableParams.total() > 0) {
                                ctrl.rxStatusRulesTableParams.page(ctrl.rxStatusRulesTableParams.page() - 1);
                                ctrl.rxStatusRulesTableParams.reload();
                            }
                        });

                        return res;
                    });
                }
            })
            .catch((err) => {
                return err;
            });
    }

    function cancelChanges() {
        resetTableStatus();
        const currentPage = ctrl.tableParams.page();

        ctrl.tableParams.settings({
            dataset: angular.copy(ctrl.medicationRulesDataset),
        });

        ctrl.medicationRules = angular.copy(ctrl.originalMedicationRules);

        ctrl.tableParams.sorting({
            'medication.name': 'asc',
        });

        // Keep the user on the current page when we can
        if (!ctrl.isAdding) {
            ctrl.tableParams.page(currentPage);
        }
    }

    function del(row) {
        // Delete the row from the display
        _.remove(ctrl.tableParams.settings().dataset, (item) => {
            return row === item;
        });

        // If the row was part of initial rule set, delete all matching rules
        if (row.id) {
            _.remove(ctrl.medicationRules, (item) => {
                return row.medication.name === item.medication.name;
            });
        }

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-plusplus
        ctrl.deleteCount++;
        ctrl.tableTracker.untrack(row);
        ctrl.tableParams.reload().then((data) => {
            if (data.length === 0 && ctrl.tableParams.total() > 0) {
                ctrl.tableParams.page(ctrl.tableParams.page() - 1);
                ctrl.tableParams.reload();
            }
        });
    }

    function deleteRxStatusRule(row) {
        return rmsService.deleteRxStatusRule(ctrl.user.active_branch.ID, row.id).then(() => {
            _.remove(ctrl.rxStatusRulesTableParams.settings().dataset, (item) => {
                return row.id === item.id;
            });

            ctrl.rxStatusRulesTableParams.reload().then((data) => {
                if (data.length === 0 && ctrl.rxStatusRulesTableParams.total() > 0) {
                    ctrl.rxStatusRulesTableParams.page(ctrl.rxStatusRulesTableParams.page() - 1);
                    ctrl.rxStatusRulesTableParams.reload();
                }
            });
        });
    }

    function hasChanges() {
        return ctrl.tableForm.$dirty || ctrl.deleteCount > 0;
    }

    function resetTableStatus() {
        ctrl.isEditing = false;
        ctrl.isAdding = false;
        ctrl.deleteCount = 0;
        ctrl.tableTracker.reset();
        ctrl.tableForm.$setPristine();
    }

    function saveChanges() {
        ctrl.saving = true;

        resetTableStatus();
        const currentPage = ctrl.tableParams.page();

        const medicationRules = _.map(ctrl.medicationRules, (r) => {
            return {
                medication_id: r.medication_id,
                trigger_on: r.trigger_on,
                triggers: r.triggers,
            };
        });
        const medicationRulesDataset = angular.copy(ctrl.tableParams.settings().dataset);

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-plusplus
        for (let i = 0, len1 = medicationRulesDataset.length; i < len1; i++) {
            if (Array.isArray(medicationRulesDataset[i].medication_id)) {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-plusplus
                for (let j = 0, len2 = medicationRulesDataset[i].medication_id.length; j < len2; j++) {
                    medicationRules.push({
                        medication_id: medicationRulesDataset[i].medication_id[j].medication_id,
                        trigger_on: medicationRulesDataset[i].trigger_on,
                        triggers: medicationRulesDataset[i].triggers,
                    });
                }
            }
        }

        return rmsService
            .createMedicationRules(ctrl.user.active_branch.ID, {
                data: medicationRules,
            })
            .then((res) => {
                ctrl.medicationRules = angular.copy(res);
                ctrl.originalMedicationRules = angular.copy(res);
                ctrl.medicationRulesDataset = _.uniqBy(ctrl.medicationRules, 'medication.name');

                ctrl.tableParams.sorting({ 'medication.name': 'asc' });
                ctrl.tableParams.settings({
                    dataset: angular.copy(ctrl.medicationRulesDataset),
                });

                ctrl.tableParams.page(currentPage);

                return res;
            })
            .finally(() => {
                ctrl.saving = false;
            });
    }

    function toggleSelection(row, trigger) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        row.trigger_on = trigger ? [trigger] : [];

        if (row.id) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-plusplus
            for (let i = 0, len = ctrl.medicationRules.length; i < len; i++) {
                if (row.medication.name === ctrl.medicationRules[i].medication.name) {
                    ctrl.medicationRules[i].trigger_on = row.trigger_on;
                }
            }
        }
    }

    function updateDefaultRule() {
        ctrl.disableOnCreateRule = ctrl.defaultRule.defaults[0] === 'none';
        return rmsService
            .updateDefaultRule(ctrl.user.active_branch.ID, {
                defaults: [ctrl.defaultRule.defaults[0]],
                medication_creates_referral: ctrl.defaultRule.medication_creates_referral,
            })
            .then((res) => {
                toast.success('Default referral manager settings have been saved successfully.', {
                    preventOpenDuplicates: false,
                    tapToDismiss: true,
                    timeOut: 2000,
                });
                return res;
            });
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function medicationFilter(medication, viewValue) {
        if (typeof medication.medication_name === 'string') {
            return medication.medication_name.substr(0, viewValue.length).toLowerCase() === viewValue.toLowerCase();
        }
    }
}

export default ReferralManagerSettingsController;
