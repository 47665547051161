import * as R from 'ramda';
import React from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { TextField } from 'Lib/form/TextField';
import { DatePickerField } from 'Lib/form/DatePickerField';
import { PhoneField } from 'App/components/PatientForm/components/PhoneField';
import { CheckboxField } from 'Lib/form/CheckboxField';
import SelectField from 'Lib/form/SelectField';
import { useFormikContext } from 'formik';
import {
    authorizedUnitsOfMeasureOptions,
    methodApprovalReceivedOptions,
    ndcSpecificApprovalOptions,
} from './constants';
import PropTypes from 'prop-types';
import { NumberField } from 'Lib/form/NumberField';
import { FaExclamationCircle } from 'react-icons/fa';

const propTypes = {
    isFormDisabled: PropTypes.bool.isRequired,
};

const defaultProps = {};

const tooltip = <Tooltip id="actions-tooltip">Must have an applicable protocol enabled.</Tooltip>;

function OverturnedForm({ isFormDisabled }) {
    const { values, setValues } = useFormikContext();

    return (
        <>
            <Row>
                <Col md={2}>
                    <TextField
                        disabled={isFormDisabled}
                        fieldName="details.paNumber"
                        label="PA Number"
                        placeholder="PA Number"
                        required
                    />
                </Col>

                <Col md={2}>
                    <PhoneField
                        disabled={isFormDisabled}
                        fieldName="details.followUpPhone"
                        label="PA Follow Up Phone Number"
                        placeholder="(###) ###-####"
                        required
                    />
                </Col>
                <Col md={2}>
                    <DatePickerField
                        disabled={isFormDisabled}
                        label="Effective Start Date"
                        fieldName="details.effectiveStartDate"
                        placeholder="YYYY-MM-DD"
                        required
                    />
                </Col>
                <Col md={2}>
                    <DatePickerField
                        disabled={isFormDisabled}
                        label="Effective End Date"
                        fieldName="details.effectiveEndDate"
                        placeholder="YYYY-MM-DD"
                        required
                    />
                </Col>
            </Row>

            <Row>
                <Col md={8}>
                    <table className="therigy-table table-condensed">
                        <colgroup>
                            <col width="20%" />
                            <col width="20%" />
                            <col width="20%" />
                            <col width="20%" />
                            <col width="20%" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th id="medication-column">Medication</th>
                                <th id="authorized-quantity-column">Authorized Quantity</th>
                                <th id="unit-of-measure-column">Unit of Measure</th>
                                <th id="ndc-specific-column">Is Approval NDC Specific?</th>
                                <th id="approved-ndc-column">Approved NDC</th>
                            </tr>
                        </thead>
                        <tbody>
                            {values.medications.map((medication, idx) => {
                                return (
                                    <tr key={medication.id}>
                                        <th style={{ verticalAlign: 'middle' }}>{medication.medication.name}</th>
                                        <td>
                                            <NumberField
                                                aria-labelledby="authorized-quantity-column"
                                                min={0}
                                                disabled={isFormDisabled}
                                                fieldName={`medications.${idx}.data.authorizedQuantity`}
                                                placeholder="Authorized Quantity"
                                            />
                                        </td>
                                        <td>
                                            <SelectField
                                                aria-labelledby="unit-of-measure-column"
                                                isDisabled={isFormDisabled}
                                                isClearable
                                                options={authorizedUnitsOfMeasureOptions}
                                                fieldName={`medications.${idx}.data.authorizedUom`}
                                            />
                                        </td>
                                        <td>
                                            <SelectField
                                                aria-labelledby="ndc-specific-column"
                                                isDisabled={isFormDisabled}
                                                isClearable
                                                options={ndcSpecificApprovalOptions}
                                                fieldName={`medications.${idx}.data.ndcSpecificApproval`}
                                            />
                                        </td>
                                        <td>
                                            <TextField
                                                aria-labelledby="approved-ndc-column"
                                                disabled={isFormDisabled}
                                                fieldName={`medications.${idx}.data.approvedNdc`}
                                                placeholder="Approved NDC"
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </Col>
            </Row>

            <Row>
                <Col md={4}>
                    <SelectField
                        isDisabled={isFormDisabled}
                        fieldName="details.methodApprovalReceived"
                        options={methodApprovalReceivedOptions}
                        label="How was approval received?"
                    />
                </Col>
            </Row>

            <Row>
                <Col md={4}>
                    <CheckboxField
                        disabled={isFormDisabled}
                        fieldName="details.benefitRequiresReVerification"
                        label={
                            <>
                                <span
                                    style={{
                                        marginRight: '.5rem',
                                    }}
                                >
                                    Create a Prior Authorization Renewal activity
                                </span>
                                <span>
                                    <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={tooltip}>
                                        <FaExclamationCircle data-testid="icon" />
                                    </OverlayTrigger>
                                </span>
                            </>
                        }
                        onChange={() => {
                            R.compose(
                                setValues,
                                R.over(R.lensPath(['details', 'benefitRequiresReVerification']), R.not)
                            )(values);
                        }}
                    />
                </Col>
            </Row>
        </>
    );
}

OverturnedForm.propTypes = propTypes;
OverturnedForm.defaultProps = defaultProps;

export default OverturnedForm;
