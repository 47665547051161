import toast from 'Lib/toast';

/** @ngInject */
function EngageEducationContentOptInModalController(_paq, __env, $q, dateService, engageService, patientService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.doCloseModal = doCloseModal;
    ctrl.submit = submit;
    ctrl.emailRegex = __env.emailRegex;

    function $onChanges(changes) {
        if (changes.patient && ctrl.patient) {
            ctrl.patient = angular.copy(ctrl.patient);
            ctrl.originalPatient = angular.copy(ctrl.patient);
        }

        if (changes.subscription && ctrl.subscription !== null) {
            ctrl.subscription = angular.copy(ctrl.subscription);
            ctrl.infoMessage = ctrl.subscription
                ? 'Submitting this form will resend the invitation email.'
                : 'Upon sending this form, the patient will receive an email invitation to subscribe to patient ' +
                  'education content. The patient will have 24 hours to click on the link in the email to ' +
                  'subscribe to patient education content.';

            if (ctrl.subscription && typeof ctrl.subscription.subscribedOn === 'string') {
                ctrl.subscription.subscribedOn = dateService.create(ctrl.subscription.subscribedOn);
            }
        }
    }

    function $onInit() {
        ctrl.notification = false;
        ctrl.loading = false;
    }

    function doCloseModal() {
        _paq.push(['trackEvent', 'TherigyEngage', 'Modal from Profile - Cancel button']);
        ctrl.close();
    }

    function submit() {
        ctrl.loading = true;

        if (ctrl.subscription && ctrl.originalPatient.email === ctrl.patient.email) {
            ctrl.close({ $value: false });
            return true;
        }

        let promise = $q.when();

        // Update the patient's email if it has changed.
        if (ctrl.originalPatient.email !== ctrl.patient.email) {
            promise = patientService.update(ctrl.patient.id, {
                email: ctrl.patient.email,
            });
        }

        // Subscribe if not subscription exists or update subscription if email has changed.
        return promise
            .then(() => {
                if (!ctrl.subscription || ctrl.originalPatient.email !== ctrl.patient.email) {
                    return engageService.subscribeEducationContent(ctrl.patient.id);
                }
                return $q.when();
            })
            .then(() => {
                ctrl.notification = false;
                ctrl.close({ $value: true });
                toast.success('Invitation sent successfully');
                return true;
            })
            .catch((err) => {
                ctrl.loading = false;
                ctrl.notification = err.data.message;
                return false;
            });
    }
}

export default EngageEducationContentOptInModalController;
