import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import hasLength from 'App/utils/hasLength';
import useIsPermitted from 'App/utils/hooks/useIsPermitted';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import { patientSubscriptionPropType } from 'Lib/types';
import ClickLink from 'App/components/styles/ClickLink';
import NoSubmission from 'App/components/PatientProfile/components/PatientEngagement/MessagingStatus/NoSubmission';
import Unconfimed from 'App/components/PatientProfile/components/PatientEngagement/MessagingStatus/Unconfimed';
import Subscribed from 'App/components/PatientProfile/components/PatientEngagement/MessagingStatus/Subscribed';
import Unsubscribed from 'App/components/PatientProfile/components/PatientEngagement/MessagingStatus/Unsubscribed';

const propTypes = {
    subscription: patientSubscriptionPropType,
    showMessagingModal: PropTypes.func.isRequired,
    showHistoryModal: PropTypes.func.isRequired,
    showUnenrollmentModal: PropTypes.func.isRequired,
    showOneTimeModal: PropTypes.func.isRequired,
};

const defaultProps = {
    subscription: null,
};

const States = {
    NEVER_SUBMITTED: 'NEVER_SUBMITTED',
    UNCONFIRMED: 'UNCONFIRMED',
    SUBSCRIBED: 'SUBSCRIBED',
    UNSUBSCRIBED: 'UNSUBSCRIBED',
};

const isNilOrEmpty = R.anyPass([R.isEmpty, R.isNil]);
const isSubscribed = R.allPass([
    R.propEq(1, 'confirmed'),
    R.prop('subscribedOn'),
    R.propSatisfies(isNilOrEmpty, 'unsubscribedOn'),
]);

//prettier-ignore
const isUnsubscibed = R.allPass([
    R.propEq(1, 'confirmed'),
    R.propSatisfies(hasLength, 'unsubscribedOn')
]);

function getCurrentState(subscription = null) {
    return R.cond([
        [R.isNil, R.always(States.NEVER_SUBMITTED)],
        [isSubscribed, R.always(States.SUBSCRIBED)],
        [isUnsubscibed, R.always(States.UNSUBSCRIBED)],
        [R.T, R.always(States.UNCONFIRMED)],
    ])(subscription);
}

function MessagingStatus({
    subscription,
    showMessagingModal,
    showHistoryModal,
    showUnenrollmentModal,
    showOneTimeModal,
}) {
    const hasEnabledMassTexting = useIsPermitted(PERMISSION_TYPES.MASS_TEXTING);

    const state = getCurrentState(subscription);

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {R.cond([
                [
                    R.equals(States.NEVER_SUBMITTED),
                    () => {
                        return <NoSubmission showMessagingModal={showMessagingModal} />;
                    },
                ],
                [
                    R.equals(States.UNCONFIRMED),
                    () => {
                        return (
                            <Unconfimed showMessagingModal={showMessagingModal} showHistoryModal={showHistoryModal} />
                        );
                    },
                ],
                [
                    R.equals(States.SUBSCRIBED),
                    () => {
                        return (
                            <Subscribed
                                showMessagingModal={showMessagingModal}
                                showHistoryModal={showHistoryModal}
                                showUnenrollmentModal={showUnenrollmentModal}
                            />
                        );
                    },
                ],
                [
                    R.equals(States.UNSUBSCRIBED),
                    () => {
                        return (
                            <Unsubscribed showMessagingModal={showMessagingModal} showHistoryModal={showHistoryModal} />
                        );
                    },
                ],
            ])(state)}

            {subscription?.status && hasEnabledMassTexting && (
                <ClickLink onClick={showOneTimeModal}>
                    <i className="fa fa-mobile" id="send-one-time-text-message-link" /> Send One-Time Text Message
                </ClickLink>
            )}
        </div>
    );
}

MessagingStatus.propTypes = propTypes;
MessagingStatus.defaultProps = defaultProps;

export default MessagingStatus;
