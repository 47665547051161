import angular from 'angular';
import CommonConfig from './common.config';

// Controllers
import CommonController from './common.controller';
import AppNavController from './app-nav.controller';
import AppHeaderController from './app-header/app-header.controller';
import ConfirmationModalController from './confirmation-modal/confirmation-modal.controller';
import PageController from './page/page.controller';
import TherigyForbiddenController from './therigy-forbidden/therigy-forbidden.controller';
import TherigyNotFoundController from './therigy-not-found/therigy.not-found.controller';
import TherigySpinnerController from './therigy-app-spinner/therigy-app-spinner.controller';
import TherigyUnknownErrorController from './therigy-unknown-error/therigy-unknown-error.controller';
// Directives
import TherigyTrackedTableCell from './therigy-tracked-table/cell.directive';
import TherigyTrackedTableRow from './therigy-tracked-table/row.directive';
import TherigyTrackedTable from './therigy-tracked-table/table.directive';

import UserService from '../services/UserService';

/** @ngInject */
function run(_paq, $rootScope, $state, $transitions, authService) {
    window.myAppErrorLog = [];
    $state.defaultErrorHandler((error) => {
        // This is silencing console errors that occur when a state is rejected intentionally.
        // When our error logging becomes more sophisticated we will build upon this feature.
        window.myAppErrorLog.push(error);
    });

    $transitions.onSuccess(
        {
            to: 'app.*',
        },
        async (trans) => {
            await authService.updateJwt();
            await UserService.getMessageCount();
            $rootScope.$emit('messageCount', window.localStorage.getItem('messageCount'));

            // Have to use transition.to, using $window will result in reporting the previous URL.
            _paq.push(['setCustomUrl', trans.to().url]);
            _paq.push(['setDocumentTitle', trans.to().name]);
            _paq.push(['trackPageView']);
        }
    );

    $transitions.onError(
        {
            from: '*',
        },
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line consistent-return
        (trans) => {
            const err = trans.error().detail;
            if (err.status && err.status === 404) {
                return trans.router.stateService.go('404');
            }
        }
    );
}

/**
 *
 * @ngdoc module
 * @name common
 *
 * @requires ui.router
 *
 * @description
 *
 * This is the common module. It includes a run method that setups the loading bar.
 *
 **/
export default angular
    .module('common', ['angular.filter', 'angular-ladda', 'ui.router'])
    .component('app', {
        controller: CommonController,
        bindings: {
            user: '<',
        },
        template: require('./common.html'),
    })
    .component('appNav', {
        bindings: {
            user: '<',
        },
        controller: AppNavController,
        template: require('./app-nav.html'),
    })
    .component('appHeader', {
        bindings: {
            newMessages: '<',
            user: '<',
        },
        controller: AppHeaderController,
        template: require('./app-header/app-header.html'),
    })
    .component('confirmationModal', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: ConfirmationModalController,
        template: require('./confirmation-modal/confirmation-modal.html'),
    })
    .component('page', {
        bindings: {
            pageTitle: '<',
        },
        controller: PageController,
        template: require('./page/page.html'),
    })
    .component('therigyAppSpinner', {
        bindings: {
            message: '<',
        },
        controller: TherigySpinnerController,
        template: require('./therigy-app-spinner/therigy-app-spinner.html'),
    })
    .component('therigyDangerMessage', {
        bindings: {
            message: '<',
        },
        template: require('./therigy-danger-message/therigy-danger-message.html'),
    })
    .component('therigyForbidden', {
        controller: TherigyForbiddenController,
        template: require('./therigy-forbidden/therigy-forbidden.html'),
    })
    .component('therigyInfoMessage', {
        bindings: {
            message: '<',
        },
        template: require('./therigy-info-message/therigy-info-message.html'),
    })
    .component('therigyLoadingSpinner', {
        bindings: {
            message: '<',
        },
        template: require('./therigy-loading-spinner/therigy-loading-spinner.html'),
    })
    .component('therigyNotFound', {
        controller: TherigyNotFoundController,
        template: require('./therigy-not-found/therigy-not-found.html'),
    })
    .component('therigyUnknownError', {
        controller: TherigyUnknownErrorController,
        template: require('./therigy-unknown-error/therigy-unknown-error.html'),
    })
    .directive('therigyTrackedTableCell', TherigyTrackedTableCell)
    .directive('therigyTrackedTableRow', TherigyTrackedTableRow)
    .directive('therigyTrackedTable', TherigyTrackedTable)
    .config(CommonConfig)
    .run(run).name;
