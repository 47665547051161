import React, { useMemo } from 'react';
import { CheckboxField } from 'Lib/form/CheckboxField';
import { CheckboxMultiField } from 'Lib/form/CheckboxMultiField';
import { useFormikContext } from 'formik';
import { sortBy } from 'lodash';
import useFetchTherapeuticCategory from '../../hooks/useFetchTherapeuticCategory';
import { setWorkQueueFilterGenerator } from '../../workQueue.utils';

const TherapeuticCategoryFilter = () => {
    const { data: availableTherapies } = useFetchTherapeuticCategory();
    const {
        values: { filterParams },
        setFieldValue,
    } = useFormikContext();
    const { allCategories, categories } = filterParams;
    const setWorkQueueFilter = setWorkQueueFilterGenerator(setFieldValue);

    const options = useMemo(() => {
        return sortBy(
            Object.values(availableTherapies || {}).map((opt) => ({ id: opt.id, option: opt.name })),
            'option'
        );
    }, [availableTherapies]);

    return (
        <div className="filter form-group">
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="control-label filter-header">Therapeutic Category</label>
            <CheckboxField
                fieldName="filterParams.allCategories"
                label="All Therapeutic Categories"
                onChange={(e) => setWorkQueueFilter(filterParams, 'allCategories', e.target.checked)}
            />
            {!allCategories && (
                <CheckboxMultiField
                    fieldName="filterParams.categories"
                    value={categories}
                    options={options}
                    onChange={(value) => setWorkQueueFilter(filterParams, 'categories', value)}
                />
            )}
        </div>
    );
};

export default TherapeuticCategoryFilter;
