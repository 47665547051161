import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';

import ClickLink from 'App/components/styles/ClickLink';
import useActivityDueDateModal from '../../hooks/useActivityDueDateModal';
import { cellPropsType } from '../../workQueue.constant';

const ActivityDueDateMobileAnswersCell = ({ row, value }) => {
    const { setFieldValue } = useFormikContext();
    const onActivityDateClick = useCallback(
        (state) => setFieldValue('modalMobileAnswersState.mobileAnswersActivityDateModal', state),
        [setFieldValue]
    );
    const modalState = useActivityDueDateModal(row);
    return (
        <span>
            <ClickLink onClick={() => onActivityDateClick(modalState)}>{value}</ClickLink>
        </span>
    );
};

ActivityDueDateMobileAnswersCell.propTypes = cellPropsType;

export default ActivityDueDateMobileAnswersCell;
