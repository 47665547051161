import PESService from 'App/services/PESService';
import { useQuery } from 'react-query';
import { useUserContext } from '@/app/contexts/UserContext';

function useFetchWorkQueueMobileAnswers(reqBody) {
    const user = useUserContext();
    return useQuery(
        [
            'company',
            user.active_company.ID,
            'branch',
            user.active_branch.ID,
            'workQueue',
            'mobileAnswersComplete',
            JSON.stringify(reqBody),
        ],
        () => PESService.getMobileAnswersQueue(reqBody),
        {
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useFetchWorkQueueMobileAnswers;
