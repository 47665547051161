import * as R from 'ramda';
import toast from 'Lib/toast';

const isString = R.is(String);

const noop = R.always(undefined);

const isNotEmpty = R.complement(R.isEmpty);

function toastIfMessage(path) {
    return [
        R.pathSatisfies(R.allPass([isString, isNotEmpty]), path),
        (error) => {
            return toast.error(R.path(path, error));
        },
    ];
}

function errorHandler(error, showToast = true) {
    // eslint-disable-next-line no-console
    console.error(error);

    if (window.location.hash !== '#!/login' && window.location.hash !== '#!/logout' && showToast) {
        // prettier-ignore
        R.cond([
            [R.pathEq(401, ['response', 'status']), noop],
            toastIfMessage(['response', 'errors', 0, 'message']),
            toastIfMessage(['response', 'data', 'message']),
            toastIfMessage(['message']),
            [R.T, () => toast.error('An unknown error occurred.')],
        ])(error)
    }
}

export default errorHandler;
