import angular from 'angular';

import AddTherapyModalController from '../therapy/add-therapy-modal/add-therapy-modal.controller';
import MedicationAddNoteController from './patient-medication/medication-add-note/medication-add-note.controller';
import MedicationHistoryModalController from './patient-medication/medication-history/medication-history-modal.controller';
import MedicationNotesController from './patient-medication/medication-notes/medication-notes-modal.controller';
import OptInTherapyController from '../therapy/opt-in-therapy/opt-in-therapy.component';
import OptOutTherapyController from '../therapy/opt-out-therapy/opt-out-therapy.component';
import PatientAllergiesController from './patient-allergies/patient-allergies.controller';
import PatientCardController from './patient-card/patient-card.controller';
import PatientConfig from './patient.config';
import PatientEmergencyContactInformationController from './patient-emergency-contact/patient-emergency-contact.controller';
import PatientHighRiskIndicatorController from './patient-high-risk-indicator/patient-high-risk-indicator.controller';
import PatientInformationController from './patient-information/patient-information.controller';
import PatientLabelsController from './patient-labels/patient-labels.controller';
import PatientNotesController from './patient-notes/patient-notes.controller';
import PatientOtherConditionController from './patient-other-condition/patient-other-condition.controller';
import PatientOtherMedicationController from './patient-other-medication/patient-other-medication.controller';
import PatientOtherMedicationHistoryController from './patient-other-medication-history/patient-other-medication-history.controller';
import PatientProfileActivitiesController from './profile/past-activities/past-activities.controller';
import PatientProfileAddActivityController from './profile/add-activity/add-activity.controller';
import PatientProfileAddReferralModalController from './profile/add-referral-modal/add-referral-modal.controller';
import PatientProfileDeletedActivitiesController from './profile/deleted-activities/deleted-activities.controller';
import PatientProfileEditDueDateModalController from './profile/edit-due-date-modal/edit-due-date-modal.controller';
import PatientProfilePendingActivitiesController from './profile/pending-activities/pending-activities.controller';
import PatientProfileTherapiesController from './profile/therapies/therapies.controller';
import PatientReferralManagerController from './patient-referral-manager/patient-referral-manager.controller';
import PatientRun from './patient.run';
import PatientSynchronizationController from './profile/pdx-synchronization/patient-synchronization.controller';
import PatientTherapeuticCategoryController from './patient-therapeutic-category/patient-therapeutic-category.controller';
import PatientUserAlertsController from './patient-user-alerts/patient-user-alerts.controller';
import PatientViewAttachmentsController from './profile/view-attachments/view-attachments.controller';
import PatientWelcomePacketController from './patient-welcome-packet/patient-welcome-packet.controller';
import PharmacyInformationController from './patient-pharmacy/patient-pharmacy.controller';
import PrescriberAdapterService from '../prescriber/prescriber/prescriber.adapter';
import SummaryNotesController from '../patient/summary-notes/summary-notes.controller';

export default angular
    .module('components.patient', ['ngMessages', 'ngTable', 'ui.bootstrap'])
    .component('patient', {
        controller: () => {},
    })
    .config(PatientConfig)
    .component('patientAllergies', {
        bindings: {
            disabledFields: '<',
            patient: '<',
            requiredFields: '<',
            form: '<',
        },
        require: {
            form: '^form',
        },
        controller: PatientAllergiesController,
        template: require('./patient-allergies/patient-allergies.html'),
    })
    .component('patientCard', {
        bindings: {
            patient: '<',
            user: '<',
        },
        controller: PatientCardController,
        template: require('./patient-card/patient-card.html'),
    })
    .component('patientEmergencyContact', {
        bindings: {
            disabledFields: '<',
            patient: '<',
            requiredFields: '<',
        },
        controller: PatientEmergencyContactInformationController,
        template: require('./patient-emergency-contact/patient-emergency-contact.html'),
    })
    .component('patientHighRiskIndicator', {
        bindings: {
            patientId: '@',
            triggers: '<',
        },
        controller: PatientHighRiskIndicatorController,
        template: require('./patient-high-risk-indicator/patient-high-risk-indicator.html'),
    })
    .component('patientInformation', {
        bindings: {
            patient: '<',
            highRiskReasons: '<',
            patientLabels: '<',
            emptyPatientLabel: '<',
            form: '<',
            disabledFields: '<',
            requiredFields: '<',
        },
        require: {
            form: '^form',
        },
        controller: PatientInformationController,
        template: require('./patient-information/patient-information.html'),
    })
    .component('patientLabels', {
        bindings: {
            patientId: '@',
        },
        controller: PatientLabelsController,
        template: require('./patient-labels/patient-labels.html'),
    })
    .component('medicationAddNote', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: MedicationAddNoteController,
        template: require('./patient-medication/medication-add-note/medication-add-note.html'),
    })
    .component('medicationHistoryModal', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: MedicationHistoryModalController,
        template: require('./patient-medication/medication-history/medication-history-modal.html'),
    })
    .component('medicationNotes', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: MedicationNotesController,
        template: require('./patient-medication/medication-notes/medication-notes-modal.html'),
    })
    .component('patientNotes', {
        bindings: {
            patient: '<',
        },
        controller: PatientNotesController,
        template: require('./patient-notes/patient-notes.html'),
    })
    .component('patientOtherCondition', {
        bindings: {
            disabledFields: '<',
            patient: '<',
            requiredFields: '<',
        },
        controller: PatientOtherConditionController,
        require: {
            form: '^form',
        },
        template: require('./patient-other-condition/patient-other-condition.html'),
    })
    .component('patientOtherMedication', {
        bindings: {
            disabledFields: '<',
            patient: '<',
            requiredFields: '<',
            form: '<',
        },
        require: {
            form: '^form',
        },
        controller: PatientOtherMedicationController,
        template: require('./patient-other-medication/patient-other-medication.html'),
    })
    .component('patientOtherMedicationHistory', {
        bindings: {
            patientId: '<',
        },
        controller: PatientOtherMedicationHistoryController,
        template: require('./patient-other-medication-history/patient-other-medication-history.html'),
    })
    .component('patientOtherMedicationModal', {
        bindings: {
            close: '&',
            resolve: '<',
        },
        template: require('./patient-other-medication-modal/patient-other-medication-modal.html'),
    })
    .component('patientPharmacy', {
        bindings: {
            disabledFields: '<',
            patient: '<',
            requiredFields: '<',
        },
        controller: PharmacyInformationController,
        template: require('./patient-pharmacy/patient-pharmacy.html'),
    })
    .component('patientReferralManager', {
        bindings: {
            patient: '<',
            user: '<',
        },
        controller: PatientReferralManagerController,
        require: {
            form: '^form',
        },
        template: require('./patient-referral-manager/patient-referral-manager.html'),
    })
    .component('patientTherapeuticCategory', {
        bindings: {
            disabledFields: '<',
            patient: '<',
            user: '<',
            requiredFields: '<',
            icd10selected: '<',
        },
        controller: PatientTherapeuticCategoryController,
        require: {
            form: '^form',
        },
        template: require('./patient-therapeutic-category/patient-therapeutic-category.html'),
    })
    .component('patientUserAlerts', {
        bindings: {
            disabledFields: '<',
            patient: '<',
            requiredFields: '<',
        },
        require: {
            form: '^form',
        },
        controller: PatientUserAlertsController,
        template: require('./patient-user-alerts/patient-user-alerts.html'),
    })
    .component('patientWelcomePacket', {
        bindings: {
            disabledFields: '<',
            patient: '<',
            requiredFields: '<',
        },
        controller: PatientWelcomePacketController,
        template: require('./patient-welcome-packet/patient-welcome-packet.html'),
    })
    .component('patientProfileAddActivity', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: PatientProfileAddActivityController,
        template: require('./profile/add-activity/add-activity.html'),
    })
    .component('patientProfileAddReferralModal', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: PatientProfileAddReferralModalController,
        template: require('./profile/add-referral-modal/add-referral-modal.html'),
    })
    .component('patientProfileDeletedActivities', {
        bindings: {
            activities: '<',
            patient: '<',
            user: '<',
        },
        controller: PatientProfileDeletedActivitiesController,
        template: require('./profile/deleted-activities/deleted-activities.html'),
    })
    .component('patientProfileEditDueDateModal', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: PatientProfileEditDueDateModalController,
        template: require('./profile/edit-due-date-modal/edit-due-date-modal.html'),
    })
    .component('patientProfilePastActivities', {
        bindings: {
            activities: '<',
            patient: '<',
            user: '<',
            branchSettings: '<',
        },
        controller: PatientProfileActivitiesController,
        template: require('./profile/past-activities/past-activities.html'),
    })
    .component('patientSynchronization', {
        bindings: {
            patient: '<',
        },
        controller: PatientSynchronizationController,
        template: require('./profile/pdx-synchronization/patient-synchronization.html'),
    })
    .component('patientProfilePendingActivities', {
        bindings: {
            activities: '<',
            patient: '<',
            user: '<',
        },
        controller: PatientProfilePendingActivitiesController,
        template: require('./profile/pending-activities/pending-activities.html'),
    })
    .component('patientProfileTherapies', {
        bindings: {
            medications: '<',
            showEndedMedications: '<',
            therapies: '<',
        },
        controller: PatientProfileTherapiesController,
        template: require('./profile/therapies/therapies.html'),
    })
    .component('patientViewAttachments', {
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&',
        },
        controller: PatientViewAttachmentsController,
        template: require('./profile/view-attachments/view-attachments.html'),
    })
    .component('summaryNotes', {
        bindings: {
            activity: '<',
            patient: '<',
            user: '<',
        },
        controller: SummaryNotesController,
        template: require('./summary-notes/summary-notes.html'),
    })
    .component('addTherapyModal', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: AddTherapyModalController,
        template: require('../therapy/add-therapy-modal/add-therapy-modal.html'),
    })
    .component('optInTherapy', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: OptInTherapyController,
        template: require('../therapy/opt-in-therapy/opt-in-therapy.html'),
    })
    .component('patientPrescription', {
        bindings: {
            patientPrescriptions: '=',
            icd10selected: '=',
        },
        controller: AddTherapyModalController,
        template: require('../therapy/add-therapy-modal/add-therapy-modal.html'),
    })
    .component('optOutTherapy', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: OptOutTherapyController,
        template: require('../therapy/opt-out-therapy/opt-out-therapy.html'),
    })
    .service('PrescriberAdapterService', PrescriberAdapterService)
    .run(PatientRun).name;
