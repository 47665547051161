import CompanyService from 'App/services/CompanyService';
import * as R from 'ramda';
import PatientPrescription from 'App/components/PatientPrescription/PatientPrescription';
import PatientICD10s from 'App/components/PatientICD10s/PatientICD10s';
import React from 'react';
import mountReactComponent from 'App/utils/mountReactComponent';
import convertKeysToSnakeCase from '../../../utils/keyConversion/convertKeysToSnakeCase';
import convertKeysToCamelCase from '../../../utils/keyConversion/convertKeysToCamelCase';
import * as yup from 'yup';
import AppContextForAngular from 'App/components/AppContextForAngular';
import { v4 as uuid } from 'uuid';

/** @ngInject */
function AddTherapyModalController($uibModal, $rootScope, $scope, _, medicationsService, physicianService) {
    const ctrl = this;

    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.doChangeMedication = doChangeMedication;
    ctrl.doChangeTherapy = doChangeTherapy;
    ctrl.doChangeStatusStart = doChangeStatusStart;
    ctrl.isLocked = isLocked;
    ctrl.isRequired = isRequired;
    ctrl.openEndMedicationModal = openEndMedicationModal;
    ctrl.openEndPrescriptionModal = openEndPrescriptionModal;
    ctrl.optInTherapy = optInTherapy;
    ctrl.optOutTherapy = optOutTherapy;
    ctrl.removeRxNumber = removeRxNumber;
    ctrl.resetEndMedication = resetEndMedication;
    ctrl.resetEndPrescription = resetEndPrescription;
    ctrl.resetOptOut = resetOptOut;
    ctrl.submitForm = submitForm;
    ctrl.onChangeICD9 = onChangeICD9;
    ctrl.onChangeICD10 = onChangeICD10;
    ctrl.patientPrescriptions = {};
    ctrl.patientPrescriptionsArray = [];
    ctrl.searchPrescriber = [];
    ctrl.selectedPrescriberId = '';
    ctrl.isNewRxLoading = false;
    ctrl.validateRxFillNumberErrors = {};
    ctrl.validatePatientICD10sErrors = {};
    ctrl.validationOfRxFillNumber = [];
    ctrl.validationOfICD10 = [];
    ctrl.icd10selected = [];

    function handleEndRxNumber(prescription, rxIndex, medication) {
        openEndPrescriptionModal(convertKeysToSnakeCase(prescription), rxIndex, medication);
        mountUpdatePatientRx();
    }

    function handleResetEndPrescription(rxIndex, medication) {
        resetEndPrescription(rxIndex, medication);
        mountUpdatePatientRx();
    }

    function emitEventToAddPrescriber(prescriber) {
        $scope.$broadcast('openPrescriberModal', prescriber);
    }

    function handleSelectedPrescriber(value) {
        ctrl.selectedPrescriberId = value;
        mountUpdatePatientRx();
    }

    function handleSearchPrescriber(value) {
        if (value) {
            return physicianService
                .autocomplete(value)
                .then((res) => {
                    ctrl.searchPrescriber = _.sortBy(res, ['physician_first_name', 'physician_last_name']).splice(0, 5);
                    mountUpdatePatientRx();
                    return ctrl.searchPrescriber;
                })
                .catch(() => {
                    return {};
                });
        }
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function $onChanges(changes) {
        if (changes.resolve) {
            if (ctrl.resolve.disabledFields) {
                ctrl.disabledFields = angular.copy(ctrl.resolve.disabledFields);
            }

            if (ctrl.resolve.requiredFields) {
                ctrl.requiredFields = angular.copy(ctrl.resolve.requiredFields);
            }

            if (ctrl.resolve.patient) {
                ctrl.patient = angular.copy(ctrl.resolve.patient);
            }

            if (ctrl.resolve.user) {
                ctrl.user = angular.copy(ctrl.resolve.user);
            }

            if (ctrl.resolve.originalTherapy) {
                ctrl.originalTherapy = angular.copy(ctrl.resolve.originalTherapy) || {};
            }

            if (ctrl.resolve.icd10selected) {
                ctrl.icd10selected = angular.copy(ctrl.resolve.icd10selected);
                mountPatientICD10s();
            }

            if (ctrl.resolve.therapy) {
                ctrl.therapy = angular.copy(ctrl.resolve.therapy);
                ctrl.therapyLocked = true;
            } else {
                ctrl.therapy = {
                    newTherapy: true,
                    status: {},
                };
                ctrl.therapyLocked = false;
            }

            if (ctrl.resolve.medication) {
                ctrl.medication = angular.copy(ctrl.resolve.medication);
            } else if (ctrl.resolve.medication === false) {
                ctrl.medication = false;
            } else {
                ctrl.medication = {};
                ctrl.medicationLocked = false;
            }

            ctrl.prescriptions = {};

            if (ctrl.resolve.patientPrescriptions) {
                ctrl.patientPrescriptions = angular.copy(ctrl.resolve.patientPrescriptions);
                mountUpdatePatientRx();
            } else {
                ctrl.patientPrescriptions = {};
            }

            ctrl.unavailableMedications = getUnavailableMedicationIds(
                ctrl.patient.medications,
                ctrl.resolve.medication,
                ctrl.resolve.isEdit
            );

            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line consistent-return
            return CompanyService.getTherapyCategories().then((therapies) => {
                ctrl.availableTherapies = therapies;

                if (R.path(['therapy', 'id'], ctrl)) {
                    return medicationsService
                        .get(ctrl.therapy.id)
                        .then(R.omit(ctrl.unavailableMedications))
                        .then((res) => {
                            ctrl.therapyMedications = res;
                        });
                }
            });
        }
    }

    function getUnavailableMedicationIds(patientMeds, resolveMed, isEdit) {
        if (isEdit) {
            return R.compose(
                R.map(R.path(['medication', 'id'])),
                R.reject(R.pathEq(R.prop('id', resolveMed), ['medication', 'id'])),
                R.values
            )(patientMeds);
        }

        return R.compose(R.map(R.path(['medication', 'id'])), R.values)(patientMeds);
    }

    function handleFillPropChange(medicationId, rxNumberIndex, fillNumberIndex, fieldName, value) {
        if (!ctrl.patientPrescriptions[medicationId]) {
            return;
        }
        ctrl.patientPrescriptions = R.set(
            R.lensPath([medicationId, 'rxNumbers', rxNumberIndex, 'rxFill', fillNumberIndex, fieldName]),
            value,
            ctrl.patientPrescriptions
        );

        mountUpdatePatientRx();
    }

    function handlePropChange(medicationId, rxNumberIndex, id, value) {
        if (!medicationId) {
            return;
        }
        ctrl.patientPrescriptions[medicationId] = ctrl.patientPrescriptions[medicationId] || {};
        ctrl.patientPrescriptions[medicationId].rxNumbers = ctrl.patientPrescriptions[medicationId].rxNumbers || [];
        ctrl.patientPrescriptions[medicationId].rxNumbers[rxNumberIndex] =
            ctrl.patientPrescriptions[medicationId].rxNumbers[rxNumberIndex] || {};

        ctrl.patientPrescriptions[medicationId].rxNumbers[rxNumberIndex].patientMedicationId =
            ctrl.medication.patient_medication_id;
        ctrl.patientPrescriptions[medicationId].rxNumbers[rxNumberIndex][id] = value;
        mountUpdatePatientRx();
    }

    function handleRemoveRxNumber(medicationId, rx) {
        ctrl.patientPrescriptions[medicationId].rxNumbers.splice(rx, 1);
        mountUpdatePatientRx();
    }

    function handleRemoveFillNumber(medicationId, rx, fill) {
        ctrl.patientPrescriptions[medicationId].rxNumbers[rx].rxFill.splice(fill, 1);
        mountUpdatePatientRx();
    }

    function handleICD10(value) {
        ctrl.icd10selected = value;
        mountPatientICD10s();
    }

    function validatePatientICD10s(id, value) {
        ctrl.validatePatientICD10sErrors[id] = '';
        if (!value.length && ctrl.isRequired('icd_10_code')) {
            if (id === 'icd10Code') {
                ctrl.validatePatientICD10sErrors[id] = 'Please enter a ICD10 code';
            }
        }
    }

    function validatePatientICD10sOnSubmit() {
        if (ctrl.isRequired('icd_10_code')) {
            const schema = yup
                .array()
                .of(
                    yup.object().shape({
                        id: yup.string(),
                        code: yup.string(),
                        longDescription: yup.string(),
                        shortDescription: yup.string(),
                    })
                )
                .required('Please enter a ICD10 code')
                .label('ICD10 code');

            try {
                schema.validateSync(ctrl.icd10selected, { abortEarly: false });
            } catch (e) {
                if (e.name === 'ValidationError') {
                    for (const error of e.inner) {
                        ctrl.validatePatientICD10sErrors.icd10Code = error.errors[0];
                    }
                }
            }
            ctrl.validationOfICD10 = [...new Set(Object.values(ctrl.validatePatientICD10sErrors))];
            return ctrl.validatePatientICD10sErrors;
        }
        return {};
    }

    function validateRxFillNumberOnSubmit() {
        ctrl.prescriptionKeys = Object.keys(ctrl.patientPrescriptions);
        const schema = yup.object().shape({
            rxNumber:
                ctrl.isRequired('rx_number') &&
                yup.string().nullable().required('Please enter a Rx Number').label('Rx Number'),
            strength:
                ctrl.isRequired('rx_strength') &&
                yup.string().nullable().required('Please enter a Strength').label('Strength'),
            refillsAuthorized:
                ctrl.isRequired('refills_allowed') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a Refills Authorized')
                    .required('Please enter a Refills Authorized')
                    .label('Refills Authorized'),
            physician:
                ctrl.isRequired('rx_prescriber') &&
                yup.object().nullable().required('Please enter a Rx Prescriber').label('Rx Prescriber'),
            start:
                ctrl.isRequired('rx_number_written_date') &&
                yup
                    .string()
                    .nullable()
                    .required('Please enter a Rx Number Written Date')
                    .label('Rx Number Written Date'),
            fillExpiration:
                ctrl.isRequired('rx_number_written_date') &&
                yup
                    .string()
                    .nullable()
                    .matches(/\b(?!Select\b)\w+/, 'Please enter a Rx Number Exp. Date')
                    .required('Please enter a Rx Number Exp. Date')
                    .label('Rx Number Exp. Date'),
            rxFill: yup.array().of(
                yup.object().shape({
                    fillNumber:
                        ctrl.isRequired('fill_number') &&
                        yup
                            .string()
                            .nullable()
                            .matches(/\b(?!Select\b)\w+/, 'Please enter a Fill Number')
                            .required('Please enter a Fill Number')
                            .label('Fill Number'),
                    fillDate:
                        ctrl.isRequired('fill_date') &&
                        yup
                            .string()
                            .nullable()
                            .matches(/\b(?!Select\b)\w+/, 'Please enter a Fill Date')
                            .required('Please enter a Fill Date')
                            .label('Fill Date'),
                    dispensedQuantity:
                        ctrl.isRequired('dispensed_quantity') &&
                        yup.number().min(1).required('Please enter a Dispensed Qty').label('Dispensed Qty'),
                    daysSupply:
                        ctrl.isRequired('days_supply') &&
                        yup.number().min(1).required('Please enter a Days Supply').label('Days Supply'),
                })
            ),
        });
        for (const key of ctrl.prescriptionKeys) {
            if (ctrl.patientPrescriptions[key].rxNumbers.length) {
                ctrl.patientPrescriptions[key].rxNumbers.map((rx, rxIndex) => {
                    ctrl.validateRxFillNumberErrors = {};
                    ctrl.validateRxFillNumberErrors[rxIndex] = ctrl.validateRxFillNumberErrors[rxIndex] || {};
                    try {
                        schema.validateSync(ctrl.patientPrescriptions[key].rxNumbers[rxIndex], { abortEarly: false });
                    } catch (e) {
                        if (e.name === 'ValidationError') {
                            for (const error of e.inner) {
                                ctrl.validateRxFillNumberErrors[rxIndex][error.path] = error.errors[0];
                            }
                        }
                    }
                    ctrl.validationOfRxFillNumber = [
                        ...new Set(
                            ctrl.validationOfRxFillNumber.concat(
                                Object.values(ctrl.validateRxFillNumberErrors[rxIndex])
                            )
                        ),
                    ];
                    return ctrl.validationOfRxFillNumber;
                });
            }
        }
        ctrl.validationOfRxFillNumber = ctrl.validationOfRxFillNumber.map((item) =>
            item.replace('Please enter a ', '')
        );
        $rootScope.$broadcast('validateRxFillNumber', ctrl.validationOfRxFillNumber);
        return ctrl.validateRxFillNumberErrors;
    }

    function validateRxFillNumber(item, id, value, fill = -1) {
        ctrl.validateRxFillNumberErrors[item] = ctrl.validateRxFillNumberErrors[item] || {};
        ctrl.validateRxFillNumberErrors[item][id] = '';
        if (!value) {
            if (id === 'rxNumber') {
                ctrl.validateRxFillNumberErrors[item][id] = 'Please enter a Rx Number';
            }
            if (id === 'strength') {
                ctrl.validateRxFillNumberErrors[item][id] = 'Please enter a Rx Strength';
            }
            if (id === 'refillsAuthorized' && value < 0) {
                ctrl.validateRxFillNumberErrors[item][id] = 'Please enter a Refills Authorized';
            }
            if (id === 'start') {
                ctrl.validateRxFillNumberErrors[item][id] = 'Please enter a Rx Number Written Date';
            }
            if (id === 'fillExpiration') {
                ctrl.validateRxFillNumberErrors[item][id] = 'Please enter a Rx Number Exp. Date';
            }
            if (id === 'physician') {
                ctrl.validateRxFillNumberErrors[item][id] = 'Please enter a Rx Prescriber';
            }
            if (id === 'fillNumber' && value < 0) {
                ctrl.validateRxFillNumberErrors[item][`rxFill[${fill}].fillNumber`] = 'Please enter a Fill Number';
            }
            if (id === 'fillDate') {
                ctrl.validateRxFillNumberErrors[item][`rxFill[${fill}].fillDate`] = 'Please enter a Fill Date';
            }
            if (id === 'dispensedQuantity') {
                ctrl.validateRxFillNumberErrors[item][`rxFill[${fill}].dispensedQuantity`] =
                    'Please enter a Dispensed Qty';
            }
            if (id === 'daysSupply') {
                ctrl.validateRxFillNumberErrors[item][`rxFill[${fill}].daysSupply`] = 'Please enter a Days Supply';
            }
        } else {
            if (id === 'fillNumber') {
                ctrl.validateRxFillNumberErrors[item][`rxFill[${fill}].fillNumber`] = '';
            }
            if (id === 'fillDate') {
                ctrl.validateRxFillNumberErrors[item][`rxFill[${fill}].fillDate`] = '';
            }
            if (id === 'dispensedQuantity') {
                ctrl.validateRxFillNumberErrors[item][`rxFill[${fill}].dispensedQuantity`] = '';
            }
            if (id === 'daysSupply') {
                ctrl.validateRxFillNumberErrors[item][`rxFill[${fill}].daysSupply`] = '';
            }
        }
        mountUpdatePatientRx();
    }

    /**
     * Mount react
     */
    mountUpdatePatientRx();

    function mountUpdatePatientRx() {
        mountReactComponent(
            '#edit-patient-rx-number',
            <AppContextForAngular>
                <PatientPrescription
                    onAddRxNumber={addRxNumber}
                    onAddRxFill={addRxFill}
                    patientPrescriptions={ctrl.patientPrescriptions}
                    handlePropChange={handlePropChange}
                    medication={ctrl.medication}
                    handleFillPropChange={handleFillPropChange}
                    emitEventToAddPrescriber={emitEventToAddPrescriber}
                    handleRemoveRxNumber={handleRemoveRxNumber}
                    handleRemoveFillNumber={handleRemoveFillNumber}
                    handleSearchPrescriber={handleSearchPrescriber}
                    searchPrescriber={ctrl.searchPrescriber || []}
                    handleSelectedPrescriber={handleSelectedPrescriber}
                    selectedPrescriber={ctrl.selectedPrescriberId}
                    handleEndRxNumber={handleEndRxNumber}
                    handleResetEndPrescription={handleResetEndPrescription}
                    validateRxFillNumber={validateRxFillNumber}
                    validateRxFillNumberErrors={ctrl.validateRxFillNumberErrors}
                />
            </AppContextForAngular>
        );
    }

    function addRxFill(medicationId, rxNumberIndex) {
        const starterRxFill = {
            _internalUiId: uuid(),
            fillNumber: null,
            fillDate: null,
            dispensedQuantity: null,
            daysSupply: null,
        };

        if (!ctrl.patientPrescriptions[medicationId]) {
            return;
        }

        ctrl.patientPrescriptions = R.over(
            R.lensPath([medicationId, 'rxNumbers', rxNumberIndex, 'rxFill']),
            R.append(starterRxFill),
            ctrl.patientPrescriptions
        );

        mountUpdatePatientRx();
    }

    function mountPatientICD10s() {
        mountReactComponent(
            '#multiple-icd10s',
            <AppContextForAngular>
                <PatientICD10s
                    onChangeICD10={handleICD10}
                    icd10selected={ctrl.icd10selected || []}
                    validatePatientICD10s={validatePatientICD10s}
                    validatePatientICD10sErrors={ctrl.validatePatientICD10sErrors}
                />
            </AppContextForAngular>
        );
    }

    function $onInit() {
        ctrl._ = _;
        ctrl.addAdditional = false;
        ctrl.today = new Date();
        ctrl.today.setHours(0, 0, 0, 0);
    }

    function addRxNumber() {
        const starterRx = {
            _internalUiId: uuid(),
            rxNumber: '',
            strength: '',
            refillsAuthorized: 0,
            prescriberId: '',
            start: null,
            fillExpiration: null,
            rxFill: [],
        };

        if (!ctrl.patientPrescriptions[ctrl.medication.id]) {
            ctrl.patientPrescriptions[ctrl.medication.id] = {
                rxNumbers: [starterRx],
            };
        } else {
            ctrl.patientPrescriptions[ctrl.medication.id].rxNumbers.push(starterRx);
        }

        mountUpdatePatientRx();
    }

    function doChangeMedication() {
        ctrl.addTherapyForm.$setPristine();
        ctrl.addTherapyForm.$setUntouched();
        const originalMedicationId = ctrl.medication.original_medication_id;
        if (originalMedicationId !== ctrl.medication.id) {
            if (ctrl.patient.medications[ctrl.medication.id]) {
                ctrl.addTherapyForm.medication.$setValidity('duplicate', false);
            } else {
                ctrl.addTherapyForm.medication.$setValidity('duplicate', true);
            }
        }

        if (ctrl.medication.id) {
            ctrl.medication = angular.copy(ctrl.therapyMedications[ctrl.medication.id]);
            ctrl.medication.newMedication = true;
        } else {
            ctrl.medication = {
                original_medication_id: originalMedicationId,
                newMedication: true,
            };
        }

        if (originalMedicationId) {
            ctrl.medication.original_medication_id = originalMedicationId;
        }
        mountUpdatePatientRx();
    }

    function doChangeStatusStart() {
        ctrl.showResetChanges = !_.eq(ctrl.therapy.status, ctrl.originalTherapy.status);
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function doChangeTherapy() {
        if (ctrl.therapy.id) {
            ctrl.therapy = angular.copy(ctrl.availableTherapies[ctrl.therapy.id]);
            ctrl.therapy.newTherapy = true;

            if (ctrl.therapy.status) {
                ctrl.therapy.status.opt_in = true;
            } else {
                ctrl.therapy.status = {
                    opt_in: true,
                };
            }
            mountPatientICD10s();

            return medicationsService.get(ctrl.therapy.id).then((res) => {
                ctrl.therapyMedications = res;
                ctrl.medication = {
                    newMedication: true,
                };
                mountUpdatePatientRx();
            });
        }
        ctrl.therapy = {
            newTherapy: true,
        };
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function isLocked(field) {
        if (ctrl.disabledFields && Array.isArray(ctrl.disabledFields)) {
            return ctrl.disabledFields.indexOf(field) > -1;
        }
    }

    function isRequired(field) {
        if (ctrl.requiredFields && Array.isArray(ctrl.requiredFields)) {
            return ctrl.requiredFields.indexOf(field) > -1;
        }
    }

    function openEndMedicationModal() {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'endMedication',
            keyboard: false,
            resolve: {
                medication() {
                    return ctrl.medication;
                },
            },
        });

        modalInstance.result
            .then((value) => {
                ctrl.medication = value;
                return value;
            })
            .catch((err) => {
                return err.data;
            });
    }

    function openEndPrescriptionModal(prescription, index, medication) {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'prescriptionEndModal',
            keyboard: false,
            resolve: {
                prescription() {
                    return prescription;
                },
                index() {
                    return index;
                },
                medication() {
                    return medication;
                },
            },
        });

        modalInstance.result
            .then((value) => {
                ctrl.patientPrescriptions[value.medication.id].rxNumbers[value.index] = convertKeysToCamelCase(
                    value.prescription
                );
                mountUpdatePatientRx();
                ctrl.prescriptions[value.medication.id][value.index] = value.prescription;
            })
            .catch((err) => {
                return err.data;
            });
    }

    function optInTherapy() {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'optInTherapy',
            keyboard: false,
            resolve: {
                therapy() {
                    return ctrl.therapy;
                },
            },
        });

        modalInstance.result
            .then((value) => {
                ctrl.therapy = value;
                ctrl.showResetChanges = !_.eq(ctrl.therapy.status, ctrl.originalTherapy.status);
                return value;
            })
            .catch((err) => {
                return err.data;
            });
    }

    function optOutTherapy() {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'optOutTherapy',
            windowClass: 'opt-out-therapy-modal',
            keyboard: false,
            resolve: {
                therapy() {
                    return ctrl.therapy;
                },
            },
        });

        modalInstance.result
            .then((therapy) => {
                ctrl.therapy = therapy;
                ctrl.showResetChanges = !_.eq(ctrl.therapy.status, ctrl.originalTherapy.status);
                return therapy;
            })
            .catch((err) => {
                return err.data;
            });
    }

    function onChangeICD9(icd9) {
        const newTherapy = Object.assign(ctrl.therapy, { icd_9: icd9 });
        ctrl.therapy = newTherapy;
    }

    function onChangeICD10(icd10) {
        const newTherapy = Object.assign(ctrl.therapy, { icd_10: icd10 });
        ctrl.therapy = newTherapy;
    }

    function removeRxNumber(index) {
        ctrl.prescriptions[ctrl.medication.id].splice(index, 1);
    }

    function resetEndMedication() {
        ctrl.medication.end_notes = undefined;
        ctrl.medication.end_reason = undefined;
        ctrl.medication.end = undefined;
        ctrl.medication.summary = undefined;
    }

    function resetEndPrescription(index, medication) {
        ctrl.patientPrescriptions[medication.id].rxNumbers[index].end_notes = undefined;
        ctrl.patientPrescriptions[medication.id].rxNumbers[index].end_reason = undefined;
        ctrl.patientPrescriptions[medication.id].rxNumbers[index].end = undefined;
        ctrl.patientPrescriptions[medication.id].rxNumbers[index].summary = undefined;
    }

    function resetOptOut() {
        ctrl.therapy.status = angular.copy(ctrl.originalTherapy.status);
        ctrl.showResetChanges = false;
    }

    function submitForm() {
        const cleanPrescriptions = [];
        ctrl.validateRxFillNumberErrors = {};
        ctrl.validatePatientICD10sErrors = {};
        ctrl.validationOfRxFillNumber = [];
        ctrl.validationOfICD10 = [];
        validateRxFillNumberOnSubmit();
        validatePatientICD10sOnSubmit();
        if (ctrl.validationOfRxFillNumber.length) {
            mountUpdatePatientRx();
            return false;
        }
        if (ctrl.validationOfICD10.length) {
            mountPatientICD10s();
            return false;
        }
        if (ctrl.addTherapyForm.$invalid) {
            return false;
        }
        ctrl.saving = true;

        /**
         * Do we still need "prescriptions", if all the new RX stuff is "patientPrescriptions"? 🤷:
         */
        if (ctrl.prescriptions[ctrl.medication.id]) {
            for (let i = 0; i < ctrl.prescriptions[ctrl.medication.id].length; i += 1) {
                if (
                    ctrl.prescriptions[ctrl.medication.id][i].rx_number ||
                    ctrl.prescriptions[ctrl.medication.id][i].start ||
                    ctrl.prescriptions[ctrl.medication.id][i].strength ||
                    ctrl.prescriptions[ctrl.medication.id][i].physician
                ) {
                    cleanPrescriptions.push(_.cloneDeep(ctrl.prescriptions[ctrl.medication.id][i]));
                }
            }
            ctrl.prescriptions[ctrl.medication.id] = cleanPrescriptions;
        }
        ctrl.close({
            $value: {
                therapy: ctrl.therapy,
                medication: ctrl.medication || undefined,
                prescriptions: ctrl.prescriptions,
                patientPrescriptions: ctrl.patientPrescriptions,
                addAdditional: ctrl.addAdditional,
                icd10selected: ctrl.icd10selected,
            },
        });
    }
}

export default AddTherapyModalController;
