import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap/lib/InputGroup';
import { errorHandler } from 'App/utils';
import toast from 'Lib/toast';
import { PERMISSION_TYPES } from 'App/utils/isPermitted/isPermitted';
import useUpdateReferralMedication from 'App/hooks/useUpdateReferralMedication';
import useGetReferralMedication from 'App/hooks/useGetReferralMedication';
import { referralModalPropType } from '../AddReferralActivity/types';
import FormButtonGroup from '../styles/FormButtonGroup';
import MedicationTable from '../ReferralMedicationTable/MedicationTable';
import * as R from 'ramda';
import dayjs from 'Lib/dayjs';
import getFeatureFlagStatus from 'App/utils/getFeatureFlagStatus';

const defaultProps = {};

const EditMedicationModal = ({ show, onHide, onUpdate, patientId, referralId }) => {
    const isFeatureReferralPMEnabled = getFeatureFlagStatus(PERMISSION_TYPES.FEATURE_REFERRAL_PROTOCOL_MANAGER);
    const updateMedicationMutation = useUpdateReferralMedication();
    const { data: medications, isLoading } = useGetReferralMedication(patientId, referralId);
    const initialValues = useMemo(() => {
        const state = R.compose(
            R.indexBy(R.prop('medicationId')),
            R.map((it) => {
                return {
                    id: it.id,
                    status: {
                        valueName: it.status,
                    },
                    statusDate: new Date(it.statusDate),
                    medicationId: it.medicationId,
                };
            })
        )(medications);
        return {
            medications: state,
        };
    }, [medications]);

    return (
        <Modal bsSize="lg" show={show} onHide={() => onHide()} backdrop="static" className="referral-add-communication">
            <Modal.Header>
                <Modal.Title>{isFeatureReferralPMEnabled ? 'Edit Medication' : 'Edit Medications'}</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    const { medications: formMedicationValue } = values;
                    const promiseArray = Object.values(formMedicationValue || {}).map((med) => {
                        const statusDate = dayjs(med?.statusDate)
                            .utc()
                            .set('hour', 12)
                            .set('minute', 1)
                            .set('second', 0)
                            .set('millisecond', 0)
                            .toISOString();

                        return updateMedicationMutation.mutateAsync({
                            patientId,
                            referralId,
                            medicationId: med.id,
                            body: {
                                medication_id: med.medicationId,
                                status: med?.status?.valueName,
                                status_date: statusDate,
                            },
                        });
                    });
                    Promise.all(promiseArray)
                        .then((data) => {
                            onUpdate(data);
                            toast.success('Referral medication successfully updated');
                        })
                        .catch(errorHandler)
                        .finally(() => {
                            setSubmitting(false);
                            onHide();
                        });
                }}
            >
                {({ isSubmitting, handleSubmit, submitForm, isValid }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <Modal.Body>
                                <MedicationTable data={medications} isLoading={isLoading} insideModal />
                            </Modal.Body>
                            <Modal.Footer>
                                <FormButtonGroup>
                                    <Button
                                        disabled={isSubmitting}
                                        bsClass="btn btn-default"
                                        onClick={() => onHide(false)}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        bsClass="btn btn-primary"
                                        className="save-due-date"
                                        onClick={submitForm}
                                        id="add-referral-communication-modal-submit"
                                        disabled={!isValid || isSubmitting}
                                    >
                                        Save
                                    </Button>
                                </FormButtonGroup>
                            </Modal.Footer>
                        </form>
                    );
                }}
            </Formik>
        </Modal>
    );
};

EditMedicationModal.propTypes = { ...referralModalPropType, onUpdate: PropTypes.func.isRequired };
EditMedicationModal.defaultProps = defaultProps;

export default EditMedicationModal;
