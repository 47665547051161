import useFetchReferralActivitiesQuery from 'App/hooks/useFetchReferralActivitiesQuery';
import { useMemo } from 'react';
import { mapPriorAuthsForAppeal } from '../referral.utils';

function useFetchPriorAuthsAndRenewals(patientId, referralId) {
    const priorAuths = useFetchReferralActivitiesQuery(patientId, referralId, 1);
    const renewals = useFetchReferralActivitiesQuery(patientId, referralId, 4);

    const data = useMemo(() => {
        if (priorAuths.isLoading || renewals.isLoading) {
            return [];
        }
        return mapPriorAuthsForAppeal(priorAuths.data || [], renewals.data || []);
    }, [priorAuths, renewals]);

    return {
        isLoading: priorAuths.isLoading || renewals.isLoading,
        isError: priorAuths.isError || renewals.isError,
        data,
    };
}

export default useFetchPriorAuthsAndRenewals;
