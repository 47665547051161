import CompanyService from './CompanyService';
import UserService from './UserService';
import AuthService2 from './AuthService';
import { queryClient } from 'Lib/queryClient';

/** @ngInject */
function AuthService(
    $cookies,
    $http,
    $httpParamSerializerJQLike,
    $rootScope,
    $window,
    jwtHelper,
    sessionStorageService,
    userService,
    __env
) {
    const endpoint = `${__env.tasEndpoint}:${__env.tasPort}/tas/${__env.tasVersion}`;

    const service = {
        changeBranch,
        changePassword,
        clearTargetPage,
        fetchUserBranches,
        get,
        getTargetPage: AuthService2.getTargetPage,
        getUser,
        getUserBranches: AuthService2.getUserBranches,
        login,
        logout,
        set,
        setTargetPage: AuthService2.setTargetPage,
        updateJwt,
    };

    return service;

    /**
     * @ngdoc method
     * @name authService#changeBranch
     *
     * @description
     * Changes the branch a users session is attached to.
     *
     * @param {string} divisionId - The id of the division the user will be switched to.
     */
    async function changeBranch(divisionId) {
        await userService.setBranch(divisionId);
        await service.updateJwt();
        const user = await service.getUser();
        const branches = await service.getUserBranches(true);
        if (branches) {
            user.branches = branches;
        }
        // this clear the Cached Therapy Categories call
        await CompanyService.getTherapyCategories(true);
        $rootScope.$emit('updateUser', user);
        return user;
    }

    function changePassword(currentPassword, newPassword) {
        const session = sessionStorageService.getJwt();
        return $http({
            method: 'PATCH',
            url: `${endpoint}/user/changePassword`,
            data: $httpParamSerializerJQLike({
                currentPassword,
                newPassword,
            }),
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${session}`,
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
        }).then((res) => {
            return res.data;
        });
    }

    /**
     * @ngdoc method
     * @name authService#get
     *
     * @description
     * Gets the current session token from one of two places:
     * 1. The currentToken object in local storage
     * 2. The Cookie for SSO users
     *
     * If no key is found, it will redirect to the login page.
     *
     * @returns {string|null} The session token if it exists, or null
     */
    function get() {
        const token = $window.localStorage.getItem('currentToken');
        if ($cookies.get('sso_jwt') && !token) {
            // If the user is arriving from single sign-on we need to set these values.
            sessionStorageService.getJwt();

            fetchUserBranches(true);
            CompanyService.getCustomMenus();
            UserService.getMessageCount();

            return this.get();
        }
        if (typeof token === 'string') {
            return token;
        }
        return null;
    }

    /**
     * Clears anything stored for target page.
     * @returns {boolean}
     */
    function clearTargetPage() {
        $window.localStorage.removeItem('targetPage');
        return true;
    }

    /**
     * @ngdoc method
     * @name authService#getUser
     *
     * @description
     * Gets User's Object
     */
    function getUser() {
        return userService.get();
    }

    /**
     * Fetches branches the user has access to.
     * @param {Boolean} setInLocalStorage - If true, will set the userBranches in localStorage
     * @returns {<Promise>.UserBranches} - Resolves into the User Branches object keyed by Id
     */
    function fetchUserBranches(setInLocalStorage = false) {
        const session = sessionStorageService.getJwt();
        if (typeof session !== 'string') {
            return false;
        }

        return AuthService2.fetchUserBranches(setInLocalStorage);
    }

    /**
     * @ngdoc method
     * @name authService#remove
     *
     * @description
     * Removes the current token
     */
    function clearSession() {
        $window.localStorage.removeItem('branchId');
        $window.localStorage.removeItem('companyId');
        $window.localStorage.removeItem('currentToken');
        $window.localStorage.removeItem('JWT-chunks');
        $window.localStorage.removeItem('messageCount');
        $window.localStorage.removeItem('sessionExpirationShown');
        $window.localStorage.removeItem('sessionId');
        $window.localStorage.removeItem('targetPage');
        $window.localStorage.removeItem('userAge');
        $window.localStorage.removeItem('userBranches');
        $window.localStorage.removeItem('userId');
        sessionStorageService.remove('timeout');
        let jwtCount = 0;
        while (jwtCount >= 0) {
            if (typeof $window.localStorage.getItem(`JWT-${jwtCount.toString()}`) === 'string') {
                $window.localStorage.removeItem(`JWT-${jwtCount.toString()}`);
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-plusplus
                ++jwtCount;
            } else {
                jwtCount = -1;
            }
        }
    }

    /**
     * @summary Fetches an updated JWT from Therigy Authentication Service
     *
     * @param {string} username - The username.
     * @param {string} password - The password.
     * @returns {Promise} The JWT
     */
    function login(username, password) {
        return $http({
            method: 'POST',
            url: `${endpoint}/jwt`,
            data: $httpParamSerializerJQLike({
                username,
                password,
            }),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
        }).then(async (res) => {
            sessionStorageService.setJwt(res.data);
            await CompanyService.getDevFeatureFlags().then((flags) => {
                queryClient.setQueryData(['featureFlags'], flags);
            });
            await fetchUserBranches(true);
            await CompanyService.getCustomMenus();
            await UserService.getMessageCount();

            return jwtHelper.decodeToken(res.data);
        });
    }

    /**
     * @ngdoc method
     * @name authService#logout
     *
     * @description
     * Logs out the current user and removes their token.
     *
     */
    function logout() {
        return $http({
            method: 'POST',
            url: '/stm/logout.php?angular=true',
            data: $httpParamSerializerJQLike({}),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
        })
            .then((res) => {
                clearSession();
                return res.data;
            })
            .catch((err) => {
                clearSession();
                return err.data;
            });
    }

    /**
     * @ngdoc method
     * @name authService#set
     *
     * @description
     * Sets a user token into local storage. This method is used to set the 'currentToken' value in local storage, this
     * action needs to occur here as requring SessionStorage will create a circular dependency.
     *
     * @param {string} token - The token value
     */
    function set(token) {
        $window.localStorage.setItem('currentToken', token);
    }

    /**
     * @ngdoc method
     * @name authService#updateJwt
     *
     * @description
     * Fetches an update JWT from Therigy Authentication Service
     *
     * @returns {Promise}
     */
    function updateJwt() {
        // In order to ensure that tokens remain fresh, we request a new one every time there is UI activity.
        const session = sessionStorageService.getJwt();
        if (typeof session !== 'string') {
            return false;
        }

        return $http({
            method: 'POST',
            url: `${endpoint}/jwt`,
            headers: {
                Authorization: `Bearer ${session}`,
            },
        }).then((res) => {
            sessionStorageService.setJwt(res.data);
            return res.data;
        });
    }
}

export default AuthService;
