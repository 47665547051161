import { useMutation, useQueryClient } from 'react-query';
import { createActivity } from 'App/services/RMSService';

const useCreateReferralActivityMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ patientId, referralId, body }) => {
            return createActivity(patientId, referralId, body);
        },
        {
            onSettled: (activity) => {
                return queryClient.refetchQueries([
                    'patient',
                    activity.patient_id,
                    'referrals',
                    activity.referral_id,
                    'activities',
                ]);
            },
        }
    );
};

export default useCreateReferralActivityMutation;
