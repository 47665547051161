const scrollContainerId = 'assessment-scroll-container';

const rapid3TotalScoreQuestionIds = ['5a0b0d3ea54c8e0ca1', '5a0b0de63b732e5e46'];

const rapid3TotalScoringIds = ['15555', '15559'];

const rapid3FunctionalScoreQuestionIds = ['5a0b0a8717f1c6ad04', '5a0b0de6e33b117541'];

const rapid3FunctionalScoringIds = ['15554', '15558'];

const viewModes = Object.freeze({
    VIEW_FULLY_SCRIPTED: 'View Fully Scripted',
    VIEW_COMPACT: 'View Compact',
    VIEW_MOBILE: 'View Mobile',
});

const hideScoringForGroupIds = [
    32, // QOL Assessment
    48, // QOL Assessment
    52, // QOL Assessment
    56, // QOL Assessment
    97, // RAPID3 Questionnaire
];

export {
    hideScoringForGroupIds,
    rapid3FunctionalScoreQuestionIds,
    rapid3FunctionalScoringIds,
    rapid3TotalScoreQuestionIds,
    rapid3TotalScoringIds,
    scrollContainerId,
    viewModes,
};

