import styled from '@emotion/styled';
import { catchPhrase } from 'App/components/config/config';
import {
    addMedication,
    disableMedication,
    getMedications,
    getTherapy,
    updateMedication,
    updateTherapy,
} from 'App/services/AdminService';
import { errorHandler } from 'App/utils';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Row from 'react-bootstrap/lib/Row';
import { Helmet } from 'react-helmet';
import { useHistory, useRouteMatch } from 'react-router';
import * as Yup from 'yup';
import DeleteModal from '../DeleteModal';
import { Text } from '../StandaloneFormField';
import PageTitle from '../styles/PageTitle/PageTitle';
import PageWrapper from '../styles/PageWrapper/PageWrapper';
import TherigyLoadingSpinner from '../TherigyLoadingSpinner/TherigyLoadingSpinner';
import DiagnosisList from './DiagnosisList';
import EditCategoryConfirmationModal from './EditCategoryConfirmationModal';
import SaveModal from './SaveModal';
import MedicationsTable from './MedicationsTable';
import toast from 'Lib/toast';

const propTypes = {};

const defaultProps = {};

const CenterWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
`;

function EditCategoryPage() {
    //#region State
    const { params } = useRouteMatch();
    const { therapyId } = params;
    const history = useHistory();

    const [therapy, setTherapy] = useState({});
    const [medications, setMedications] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [deleteMedicationModalData, setDeleteMedicationModalData] = useState(null);
    const [editModalData, setEditModalData] = useState(null);
    //#endregion

    //#region Click/Action/Event Handlers

    /**
     * Validates the name value
     * @param {array} meds - The array of meds to use in checking for duplicates
     * @param {string} name - The name value to be validated.
     * @returns {Promise}
     */
    const medicationValidator = (meds, name) =>
        Yup.string('Value must be a string.')
            .required('Please enter a medication.')
            .test(
                'duplicate',
                'This medication already exists in this therapeutic category.',
                (v) => !R.find(R.propEq(v, 'name'), meds)
            )
            .validate(name);

    const categoryNameValidator = (name) =>
        Yup.string('Value must be a string.').required('Please enter a therapeutic category name.').validate(name);

    /**
     * Saves a new medication.
     * @param {object} med - The medication object.
     * @param {string} name - The new name.
     * @returns {Promise<Medication>}
     */
    const saveMedication = (med, name) => {
        setIsSaving(true);
        return addMedication(therapyId, name)
            .then((m) => {
                setMedications((meds) => {
                    const idx = R.findIndex(R.propEq(med.id, 'id'), meds);
                    return R.compose(R.insert(idx, m), R.remove(idx, 1))(meds);
                });
                toast.success('Medication added successfully');
            })
            .finally(() => setIsSaving(false));
    };

    /**
     * Wrapper for abstracting both the saving and updating of a medication.
     * @param {object} med - The medication object in the list
     * @param {string} name - The name of the mediation to be saved.
     * @returns Promise
     */
    const handleSaveMedication = R.curry((med, name) => {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-use-before-define
        return med.isNew ? saveMedication(med, name) : editMedication(med, name);
    });

    /**
     * Sets the edit modal notification and callback.
     * @param {object} med - The medication object.
     * @param {string} name - The new name.
     */
    const editMedication = (med, name) => {
        return new Promise((resolve, reject) => {
            setEditModalData({
                cancel: () => {
                    setEditModalData(null);
                    reject(Error('The save operation has been canceled.'));
                },
                confirm: () => {
                    setIsSaving(true);
                    setEditModalData(null);
                    return resolve(
                        updateMedication(med.id, name)
                            .then((m) => {
                                setMedications((meds) => {
                                    const idx = R.findIndex(R.propEq(med.id, 'id'), meds);
                                    return R.compose(R.insert(idx, m), R.remove(idx, 1))(meds);
                                });
                                toast.success('Medication updated successfully');
                            })
                            .finally(() => setIsSaving(false))
                    );
                },
                // eslint-disable-next-line max-len
                message: `WARNING: All patients on "${med.name}" will now be on "${name}". Additionally, all content that references "${med.name}" will now reference "${name}".`,
            });
        });
    };

    /**
     * Remove a medication
     * @param {object} med - The medication object.
     * @param {number} idx - The index of the object.
     * @returns {Promise<Medication>}
     */
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    const removeMedication = (med, idx) => {
        if (med.isNew) {
            setMedications(R.remove(idx, 1));
            return Promise.resolve(med);
        }
        setDeleteMedicationModalData({
            message: `Are you sure you want to delete ${R.propOr('medication', 'name', med)}?`,
            fn: () => {
                setDeleteMedicationModalData(null);
                setIsSaving(true);
                return disableMedication(med.id)
                    .then(() => {
                        setMedications(R.remove(idx, 1));
                        toast.success('Medication removed successfully');
                    })
                    .finally(() => setIsSaving(false));
            },
        });
    };

    const gotToLandingPage = () => history.push('/therapeutic-categories');
    //#endregion

    //#region Side Effects
    useEffect(() => {
        if (therapyId) {
            Promise.all([getTherapy(therapyId), getMedications(therapyId)])
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-shadow
                .then(([therapy, medications]) => {
                    setTherapy(therapy);
                    setMedications(R.values(medications));
                })
                .catch(errorHandler)
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [therapyId]);

    //#endregion

    return (
        <>
            <Helmet>
                <title>Edit Therapeutic Category - {catchPhrase}</title>
            </Helmet>
            <PageTitle>Edit Therapeutic Category</PageTitle>
            <PageWrapper>
                {isLoading ? (
                    <CenterWrapper>
                        <TherigyLoadingSpinner />
                    </CenterWrapper>
                ) : (
                    <>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <ControlLabel htmlFor="name" className="field-required">
                                        Name
                                    </ControlLabel>
                                    <Text
                                        key={therapy.name}
                                        id="name"
                                        onSave={(v) => updateTherapy(therapyId, v)}
                                        name="Name"
                                        placeholder="Name"
                                        initialValue={therapy.name}
                                        validator={categoryNameValidator}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <MedicationsTable
                                    data={medications}
                                    isLoading={isLoading}
                                    onDelete={removeMedication}
                                    onSave={handleSaveMedication}
                                    validator={medicationValidator}
                                    updateMedicationsState={setMedications}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <DiagnosisList therapyId={therapyId} setIsSaving={setIsSaving} />
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <div className="form-button-group">
                                    <Button type="button" onClick={gotToLandingPage}>
                                        Go Back
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </>
                )}
            </PageWrapper>
            {isSaving && <SaveModal />}
            {deleteMedicationModalData && (
                <DeleteModal
                    onHide={() => setDeleteMedicationModalData(null)}
                    show
                    title="Delete Medication"
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line react/jsx-handler-names
                    onDelete={deleteMedicationModalData.fn}
                    message={deleteMedicationModalData.message}
                />
            )}
            {editModalData && (
                <EditCategoryConfirmationModal
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line react/jsx-handler-names
                    onHide={editModalData.cancel}
                    show
                    title="Edit Medication"
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line react/jsx-handler-names
                    onConfirm={editModalData.confirm}
                    message={editModalData.message}
                />
            )}
        </>
    );
}

EditCategoryPage.propTypes = propTypes;
EditCategoryPage.defaultProps = defaultProps;

export default EditCategoryPage;
