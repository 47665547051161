import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { Col, Modal, Row } from 'react-bootstrap';
import { Button } from 'react-bootstrap/lib/InputGroup';
import { errorHandler } from 'App/utils';
import * as yup from 'yup';
import * as R from 'ramda';
import SelectField from 'Lib/form/SelectField';
import ReferralMedicationSelect from './ReferralMedicationSelect';
import useCreateReferralActivityMutation from 'App/hooks/useCreateReferralActivityMutation';
import toast from 'Lib/toast';
import { referralActivityPropType, referralModalPropType } from './types';
import FormButtonGroup from '../styles/FormButtonGroup';

const validationSchema = yup.object().shape({
    isReferralPMEnabled: yup.boolean(),
    type: yup.object().required('Please select an activity.').nullable(),
    medication: yup.array().when('isReferralPMEnabled', {
        is: false,
        then: yup.array().of(yup.string()).required('Please select a medication(s).'),
        otherwise: yup.array(),
    }),
    paActivityId: yup
        .object()
        .when('type', {
            is: (type, isReferralPMEnabled) => isReferralPMEnabled && type?.id === 3,
            then: yup.object().required('Please select an Upheld Prior Authorization to Appeal.'),
        })
        .nullable(),
});

const activityTypes = [
    // Index matches activity type
    { name: 'Benefits Investigation', id: 0 },
    { name: 'Prior Authorization', id: 1 },
    { name: 'Financial Assistance', id: 2 },
];

const defaultProps = {};
const propTypes = {
    ...referralModalPropType,
    ...referralActivityPropType,
};

const AddReferralActivity = ({ show, onHide, patientId, referralId, activities }) => {
    const initialValues = {
        medication: [],
        paActivityId: null,
        type: null,
        isReferralPMEnabled: false,
    };
    const createReferralActivityMutation = useCreateReferralActivityMutation();
    const activitiesMap = useMemo(() => {
        return (activities || []).reduce(
            (result, value) => {
                const next = result;
                if (['Pending', 'New'].includes(value.status)) {
                    next[value.type] = next[value.type].concat((value.medications || []).map((it) => it.medicationId));
                    // For appeals, get the Prior Auth activity ids
                    if (value.type === 3) {
                        next[value.type].push(value.details.paActivityId);
                    }
                    if (value.type === 5) {
                        next[value.type].push(value.details.faActivityId);
                    }
                }
                return next;
            },
            { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [] }
        );
    }, [activities]);
    const deniedPAActivities = useMemo(() => {
        if (!activities) {
            return [];
        }
        return activities.filter(
            (it) => it.type === 1 && (it.status === 'Denied' || it.status === 'Appealed - Upheld')
        );
    }, [activities]);
    const validPriorAuthorizations = useMemo(
        () =>
            deniedPAActivities.filter((a) => {
                return activitiesMap[3].indexOf(a.id) === -1;
            }),
        [activitiesMap, deniedPAActivities]
    );
    const activitiesList = useMemo(
        () => [...activityTypes, ...(validPriorAuthorizations.length > 0 ? [{ name: 'Appeal', id: 3 }] : [])],
        [validPriorAuthorizations]
    );

    return (
        <Modal show={show} onHide={() => onHide(false)} backdrop="static" className="referral-add-activity">
            <Modal.Header>
                <Modal.Title>Add Activity</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnBlur={false}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    createReferralActivityMutation
                        .mutateAsync({
                            patientId,
                            referralId,
                            body: {
                                details: values.type.id === 3 ? { pa_activity_id: values.paActivityId.id } : {},
                                medication: values.medication.map((it) => ({ medication_id: it.id })),
                                type: values.type.id,
                            },
                        })
                        .then(() => {
                            toast.success('The referral activity was created successfully.');
                        })
                        .catch(errorHandler)
                        .finally(() => {
                            setSubmitting(false);
                            onHide(true);
                        });
                }}
            >
                {({ isSubmitting, handleSubmit, submitForm, isValid }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <Modal.Body>
                                <Row>
                                    <Col md={12}>
                                        <SelectField
                                            options={activitiesList}
                                            isClearable
                                            required
                                            label="Select an Activity"
                                            fieldName="type"
                                            getOptionValue={R.prop('id')}
                                            getOptionLabel={R.prop('name')}
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <ReferralMedicationSelect
                                            patientId={patientId}
                                            referralId={referralId}
                                            activities={activities}
                                            activitiesMap={activitiesMap}
                                            validPriorAuthorizations={validPriorAuthorizations}
                                        />
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <FormButtonGroup>
                                    <Button
                                        disabled={isSubmitting}
                                        bsClass="btn btn-default"
                                        className="pull-right"
                                        onClick={() => onHide(false)}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        bsClass="btn btn-primary"
                                        className="pull-right save-due-date"
                                        onClick={submitForm}
                                        id="add-referral-activity-modal-submit"
                                        disabled={!isValid || isSubmitting}
                                    >
                                        Save
                                    </Button>
                                </FormButtonGroup>
                            </Modal.Footer>
                        </form>
                    );
                }}
            </Formik>
        </Modal>
    );
};

AddReferralActivity.propTypes = propTypes;
AddReferralActivity.defaultProps = defaultProps;

export default AddReferralActivity;
