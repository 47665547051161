import React from 'react';
import PatientProfile from './PatientProfile';
import PatientProfileLoadingWrapper from './PatientProfileLoadingWrapper';
import PatientPdxSync from './PatientPdxSync';

const PatientProfileWrapper = () => (
    <PatientProfileLoadingWrapper>
        <PatientPdxSync>
            <PatientProfile />
        </PatientPdxSync>
    </PatientProfileLoadingWrapper>
);

export default PatientProfileWrapper;
