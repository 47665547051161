import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import R from 'ramda';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import useFetchPatientStatusOptions from 'App/hooks/useFetchPatientStatusOptions';
import useGetCustomMenuQuery from 'App/hooks/useGetCustomMenuQuery';
import { CheckboxField } from 'Lib/form/CheckboxField';
import { DatePickerField } from 'Lib/form/DatePickerField';
import { NumberField } from 'Lib/form/NumberField';
import SelectAllField from 'Lib/form/SelectAllField';
import SelectField from 'Lib/form/SelectField';
import { TextField } from 'Lib/form/TextField';

import { highRiskOptions, states } from '../constants';
import CommonPatientFieldWrapper from './CommonPatientFieldWrapper';
import { PhoneField } from './PhoneField';
import SectionTitleStyle from './SectionTitleStyle';

const PatientDemographicsFormStyle = styled.div`
    & input.form-control {
        min-height: 38px;
    }
`;

const RequiredOnlyCheckboxStyle = styled(CheckboxField)`
    label {
        font-weight: bold;

        &::after {
            content: '*';
            color: var(--brandDanger);
            margin-left: 5px;
        }
    }
`;

const PatientDemographicsForm = () => {
    const { values } = useFormikContext();
    const { data: genders, isLoading: isGendersLoading } = useGetCustomMenuQuery('patient_gender');
    const { data: pronouns, isLoading: isPronounsLoading } = useGetCustomMenuQuery('pronouns');
    const { data: genderIdentities, isLoading: isGenderIdentitiesLoading } = useGetCustomMenuQuery('gender_identity');
    const { data: ethnicities, isLoading: isEthnicitiesLoading } = useGetCustomMenuQuery('ethnicity');
    const { data: pregnancyIndicators, isLoading: isPregnancyIndicatorsLoading } =
        useGetCustomMenuQuery('pregnancy_indicator');
    const { data: lactatingOptions, isLoading: isLactatingOptionsLoading } = useGetCustomMenuQuery('lactating');
    const { data: martialStatuses, isLoading: isMaritalStatusesLoading } = useGetCustomMenuQuery('marital_status');
    const { data: patientStatuses, isLoading: isPatientStatusesLoading } = useFetchPatientStatusOptions();
    const { data: contactMethods, isLoading: isContactMethodsLoading } =
        useGetCustomMenuQuery('preferred_contact_method');
    const { data: languagesSpoken, isLoading: isLanguagesSpokenLoading } = useGetCustomMenuQuery('language_spoken');
    const { data: terminallyIllOptions, isLoading: isTerminallyIllOptionsLoading } =
        useGetCustomMenuQuery('terminally_ill');
    const { data: smokerStatuses, isLoading: isSmokerStatusesLoading } = useGetCustomMenuQuery('smoker_status');
    const { data: highRiskReasons, isLoading: isHighRiskReasonsLoading } = useGetCustomMenuQuery('high_risk_reason');
    const { data: patientLabels, isLoading: isPatientLabelsLoading } = useGetCustomMenuQuery('patient_labels');

    const rightSideFields = [
        ...(values.gender?.value !== 'Male'
            ? [
                  <>
                      <CommonPatientFieldWrapper fieldName="pregnancyStatus">
                          {(commonProps) => (
                              <Col md={3}>
                                  <SelectField
                                      {...commonProps}
                                      label="Pregnancy Indicator"
                                      options={pregnancyIndicators || []}
                                      isLoading={isPregnancyIndicatorsLoading}
                                  />
                              </Col>
                          )}
                      </CommonPatientFieldWrapper>
                      <CommonPatientFieldWrapper fieldName="lactating">
                          {(commonProps) => (
                              <Col md={3}>
                                  <SelectField
                                      {...commonProps}
                                      label="Lactating"
                                      options={lactatingOptions || []}
                                      isLoading={isLactatingOptionsLoading}
                                  />
                              </Col>
                          )}
                      </CommonPatientFieldWrapper>
                  </>,
              ]
            : []),
        <>
            <CommonPatientFieldWrapper fieldName="maritalStatus">
                {(commonProps) => (
                    <Col md={3}>
                        <SelectField
                            {...commonProps}
                            label="Marital Status"
                            options={martialStatuses || []}
                            isLoading={isMaritalStatusesLoading}
                        />
                    </Col>
                )}
            </CommonPatientFieldWrapper>
            <CommonPatientFieldWrapper fieldName="patientStatus">
                {(commonProps) => (
                    <Col md={3}>
                        <SelectField
                            {...commonProps}
                            fieldName="status"
                            label="Patient Status"
                            options={patientStatuses || []}
                            isLoading={isPatientStatusesLoading}
                            getOptionValue={R.prop('ValueName')}
                            getOptionLabel={R.prop('ValueName')}
                        />
                    </Col>
                )}
            </CommonPatientFieldWrapper>
        </>,
        <>
            <CommonPatientFieldWrapper fieldName="preferredContactMethod">
                {(commonProps) => (
                    <Col md={3}>
                        <SelectField
                            {...commonProps}
                            label="Preferred Contact Method"
                            options={contactMethods || []}
                            isLoading={isContactMethodsLoading}
                        />
                    </Col>
                )}
            </CommonPatientFieldWrapper>
            <CommonPatientFieldWrapper fieldName="languageSpoken">
                {(commonProps) => (
                    <Col md={3}>
                        <SelectField
                            {...commonProps}
                            label="Preferred Language"
                            options={languagesSpoken || []}
                            isLoading={isLanguagesSpokenLoading}
                        />
                    </Col>
                )}
            </CommonPatientFieldWrapper>
        </>,
        <>
            <CommonPatientFieldWrapper fieldName="email">
                {(commonProps) => (
                    <Col md={3}>
                        <TextField {...commonProps} label="Email" placeholder="Email" maxLength={100} />
                    </Col>
                )}
            </CommonPatientFieldWrapper>
            <CommonPatientFieldWrapper fieldName="mobilePhone">
                {(commonProps) => (
                    <Col md={3}>
                        <PhoneField {...commonProps} label="Mobile Phone" />
                    </Col>
                )}
            </CommonPatientFieldWrapper>
        </>,
        <>
            <CommonPatientFieldWrapper fieldName="homePhone">
                {(commonProps) => (
                    <Col md={3}>
                        <PhoneField {...commonProps} label="Home Phone" />
                    </Col>
                )}
            </CommonPatientFieldWrapper>
            <CommonPatientFieldWrapper fieldName="workPhone">
                {(commonProps) => (
                    <Col md={3}>
                        <PhoneField {...commonProps} label="Work Phone" />
                    </Col>
                )}
            </CommonPatientFieldWrapper>
        </>,
        <>
            <CommonPatientFieldWrapper fieldName="pharmacy">
                {(commonProps) => (
                    <Col md={3}>
                        <TextField {...commonProps} label="Pharmacy" placeholder="Pharmacy" maxLength={100} />
                    </Col>
                )}
            </CommonPatientFieldWrapper>
            <CommonPatientFieldWrapper fieldName="ssnLastFour">
                {(commonProps) => (
                    <Col md={3}>
                        <TextField {...commonProps} label="SSN Last Four" placeholder="SSN Last Four" maxLength={4} />
                    </Col>
                )}
            </CommonPatientFieldWrapper>
        </>,
        <>
            <CommonPatientFieldWrapper fieldName="otherId">
                {(commonProps) => (
                    <Col md={3}>
                        <TextField {...commonProps} label="Other ID" placeholder="Other ID" maxLength={50} />
                    </Col>
                )}
            </CommonPatientFieldWrapper>
            <CommonPatientFieldWrapper fieldName="dispensingId">
                {(commonProps) => (
                    <Col md={3}>
                        <TextField
                            {...commonProps}
                            fieldName="externalId"
                            label="Dispensing ID"
                            placeholder="Dispensing ID"
                            maxLength={100}
                        />
                    </Col>
                )}
            </CommonPatientFieldWrapper>
        </>,
    ];

    return (
        <PatientDemographicsFormStyle>
            <SectionTitleStyle>
                <h2>Patient Demographics</h2>
                <RequiredOnlyCheckboxStyle fieldName="requiredOnly" label="Only show required fields" />
            </SectionTitleStyle>

            <Row>
                <CommonPatientFieldWrapper fieldName="firstName">
                    {(commonProps) => (
                        <Col md={3}>
                            <TextField {...commonProps} label="First Name" placeholder="First Name" maxLength={255} />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
                <CommonPatientFieldWrapper fieldName="lastName">
                    {(commonProps) => (
                        <Col md={3}>
                            <TextField {...commonProps} label="Last Name" placeholder="Last Name" maxLength={255} />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
                <CommonPatientFieldWrapper fieldName="birthDate">
                    {(commonProps) => (
                        <Col md={3}>
                            <DatePickerField
                                {...commonProps}
                                label="Birth Date"
                                placeholder="YYYY/MM/DD"
                                maxDate={dayjs().toDate()}
                            />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
                <CommonPatientFieldWrapper fieldName="gender">
                    {(commonProps) => (
                        <Col md={3}>
                            <SelectField
                                {...commonProps}
                                label="Sex"
                                options={genders || []}
                                isLoading={isGendersLoading}
                            />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
            </Row>

            <Row>
                <CommonPatientFieldWrapper fieldName="preferredName">
                    {(commonProps) => (
                        <Col md={3}>
                            <TextField
                                {...commonProps}
                                label="Preferred Name"
                                placeholder="Preferred Name"
                                maxLength={255}
                            />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
                <CommonPatientFieldWrapper fieldName="pronouns">
                    {(commonProps) => (
                        <Col md={3}>
                            <SelectField
                                {...commonProps}
                                label="Pronouns"
                                options={pronouns || []}
                                isLoading={isPronounsLoading}
                            />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
                <CommonPatientFieldWrapper fieldName="ethnicity">
                    {(commonProps) => (
                        <Col md={3}>
                            <SelectField
                                {...commonProps}
                                label="Ethnicity"
                                options={ethnicities || []}
                                isLoading={isEthnicitiesLoading}
                            />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
                <CommonPatientFieldWrapper fieldName="genderIdentity">
                    {(commonProps) => (
                        <Col md={3}>
                            <SelectField
                                {...commonProps}
                                label="Gender Identity"
                                options={genderIdentities || []}
                                isLoading={isGenderIdentitiesLoading}
                            />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
            </Row>

            <Row>
                <CommonPatientFieldWrapper fieldName="middleName">
                    {(commonProps) => (
                        <Col md={3}>
                            <TextField {...commonProps} label="Middle Name" placeholder="Middle Name" maxLength={255} />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
                <CommonPatientFieldWrapper fieldName="suffix">
                    {(commonProps) => (
                        <Col md={3}>
                            <TextField {...commonProps} label="Suffix" placeholder="Suffix" maxLength={20} />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
                {rightSideFields[0]}
            </Row>

            <Row>
                <CommonPatientFieldWrapper fieldName="addressLine1">
                    {(commonProps) => (
                        <Col md={3}>
                            <TextField
                                {...commonProps}
                                label="Address Line 1"
                                placeholder="Address Line 1"
                                maxLength={255}
                            />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
                <CommonPatientFieldWrapper fieldName="addressLine2">
                    {(commonProps) => (
                        <Col md={3}>
                            <TextField
                                {...commonProps}
                                label="Address Line 2"
                                placeholder="Address Line 2"
                                maxLength={255}
                            />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
                {rightSideFields[1]}
            </Row>

            <Row>
                <CommonPatientFieldWrapper fieldName="city">
                    {(commonProps) => (
                        <Col md={3}>
                            <TextField {...commonProps} label="City" placeholder="City" maxLength={100} />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
                <CommonPatientFieldWrapper fieldName="state">
                    {(commonProps) => (
                        <Col md={3}>
                            <SelectField {...commonProps} label="State" options={states} />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
                {rightSideFields[2]}
            </Row>

            <Row>
                <CommonPatientFieldWrapper fieldName="zip">
                    {(commonProps) => (
                        <Col md={3}>
                            <TextField {...commonProps} label="Zip" placeholder="Zip" maxLength={50} />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
                <CommonPatientFieldWrapper fieldName="country">
                    {(commonProps) => (
                        <Col md={3}>
                            <TextField {...commonProps} label="Country" placeholder="Country" maxLength={50} />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
                {rightSideFields[3]}
            </Row>

            <Row>
                <CommonPatientFieldWrapper fieldName="height">
                    {(commonProps) => (
                        <Col md={3}>
                            <NumberField {...commonProps} label="Height (Inches)" placeholder="Height in Inches" />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
                <CommonPatientFieldWrapper fieldName="weight">
                    {(commonProps) => (
                        <Col md={3}>
                            <NumberField {...commonProps} label="Weight (Pounds)" placeholder="Weight in Pounds" />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
                {rightSideFields[4]}
            </Row>

            <Row>
                <CommonPatientFieldWrapper fieldName="terminallyIll">
                    {(commonProps) => (
                        <Col md={3}>
                            <SelectField
                                {...commonProps}
                                label="Terminally Ill"
                                options={terminallyIllOptions || []}
                                isLoading={isTerminallyIllOptionsLoading}
                            />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
                <CommonPatientFieldWrapper fieldName="smokerStatus">
                    {(commonProps) => (
                        <Col md={3}>
                            <SelectField
                                {...commonProps}
                                label="Smoker?"
                                options={smokerStatuses || []}
                                isLoading={isSmokerStatusesLoading}
                            />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
                {rightSideFields[5]}
            </Row>

            <Row>
                <CommonPatientFieldWrapper fieldName="highRisk">
                    {(commonProps) => (
                        <Col md={3}>
                            <SelectField {...commonProps} label="High-Risk" options={highRiskOptions} />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
                {values.highRisk?.value && (
                    <CommonPatientFieldWrapper fieldName="highRiskReasons">
                        {(commonProps) => (
                            <Col md={3}>
                                <SelectAllField
                                    {...commonProps}
                                    label="High-Risk Reasons"
                                    options={highRiskReasons || []}
                                    isLoading={isHighRiskReasonsLoading}
                                />
                            </Col>
                        )}
                    </CommonPatientFieldWrapper>
                )}

                <CommonPatientFieldWrapper fieldName="labels">
                    {(commonProps) => (
                        <Col md={3}>
                            <SelectAllField
                                {...commonProps}
                                label="Labels"
                                options={patientLabels || []}
                                isLoading={isPatientLabelsLoading}
                                getOptionValue={R.prop('label')}
                            />
                        </Col>
                    )}
                </CommonPatientFieldWrapper>
                {rightSideFields[6]}
            </Row>
        </PatientDemographicsFormStyle>
    );
};

export default PatientDemographicsForm;
