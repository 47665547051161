import CompanyService from 'App/services/CompanyService';
import { errorHandler } from 'App/utils';
import { useQuery } from 'react-query';

function useFetchWorkQueueUnassignedMedication(reqBody) {
    return useQuery(
        ['workQueue', 'unassignedMedication', JSON.stringify(reqBody)],
        () => CompanyService.getUnassignedMedication(reqBody),
        {
            onError: errorHandler,
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useFetchWorkQueueUnassignedMedication;
