import { useQuery } from 'react-query';

import CompanyService from 'App/services/CompanyService';

function useFetchServerTime() {
    return useQuery(['company', 'resource', 'serverTime'], CompanyService.getServerTime, {
        retry: false,
        refetchInterval: false,
        refetchOnWindowFocus: false,
        // Disable cache for this query
        cacheTime: 0,
    });
}

export default useFetchServerTime;
