import { useQuery } from 'react-query';
import PESService from 'App/services/PESService';

function useFetchPatientUnassignedMedications(patientId) {
    return useQuery(
        ['patient', patientId, 'unassignedMedications'],
        () => PESService.getPatientUnassignedMedications(patientId),
        {
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useFetchPatientUnassignedMedications;
