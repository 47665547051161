import CMSService from 'App/services/CMSService';
import toast from 'Lib/toast';

/** @ngInject */
function AssociateContentGroupResourceModalController(_) {
    const ctrl = this;

    ctrl.$onChanges = $onChanges;
    ctrl.createChildContentGroup = createChildContentGroup;
    ctrl.getContentGroups = getContentGroups;

    function $onChanges(changes) {
        if (changes.resolve && ctrl.resolve) {
            if (ctrl.resolve.contentGroup) {
                ctrl.contentGroup = angular.copy(ctrl.resolve.contentGroup);
            }
        }
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function createChildContentGroup() {
        if (ctrl.contentGroup.type === 'teaching') {
            ctrl.saving = true;
            return CMSService.createChildContentGroup(ctrl.contentGroup.uuid, ctrl.resourceUuid)
                .then((res) => {
                    toast.success('The resource has been associated successfully.');
                    ctrl.close({ $value: res });
                })
                .catch((err) => {
                    if (err.response && err.response.status === 409) {
                        let { resourceType } = ctrl;
                        if (resourceType === 'disease') {
                            resourceType = 'therapeutic category';
                        }
                        toast.error(`The ${resourceType} is already associated to this teaching point monograph.`);
                    } else {
                        toast.error('We were unable to associate this resource at this time. Please try again later.');
                    }
                    ctrl.dismiss({ $value: err });
                })
                .finally(() => {
                    ctrl.saving = false;
                });
        }
    }

    function getContentGroups(resourceType) {
        ctrl.loading = true;

        return CMSService.getContentGroups(resourceType)
            .then((res) => {
                ctrl.contentResources = _.values(res);
                return res;
            })
            .catch((err) => {
                ctrl.dismiss({ $value: err });
                return false;
            })
            .finally(() => {
                ctrl.loading = false;
            });
    }
}

export default AssociateContentGroupResourceModalController;
