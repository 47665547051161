import React from 'react';
import PropTypes from 'prop-types';
import ClickLink from 'App/components/styles/ClickLink';

const propTypes = {
    showMessagingModal: PropTypes.func.isRequired,
    showHistoryModal: PropTypes.func.isRequired,
};

const defaultProps = {};

function Unconfimed({ showHistoryModal, showMessagingModal }) {
    return (
        <>
            <div style={{ flexGrow: 1, flexBasis: '50%' }}>
                <i className="fas fa-fw" />
                <span>
                    Patient Messaging: <span id="engage-patient-messaging-enrollment-status">Not Yet Enrolled</span>
                    {' | '}
                </span>
                <ClickLink onClick={showMessagingModal}>Edit</ClickLink>
            </div>
            <div>
                <ClickLink onClick={showHistoryModal}>
                    <i className="fa fa-fw fa-history" id="view-engage-patient-messaging-history-link" /> View Patient
                    Messaging History
                </ClickLink>
            </div>

            <div style={{ flexGrow: 1, flexBasis: '50%' }} />
        </>
    );
}

Unconfimed.propTypes = propTypes;
Unconfimed.defaultProps = defaultProps;

export default Unconfimed;
