import CompanyService from 'App/services/CompanyService';
import { useQuery } from 'react-query';

function useFetchWorkQueueUnassignedMedicationCount(reqBody) {
    return useQuery(
        ['workQueue', 'unassignedMedicationCount', JSON.stringify(reqBody?.search)],
        () => CompanyService.getUnassignedMedicationCount(reqBody),
        {
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useFetchWorkQueueUnassignedMedicationCount;
