import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';

import { patientPropType } from 'Lib/types';
import { activityPropType, questionPropType } from '../types';
import AssessmentQuestion from './AssessmentQuestion';
import MedicationAssociation from './MedicationAssociation';

const SpecialQuestionsStyle = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const SpecialQuestionStyle = styled.div`
    min-width: 250px;
    margin-right: 15px;
`;

const AssessmentSpecialQuestions = ({ specialQuestions, activity, patient }) => {
    return (
        <SpecialQuestionsStyle>
            {specialQuestions.map((question) => (
                <SpecialQuestionStyle key={question.questionId}>
                    <AssessmentQuestion question={question} activity={activity} patient={patient} isSpecialQuestion />
                </SpecialQuestionStyle>
            ))}

            <MedicationAssociation
                activityId={activity.id}
                activityStatus={activity.status}
                required={activity.assessment.settings?.settings.requireMedicationAssociation}
                patientId={patient.id}
            />
        </SpecialQuestionsStyle>
    );
};

AssessmentSpecialQuestions.propTypes = {
    specialQuestions: PropTypes.arrayOf(questionPropType).isRequired,
    activity: activityPropType.isRequired,
    patient: patientPropType.isRequired,
};

export default AssessmentSpecialQuestions;
