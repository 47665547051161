import { isNil } from 'ramda';
import { hasLength } from '../utils';

function shouldExecutePatientSearch(identifiers, branchSettings) {
    if (branchSettings.ehrNoteRequiresVisit) {
        if (!hasLength(identifiers)) return true;

        const fhirIdentifier = identifiers.find((i) => i.identifierType === 'FHIR');
        return isNil(fhirIdentifier);
    }
    if (branchSettings.ehrNoteRequiresNoPatientIdentifier) return false;

    const patientEhrIdentifier = identifiers.find((i) => i.identifierType === branchSettings.ehrPrimaryIdType);
    return isNil(patientEhrIdentifier);
}

function shouldExecuteVisitSearch(visits, branchSettings) {
    return Object.values(visits).length === 0 && branchSettings.ehrNoteRequiresVisit;
}

function shouldSyncPatient(identifiers, visits, branchSettings) {
    const patientEhrIdentifier = identifiers.find((i) => i.identifierType === branchSettings.ehrPrimaryIdType);
    return (
        isNil(patientEhrIdentifier) ||
        shouldExecutePatientSearch(identifiers, branchSettings) ||
        shouldExecuteVisitSearch(visits, branchSettings)
    );
}

export { shouldExecutePatientSearch, shouldExecuteVisitSearch, shouldSyncPatient };
