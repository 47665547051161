import React, { useState } from 'react';
import useSaveWorkQueueFilterMutation from '../hooks/useSaveWorkQueueFilterMutation';
import { useFormikContext } from 'formik';
import { Button, FormControl, FormGroup, InputGroup } from 'react-bootstrap';
import { transformReactFilterToSave } from '../workQueue.utils';

const SaveFilterForm = () => {
    const saveFilterMutation = useSaveWorkQueueFilterMutation();
    const [filterName, setFilterName] = useState('');

    const {
        values: { filterParams },
    } = useFormikContext();

    const saveFilter = (e) => {
        e.stopPropagation();
        saveFilterMutation
            .mutateAsync({ filter: transformReactFilterToSave(filterParams), name: filterName })
            .then(() => {
                setFilterName('');
            });
    };

    const onSetFilterName = (e) => {
        if (e.target.value?.length <= 20) {
            setFilterName(e.target.value);
        }
    };

    return (
        <div className="filter form-group">
            <FormGroup controlId="save-filter" bsSize="small">
                <InputGroup>
                    <FormControl type="text" value={filterName} placeholder="Filter name" onChange={onSetFilterName} />
                    <InputGroup.Button>
                        <Button bsStyle="primary" bsSize="small" disabled={!filterName} onClick={saveFilter}>
                            Save Filter
                        </Button>
                    </InputGroup.Button>
                </InputGroup>
            </FormGroup>
        </div>
    );
};

export default SaveFilterForm;
