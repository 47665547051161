import * as R from 'ramda';

/**
 * Maps the current value (usually a string) to a value/label object.
 * Useful for converting an array of strings into an React Select option.
 * @param data
 * @returns {*|null}
 */
function mapIdentityToValueLabel(data) {
    if (data) {
        return R.applySpec({
            value: R.identity,
            label: R.identity,
        })(data);
    }
    return null;
}

export default mapIdentityToValueLabel;
