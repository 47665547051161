import React from 'react';
import { Button, Col, ModalBody, ModalFooter, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import SubmitButton from 'Lib/form/SubmitButton';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import SelectField from 'Lib/form/SelectField';
import * as Yup from 'yup';
import useCreateReferralMutation from 'App/hooks/useCreateReferralMutation';
import toast from 'Lib/toast';

const propTypes = {
    onHide: PropTypes.func.isRequired,
    patientId: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    medications: PropTypes.array,
};

const defaultProps = {
    isLoading: false,
    medications: [],
};

const validationSchema = Yup.object().shape({
    medications: Yup.array().min(1).required().nullable().label('Medication'),
});

function getOptionValue(option) {
    return `${option.patient_medication_id}-${option.rx_number}`;
}

function NoReferralProtocolForm({ onHide, patientId, isLoading, medications }) {
    const createReferralMutation = useCreateReferralMutation();

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={{
                medications: null,
            }}
            onSubmit={(values) => {
                return createReferralMutation
                    .mutateAsync({
                        patientId,
                        medication_creates_referral: false,
                        medications: R.map(
                            R.applySpec({
                                medication_id: R.prop('medication_id'),
                                rx_number_id: R.prop('id'),
                            })
                        )(values.medications),
                    })
                    .then(() => {
                        toast.success('The referral was created successfully.');
                    })
                    .then(onHide);
            }}
        >
            {({ handleSubmit, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <SelectField
                                    required
                                    isMulti
                                    getOptionValue={getOptionValue}
                                    isLoading={isLoading}
                                    options={medications}
                                    label="Medication"
                                    fieldName="medications"
                                    onChange={(option) => {
                                        setFieldValue('medications', option);
                                    }}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <FormButtonGroup>
                            <Button onClick={onHide}>Cancel</Button>
                            <SubmitButton>Add Referral</SubmitButton>
                        </FormButtonGroup>
                    </ModalFooter>
                </form>
            )}
        </Formik>
    );
}

NoReferralProtocolForm.propTypes = propTypes;
NoReferralProtocolForm.defaultProps = defaultProps;

export default NoReferralProtocolForm;
