import toast from 'Lib/toast';

/** @ngInject */
function ReferralActivitiesEditDueDateModalController(rmsService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.submit = submit;

    function $onChanges(changes) {
        if (changes.resolve && ctrl.resolve.activity) {
            ctrl.activity = angular.copy(ctrl.resolve.activity);
        }
    }

    function submit() {
        ctrl.saving = true;

        return rmsService
            .updateActivity(ctrl.activity.patient_id, ctrl.activity.referral_id, ctrl.activity.id, ctrl.activity)
            .then((res) => {
                toast.success('Activity due date successfully updated');
                ctrl.close({ $value: res });
                return res;
            })
            .catch((err) => {
                toast.error('Activity due date did not update successfully. Please try again.');
                ctrl.dismiss({ $value: null });
                return err;
            })
            .finally(() => {
                ctrl.saving = false;
            });
    }
}

export default ReferralActivitiesEditDueDateModalController;
