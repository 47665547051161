import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { PERMISSION_TYPES } from 'App/utils/isPermitted/isPermitted';
import TherigyInfoMessage from '../TherigyInfoMessage/TherigyInfoMessage';
import Table from '../Table/Table';
import propOrDash from 'App/utils/propOrDash';
import dateOrDashUtc from 'App/utils/dateOrDashUtc';
import pathOrDash from 'App/utils/pathOrDash';
import CustomMenuField from 'Lib/form/CustomMenu';
import { DatePickerField } from 'Lib/form/DatePickerField';
import getFeatureFlagStatus from 'App/utils/getFeatureFlagStatus';

const propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    isLoading: PropTypes.bool.isRequired,
    insideModal: PropTypes.bool.isRequired,
};

const defaultProps = {};

const rowPropTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            medicationId: PropTypes.string.isRequired,
        }),
    }).isRequired,
};

const RowDatePicker = ({ row }) => (
    <DatePickerField
        fieldName={`medications.${row.original.medicationId}.statusDate`}
        required
        placeholder="YYYY/MM/DD"
    />
);
const RowCustomMenu = ({ row }) => (
    <CustomMenuField
        menuName="referral_medication_status_reason"
        fieldName={`medications.${row.original.medicationId}.status`}
    />
);

RowDatePicker.propTypes = rowPropTypes;
RowDatePicker.defaultProps = {};

RowCustomMenu.propTypes = rowPropTypes;
RowCustomMenu.defaultProps = {};

function MedicationTable({ data, isLoading, insideModal }) {
    const isFeatureReferralPMEnabled = getFeatureFlagStatus(PERMISSION_TYPES.FEATURE_REFERRAL_PROTOCOL_MANAGER);
    function getIcd10(row) {
        const icd10Details = row.medication?.icd10?.icd10Details;
        if (icd10Details?.dxCode) {
            return `${icd10Details.dxCode} ${icd10Details.dxDescShort}`;
        }
        return row.icd_10 || '--';
    }
    const columns = useMemo(
        () => [
            {
                Header: isFeatureReferralPMEnabled ? 'Medication' : 'Medications',
                accessor: 'medication.name',
                Cell: ({ row }) => pathOrDash(['original', 'medication', 'name'], row),
                id: 'medication',
            },
            {
                Header: 'ICD-10',
                accessor: getIcd10,
                id: 'icd_10',
            },
            {
                Header: 'Rx Number',
                accessor: 'rx.rxNumber',
                Cell: ({ row }) => pathOrDash(['original', 'rx', 'rxNumber'], row),
                id: 'rxNumber',
            },
            {
                Header: 'Rx Status',
                accessor: propOrDash('status'),
                ...(insideModal ? { Cell: RowCustomMenu } : {}),
                id: 'status',
            },
            {
                Header: 'Rx Status Date',
                accessor: dateOrDashUtc(['statusDate']),
                ...(insideModal ? { Cell: RowDatePicker } : {}),
                id: 'statusDate',
            },
        ],
        [isFeatureReferralPMEnabled, insideModal]
    );

    return (
        <div>
            {data?.length > 0 && !isLoading ? (
                <Table
                    enablePagination={false}
                    columns={columns}
                    data={data}
                    isLoading={isLoading}
                    placeholderText="No Medication"
                />
            ) : (
                <TherigyInfoMessage message="There are currently no medications attached to this referral." />
            )}
        </div>
    );
}

MedicationTable.propTypes = propTypes;
MedicationTable.defaultProps = defaultProps;

export default MedicationTable;
