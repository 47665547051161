import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import PageTitle from 'App/components/styles/PageTitle/PageTitle';
import Select from 'react-select';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import { FaInfoCircle } from 'react-icons/fa';
import Button from 'react-bootstrap/lib/Button';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import * as R from 'ramda';

import CompletionOfClinicalActivityForm from '../CompletionOfClinicalActivityForm';
import { dateRelationOptions, REFERRAL_TYPES, RULE_TYPES } from '../constants';
import AdditionOfMedicationForm from '../AdditionOfMedicationForm';
import AddFilter from './AddFilter';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { hasLength } from 'App/utils';
import { isFieldValid, mapAddRuleValuesForLocalSave, mapAddRuleValuesForRemoteSave } from '../utils';
import AdditionOfInsuranceForm from '../AdditionOfInsuranceForm';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import ChangeOfReferralStatusForm from 'App/features/ProtocolManager/ChangeOfReferralStatusForm';
import ChangeOfReferralActivityStatusForm from 'App/features/ProtocolManager/ChangeOfReferralActivityStatusForm';
import useEditProtocolContext from 'App/features/ProtocolManager/EditProtocolPage/useEditProtocolContext';
import getFeatureFlagStatus from 'App/utils/getFeatureFlagStatus';

const validationSchema = Yup.object().shape({
    triggeredFrom: Yup.object().nullable().required('Trigger From is required'),
    activityToComplete: Yup.object()
        .when('triggeredFrom', {
            is: (triggeredFrom) => {
                return triggeredFrom?.value === RULE_TYPES.ACTIVITY;
            },
            then: Yup.object().required('Activity to Complete is required'),
        })
        .nullable(),
    activityToTrigger: Yup.object().required('Activity to Trigger is required'),
    days: Yup.number().min(0).required(),
    dateRelation: Yup.object().required(),
    dateType: Yup.object().required(),
});

const initialValues = {
    triggeredFrom: null,
    activityToComplete: null,
    activityToTrigger: null,
    days: '',
    dateRelation: R.head(dateRelationOptions),
    dateType: null,
    /**
     * Temp data used to store the filter dropdown value until the user adds the "condition"
     */
    filterField: null,
    /**
     * The group that will become the filters
     */
    conditions: {},
};

const propTypes = {
    onCancel: PropTypes.func.isRequired,
    onAddRule: PropTypes.func.isRequired,
};

const defaultProps = {};

function AddRule({ onCancel, onAddRule }) {
    const { state } = useEditProtocolContext();
    const availableRuleAssessments = R.propOr([], 'availableRuleAssessments', state);

    const isFeatureReferralPMEnabled = getFeatureFlagStatus(PERMISSION_TYPES.FEATURE_REFERRAL_PROTOCOL_MANAGER);

    const triggeredFromOptions = useMemo(() => {
        let options = [
            {
                value: RULE_TYPES.ACTIVITY,
                label: 'Completion of PCC/clinical activity',
            },
            {
                value: RULE_TYPES.PROFILE,
                label: 'Addition of medication',
            },
        ];

        if (isFeatureReferralPMEnabled) {
            const referralActivityOptions = R.compose(
                R.map((option) => ({ ...option, value: option.id, label: option.name })),
                R.propOr([], 'options'),
                R.find(R.propEq('Referral', 'label'))
            )(availableRuleAssessments);

            options = options.concat([
                {
                    value: RULE_TYPES.INSURANCE,
                    label: 'Addition of insurance plan',
                },
                {
                    value: RULE_TYPES.REFERRAL_STATUS,
                    label: 'Change of referral status',
                },
                {
                    value: RULE_TYPES.REFERRAL_ACTIVITY_STATUS,
                    label: 'Change of referral activity status',
                    options: referralActivityOptions,
                },
            ]);
        }

        return options;
    }, [isFeatureReferralPMEnabled, availableRuleAssessments]);

    return (
        <Row>
            <Col md={12}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        /**
                         * The trigger object itself is the shape of the object needed to parse and display the
                         * trigger and filters in the UI.
                         *
                         * `remoteTrigger` represents the shape of the trigger and filter necessary to save the
                         * value on the server.
                         */
                        const trigger = mapAddRuleValuesForLocalSave(values);
                        trigger.remoteTrigger = mapAddRuleValuesForRemoteSave(values);

                        setSubmitting(false);
                        onAddRule(trigger);
                    }}
                >
                    {(formik) => {
                        const handleTriggerFromChange = (option) => {
                            R.compose(
                                formik.setValues,
                                R.set(R.lensProp('filterField'), null),
                                R.set(R.lensProp('dateType'), null),
                                R.set(R.lensProp('activityToComplete'), null),
                                R.set(R.lensProp('conditions'), {}),
                                R.set(R.lensProp('triggeredFrom'), option)
                            )(formik.values);
                        };

                        return (
                            <Form className="add-rule-form" data-testid="add-rule-form">
                                <Row>
                                    <Col md={6}>
                                        <PageTitle style={{ padding: 0 }}>Add Rule</PageTitle>

                                        {/**#region Triggered From **/}
                                        <FormGroup validationState={isFieldValid('triggeredFrom', formik)}>
                                            <div className="label-tooltip-group">
                                                <ControlLabel htmlFor="triggered-from" className="field-required">
                                                    Triggered From
                                                </ControlLabel>

                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="triggered-from-tooltip">
                                                            The action that will initiate this rule
                                                        </Tooltip>
                                                    }
                                                >
                                                    <FaInfoCircle className="info-icon" />
                                                </OverlayTrigger>
                                            </div>
                                            <Select
                                                inputId="triggered-from"
                                                options={triggeredFromOptions}
                                                value={formik.values.triggeredFrom}
                                                onChange={handleTriggerFromChange}
                                                onBlur={() => formik.setFieldTouched('triggeredFrom')}
                                            />
                                        </FormGroup>
                                        {/**#endregion **/}

                                        {/*#region Activity/Profile */}
                                        {R.cond([
                                            [
                                                R.propEq(RULE_TYPES.ACTIVITY, 'value'),
                                                () => <CompletionOfClinicalActivityForm />,
                                            ],
                                            [R.propEq(RULE_TYPES.PROFILE, 'value'), () => <AdditionOfMedicationForm />],
                                            [
                                                R.propEq(RULE_TYPES.INSURANCE, 'value'),
                                                () => <AdditionOfInsuranceForm />,
                                            ],
                                            [
                                                R.propEq(RULE_TYPES.REFERRAL_STATUS, 'value'),
                                                () => <ChangeOfReferralStatusForm />,
                                            ],
                                            [
                                                R.propSatisfies(
                                                    R.flip(R.includes)(R.keys(REFERRAL_TYPES).map(Number)),
                                                    'value'
                                                ),
                                                () => <ChangeOfReferralActivityStatusForm />,
                                            ],
                                        ])(formik.values.triggeredFrom || {})}

                                        {/*#endregion */}
                                    </Col>
                                </Row>

                                {formik.dirty && R.compose(hasLength, R.values)(formik.values.conditions) && (
                                    <Row>
                                        <Col md={12}>
                                            <AddFilter />
                                        </Col>
                                    </Row>
                                )}

                                {/*#region Add Rule CTA */}
                                <FormButtonGroup>
                                    <Button type="button" onClick={onCancel}>
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        bsStyle="primary"
                                        disabled={!formik.dirty ? true : !formik.isValid || formik.isSubmitting}
                                    >
                                        Add Rule
                                    </Button>
                                </FormButtonGroup>
                                {/*#endregion*/}
                            </Form>
                        );
                    }}
                </Formik>
            </Col>
        </Row>
    );
}

AddRule.propTypes = propTypes;
AddRule.defaultProps = defaultProps;

export default AddRule;
