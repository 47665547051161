import React from 'react';
import { useFormikContext } from 'formik';
import { Col, Row } from 'react-bootstrap';

const FAMedicationTable = () => {
    const { values } = useFormikContext();

    return (
        <Row>
            <Col md={6}>
                <h3>Medication</h3>
                <table className="therigy-table table-condensed">
                    <colgroup>
                        <col width="20%" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th id="medication-column">Medication</th>
                        </tr>
                    </thead>
                    <tbody>
                        {values?.medications?.map((medication) => {
                            return (
                                <tr key={`${medication.id}-${medication.medication.id}`}>
                                    <td style={{ verticalAlign: 'middle' }}>{medication.medication.name}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Col>
        </Row>
    );
};

export default FAMedicationTable;
