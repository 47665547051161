import angular from 'angular';

import AssessmentManagerController from './assessment-manager.controller';
import AssessmentManagerConfig from './assessment-manager.config';
import AssessmentCreateController from './create/create.controller';
import AssessmentCreateConfig from './create/create.config';
import AssessmentDeleteModalController from './delete-modal/delete-modal.controller';
import AssessmentEditController from './edit/edit.controller';
import AssessmentEditConfig from './edit/edit.config';
import QuestionManagerController from './question-manager/question-manager.controller';
import QuestionManagerConfig from './question-manager/question-manager.config';
import QuestionDeleteWarningModalController from './question-manager/question-delete-warning-modal/question-delete-warning-modal.controller';

export default angular
    .module('components.assessmentManager', ['ngAnimate', 'ngTable', 'ui.router'])
    .component('assessmentManager', {
        bindings: {
            assessments: '<',
            categories: '<',
            user: '<',
        },
        controller: AssessmentManagerController,
        template: require('./assessment-manager.html'),
    })
    .config(AssessmentManagerConfig)
    .component('assessmentCreate', {
        bindings: {
            busy: '<',
            assessment: '<',
            user: '<',
            ehrProvider: '<',
        },
        controller: AssessmentCreateController,
        template: require('./create/create.html'),
    })
    .config(AssessmentCreateConfig)
    .component('deleteAssessmentModal', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: AssessmentDeleteModalController,
        template: require('./delete-modal/delete-modal.html'),
    })
    .component('assessmentEdit', {
        bindings: {
            user: '<',
            categories: '<',
            ehrProvider: '<',
        },
        controller: AssessmentEditController,
        template: require('./edit/edit.html'),
    })
    .config(AssessmentEditConfig)
    .component('assessmentQuestions', {
        bindings: {
            user: '<',
        },
        controller: QuestionManagerController,
        template: require('./question-manager/question-manager.html'),
    })
    .config(QuestionManagerConfig)
    .component('questionDeleteWarningModal', {
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<',
        },
        controller: QuestionDeleteWarningModalController,
        template: require('./question-manager/question-delete-warning-modal/question-delete-warning-modal.html'),
    }).name;
