import { getAllPrograms, getProgramHistory } from 'App/services/FinancialAssistanceService';
import { trackFaProgramSearch } from 'App/services/TrackingService';
import { setPatientIdentifiers } from 'App/utils/setPatientIdentifiers';
import * as R from 'ramda';
import * as uuid from 'uuid';
import { errorHandler } from 'App/utils';
import { formatUtcDate, isoDateOnlyFormat } from 'App/services/DateService';
import { queryClient } from 'Lib/queryClient';
import toast from 'Lib/toast';

/** @ngInject */
function ReferralActivityFinancialAssistanceController(_, $state, $uibModal, moment, rmsService, NgTableParams, __env) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.doChangeActivityStatus = doChangeActivityStatus;
    ctrl.deleteActivity = deleteActivity;
    ctrl.isEffectiveEndDateValid = isEffectiveEndDateValid;
    ctrl.updateActivity = updateActivity;
    ctrl.selectProgram = selectProgram;
    ctrl.removeProgram = removeProgram;
    ctrl.showProgramDetail = showProgramDetail;
    ctrl.isPending = isPending;
    ctrl.isCompleted = isCompleted;
    ctrl.isLoadingAvailablePrograms = true;
    ctrl.isLoadingSelectedPrograms = true;
    ctrl.availablePrograms = [];
    ctrl.selectedPrograms = [];

    ctrl.isReferralPMEnabled = false;

    function stringToBool(value) {
        return value === 'Yes';
    }

    function boolToString(value) {
        return value ? 'Yes' : 'No';
    }

    // This is to keep the program history from being pulled every time a change happens.
    let hasFetched = false;

    function isPending(status) {
        return R.includes(status, ['New', 'Pending', 'Pending Submission', 'Submitted']);
    }

    function isCompleted(status) {
        return R.includes(status, ['Partially Approved', 'Approved', 'Denied', 'Deleted']);
    }

    async function $onChanges(changes) {
        if (ctrl.user && changes.user) {
            ctrl.user = angular.copy(ctrl.user);
        }

        if (ctrl.activity && changes.activity) {
            ctrl.activity = angular.copy(ctrl.activity);
            ctrl.original = angular.copy(ctrl.activity);

            ctrl.disableInputs = ctrl.activity.status === 'Deleted';

            ctrl.disableActivity = isCompleted(ctrl.original.status);

            if (ctrl.original.status === 'Pending') {
                ctrl.original.status = 'Pending Submission';
                ctrl.activity.status = 'Pending Submission';
            }

            ctrl.activity.status = ctrl.activity.status ? ctrl.activity.status : 'Pending Submission';
            ctrl.activity.details.file_application = R.pathOr('No', ['activity', 'details', 'file_application'], ctrl);
            ctrl.activity.details.schedule_follow_up_activity = stringToBool(
                ctrl.activity.details.schedule_follow_up_activity
            );

            if (!hasFetched) {
                hasFetched = true;
                if (ctrl.activity.details.program_id) {
                    const params = getFaParams();

                    return getProgramHistory(ctrl.activity.details.program_id, params)
                        .then(setSelectedProgram)
                        .then(() => {
                            if (isPending(ctrl.activity.status)) {
                                return getAndMapAllPrograms(true);
                            }
                        })
                        .finally(() => {
                            ctrl.isLoadingSelectedPrograms = false;
                        })
                        .catch(errorHandler);
                }
                ctrl.isLoadingSelectedPrograms = false;
                if (isPending(ctrl.activity.status)) {
                    return getAndMapAllPrograms();
                }
            }
        }
        return Promise.resolve();
    }

    function $onInit() {
        ctrl.activityId = $state.params.activityId;
        ctrl.patientId = $state.params.patientId;
        ctrl.referralId = $state.params.referralId;
        ctrl.supportEmail = __env.supportEmail;

        const flags = queryClient.getQueryData(['featureFlags']);
        ctrl.isReferralPMEnabled = R.propOr(false, 'FEATURE_REFERRAL_PROTOCOL_MANAGER', flags);
        return setPatientIdentifiers(ctrl).catch((err) => {
            ctrl.displayedId = '';
            return err;
        });
    }

    const getFundLevel = R.compose(R.ifElse(R.isNil, R.always('--'), R.identity), R.head);

    const getSortedListFromArray = R.compose(R.join(', '), R.sort(R.ascend(R.identity)));

    function mapSelectedFinancialProgram(program) {
        if (R.anyPass([R.isNil, R.isEmpty])(program)) {
            return program;
        }
        return {
            uuid: program.uuid,
            programId: program.programId,
            programName: program.programName,
            assistanceType: program.assistanceType,
            drugs: getSortedListFromArray(program.drugs),
            therapeuticAreas: getSortedListFromArray(program.therapeuticAreas),
            manufacture: getSortedListFromArray(program.manufacture),
            eligibility: getSortedListFromArray(program.eligibility),
            foundationFundLevels: getFundLevel(program.foundationFundLevels),
            lastUpdated: program.lastUpdated,
            selected: true,
            original: program,
        };
    }

    function mapFinancialProgram(program) {
        return {
            uuid: uuid.v4(),
            programId: program.ProgramID,
            programName: program.ProgramName,
            assistanceType: program.AssistanceType,
            drugs: getSortedListFromArray(program.Drugs),
            therapeuticAreas: getSortedListFromArray(program.TherapAreas),
            eligibility: getSortedListFromArray(program.Eligibilities),
            manufacture: getSortedListFromArray(program.Manufacturers),
            foundationFundLevels: getFundLevel(program.FoundationFundLevels),
            original: program,
        };
    }

    /**
     * Fetches all the available programs based on the medication names for each item
     * @param {boolean} markSelected - Appends a prop called `selected` to indicate that the Select button should be
     * enabled (false) or disabled (true).
     * @returns {Promise<void>}
     */
    function getAndMapAllPrograms(markSelected = false) {
        const params = R.compose(R.pick(['drugs']), getFaParams)();

        return getAllPrograms(params)
            .then(R.map(R.compose(R.assoc('selected', markSelected), mapFinancialProgram)))
            .then((available) => {
                ctrl.availablePrograms = available;
            })
            .catch(errorHandler)
            .finally(() => {
                ctrl.isLoadingAvailablePrograms = false;
            });
    }

    function doChangeActivityStatus() {
        if (ctrl.activity.status === 'Denied') {
            ctrl.activity.denial_reason = 'Insufficient information submitted';
        } else {
            ctrl.activity.denial_reason = undefined;
        }
        if (ctrl.activity.status === 'Pending Submission') {
            ctrl.activity.details.application_submission = null;
        }
    }

    function deleteActivity() {
        ctrl.disableInputs = true;

        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'confirmationModal',
            size: 'md',
            windowClass: 'delete-confirmation-modal',
            resolve: {
                message() {
                    return 'Are you sure you want to delete this activity?';
                },
                title() {
                    return 'Delete Activity';
                },
            },
        });

        return modalInstance.result
            .then(() => {
                return rmsService
                    .deleteActivity(ctrl.patientId, ctrl.referralId, ctrl.activityId, ctrl.activity)
                    .then(() => {
                        return $state.go('app.referral', {
                            patientId: ctrl.patientId,
                            referralId: ctrl.referralId,
                        });
                    });
            })
            .catch((err) => {
                ctrl.disableInputs = false;
                toast.error(err.data.message ? err.data.message : 'An error occurred while deleting this activity.');
                return err;
            });
    }

    function isEffectiveEndDateValid() {
        if (!ctrl.isReferralPMEnabled) {
            ctrl.saveActivityForm.effective_end_date.$setValidity('invalid', true);
            if (ctrl.activity.details.schedule_follow_up_activity === true) {
                if (!ctrl.activity.details.effective_end_date) {
                    ctrl.saveActivityForm.effective_end_date.$setValidity('invalid', false);
                    return;
                }

                if (ctrl.activity.details.follow_up_schedule_option === 'days_prior') {
                    // Check that the effective end date is two weeks out and set the due_on date to two weeks prior
                    // to the effective end date
                    ctrl.fortnight = moment().startOf('day').add(14, 'day').toDate();
                    ctrl.due_on = moment(ctrl.activity.details.effective_end_date).subtract(14, 'day').toDate();
                    ctrl.saveActivityForm.effective_end_date.$setValidity(
                        'invalid',
                        ctrl.fortnight <= ctrl.activity.details.effective_end_date
                    );
                }

                if (ctrl.activity.details.follow_up_schedule_option === 'days_after') {
                    ctrl.due_on = moment().add(30, 'day').toDate();
                    ctrl.saveActivityForm.effective_end_date.$setValidity('invalid', true);
                }

                if (ctrl.activity.details.follow_up_schedule_option === 'specific_date') {
                    ctrl.due_on = ctrl.activity.details.follow_up_due_date;
                    ctrl.saveActivityForm.effective_end_date.$setValidity('invalid', true);
                }
            }
        }
    }

    function updateActivity() {
        ctrl.disableInputs = true;
        ctrl.saving = true;

        const promises = [];

        if (!ctrl.isReferralPMEnabled && ctrl.activity.details.schedule_follow_up_activity === true) {
            promises.push(
                rmsService.createActivity(ctrl.activity.patient_id, ctrl.activity.referral_id, {
                    type: '2',
                    due_on: ctrl.due_on,
                    medication: _.map(ctrl.activity.medications, (m) => {
                        return {
                            medication_id: m.medication_id,
                        };
                    }),
                })
            );
        }

        promises.push(
            rmsService.updateActivity(
                ctrl.activity.patient_id,
                ctrl.activity.referral_id,
                ctrl.activity.id,
                R.over(R.lensPath(['details', 'schedule_follow_up_activity']), boolToString)(ctrl.activity)
            )
        );

        promises.push(
            rmsService.createReferralActivityNotes(ctrl.activityId, {
                note: null,
                status: ctrl.activity.status,
            })
        );

        return Promise.all(promises)
            .then((res) => {
                toast.success('Activity information has been updated successfully.');
                $state.go('app.referral', {
                    patientId: ctrl.patientId,
                    referralId: ctrl.referralId,
                });
                return res;
            })
            .catch((err) => {
                // eslint-disable-next-line no-multi-assign
                ctrl.disableInputs = ctrl.saving = true;

                toast.error('This activity did not update successfully. Please try again.', {
                    closeButton: true,
                    closeHtml: '<button><i class="fa fa-fw fa-times"></i></button>',
                    tapToDismiss: false,
                    timeOut: 25000,
                });

                return err;
            });
    }

    async function selectProgram(program) {
        const found = R.find(R.propEq(program.uuid, 'uuid'), ctrl.selectedPrograms);
        if (!found) {
            ctrl.isLoadingSelectedPrograms = true;
            ctrl.availablePrograms = R.map(R.assoc('selected', true), ctrl.availablePrograms);
            ctrl.activity.details.program_id = program.programId;

            const params = getFaParams();

            return getProgramHistory(program.programId, params)
                .then(setSelectedProgram)
                .catch(errorHandler)
                .finally(() => {
                    ctrl.isLoadingSelectedPrograms = false;
                });
        }
    }

    function setSelectedProgram(program) {
        /**
         * Mark all as selected so the buttons will be disabled.
         * In the future, if we decide to allow selecting multiple, this
         * can be removed here, and be a bit more targeted to the individual
         * programs.
         *
         * The mapper below will add the `selected` prop
         */
        ctrl.selectedPrograms = [mapSelectedFinancialProgram(program)];
    }

    function removeProgram(program) {
        ctrl.selectedPrograms = R.reject(R.propEq(program.uuid, 'uuid'), ctrl.selectedPrograms);

        ctrl.availablePrograms = R.map(R.assoc('selected', false), ctrl.availablePrograms);
        ctrl.activity.details.program_id = null;
    }

    function getMedicationParam(medications) {
        return R.compose(
            encodeURI,
            R.join('|'),
            R.sortBy(R.prop(0)),
            R.reduce((result, medication) => {
                /**
                 * ZHI, when unable to match the query param, will fall back to just returning everything. Since we
                 * have some medications with spaces, forward-slashes, and dashes, we want to send both the base name
                 * and the complete name to ZHI when we encounter one of these meds.
                 */
                const originalName = R.pathOr('', ['medication', 'name'], medication);
                const baseName = R.compose(R.trim, R.head, R.split(/(\s|\/|-)/))(originalName);

                return R.compose(
                    R.ifElse(R.always(originalName !== baseName), R.append(baseName), R.identity),
                    R.append(originalName)
                )(result);
            }, [])
        )(medications);
    }

    function getVersionDateParam() {
        if (ctrl.activity.completed_on) {
            return formatUtcDate({ date: ctrl.activity.completed_on, format: isoDateOnlyFormat });
        }
    }

    function getFaParams() {
        return {
            drugs: getMedicationParam(R.pathOr([], ['activity', 'medications'], ctrl)),
            versionDate: getVersionDateParam(),
        };
    }

    function showProgramDetail(program) {
        trackFaProgramSearch(ctrl.user.active_company.Name, program.programName);
        $uibModal.open({
            backdrop: 'static',
            component: 'programDetailModal',
            size: 'lg',
            windowClass: 'program-detail-modal',
            resolve: {
                program,
                params: R.thunkify(getFaParams)(),
                getSetter: () => {
                    return R.thunkify(selectProgram)(program);
                },
            },
        });
    }
}

export default ReferralActivityFinancialAssistanceController;
