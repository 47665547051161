import { errorHandler } from '../../../../utils';
import toast from 'Lib/toast';

/** @ngInject */
function EngageMessagingOptOutModalController(
    _paq,
    $http,
    $q,
    $scope,
    $window,
    dateService,
    engageService,
    patientService
) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.doCloseModal = doCloseModal;
    ctrl.submit = submit;

    function $onChanges() {
        ctrl.patientMessagingSubscription = angular.copy(ctrl.subscription);
        ctrl.patient = angular.copy(ctrl.patient);
        ctrl.user = angular.copy(ctrl.user);

        ctrl.infoMessage =
            `Submitting this form will unsubscribe the patient from patient messaging. ` +
            `The patient will no longer receive text messages.` +
            `<br><br>The patient can resubscribe by texting "SUBSCRIBE" to 833-354-1731.`;

        ctrl.successMessage = 'Patient unenrolled successfully';
    }

    function $onInit() {
        ctrl.notification = false;
        ctrl.loading = false;
    }

    function doCloseModal() {
        _paq.push(['trackEvent', 'TherigyEngage', 'Modal from Profile - Cancel button']);
        ctrl.close();
    }

    function submit() {
        ctrl.loading = true;
        const promise = $q.when();

        // Subscribe if not subscription exists or update subscription if phone number has changed.
        return promise
            .then(() => {
                const reasonMsg = `Patient unsubscribed by  ${ctrl.user.first_name} ${ctrl.user.last_name}`;
                return patientService.unsubscribePatientMessaging(
                    ctrl.patient.id,
                    ctrl.patientMessagingSubscription.id,
                    { reason: reasonMsg }
                );
            })
            .then(() => {
                ctrl.notification = false;
                ctrl.close({ $value: true });
                toast.success(ctrl.successMessage);
                return true;
            })
            .catch((err) => {
                errorHandler(err);
            });
    }
}

export default EngageMessagingOptOutModalController;
