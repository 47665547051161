import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import { Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import toast from 'Lib/toast';
import './PatientMessagingEnrollmentModalStyle.scss';
import { errorHandler } from '../../../../utils';
import PESService from '../../../../services/PESService';
import { patientSubscriptionPropType } from 'Lib/types';
import { localize } from 'App/utils';
import SubmitButton from 'Lib/form/SubmitButton';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import { PhoneField } from 'App/components/PatientForm/components/PhoneField';
import * as Yup from 'yup';
import PreviousEnrollmentMessage from './PatientEducationMessages/PreviousEnrollmentMessage';
import NoPreviousEnrollmentMessage from './PatientEducationMessages/NoPreviousEnrollmentMessage';

const propTypes = {
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
    subscriptionValue: patientSubscriptionPropType,
    mobilePhone: PropTypes.string,
    patientId: PropTypes.string,
};

const defaultProps = {
    show: false,
    subscriptionValue: null,
    mobilePhone: '',
    patientId: '',
};

const validationSchema = Yup.object().shape({
    phone: Yup.string()
        .matches(/\d{10}/, 'Invalid phone number')
        .required()
        .label('Patient Mobile Phone'),
});

function PatientMessagingEnrollmentModal({ onHide, show, subscriptionValue, mobilePhone, patientId }) {
    const onSubmit = (values) => {
        if (subscriptionValue && mobilePhone === values.phone) {
            onHide(false);
        } else {
            PESService.updatePatient(patientId, { mobile_phone: values.phone })
                .then(() => {
                    return PESService.subscribeSMS(patientId).then(() => {
                        onHide(true);
                        toast.success('Patient enrolled successfully');
                    });
                })
                .catch((error) => {
                    errorHandler(error);
                    onHide(false);
                });
        }
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            backdrop="static"
            className="patientMessagingModal"
            data-testid="patient-messaging-enrollment-modal"
        >
            <Modal.Header closeButton={false}>
                <Modal.Title className="header">Patient Messaging</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={{
                    phone: mobilePhone || '',
                }}
                onSubmit={(values, actions) => {
                    actions.setSubmitting(false);
                    onSubmit(values);
                }}
                validationSchema={validationSchema}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Modal.Body>
                            <Row>
                                <Col sm={6}>
                                    <div className="enrollment-information">
                                        {`Enrolled: ${subscriptionValue?.status === true ? 'Yes' : 'No'}`}
                                    </div>
                                </Col>
                                {subscriptionValue?.status && (
                                    <Col sm={6}>
                                        <div className="enrollment-information">
                                            <div>
                                                Enrollment Date:&nbsp;
                                                <span>
                                                    {localize(subscriptionValue?.subscribedOn, 'YYYY-MM-DD hh:mm:ss')}
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                )}
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Row>
                                        <Col sm={12}>
                                            <PhoneField
                                                fieldName="phone"
                                                required
                                                placeholder="(###) ###-####"
                                                label="Patient Mobile Phone"
                                            />
                                        </Col>
                                    </Row>
                                    {subscriptionValue ? (
                                        <PreviousEnrollmentMessage />
                                    ) : (
                                        <NoPreviousEnrollmentMessage />
                                    )}
                                </Col>
                            </Row>
                        </Modal.Body>

                        <Modal.Footer>
                            <FormButtonGroup>
                                <Button
                                    bsStyle="default"
                                    type="button"
                                    onClick={() => {
                                        onHide(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <SubmitButton ignoreDirty={!subscriptionValue}>Submit</SubmitButton>
                            </FormButtonGroup>
                        </Modal.Footer>
                    </form>
                )}
            </Formik>
        </Modal>
    );
}

PatientMessagingEnrollmentModal.propTypes = propTypes;
PatientMessagingEnrollmentModal.defaultProps = defaultProps;

export default PatientMessagingEnrollmentModal;
