import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';

const StyledButton = styled(Button)`
    padding: 0;
    font-size: inherit;
    text-align: inherit;
    white-space: normal;
`;

const ClickLink = ({ children, onClick, ...rest }) => {
    return (
        <StyledButton {...rest} bsStyle="link" onClick={onClick}>
            {children}
        </StyledButton>
    );
};

ClickLink.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default ClickLink;
