import convertKeysToSnakeCase from 'App/utils/keyConversion/convertKeysToSnakeCase';
import dayjs from 'Lib/dayjs';
import { BOOLEAN_TYPE, STATUS_TYPE } from './PriorAuthorization/constant';
import * as R from 'ramda';
import { stripAllHtml } from 'App/utils';
import hasLength from 'App/utils/hasLength';
import mapIdentityToValueLabel from 'App/utils/mapIdentityToValueLabel';
import { statuses } from './AppealPage/constants';
import { formatPhone } from 'App/utils';
import { keyBy } from 'lodash';

function convertBooleanToYesNo(field) {
    if (typeof field === 'boolean') {
        return field ? 'Yes' : 'No';
    }
    return field;
}

function utcIsoStringOrNull(date) {
    return date ? dayjs.utc(date).toISOString() : null;
}

function dateOrNull(date) {
    return dayjs(date, 'YYYY-MM-DD hh:mm:ss').isValid() ? dayjs.utc(date).toDate() : null;
}

function convertPAtoView(activityDetails) {
    return {
        ...activityDetails,
        details: {
            ...activityDetails?.details,
            effectiveStartDate:
                activityDetails?.details?.effectiveStartDate &&
                dayjs(activityDetails?.details?.effectiveStartDate).toDate(),
            effectiveEndDate:
                activityDetails?.details?.effectiveEndDate &&
                dayjs(activityDetails?.details?.effectiveEndDate).toDate(),
            reVerificationDueDate:
                activityDetails?.details?.reVerificationDueDate &&
                dayjs(activityDetails?.details?.reVerificationDueDate).toDate(),
            methodApprovalReceived: activityDetails?.details?.methodApprovalReceived && {
                id: activityDetails?.details?.methodApprovalReceived,
                name: activityDetails?.details?.methodApprovalReceived,
            },
            benefitRequiresReVerification: activityDetails?.details?.benefitRequiresReVerification && {
                id: activityDetails?.details?.benefitRequiresReVerification,
                name: activityDetails?.details?.benefitRequiresReVerification,
            },
            denialReason: activityDetails?.details?.denialReason && {
                id: activityDetails?.details?.denialReason,
                name: activityDetails?.details?.denialReason,
            },
            isPrescriberSubmittingAppeal: activityDetails?.details?.isPrescriberSubmittingAppeal && {
                id: activityDetails?.details?.isPrescriberSubmittingAppeal,
                name: activityDetails?.details?.isPrescriberSubmittingAppeal,
            },
            isReAuthorizationRequired: activityDetails?.details?.isReAuthorizationRequired === BOOLEAN_TYPE.yes,
        },
        medications: activityDetails?.medications?.map((med) => ({
            ...med,
            data: {
                ...med.data,
                authorizedUom: med?.data?.authorizedUom && {
                    id: med?.data?.authorizedUom,
                    name: med?.data?.authorizedUom,
                },
                ndcSpecificApproval: med?.data?.ndcSpecificApproval && {
                    id: med?.data?.ndcSpecificApproval,
                    name: med?.data?.ndcSpecificApproval === '1' ? 'Yes' : 'No',
                },
            },
        })),
        status: activityDetails?.status && {
            id: activityDetails?.status,
            name: activityDetails?.status,
        },
    };
}

function convertPAtoSave(formValues) {
    const { completedOn, dueOn, type, status, statusReason, statusDate, details, medications } = formValues || {};
    const modifiedDetails = {
        ...details,
        methodApprovalReceived: details?.methodApprovalReceived?.id,
        benefitRequiresReVerification: details?.benefitRequiresReVerification?.id,
        denialReason: details?.denialReason?.id,
        isPrescriberSubmittingAppeal: details?.isPrescriberSubmittingAppeal?.id,
        isReAuthorizationRequired: details?.isReAuthorizationRequired ? BOOLEAN_TYPE.yes : BOOLEAN_TYPE.no,
        deniedOn: status?.id === STATUS_TYPE.denied ? new Date() : null,
    };
    const activityPayload = {
        completed_on: completedOn,
        due_on: dueOn,
        type,
        status: status?.id,
        status_reason: statusReason,
        status_date: statusDate,
        details: convertKeysToSnakeCase(modifiedDetails),
    };

    const medicationPayload = medications?.map((med) => {
        const data = {
            ...med.data,
            authorizedUom: med?.data?.authorizedUom?.id,
            ndcSpecificApproval: med?.data?.ndcSpecificApproval?.id,
        };
        return {
            id: med.id,
            medication_id: med.medicationId,
            data: convertKeysToSnakeCase(data),
        };
    });

    const notesPayload = {
        note: null,
        status: status?.id,
    };

    const appealActivityPayload =
        modifiedDetails.isPrescriberSubmittingAppeal === BOOLEAN_TYPE.yes
            ? {
                  type: '3',
                  details: {
                      pa_activity_id: formValues.id,
                  },
                  medication: medications.map((med) => {
                      return {
                          medication_id: med.medicationId,
                      };
                  }),
              }
            : null;

    return { medicationPayload, activityPayload, notesPayload, appealActivityPayload };
}

function convertAppealToSave(body) {
    return R.applySpec({
        completedOn: getCompletedOn,
        createdOn: R.compose(utcIsoStringOrNull, R.prop('createdOn')),
        currentEmployeeId: R.propOr(null, 'currentEmployeeId'),
        currentEmployeeTime: R.propOr(null, 'currentEmployeeTime'),
        dueOn: R.compose(utcIsoStringOrNull, R.prop('dueOn')),
        status: R.path(['status', 'value']),
        statusDate: R.compose(utcIsoStringOrNull, R.prop('statusDate')),
        statusReason: R.ifElse(R.prop('statusReason'), R.prop('statusReason'), R.always('N/A')),
        type: R.prop('type'),
        details: {
            appealDate: R.path(['details', 'appealDate']),
            appealDecisionDate: R.path(['details', 'appealDecisionDate']),
            benefitRequiresReVerification: R.compose(
                convertBooleanToYesNo,
                R.path(['details', 'benefitRequiresReVerification'])
            ),
            completeDate: R.compose(utcIsoStringOrNull, R.path(['details', 'completeDate'])),
            createdOn: R.compose(utcIsoStringOrNull, R.path(['details', 'createdOn'])),
            effectiveEndDate: R.compose(utcIsoStringOrNull, R.path(['details', 'effectiveEndDate'])),
            effectiveStartDate: R.compose(utcIsoStringOrNull, R.path(['details', 'effectiveStartDate'])),
            followUpPhone: R.path(['details', 'followUpPhone']),
            id: R.path(['details', 'id']),
            methodApprovalReceived: R.pathOr(null, ['details', 'methodApprovalReceived', 'value']),
            notes: R.compose(
                R.ifElse(R.identity, R.identity, R.always(null)),
                stripAllHtml,
                R.path(['details', 'notes'])
            ),
            paActivityId: R.path(['details', 'paActivityId', 'id']),
            pa_number: R.path(['details', 'paNumber']),
            reVerificationDueDate: R.compose(utcIsoStringOrNull, R.path(['details', 'reVerificationDueDate'])),
            reVerificationScheduleOption: R.propOr(null, 'reVerificationScheduleOption'),
            scheduleFollowupAppeal: R.pathOr(null, ['details', 'scheduleFollowupAppeal', 'value']),
            status: R.path(['status', 'value']),
        },
    })(body);
}

const COMPLETED_ACTIVITY_STATUS = Object.freeze({
    COMPLETE: 'Complete',
    COMPLETED: 'Completed',
    APPROVED: 'Approved',
    PARTIALLY_APPROVED: 'Partially Approved',
    NOT_REQUIRED: 'Not Required',
    DENIED: 'Denied',
    UPHELD: 'Upheld',
    OVERTURNED: 'Overturned',
    APPEALED: 'Appealed',
    APPEALED_UPHELD: 'Appealed - Upheld',
    APPEALED_OVERTURNED: 'Appealed - Overturned',
});

function getCompletedOn(data) {
    let completedOn = null;

    if (R.includes(data.status.value, R.values(COMPLETED_ACTIVITY_STATUS))) {
        completedOn = data.completedOn ? data.completedOn : new Date().toISOString();
    }

    return completedOn;
}

function mapPriorAuthsForAppeal(priorAuths = [], renewals = []) {
    return R.compose(
        R.map(
            R.chain(
                R.assoc('medicationList'),
                R.compose(R.join(', '), R.uniq, R.map(R.path(['medication', 'name'])), R.prop('medications'))
            )
        ),
        R.filter(
            //prettier-ignore
            R.anyPass([
                R.propSatisfies(R.includes('Appealed'), 'status'),
                R.propEq('Denied', 'status')
            ])
        ),
        R.concat(renewals || [])
    )(priorAuths || []);
}

function formatInoundAppealActivity(activityData, appealDocs, priorAuthsOptions) {
    /**
     * Find the selected appeal documents
     */
    //prettier-ignore
    const docs = R.ifElse(
        hasLength,
        R.map(R.prop('name')),
        R.always([])
    )(appealDocs);

    const paActivityId = R.pathOr(null, ['details', 'paActivityId'], activityData);

    /**
     * Find the selected PA being appealed
     */
    const pa = R.compose(R.propOr(null, paActivityId), R.indexBy(R.prop('id')))(priorAuthsOptions);

    return R.compose(
        R.set(R.lensPath(['details', 'paActivityId']), pa),
        /**
         * map the status
         */
        R.over(
            R.lensProp('status'),
            R.ifElse(
                R.flip(R.includes)(R.values(statuses)),
                mapIdentityToValueLabel,
                R.always({ value: statuses.NEW, label: statuses.NEW })
            )
        ),
        R.over(R.lensPath(['details', 'methodApprovalReceived']), mapIdentityToValueLabel),
        /**
         * Set fallback text area to empty string
         */
        R.over(R.lensPath(['details', 'notes']), R.ifElse(R.identity, R.identity, R.always(''))),

        R.over(R.lensPath(['details', 'benefitRequiresReVerification']), R.ifElse(R.equals('Yes'), R.T, R.F)),

        R.over(R.lensPath(['details', 'appealDate']), dateOrNull),
        R.over(R.lensPath(['details', 'effectiveEndDate']), dateOrNull),
        R.over(R.lensPath(['details', 'effectiveStartDate']), dateOrNull),
        R.over(R.lensPath(['details', 'scheduleFollowupAppeal']), mapIdentityToValueLabel),

        R.over(R.lensProp('medications'), (medications) => {
            let nextMeds = medications || [];
            if (hasLength(nextMeds)) {
                nextMeds = nextMeds.map((medication) => {
                    let nextMed = medication;
                    if (R.path(['data', 'authorizedUom'], nextMed)) {
                        nextMed = R.over(R.lensPath(['data', 'authorizedUom']), mapIdentityToValueLabel)(nextMed);
                    }

                    if (R.path(['data', 'ndcSpecificApproval'], nextMed)) {
                        nextMed = R.over(
                            R.lensPath(['data', 'ndcSpecificApproval']),
                            R.ifElse(
                                R.includes(R.__, ['1', '2']),
                                R.applySpec({
                                    value: R.cond([
                                        [R.equals('1'), R.always('Yes')],
                                        [R.equals('2'), R.always('No')],
                                    ]),
                                    label: R.cond([
                                        [R.equals('1'), R.always('Yes')],
                                        [R.equals('2'), R.always('No')],
                                    ]),
                                }),
                                R.always(null)
                            )
                        )(nextMed);
                    }

                    return nextMed;
                });
            }

            return nextMeds;
        }),
        R.assoc('appealDocs', docs)
    )(activityData);
}

/**
 * Takes the prior auths and renewals and formats them so they can be used in the Appeal form.
 * @param paActivities
 * @returns {PaActivities[]}
 */
function formatPriorAuthForAppealOptions(paActivities) {
    return R.map((activity) => {
        const deniedOn = R.compose(
            R.ifElse(R.identity, (d) => dayjs(d).format('YYYY-MM-DD'), R.always('')),
            R.path(['details', 'deniedOn'])
        )(activity);

        const medicationList = R.compose(
            R.join(', '),
            R.uniq,
            R.map(R.path(['medication', 'name'])),
            R.propOr([], 'medications')
        )(activity);

        const label = `PA denied on ${deniedOn} (${medicationList})`;

        //prettier-ignore
        return R.compose(
            R.assoc('medicationList', medicationList),
            R.assoc('label', label)
        )(activity);
    }, paActivities);
}

function convertFAtoView(faActivityDetails) {
    return {
        ...faActivityDetails,
        details: {
            ...faActivityDetails?.details,
            effectiveStartDate:
                faActivityDetails?.details?.effectiveStartDate &&
                dayjs(faActivityDetails?.details?.effectiveStartDate).toDate(),
            effectiveEndDate:
                faActivityDetails?.details?.effectiveEndDate &&
                dayjs(faActivityDetails?.details?.effectiveEndDate).toDate(),
            applicationSubmission:
                faActivityDetails?.details?.applicationSubmission &&
                dayjs(faActivityDetails?.details?.applicationSubmission).toDate(),
            followUpDueDate:
                faActivityDetails?.details?.followUpDueDate &&
                dayjs(faActivityDetails?.details?.followUpDueDate).toDate(),
            fileApplication: faActivityDetails?.details?.fileApplication
                ? {
                      id: faActivityDetails?.details?.fileApplication,
                      name: faActivityDetails?.details?.fileApplication,
                  }
                : {
                      id: 'No',
                      name: 'No',
                  },
            fundingSource: faActivityDetails?.details?.fundingSource && {
                id: faActivityDetails?.details?.fundingSource,
                name: faActivityDetails?.details?.fundingSource,
            },
            isCoveringMedicationCost: faActivityDetails?.details?.isCoveringMedicationCost && {
                id: faActivityDetails?.details?.isCoveringMedicationCost,
                name: faActivityDetails?.details?.isCoveringMedicationCost,
            },
            isReEnrollmentRequired: faActivityDetails?.details?.isReEnrollmentRequired && {
                id: faActivityDetails?.details?.isReEnrollmentRequired,
                name: faActivityDetails?.details?.isReEnrollmentRequired,
            },
            denialReason: faActivityDetails?.details?.denialReason && {
                id: faActivityDetails?.details?.denialReason,
                name: faActivityDetails?.details?.denialReason,
            },
            scheduleFollowUpActivity: faActivityDetails?.details?.scheduleFollowUpActivity === BOOLEAN_TYPE.yes,
        },
        status: faActivityDetails?.status && {
            id: faActivityDetails?.status,
            name: faActivityDetails?.status,
        },
    };
}

function convertFAtoSave(values) {
    const { completedOn, dueOn, type, status, statusReason, statusDate, medications, details } = values || {};
    const updatedDetails = R.omit(['dueOn', 'fortnight'], details);

    const modifiedDetails = {
        ...updatedDetails,
        fileApplication: details?.fileApplication?.id,
        fundingSource: details?.fundingSource?.id,
        isCoveringMedicationCost: details?.isCoveringMedicationCost?.id,
        isReEnrollmentRequired: details?.isReEnrollmentRequired?.id,
        denialReason: details?.denialReason?.id,
        scheduleFollowUpActivity: details?.scheduleFollowUpActivity ? BOOLEAN_TYPE.yes : BOOLEAN_TYPE.no,
        otherAssistance: details?.otherAssistance && details?.otherAssistance.toString(),
        status: status?.id,
    };

    const activityPayload = {
        completed_on: completedOn,
        due_on: dueOn,
        type,
        status: status?.id,
        status_reason: statusReason,
        status_date: statusDate,
        details: convertKeysToSnakeCase(modifiedDetails),
    };

    const followUpActivityPayload =
        modifiedDetails.scheduleFollowUpActivity === BOOLEAN_TYPE.yes
            ? {
                  type: '2',
                  // adding 12 hours offset to try and keep this thing from rolling forward or backward
                  due_on: dayjs(details.dueOn).set('hour', 12).toISOString(),
                  medication: medications.map((med) => {
                      return {
                          medication_id: med.medicationId,
                      };
                  }),
              }
            : null;

    return { activityPayload, followUpActivityPayload };
}

export {
    convertPAtoSave,
    convertPAtoView,
    convertAppealToSave,
    convertFAtoSave,
    convertFAtoView,
    mapPriorAuthsForAppeal,
    formatInoundAppealActivity,
    formatPriorAuthForAppealOptions,
};

export const convertBItoView = (activityDetails) => {
    return {
        ...activityDetails,
        createdOn: activityDetails?.createdOn && dayjs(activityDetails?.createdOn).toDate(),
        dueOn: activityDetails?.dueOn && dayjs(activityDetails?.dueOn).toDate(),
        details: {
            ...activityDetails.details,
            completeDate:
                activityDetails?.details?.completeDate && dayjs(activityDetails?.details?.completeDate).toDate(),
            reasonCode: activityDetails?.details?.reasonCode && {
                id: activityDetails?.details?.reasonCode,
                valueName: activityDetails?.details?.reasonCode,
            },
            initialMedSiteOfCare: activityDetails?.details?.initialMedSiteOfCare && {
                id: activityDetails?.details?.initialMedSiteOfCare,
                valueName: activityDetails?.details?.initialMedSiteOfCare,
            },
        },
        status: activityDetails.status && {
            id: activityDetails?.status,
            name: activityDetails?.status,
        },
    };
};

export const convertToNumber = (value) => {
    if (typeof value === 'string') {
        return parseFloat(value.replace(/,/g, ''));
    }
    return value;
};

export const convertBItoSave = (formValues, insurance, biInsurance) => {
    const medicationPayload = (formValues.medications || [])
        .map(({ id, medicationId, data, rxNumberId }) => ({
            medicationId,
            id,
            data: data || {},
            ...(rxNumberId ? { rxNumberId } : {}),
        }))
        .map(convertKeysToSnakeCase);

    const notesPayload = {
        note: null,
        status: formValues.status.id,
    };
    const insurancePayload = formValues.patientInsurance.map((item) => {
        return {
            ...R.omit(
                ['disabledByUser', 'referralId', 'activityId', 'answerId', 'dateTime', 'patientInsuranceUuid'],
                item
            ),
            id: Object.values(insurance).find((it) => it.uuid === item.patientInsuranceUuid)?.id,
            deductableAmount: convertToNumber(item.deductableAmount),
            deductableAmountPaidToDate: convertToNumber(item.deductableAmountPaidToDate),
            pharmacyPlanOopMax: convertToNumber(item.pharmacyPlanOopMax),
            pharmacyPlanOopMaxPaidToDate: convertToNumber(item.pharmacyPlanOopMaxPaidToDate),
            helpDeskPhone: formatPhone(item.helpDeskPhone),
            coverageType: item?.coverageType?.value,
            payerSegment: item?.payerSegment?.value,
            isIntegratedHighDeductable: item?.isIntegratedHighDeductable?.value,
            isPharmacyBenefitsCarveOutFromMedical: item?.isPharmacyBenefitsCarveOutFromMedical?.value,
        };
    });
    const inListUuid = formValues.patientInsurance.map((it) => it.uuid);
    const removeInsurancePayload = biInsurance.filter((it) => !inListUuid.includes(it.patientInsuranceUuid));

    const activityPayload = {
        completed_on: formValues.completedOn,
        created_on: formValues.createdOn,
        current_employee_id: formValues.currentEmployeeId,
        current_employee_time: formValues.currentEmployeeTime,
        due_on: formValues.dueOn,
        type: 0,
        status: formValues.status?.id,
        status_reason: formValues.statusReason,
        status_date: formValues.statusDate,
        details: {
            id: formValues.details?.id,
            division_id: formValues.details?.divisionId,
            created_on: formValues.details?.createdOn,
            status: formValues.details?.status,
            complete_date: formValues.details?.completeDate,
            patient_total_responsibility_amount: formValues.details?.patientTotalResponsibilityAmount,
            re_verification_due_date: formValues.details?.reVerificationDueDate,
            re_verification_reason_code: formValues.details?.reVerificationReasonCode,
            benefit_requires_re_verification: formValues.details?.benefitRequiresReVerification,
            re_verification_schedule_option: formValues.details?.reVerificationScheduleOption,
            notes: formValues.details?.notes,
            reason_code: formValues.details?.reasonCode?.valueName,
            initial_med_site_of_care: formValues.details?.initialMedSiteOfCare?.valueName,
        },
    };

    return { medicationPayload, notesPayload, insurancePayload, activityPayload, removeInsurancePayload };
};

export const convertBiInsuranceToView = (insurances = []) => {
    return insurances.map((insurance) => ({
        ...insurance,
        coverageType: insurance?.coverageType
            ? {
                  label: insurance.coverageType,
                  value: insurance.coverageType,
              }
            : null,
        payerSegment: insurance?.payerSegment
            ? {
                  label: insurance.payerSegment,
                  value: insurance.payerSegment,
              }
            : null,
        isIntegratedHighDeductable: insurance?.isIntegratedHighDeductable
            ? {
                  label: insurance.isIntegratedHighDeductable,
                  value: insurance.isIntegratedHighDeductable,
              }
            : null,
        isPharmacyBenefitsCarveOutFromMedical: insurance?.isPharmacyBenefitsCarveOutFromMedical
            ? {
                  label: insurance.isPharmacyBenefitsCarveOutFromMedical,
                  value: insurance.isPharmacyBenefitsCarveOutFromMedical,
              }
            : null,
    }));
};

export const convertInsuranceToView = (insurances = {}) => {
    return keyBy(convertBiInsuranceToView(Object.values(insurances)), 'position');
};

export const computePatientInsurance = (insurance = [], biInsurance = {}) => {
    let max = 0;
    const todaysDate = new Date().toISOString();
    const activeInsurance = Object.values(insurance).filter(
        (item) => item.disabledOn === null && (item.coverageEndDate === null || item.coverageEndDate > todaysDate)
    );
    for (const item of Object.values(insurance)) {
        if (item.position > max) {
            max = item.position;
        }
    }

    for (const item of biInsurance) {
        item.uuid = item.patientInsuranceUuid;
    }
    for (const item of biInsurance) {
        if (item.position === 0) {
            max += 1;
            item.position = max;
        }
    }
    const activeBiInsurance = Object.values(
        keyBy(
            biInsurance.filter((item) => item && (item.coverageEndDate === null || item.coverageEndDate > todaysDate)),
            'position'
        )
    );
    return {
        patientInsurance: activeBiInsurance,
        allActiveInsurance: [...activeInsurance, ...activeBiInsurance],
    };
};
