import toast from 'Lib/toast';

/** @ngInject */
function ReferralActivityAetnaAppealController($state, rmsService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.save = save;

    function $onChanges(changes) {
        if (changes.activity && ctrl.activity) {
            ctrl.activity = angular.copy(ctrl.activity);
            ctrl.original = angular.copy(ctrl.activity);

            ctrl.activity.status = ctrl.activity.status ? ctrl.activity.status : 'Pending';
            ctrl.activity.status_reason = ctrl.activity.status_reason ? ctrl.activity.status_reason : 'New';
        }
    }

    function $onInit() {
        ctrl.activityId = $state.params.activityId;
        ctrl.patientId = $state.params.patientId;
        ctrl.referralId = $state.params.referralId;
    }

    function save() {
        return rmsService
            .updateActivity(ctrl.activity.patient_id, ctrl.activity.referral_id, ctrl.activity.id, ctrl.activity)
            .then((res) => {
                toast.success('Activity information has been updated successfully.');
                $state.go('app.referral', {
                    patientId: ctrl.patientId,
                    referralId: ctrl.referralId,
                });
                return res;
            })
            .catch((err) => {
                if (err && err.status) {
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line no-param-reassign
                    err = 'This activity did not update successfully. Please try again.';
                }

                toast.error(err, {
                    closeButton: true,
                    closeHtml: '<button><i class="fa fa-fw fa-times"></i></button>',
                    tapToDismiss: false,
                    timeOut: 25000,
                });
            });
    }
}

export default ReferralActivityAetnaAppealController;
