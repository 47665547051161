import React from 'react';
import PropTypes from 'prop-types';
import { patientPropType } from 'Lib/types';
import { Button } from 'react-bootstrap';

const propTypes = {
    row: PropTypes.shape({
        original: patientPropType,
    }).isRequired,
    column: PropTypes.shape({
        onClick: PropTypes.func.isRequired,
    }).isRequired,
};
const defaultProps = {};

function AssignCategoryActivityNameCell({ row, column: { onClick } }) {
    return (
        <Button bsStyle="link" data-testid="unassigned-med-alert" onClick={() => onClick(row.original)}>
            Assign Therapeutic Category
        </Button>
    );
}

AssignCategoryActivityNameCell.propTypes = propTypes;
AssignCategoryActivityNameCell.defaultProps = defaultProps;

export default AssignCategoryActivityNameCell;
