import toast from 'Lib/toast';

/** @ngInject */
function ReferralAddCommunicationModalController(rmsService) {
    const ctrl = this;

    ctrl.$onInit = $onInit;
    ctrl.submit = submit;

    function $onInit() {
        ctrl.communication = {};
        ctrl.ngModelOptions = {
            updateOn: 'default blur',
            debounce: {
                default: 1000,
                blur: 0,
            },
        };
    }

    function submit() {
        ctrl.saving = true;
        return rmsService
            .createCommunication(ctrl.resolve.patientId, ctrl.resolve.referralId, ctrl.communication)
            .then((res) => {
                toast.success('Referral communication successfully added');
                ctrl.close({ $value: res });
                return res;
            })
            .catch((res) => {
                if (res.status === 404 || res.status === 403) {
                    toast.error(
                        'The referral was not found or you do not have permission to access it. ' +
                            'Please contact your STM administrator.',
                        'Referral communication unsuccessfully added'
                    );
                }
                ctrl.dismiss({ $value: ctrl.resolve });
                return res;
            })
            .finally(() => {
                ctrl.saving = false;
            });
    }
}

export default ReferralAddCommunicationModalController;
