import React from 'react';
import { privacyWebsite, supportWebsite } from 'App/components/config/config';
import './Footer.scss';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer id="footer">
            <span />
            <a id="footer-support" href={supportWebsite} target="_blank" rel="noopener noreferrer">
                Contact Support
            </a>
            <span />
            <a id="footer-privacy" href={privacyWebsite} target="_blank" rel="noopener noreferrer">
                Privacy
            </a>
            <span />
            <a id="footer-terms" href="https://cps.com/terms-of-use" target="_blank" rel="noopener noreferrer">
                Terms of Use
            </a>
            <span />
            <span id="footer-copyright" className="copyright">
                Copyright © {currentYear} Therigy, LLC. All rights reserved.
            </span>
        </footer>
    );
};

export default Footer;
