import convertKeysToSnakeCase from '../utils/keyConversion/convertKeysToSnakeCase';
import PESService from './PESService';

/** @ngInject */
function WorkQueueService(_, $http, urlBuilderService, moment) {
    return {
        deleteFilter,
        clearObject,
        createSearch,
        getDefaultColumns,
        getDefaultFilter,
        getDefaultMobileAnswersFilter,
        get,
        getMobileAnswersQueue,
        getCounts,
        getFilters,
        getHeader,
        getTableParams,
        getUiParams,
        getUiMobileAnswersParams,
        saveFilter,
    };

    function deleteFilter(params) {
        const url = urlBuilderService.build('', {
            'work-queue/delete-filter': '',
        });

        return $http
            .post(url, params)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return err.data;
            });
    }

    function clearObject(filter) {
        return _.pickBy(filter, _.identity);
    }

    function createSearch(filter) {
        const search = {};
        _.forEach(filter, (val, key) => {
            if (key.indexOf('filter_') === 0 && !_.isEmpty(val)) {
                search[key.substring(7)] = val;
            }
        });
        return search;
    }

    async function get(params) {
        return PESService.getWorkqueue(params).then(convertKeysToSnakeCase);
    }

    async function getMobileAnswersQueue(params) {
        return PESService.getMobileAnswersQueue(params).then(convertKeysToSnakeCase);
    }

    function getDefaultColumns(user) {
        return {
            activity_due_date: {
                title: 'Activity Due Date',
                field: 'dateDueValue',
                filter: { filter_date_due_search: '/text-filter.html' },
                show: false,
                sortable: 'date_due',
                order: 5,
            },
            activity_needs_by_date: {
                title: 'Activity Needs By Date',
                field: 'needsByDateValue',
                filter: { filter_needs_by_search: '/text-filter.html' },
                show: false,
                sortable: 'needs_by',
            },
            activity_name: {
                title: 'Activity Name',
                field: 'activity_name',
                filter: { filter_activity_name: '/text-filter.html' },
                show: false,
                sortable: 'activity_name',
                order: 6,
            },
            activity_status: {
                title: 'Notes Status',
                field: 'activity_status',
                filter: { filter_activity_status: '/text-filter.html' },
                show: false,
                sortable: 'activity_status',
                order: 8,
            },
            last_updated: {
                title: 'Activity Updated',
                field: 'last_updated',
                filter: { filter_updated_name: '/text-filter.html' },
                show: false,
                sortable: 'updated_on',
                order: 9,
            },
            patient_dob: {
                title: 'Patient DOB',
                field: 'patient_dob',
                filter: { filter_patient_dob: '/text-filter.html' },
                show: false,
                sortable: 'patient_dob',
                order: 7,
            },
            patient_id: {
                title: 'Patient ID',
                field: user.company_permissions.DisplayedId,
                filter: ['external_id', 'other_id', 'ehr_id'].includes(user.company_permissions.DisplayedId)
                    ? {
                          [`filter_${user.company_permissions.DisplayedId}`]: '/text-filter.html',
                      }
                    : {
                          filter_human_id: '/text-filter.html',
                      },
                show: false,
                sortable: user.company_permissions.DisplayedId,
                order: 1,
            },
            patient_name: {
                title: 'Patient Name',
                field: 'patient_name',
                filter: { filter_patient_name: '/text-filter.html' },
                show: false,
                sortable: 'patient_name',
                order: 2,
            },
            medication: {
                title: 'Medication',
                field: 'medication',
                filter: { filter_medication: '/text-filter.html' },
                show: false,
                order: 4,
            },
            md_id: {
                title: 'Prescriber NPI',
                field: 'md_id',
                show: false,
                sortable: 'md_id',
            },
            md_name: {
                title: 'Prescriber Name',
                field: 'md_name',
                show: false,
                sortable: 'md_name',
            },
            md_practice_name: {
                title: 'Prescriber Practice Name',
                field: 'md_practice_name',
                show: false,
                sortable: 'md_practice_name',
            },
            referral_stat: {
                title: 'RSV Referral Status',
                field: 'referral_stat',
                show: false,
                sortable: 'referral_stat',
            },
            referral_stat_desc: {
                title: 'RSV Referral Status Description',
                field: 'referral_stat_desc',
                show: false,
                sortable: 'referral_stat_desc',
            },
            referral_stat_date: {
                title: 'RSV Referral Status Change Date',
                field: 'referral_stat_date',
                show: false,
                sortable: 'referral_stat_date',
            },
            assigned_to: {
                title: 'Assigned To',
                field: 'assigned_to',
                filter: { filter_assigned_to: '/text-filter.html' },
                show: false,
                sortable: 'assigned_to',
            },
            patient_labels: {
                title: 'Patient Labels',
                field: 'patient_labels',
                filter: { filter_patient_labels: '/text-filter.html' },
                show: false,
                sortable: 'patient_labels',
            },
            branch_name: {
                title: 'Branch Name',
                field: 'branch_name',
                filter: { filter_branch_name: '/text-filter.html' },
                show: false,
                sortable: 'branch_name',
            },
            patient_state: {
                title: 'Patient State',
                field: 'patient_state',
                filter: { filter_patient_state: '/text-filter.html' },
                show: false,
                sortable: 'patient_state',
            },
            patient_status: {
                title: 'Patient Status',
                field: 'patient_status',
                filter: { filter_patient_status: '/text-filter.html' },
                show: false,
                sortable: 'patient_status',
            },
            insurance_plan_name: {
                title: 'Insurance Plan Name',
                field: 'insurance_plan_name',
                filter: { filter_insurance_plan_name: '/text-filter.html' },
                show: false,
                sortable: 'insurance_plan_name',
            },
            activity_status_date: {
                title: 'Activity Status Last Updated Date',
                field: 'activity_status_date',
                filter: { filter_activity_status_date: '/text-filter.html' },
                show: false,
                sortable: 'activity_status_date',
            },
            medication_association: {
                title: 'Medication Association',
                field: 'medication_association',
                filter: { filter_medication_association: '/text-filter.html' },
                show: false,
                sortable: 'medication_association',
            },
        };
    }

    function getDefaultFilter(dateRange) {
        return {
            activityTypes: {},
            all_branches: true,
            all_categories: true,
            all_types: false,
            assignedToMe: false,
            branches: {},
            dateRange,
            dateEnd: moment().endOf('isoWeek').format('YYYY-MM-DD'),
            dateStart: moment().startOf('isoWeek').format('YYYY-MM-DD'),
            filter_activity_name: '',
            filter_activity_status: '',
            filter_assigned_to: '',
            filter_date_due_search: '',
            filter_human_id: '',
            filter_medication: '',
            filter_needs_by_search: '',
            filter_patient_dob: '',
            filter_patient_labels: '',
            filter_patient_name: '',
            filter_updated_name: '',
            group_ids: {},
            highRisk: false,
            onlyMine: false,
            search: '',
            predictiveModelRisk: false,
        };
    }

    function getDefaultMobileAnswersFilter() {
        return {
            activityTypes: {},
            all_branches: true,
            all_categories: true,
            all_types: false,
            assignedToMe: false,
            branches: {},
            filter_activity_name: '',
            filter_activity_status: '',
            filter_assigned_to: '',
            filter_date_due_search: '',
            filter_human_id: '',
            filter_medication: '',
            filter_needs_by_search: '',
            filter_patient_dob: '',
            filter_patient_labels: '',
            filter_patient_name: '',
            filter_updated_name: '',
            group_ids: {},
            highRisk: false,
            onlyMine: false,
            search: '',
            predictiveModelRisk: false,
        };
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-unused-vars
    function getHeader(params) {
        return PESService.getHeader().then((res) => {
            res.headers = convertKeysToSnakeCase(res.headers);
            return res;
        });
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-unused-vars
    function getFilters(params) {
        return PESService.getFilters().then((res) => {
            return Object.values(res).map((obj) => {
                return { ...obj, filter: JSON.stringify(obj.filter) };
            });
        });
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-unused-vars
    function getCounts(params) {
        return PESService.getCounts().then(convertKeysToSnakeCase);
    }

    function getTableParams() {
        return ['count', 'filter', 'sorting', 'page'];
    }

    function getUiParams() {
        return [
            'activityTypes',
            'all_branches',
            'all_categories',
            'all_types',
            'assignedToMe',
            'branches',
            'dateEnd',
            'dateRange',
            'dateStart',
            'group_ids',
            'highRisk',
            'onlyMine',
            'search',
            'predictiveModelRisk',
        ];
    }

    function getUiMobileAnswersParams() {
        return [
            'activityTypes',
            'all_branches',
            'all_categories',
            'all_types',
            'assignedToMe',
            'branches',
            'group_ids',
            'highRisk',
            'onlyMine',
            'search',
            'predictiveModelRisk',
        ];
    }

    function saveFilter(params) {
        const url = urlBuilderService.build('', { 'work-queue/filter': '' });

        return $http
            .post(url, params)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return err.data;
            });
    }
}

export default WorkQueueService;
