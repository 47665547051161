import { useFormikContext } from 'formik';
import React from 'react';
import { Button } from 'react-bootstrap';
import { FaMinus, FaPlus } from 'react-icons/fa';

import { CheckboxField } from 'Lib/form/CheckboxField';
import { TextField } from 'Lib/form/TextField';

import CommonPatientFieldWrapper from './CommonPatientFieldWrapper';
import SectionTitleStyle from './SectionTitleStyle';

const AllergiesForm = () => {
    const { values, setFieldValue } = useFormikContext();

    return (
        <CommonPatientFieldWrapper fieldName="allergies">
            {(commonProps) => (
                <div>
                    <SectionTitleStyle>
                        <h2>Allergies</h2>
                    </SectionTitleStyle>

                    <CheckboxField
                        fieldName="noKnownAllergies"
                        label="No known allergies"
                        disabled={commonProps.disabled}
                    />

                    {!values.noKnownAllergies && (
                        <>
                            <TextField {...commonProps} fieldName="allergy" label="Allergy" placeholder="Add Allergy" />

                            {values.otherAllergy.map((allergy, index) => (
                                <TextField
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    {...commonProps}
                                    fieldName={`otherAllergy[${index}]`}
                                    label="Allergy"
                                    placeholder="Add Allergy"
                                    addonButton={
                                        <Button
                                            onClick={() => {
                                                values.otherAllergy.splice(index, 1);
                                                setFieldValue('otherAllergy', values.otherAllergy);
                                            }}
                                            aria-label="Remove Allergy"
                                        >
                                            <FaMinus />
                                        </Button>
                                    }
                                />
                            ))}

                            {!commonProps.disabled && (
                                <Button
                                    bsStyle="link"
                                    onClick={() => {
                                        const newIndex = values.otherAllergy.length;
                                        setFieldValue(`otherAllergy[${newIndex}]`, '');
                                    }}
                                >
                                    <FaPlus /> Add Allergy
                                </Button>
                            )}
                        </>
                    )}
                </div>
            )}
        </CommonPatientFieldWrapper>
    );
};

export default AllergiesForm;
