import dayjs from 'dayjs';
import { cx } from '@emotion/css';
import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from 'react-datepicker';
import utc from 'dayjs/plugin/utc';

import asFormField from './asFormField';

dayjs.extend(utc);

export const DatePickerInput = ({
    name,
    value,
    onChange,
    onBlur,
    className,
    style,

    disabled,
    placeholder,
    filterDate,
    minDate,
    maxDate,
}) => {
    const handleChange = (event, rawEvent) => {
        const rawValue = rawEvent.target.value;
        if (typeof rawValue === 'string' && rawValue.length <= 7) {
            return;
        }
        onChange(event, rawEvent);
    };

    const handleChangeRaw = (event) => {
        const newValue = event.target.value;
        if (!newValue || newValue.length <= 7) {
            return onChange(null);
        }
        const newDate = dayjs(newValue);
        if (newDate.isValid()) {
            onChange(newDate.toDate());
        }
    };

    return (
        <DatePicker
            id={name}
            name={name}
            selected={value}
            onChange={handleChange}
            onChangeRaw={handleChangeRaw}
            onBlur={onBlur}
            className={cx('form-control', className)}
            style={style}
            dateFormat="yyyy/MM/dd"
            placeholderText={placeholder}
            filterDate={filterDate}
            disabled={disabled}
            minDate={minDate}
            maxDate={maxDate}
        />
    );
};

DatePickerInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.instanceOf(Date),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.shape({}),

    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    filterDate: PropTypes.func,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
};

DatePickerInput.defaultProps = {
    value: null,
    onBlur: null,
    className: '',
    style: null,

    placeholder: '',
    disabled: false,
    filterDate: undefined,
    minDate: null,
    maxDate: null,
};

export const DatePickerField = asFormField({
    WrappedComponent: DatePickerInput,
    onValidateField: (value) => {
        if (value && dayjs(value).utc().format('YYYY/MM/DD') < dayjs('1900/01/01').utc().format('YYYY/MM/DD')) {
            return 'Cannot select date prior to 1900';
        }
    },
});
