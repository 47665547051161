import React from 'react';
import ManualPageTable from 'App/components/ManualPageTable';
import * as R from 'ramda';
import { useFormikContext } from 'formik';
import hasLength from 'App/utils/hasLength';
import PropTypes from 'prop-types';
import propOrDash from 'App/utils/propOrDash';
import PatientDisplayId from 'App/components/PatientDisplayId/PatientDisplayId';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    totalRows: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onSortChange: PropTypes.func.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/require-default-props
    currentPage: PropTypes.number.isRequired,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    columns: PropTypes.array,
};

const defaultProps = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    data: [],
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    total: 0,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/default-props-match-prop-types
    currentPage: 1,
    columns: [],
};

const fixedColumns = [
    {
        Header: 'Patient ID',
        accessor: (row) => <PatientDisplayId patientId={row.id} fallback="--" />,
        id: 'patientId',
        canSort: true,
    },
    {
        Header: 'Last Name',
        accessor: propOrDash('lastName'),
        id: 'lastName',
        canSort: true,
    },
    {
        Header: 'First Name',
        accessor: propOrDash('firstName'),
        id: 'firstName',
        canSort: true,
    },
    {
        Header: 'Middle Name',
        accessor: propOrDash('middleName'),
        id: 'middleName',
        canSort: true,
    },
    {
        Header: 'Birth Date',
        accessor: propOrDash('birthDate'),
        id: 'birthDate',
        canSort: true,
    },

    {
        Header: 'City, State',
        accessor: propOrDash('city'),
        id: 'city',
        canSort: true,
    },

    {
        Header: 'Patient Status',
        accessor: propOrDash('status'),
        id: 'status',
        canSort: true,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
            return <span style={{ color: value === 'Disabled' ? 'var(--brandDanger)' : 'inherit' }}>{value}</span>;
        },
    },

    {
        Header: 'Patient Branch',
        accessor: propOrDash('branch'),
        id: 'branch',
        canSort: true,
    },

    {
        Header: 'Actions',
        id: 'actions',
        canSort: false,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => {
            const actualId = R.path(['original', 'id'], row);
            return (
                <span>
                    <a href={`#!/patient/${actualId}`}>Profile</a>
                    <span style={{ margin: '2%' }}>|</span>
                    <a href={`#!/editPatient/${actualId}`}>Edit</a>
                </span>
            );
        },
    },
];

function FindPatientTable({ data, totalRows, onPageChange, onSortChange, currentPage, columns }) {
    const { submitCount, isValid, isSubmitting } = useFormikContext();

    /**
     * Prevent the user from sorting (submitting) the form when shouldn't
     */
    const canSort = submitCount > 0 && isValid && !isSubmitting && hasLength(data);
    return (
        <ManualPageTable
            data={data}
            totalRows={totalRows}
            onPageChange={onPageChange}
            onSortChange={onSortChange}
            noRowsText="Search results based on the search criteria above will display here."
            initialSortKey="lastName"
            initialSortOrder="asc"
            columns={columns.length ? columns : fixedColumns}
            canSort={canSort}
            canPage={canSort}
            isLoading={isSubmitting}
            currentPage={currentPage}
        />
    );
}

FindPatientTable.propTypes = propTypes;
FindPatientTable.defaultProps = defaultProps;

export default FindPatientTable;
