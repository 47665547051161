import React, { useState, useEffect, useMemo } from 'react';
import { hasLength } from '../../utils';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import './patientInsurance.scss';
import AddInsuranceDetachModal from './AddInsuranceDetachModal';
import AttachInsurance from './AttachInsurance';
import InsuranceTabs from './InsuranceTabs';
import isExpired from 'App/utils/isExpired';
import dayjs from 'dayjs';

const propTypes = {
    handleChangeProps: PropTypes.func,
    removeInsurancePlan: PropTypes.func,
    validationInsuranceErrors: PropTypes.shape({
        name: PropTypes.string,
        planPatientId: PropTypes.string,
        pharmacyInsuranceGroupId: PropTypes.string,
        pharmacyInsuranceBin: PropTypes.string,
        pharmacyInsurancePcn: PropTypes.string,
    }),
    validateInsurancePlan: PropTypes.func,
    patientInsurance: PropTypes.shape({
        name: PropTypes.string,
        planPatientId: PropTypes.string,
        pharmacyInsurancePersonCode: PropTypes.string,
        groupNumber: PropTypes.string,
        payerIdCode: PropTypes.string,
        payerSegment: PropTypes.string,
        medicalProductLine: PropTypes.string,
        pharmacyProductLine: PropTypes.string,
        deductableAmount: PropTypes.number,
        deductableAmountPaidToDate: PropTypes.number,
        pharmacyPlanOopMax: PropTypes.number,
        pharmacyPlanOopMaxPaidToDate: PropTypes.number,
        coverageEffectiveDate: PropTypes.string,
        coverageEndDate: PropTypes.string,
        coverageType: PropTypes.string,
        isIntegratedHighDeductible: PropTypes.string,
        isPharmacyBenefitsCarveOutFromMedical: PropTypes.string,
        pharmacyInsuranceGroupId: PropTypes.string,
        pharmacyInsuranceBin: PropTypes.string,
        pharmacyInsurancePcn: PropTypes.string,
        helpDeskPhone: PropTypes.string,
        disabledOn: PropTypes.string,
        disabledBy: PropTypes.string,
    }),
    handleSelectOptions: PropTypes.func,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    insuranceToAttach: PropTypes.object,
    handleDetach: PropTypes.func,
    isDisabled: PropTypes.bool,
    selectOption: PropTypes.string,
    isBiInsuranceLoading: PropTypes.bool,
};

const defaultProps = {
    patientInsurance: {},
    handleChangeProps: () => {},
    removeInsurancePlan: () => {},
    validationInsuranceErrors: {},
    validateInsurancePlan: () => {},
    handleSelectOptions: () => {},
    insuranceToAttach: {},
    handleDetach: () => {},
    isDisabled: false,
    selectOption: '',
    isBiInsuranceLoading: false,
};

function BiInsurance(props) {
    const {
        patientInsurance,
        handleChangeProps,
        removeInsurancePlan,
        validationInsuranceErrors,
        validateInsurancePlan,
        handleSelectOptions,
        insuranceToAttach,
        handleDetach,
        isDisabled,
        selectOption,
        isBiInsuranceLoading,
    } = props;

    const insuranceKeys = useMemo(() => {
        return patientInsurance ? Object.keys(patientInsurance).map(Number) : [];
    }, [patientInsurance]);

    const itemsEnable = useMemo(() => {
        return (
            insuranceKeys &&
            insuranceKeys.filter(
                (item) =>
                    patientInsurance[item].disabledOn === undefined &&
                    !isExpired(dayjs(patientInsurance[item].coverageEndDate).utc().format('YYYY-MM-DD 23:59:59'))
            )
        );
    }, [insuranceKeys, patientInsurance]);

    const [tabList, setTabList] = useState({ tabList: [], activeState: 1 });

    const [detach, setDetach] = useState(false);

    const toggleDetach = () => {
        setDetach(true);
    };

    useEffect(() => {
        const temp = itemsEnable;
        if (hasLength(insuranceKeys)) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-shadow
            setTabList(({ tabList }) => {
                return {
                    tabList: R.concat(
                        temp.filter((item) => !tabList.includes(item)),
                        tabList
                    ),
                    activeState:
                        selectOption !== '' && selectOption !== '+Add New Plan' && selectOption !== 'Select'
                            ? insuranceKeys.filter((item) => selectOption === patientInsurance[item]?.name)[0]
                            : temp[temp.length - 1],
                };
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insuranceKeys, itemsEnable, patientInsurance]);

    const removePlan = (event, activeState) => {
        event.stopPropagation();
        const idx = tabList.tabList.indexOf(activeState);
        if (idx > -1) {
            setTabList({
                tabList: tabList.tabList.filter((value, i) => i !== idx),
                activeState:
                    tabList.tabList.length > 0
                        ? tabList.tabList.filter((value, i) => i !== idx)[
                              tabList.tabList.filter((value, i) => i !== idx).length - 1
                          ]
                        : 1,
            });
        }
        removeInsurancePlan(activeState);
    };
    return (
        <>
            <div className="tab-container">
                <h2 className="plan-header"> Insurance Plans </h2>
            </div>

            <AttachInsurance
                isDisabled={isDisabled}
                handleSelectOptions={handleSelectOptions}
                selectOption={selectOption}
                insuranceKeys={insuranceKeys}
                insuranceToAttach={insuranceToAttach}
                setTabList={setTabList}
                tabList={tabList}
            />

            <InsuranceTabs
                tabList={tabList}
                setTabList={setTabList}
                patientInsurance={patientInsurance}
                handleChangeProps={handleChangeProps}
                validationInsuranceErrors={validationInsuranceErrors}
                validateInsurancePlan={validateInsurancePlan}
                removePlan={removePlan}
                itemsEnable={itemsEnable}
                isBiDisabled={isDisabled}
                biInsurance
                isBiInsuranceLoading={isBiInsuranceLoading}
                toggleDetach={toggleDetach}
            />

            <AddInsuranceDetachModal
                detach={detach}
                isDisabled={isDisabled}
                setDetach={setDetach}
                handleDetach={handleDetach}
                removePlan={removePlan}
                activeState={tabList.activeState}
            />
        </>
    );
}

BiInsurance.propTypes = propTypes;
BiInsurance.defaultProps = defaultProps;

export default BiInsurance;
