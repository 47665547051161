import React, { useMemo } from 'react';
import pathOrDash from 'App/utils/pathOrDash';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import Table from 'App/components/Table/Table';
import TherigyInfoMessage from 'App/components/TherigyInfoMessage/TherigyInfoMessage';
import MoneyInput from 'App/components/PatientInsurance/MoneyInput';
import { NumberField } from 'Lib/form/NumberField';
import { hasLength } from 'App/utils';

const CopayPercentCell = ({ row }) => {
    const {
        values: { disableActivity, disableInputs },
    } = useFormikContext();
    return (
        <NumberField
            fieldName={`medications[${row.id}].data.copayPercent`}
            disabled={disableActivity || disableInputs}
            placeholder="Coinsurance Amount"
            prefixAddon="%"
        />
    );
};

const percentCellPropTypes = {
    row: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};
CopayPercentCell.propTypes = percentCellPropTypes;

const CopayAmountCell = ({ row, value }) => {
    const {
        values: { disableActivity, disableInputs },
        setFieldValue,
    } = useFormikContext();
    return (
        <MoneyInput
            name={`medications[${row.id}].data.copayAmount`}
            id={`medications[${row.id}].data.copayAmount`}
            disabled={disableActivity || disableInputs}
            placeholder="Copay Amount"
            onChange={(event) => {
                setFieldValue(`medications[${row.id}].data.copayAmount`, event.target.value);
            }}
            value={value || ''}
        />
    );
};

const amountCellPropTypes = {
    ...percentCellPropTypes,
    value: PropTypes.string.isRequired,
};
CopayAmountCell.propTypes = amountCellPropTypes;

const MedicationTableForm = () => {
    const { values } = useFormikContext();

    const columns = useMemo(
        () => [
            {
                Header: 'Medication',
                accessor: 'medication.name',
                Cell: ({ row }) => pathOrDash(['original', 'medication', 'name'], row),
                id: 'medication',
                disableFilters: true,
                canSort: true,
                width: '50%',
            },
            {
                Header: 'Copay Amount',
                accessor: 'data.copayAmount',
                Cell: CopayAmountCell,
                id: 'copayAmount',
                disableFilters: true,
                canSort: true,
            },
            {
                Header: 'Coinsurance Amount',
                accessor: 'data.copayPercent',
                Cell: CopayPercentCell,
                id: 'copayPercent',
                disableFilters: true,
                canSort: true,
            },
        ],
        []
    );

    if (!hasLength(values?.medications)) {
        return (
            <TherigyInfoMessage message="There is currently no medication attached to this Benefits Investigation Activity." />
        );
    }

    return (
        <>
            <h3>Medication</h3>
            <Table enablePagination={false} columns={columns} data={values?.medications || []} canSort={false} />
        </>
    );
};

export default MedicationTableForm;
