import React from 'react';
import styled from '@emotion/styled';

const PageHeader = styled.h3`
    padding: 1rem;
    border-bottom: 2px solid #cfd7df;
`;

const Sidebar = styled.div`
    padding: 1rem;
    background-color: #eee;
    font-size: 1.25rem;
`;

const Blockquote = styled.div`
    padding: 1rem;
    margin: 1rem 0;
    border: 1px solid #ccc;
    font-size: 1.75rem;
`;

const HighlightSpan = styled.span`
    color: var(--brandDanger);
    font-weight: bold;
`;

const DateFormatContainer = styled.div`
    width: 150px;
    padding: 1rem;
    margin: 1rem 0;
    text-align: center;
    box-sizing: border-box;
    border: 1px solid #ccc;
`;

const StatusContainer = styled.div`
    display: flex;
    margin: auto;
    text-align: center;
`;

const Message = styled.p`
    margin: auto;
`;

const validateFiles = (filesToValidate) => {
    const maxFileSize = 8388608;
    const validated = true;
    if (Array.isArray(filesToValidate) && filesToValidate.length) {
        const fileSizeExceeded = !filesToValidate.every((file) => file.size < maxFileSize);
        if (fileSizeExceeded) {
            return false;
        }
    }
    return validated;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/prop-types
const FileUpload = ({ onFiles, accept, ...rest }) => {
    const onChange = (e) => {
        const { files } = e.target;
        onFiles([...files]);
    };

    return (
        <div>
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
                Click to select some files...
                <input {...rest} accept={accept} type="file" onChange={onChange} />
            </label>
        </div>
    );
};

export { PageHeader, Sidebar, Blockquote, HighlightSpan, DateFormatContainer, StatusContainer, Message, validateFiles };

export default FileUpload;
