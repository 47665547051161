import { useMutation, useQueryClient } from 'react-query';
import { updateReferralMedication } from 'App/services/RMSService';
import hasLength from "App/utils/hasLength";
import * as R from 'ramda'

const useUpdateReferralMedicationMutation = (patientId, referralId) => {
    const queryClient = useQueryClient();

    return useMutation(({id, ...medication}) => {
        return updateReferralMedication(patientId, referralId, id, medication);
    }, {
        onSuccess: (medication) => {
            const key = ['patient', patientId, 'referral', referralId, 'medication'];
            const existingMeds = queryClient.getQueryData(key)

            let nextMeds = [medication]

            if (hasLength(existingMeds))  {
                nextMeds = R.compose(
                    R.update(R.__, medication),
                    R.findIndex(R.propEq(medication.id, 'id')),
                )(existingMeds);
            }
            queryClient.setQueryData(key, nextMeds);
        },
    });
};

export default useUpdateReferralMedicationMutation;
