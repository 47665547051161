import React, { useCallback, useEffect, useState } from 'react';
import AccordionWrapper from 'App/components/AccordionWrapper/AccordionWrapper';
import ManualPageTable from 'App/components/ManualPageTable';
import SessionStorageService from 'App/services/SessionStorageService';
import { hasLength } from 'App/utils';
import styled from '@emotion/styled';
import useFetchWorkQueueMobileAnswers from 'App/hooks/useFetchWorkQueueMobileAnswers';
import { useFormikContext } from 'formik';
import { processWorkQueueResult, updateWorkQueueFilters } from 'App/features/WorkQueue/workQueue.utils';
import { defaultActivityDateModalState, defaultActivityNoteModalState } from '../../defaultWorkQueueState';
import EditActivityDueDateModal from 'App/components/PatientProfile/components/PatientActivities/components/EditActivityDueDateModal';
import ActivityNotesModal from 'App/components/PatientProfile/components/PatientActivities/components/ActivityNotesModal';
import { queryClient } from 'Lib/queryClient';
import { useGetWorkQueueColumns } from '../../hooks/useGetWorkQueueColumns';
import { useGetHeaderMappingForMobileAnswersQueue } from '../../hooks/useGetHeaderMappingForMobileAnswersQueue';
import { useUserContext } from '@/app/contexts/UserContext';
import * as R from 'ramda';

const MarginDiv = styled('div')`
    margin-bottom: 30px;
`;

const MobileCompletedActivitiesTable = () => {
    const {
        values: {
            modalMobileAnswersState: {
                mobileAnswersActivityDateModal: mobileAnswersActivityDateModalState,
                mobileAnswersActivityNoteModal: mobileAnswersActivityNoteModalState,
            },
            filterParams,
        },
        setFieldValue,
    } = useFormikContext();
    const headerMapping = useGetHeaderMappingForMobileAnswersQueue();
    const user = useUserContext();
    const filterDefaultState = {
        ...R.omit(['dateStart', 'dateEnd', 'type'], filterParams),
        count: 10,
        page: 1,
        sorting: {
            patient_name: 'asc',
        },
        search: {},
    };
    const [filters, setFilters] = useState(filterDefaultState);
    const [lazyFilter, setLazyFilter] = useState(filterDefaultState);
    const { data: mobileAnswersComplete, isLoading: isSubmitting } = useFetchWorkQueueMobileAnswers(lazyFilter);
    const onFilterChange = useCallback(
        (e) => {
            const prevSearch = { ...filters.search };
            if (e.target.value === '') {
                delete prevSearch[e.target.name];
            } else {
                prevSearch[e.target.name] = e.target.value;
            }
            setFilters((prev) => ({ ...prev, search: prevSearch }));
        },
        [filters.search]
    );

    useEffect(() => {
        const timer = setTimeout(() => {
            setLazyFilter(filters);
        }, 500);
        return () => clearTimeout(timer);
    }, [filters]);

    useEffect(() => {
        const params = R.omit(['dateStart', 'dateEnd', 'type'], filterParams);
        const filterMobileParams = {
            ...params,
            count: filters.count,
            page: filters.page,
            sorting: filters.sorting,
            search: filters.search,
        };
        setFilters(filterMobileParams);
    }, [filterParams, filters.count, filters.page, filters.search, filters.sorting]);

    const refetchWorkQueueMobileAnswersResult = () => {
        queryClient.refetchQueries([
            'company',
            user.active_company.ID,
            'branch',
            user.active_branch.ID,
            'workQueue',
            'mobileAnswersComplete',
            JSON.stringify(updateWorkQueueFilters(filters)),
        ]);
    };
    const onHideActivityDueDateModal = (refetch) => {
        if (refetch) {
            refetchWorkQueueMobileAnswersResult();
        }
        setFieldValue('modalMobileAnswersState.mobileAnswersActivityDateModal', defaultActivityDateModalState);
    };

    const onHideActivityNoteStatus = (refetch) => {
        if (refetch) {
            refetchWorkQueueMobileAnswersResult();
        }
        setFieldValue('modalMobileAnswersState.mobileAnswersActivityNoteModal', defaultActivityNoteModalState);
    };

    const columns = useGetWorkQueueColumns({ headerMapping, onFilterChange, searchState: filters.search });

    const canSort = hasLength(mobileAnswersComplete?.data && processWorkQueueResult(mobileAnswersComplete.data));

    const onPageChange = ({ pageIndex, pageSize }) => {
        setFilters((prev) => ({
            ...prev,
            count: pageSize,
            page: pageIndex,
        }));
    };

    const onSortChange = (sort) => {
        const sortField = sort.sortKey;
        setFilters((prev) => ({
            ...prev,
            sorting: {
                [sortField]: sort.sortOrder,
            },
        }));
    };

    return (
        <AccordionWrapper
            title={`Mobile Completed Activities (${mobileAnswersComplete?.count ?? 0})`}
            initialVisible={SessionStorageService.getOnUser('mobileAnswersCompleteActivities-toggle', true)}
            onClick={(newValue) => SessionStorageService.setOnUser('mobileAnswersCompleteActivities-toggle', newValue)}
        >
            <MarginDiv>
                <ManualPageTable
                    data={mobileAnswersComplete?.data && processWorkQueueResult(mobileAnswersComplete.data)}
                    totalRows={mobileAnswersComplete?.count}
                    placeholderText="There are no activities that match your current search criteria."
                    columns={columns}
                    canSort={canSort}
                    isLoading={isSubmitting}
                    onPageChange={onPageChange}
                    onSortChange={onSortChange}
                    pageSizeOptions={[10, 25, 50]}
                    currentPageIndex={filters.count}
                    currentPage={filters.page}
                    initialSortKey="patient_name"
                    initialSortOrder="asc"
                />
                {mobileAnswersActivityDateModalState.show && (
                    <EditActivityDueDateModal
                        onHide={onHideActivityDueDateModal}
                        {...mobileAnswersActivityDateModalState}
                    />
                )}
                {mobileAnswersActivityNoteModalState.show && (
                    <ActivityNotesModal onHide={onHideActivityNoteStatus} {...mobileAnswersActivityNoteModalState} />
                )}
            </MarginDiv>
        </AccordionWrapper>
    );
};

export default MobileCompletedActivitiesTable;
