import { useMutation } from 'react-query';
import { deleteReferralActivity } from 'App/services/RMSService';
import toast from 'Lib/toast';
import { queryClient } from 'Lib/queryClient';
import * as R from 'ramda';

const useDeleteReferralActivity = () => {
    return useMutation(
        ({ patientId, referralId, activityId }) => deleteReferralActivity(patientId, referralId, activityId),
        {
            onSuccess: async (data) => {
                toast.success('Activity has been deleted successfully.');
                const key = ['patient', data.patientId, 'referral', data.referralId, 'activity'];
                const activities = [
                    ...(queryClient.getQueryData(key) || []),
                    ...(queryClient.getQueryData(key.map(String)) || []),
                ];

                //prettier-ignore
                const next = R.compose(
                    R.update(R.__, data, activities),
                    R.findIndex(R.propEq(data.id, 'id')),
                )(activities)

                await queryClient.setQueryData(key, next);

                return queryClient.removeQueries(['patient', data.patientId, 'referral', String(data.referralId)]);
            },
        }
    );
};

export default useDeleteReferralActivity;
