import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/lib/Button';
import { hasLength } from '../../utils';
import Form from 'react-bootstrap/lib/Form';
import { keyBy } from 'lodash';
import PropTypes from 'prop-types';
import InsuranceTabs from './InsuranceTabs';
import { isExpired } from 'App/utils';
import dayjs from 'dayjs';

const propTypes = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    patientInsurance: PropTypes.object,
    handleChangeProps: PropTypes.func,
    removeInsurancePlan: PropTypes.func,
    isReadOnly: PropTypes.bool,
    editPatientInsurance: PropTypes.bool,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    validationInsuranceErrors: PropTypes.object,
    validateInsurancePlan: PropTypes.func,
    toggleInsurancePlans: PropTypes.func,
    showInsurancePlans: PropTypes.bool,
    disablePlan: PropTypes.func,
    disableInsurancePlan: PropTypes.bool,
    handleDisablePlan: PropTypes.func,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    requiredFields: PropTypes.array,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    disabledFields: PropTypes.array,
};

const defaultProps = {
    patientInsurance: {},
    handleChangeProps: () => {},
    removeInsurancePlan: () => {},
    isReadOnly: false,
    editPatientInsurance: false,
    validationInsuranceErrors: {},
    validateInsurancePlan: () => {},
    toggleInsurancePlans: () => {},
    showInsurancePlans: false,
    disablePlan: () => {},
    disableInsurancePlan: false,
    handleDisablePlan: () => {},
    requiredFields: [],
    disabledFields: [],
};

function PatientInsuranceTabs(props) {
    const {
        patientInsurance,
        handleChangeProps,
        removeInsurancePlan,
        isReadOnly,
        editPatientInsurance,
        validationInsuranceErrors,
        validateInsurancePlan,
        toggleInsurancePlans,
        showInsurancePlans,
        disablePlan,
        disableInsurancePlan,
        handleDisablePlan,
        requiredFields,
        disabledFields,
    } = props;

    const insuranceObject = patientInsurance && Object.keys(patientInsurance).map(Number);
    const itemsEnable =
        insuranceObject &&
        insuranceObject.filter(
            (item) =>
                patientInsurance[item].disabledOn === null &&
                !isExpired(dayjs(patientInsurance[item].coverageEndDate).utc().format('YYYY-MM-DD 23:59:59'))
        );
    const patientInsuranceEnable =
        itemsEnable &&
        keyBy(
            itemsEnable.map((item) => patientInsurance[item]),
            'position'
        );
    const itemsDisable =
        insuranceObject &&
        insuranceObject.filter(
            (item) =>
                patientInsurance[item].disabledOn !== null ||
                isExpired(dayjs(patientInsurance[item].coverageEndDate).utc().format('YYYY-MM-DD 23:59:59'))
        );

    const [tabList, setTabList] = useState({ tabList: [], activeState: 1 });

    useEffect(() => {
        const temp = isReadOnly && disableInsurancePlan ? itemsDisable : itemsEnable;
        if (hasLength(insuranceObject)) {
            setTabList({ tabList: [...tabList.tabList, ...temp], activeState: temp[temp.length - 1] });
        }
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientInsurance]);

    // When toggle Show Disabled Plans checkbox, setting the active state in order to make the last item to be open

    function handleTogglePlans(disable) {
        if (disable) {
            setTabList({ tabList: insuranceObject, activeState: insuranceObject[insuranceObject.length - 1] });
        } else {
            setTabList({ tabList: itemsEnable, activeState: itemsEnable[itemsEnable.length - 1] });
        }
    }

    function handleChange() {
        setTabList({
            tabList: [
                ...tabList.tabList,
                tabList.tabList.length === 0 ? 1 : tabList.tabList[tabList.tabList.length - 1] + 1,
            ],
            activeState: tabList.tabList.length === 0 ? 1 : tabList.tabList[tabList.tabList.length - 1] + 1,
        });
    }

    const removePlan = (event, activeState) => {
        event.stopPropagation();
        const idx = tabList.tabList.indexOf(activeState);
        if (idx > -1) {
            setTabList({
                tabList: tabList.tabList.filter((value, i) => i !== idx),
                activeState:
                    tabList.tabList.length > 0
                        ? tabList.tabList.filter((value, i) => i !== idx)[
                              tabList.tabList.filter((value, i) => i !== idx).length - 1
                          ]
                        : 1,
            });
        }
        removeInsurancePlan(activeState);
    };

    return (
        <>
            <div className="tab-container">
                {isReadOnly ? (
                    <>
                        {/* TODO: Fix this the next time the file is edited. */}
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
                        <h3 className="plan-header show-insurance-pans" onClick={() => toggleInsurancePlans()}>
                            <i
                                className={showInsurancePlans ? 'fa fa-caret-down' : 'fa fa-caret-right'}
                                style={{ marginRight: '8px' }}
                            />
                            Insurance Plans
                        </h3>
                        <Form>
                            {/* TODO: Fix this the next time the file is edited. */}
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label className="disable-insurance">
                                <input
                                    type="checkbox"
                                    onChange={(event) => {
                                        const { checked } = event.target;
                                        handleDisablePlan(checked);
                                        handleTogglePlans(checked);
                                    }}
                                    style={{ marginRight: '5px' }}
                                    id="showDisablePlan"
                                    label="Show Disable Plans"
                                />
                                Show Disabled Insurance Plans
                            </label>
                        </Form>
                    </>
                ) : (
                    <>
                        <h2 className="plan-header"> Insurance Plans </h2>
                        <div>
                            <Button
                                className="btn btn-link"
                                onClick={() => {
                                    handleChange();
                                }}
                            >
                                <i className="fa fa-fw fa-plus" id="addInsuranceButton" /> Add Insurance Plan
                            </Button>
                        </div>
                    </>
                )}
            </div>
            {showInsurancePlans || !isReadOnly ? (
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {tabList.tabList.length === 0 ? (
                        <div className="row">
                            <div className="col-md-12">
                                <i className="fa fa-fw fa-info-circle" />
                                <span>There are no insurance plans attached to this patient.</span>
                            </div>
                        </div>
                    ) : (
                        <InsuranceTabs
                            tabList={tabList}
                            setTabList={setTabList}
                            isReadOnly={isReadOnly}
                            patientInsurance={patientInsurance}
                            handleChangeProps={handleChangeProps}
                            patientInsuranceEnable={patientInsuranceEnable}
                            validationInsuranceErrors={validationInsuranceErrors}
                            validateInsurancePlan={validateInsurancePlan}
                            editPatientInsurance={editPatientInsurance}
                            disablePlan={disablePlan}
                            disableInsurancePlan={disableInsurancePlan}
                            removeInsurancePlan={removeInsurancePlan}
                            removePlan={removePlan}
                            itemsEnable={itemsEnable}
                            requiredFields={requiredFields}
                            disabledFields={disabledFields}
                        />
                    )}
                </>
            ) : null}
        </>
    );
}

PatientInsuranceTabs.propTypes = propTypes;
PatientInsuranceTabs.defaultProps = defaultProps;

export default PatientInsuranceTabs;
