import { useQuery } from 'react-query';

import AssessmentService from 'App/services/AssessmentService';

function useFetchAssessmentQuestions(assessmentId, params) {
    return useQuery(
        ['assessmentQuestions', assessmentId],
        () => {
            return AssessmentService.getQuestions(assessmentId, params);
        },
        {
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useFetchAssessmentQuestions;
