import toast from 'Lib/toast';

/** @ngInject */
function QueueManagerController(_, $http, $q, queueManagerService) {
    const ctrl = this;

    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.addColumn = addColumn;
    ctrl.restoreDefault = restoreDefault;
    ctrl.saveFieldOrder = saveFieldOrder;
    ctrl.updateHeaderName = updateHeaderName;

    function $onChanges(changes) {
        if (changes.branches && ctrl.branches) {
            ctrl.branches = angular.copy(ctrl.branches);
            ctrl.menu = angular.copy(ctrl.branches);
            ctrl.selectedBranch = ctrl.branches[ctrl.user.active_branch.ID];
        }

        if (changes.user && changes.user.currentValue) {
            ctrl.user = angular.copy(ctrl.user);
        }
    }

    function $onInit() {
        ctrl.requiredFields = ['activity_name', 'activity_status'];
        ctrl.saveAll = false;
    }

    function addColumn() {
        const branch = ctrl.branches[ctrl.selectedBranch.branch.division_id];
        if (branch && branch.field_order && Array.isArray(branch.field_order) && branch.field_order.length < 10) {
            const column = angular.copy(branch.field_order[0]);
            column.field_order = branch.field_order.length + 1;
            branch.field_order.push(angular.copy(column));
        }
    }

    function restoreDefault(companyId) {
        ctrl.branches[ctrl.selectedBranch.branch.division_id].field_order = angular.copy([
            {
                name_of_header: 'Patient ID',
                field_selected: 'patient_id',
                field_order: 1,
            },
            {
                name_of_header: 'Patient Name',
                field_selected: 'patient_name',
                field_order: 2,
            },
            {
                name_of_header: 'Medication',
                field_selected: 'medication',
                field_order: 3,
            },
            {
                name_of_header: 'Activity Due Date',
                field_selected: 'activity_due_date',
                field_order: 4,
            },
            {
                name_of_header: 'Activity Name',
                field_selected: 'activity_name',
                field_order: 5,
                required: true,
            },
            {
                name_of_header: 'Patient DOB',
                field_selected: 'patient_dob',
                field_order: 6,
            },
            {
                name_of_header: 'Activity Status',
                field_selected: 'activity_status',
                field_order: 7,
                required: true,
            },
            {
                name_of_header: 'Last Updated',
                field_selected: 'last_updated',
                field_order: 8,
            },
        ]);

        return saveFieldOrder(companyId);
    }

    function saveFieldOrder(companyId) {
        ctrl.loading = true;
        const branch = ctrl.branches[ctrl.selectedBranch.branch.division_id];

        return queueManagerService
            .saveFieldOrder(companyId, {
                branch_id: branch.branch.division_id,
                field_order: branch.field_order,
                save_all: ctrl.saveAll,
            })
            .then((res) => {
                ctrl.branches = angular.copy(res);
                ctrl.menu = angular.copy(res);

                if (ctrl.saveAll) {
                    toast.success('The column order for all branches was saved successfully.');
                } else {
                    toast.success(`The column order for branch ${branch.branch.division_name} was saved successfully.`);
                }

                return res;
            })
            .finally(() => {
                ctrl.loading = false;
            });
    }

    function updateHeaderName(row) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line default-case
        switch (row.field_selected) {
            case 'patient_id':
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                row.name_of_header = 'Patient ID';
                break;
            case 'patient_last_name':
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                row.name_of_header = 'Last Name';
                break;
            case 'patient_first_name':
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                row.name_of_header = 'First Name';
                break;
            case 'medication':
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                row.name_of_header = 'Medication';
                break;
            case 'activity_due_date':
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                row.name_of_header = 'Activity Due Date';
                break;
            case 'activity_needs_by_date':
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                row.name_of_header = 'Activity Needs By Date';
                break;
            case 'activity_name':
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                row.name_of_header = 'Activity Name';
                break;
            case 'patient_dob':
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                row.name_of_header = 'Patient DOB';
                break;
            case 'activity_status':
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                row.name_of_header = 'Activity Status';
                break;
            case 'last_updated':
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                row.name_of_header = 'Last Update';
                break;
            case 'links':
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                row.name_of_header = 'Links';
                break;
            case 'md_id':
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                row.name_of_header = 'Prescriber NPI';
                break;
            case 'md_name':
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                row.name_of_header = 'Prescriber Name';
                break;
            case 'md_practice_name':
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                row.name_of_header = 'Prescriber Practice Name';
                break;
            case 'referral_stat':
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                row.name_of_header = 'Referral Status';
                break;
            case 'referral_stat_desc':
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                row.name_of_header = 'Referral Status Description';
                break;
            case 'referral_stat_date':
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                row.name_of_header = 'Referral Status Change Date';
                break;
            case 'assigned_to':
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                row.name_of_header = 'Assigned To';
                break;
            case 'patient_labels':
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                row.name_of_header = 'Patient Labels';
                break;
            case 'patient_state':
                // eslint-disable-next-line no-param-reassign
                row.name_of_header = 'Patient State';
                break;
            case 'branch_name':
                // eslint-disable-next-line no-param-reassign
                row.name_of_header = 'Branch Name';
                break;
            case 'insurance_plan_name':
                // eslint-disable-next-line no-param-reassign
                row.name_of_header = 'Insurance Plan Name';
                break;
            case 'patient_status':
                // eslint-disable-next-line no-param-reassign
                row.name_of_header = 'Patient Status';
                break;
            case 'activity_status_date':
                // eslint-disable-next-line no-param-reassign
                row.name_of_header = 'Activity Status Last Updated Date';
                break;
            case 'medication_association':
                // eslint-disable-next-line no-param-reassign
                row.name_of_header = 'Medication Association';
                break;
        }
    }
}

export default QueueManagerController;
