import * as R from 'ramda';
import React from 'react';

import toast from 'Lib/toast';

/** @ngInject */
function QuestionManagerController(
    __env,
    _,
    $q,
    $scope,
    $sce,
    $state,
    $uibModal,
    $window,
    idService,
    amsService,
    assessmentQuestionService,
    assessmentService,
    dateService,
    moment,
    jwtHelper,
    sessionStorageService,
    CSRF_ID_VALUE,
    CSRF_ID_NAME
) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.addAnswer = addAnswer;
    ctrl.addQuestion = addQuestion;
    ctrl.addRisk = addRisk;
    ctrl.addRiskRange = addRiskRange;
    ctrl.cdnURL = __env.cdnURL;
    ctrl.deleteAnswer = deleteAnswer;
    ctrl.deleteQuestion = deleteQuestion;
    ctrl.deleteRisk = deleteRisk;
    ctrl.deleteRiskRange = deleteRiskRange;
    ctrl.editRisk = editRisk;
    ctrl.getQuestionCanBeMobile = getQuestionCanBeMobile;
    ctrl.questionIsMobile = questionIsMobile;
    ctrl.loadChildren = loadChildren;
    ctrl.loadQuestions = loadQuestions;
    ctrl.presentQuestionDeleteWarningDialog = presentQuestionDeleteWarningDialog;
    ctrl.saveAssessment = saveAssessment;
    ctrl.saveRisk = saveRisk;
    ctrl.toggleNumericScoringRanges = toggleNumericScoringRanges;
    ctrl.toggleQuestionScoring = toggleQuestionScoring;
    ctrl.setQuestionHiddenStatus = setQuestionHiddenStatus;
    ctrl.setQuestionRequiredStatus = setQuestionRequiredStatus;
    ctrl.updateLink = updateLink;
    ctrl.updateChildrenForMobileQuestion = updateChildrenForMobileQuestion;
    ctrl.retrieveChildQuestions = retrieveChildQuestions;
    ctrl.validateQuestion = validateQuestion;
    ctrl.csrfIdParamName = CSRF_ID_NAME;
    ctrl.csrfIdParamValue = CSRF_ID_VALUE;

    function $onChanges(changes) {
        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
            ctrl.clinicalAdmin = ctrl.user.company_permissions.TherigyAssessmentAdmin;
        }
    }

    function $onInit() {
        ctrl.loadedAt = moment.utc().unix();
        ctrl.count = 0;
        ctrl.groupLabels = [];
        ctrl.groupId = $state.params.categoryId;
        ctrl.headerQuestions = [
            'assessment_status',
            'need_by_date',
            'therapy_start_date',
            'activity_date',
            'cost_savings',
            'therapy_summary_note',
        ];
        ctrl.linkOptions = {};
        ctrl.loading = true;
        ctrl.multipleChoiceQuestions = ['select', 'radio', 'checkbox'];
        ctrl.mobileQuestionTypes = ['select', 'radio', 'checkbox', 'date', 'textbox', 'number', 'textarea'];
        ctrl.parentAnswerId = $state.params.parentAnswerId || null;
        ctrl.parentQuestionId = $state.params.parentQuestionId || null;
        ctrl.poll = { name: 'Loading...' };
        ctrl.pollId = $state.params.pollId;
        ctrl.questionGroups = [];
        ctrl.riskEnabled = false;
        ctrl.riskOptions = [];
        ctrl.riskQuestions = {};

        const promises = {
            poll: assessmentService.getAssessment($state.params.categoryId, $state.params.pollId),
            groups: assessmentQuestionService.getQuestionGroups(),
            linkOptions: assessmentQuestionService.getQuestionLinkOptions(),
            questions: ctrl.loadQuestions(),
            riskOptions: assessmentService.getRiskOptions(ctrl.pollId),
        };

        if (ctrl.parentQuestionId) {
            promises.parentQuestion = assessmentQuestionService.getSingleQuestion(ctrl.parentQuestionId);
        }

        return $q
            .all(promises)
            .then((res) => {
                ctrl.poll = res.poll;
                ctrl.groupLabels = res.groups;
                ctrl.linkOptions = res.linkOptions;
                ctrl.riskOptions = _.filter(res.riskOptions, { deleted: null });
                ctrl.riskEnabled = _.size(ctrl.riskOptions) > 0;
                if (res.parentQuestion) {
                    ctrl.parentQuestion = res.parentQuestion;
                    ctrl.parentQuestionText = $sce.trustAsHtml(ctrl.parentQuestion.question);
                    if (ctrl.parentQuestion.answers[ctrl.parentAnswerId]) {
                        ctrl.parentAnswerText = $sce.trustAsHtml(
                            ctrl.parentQuestion.answers[ctrl.parentAnswerId].option
                        );
                    } else if (ctrl.parentQuestion.custom_answers[ctrl.parentAnswerId]) {
                        ctrl.parentAnswerText = $sce.trustAsHtml(
                            ctrl.parentQuestion.custom_answers[ctrl.parentAnswerId].option
                        );
                    }
                } else {
                    // eslint-disable-next-line no-multi-assign
                    ctrl.parentQuestion = ctrl.parentQuestionText = ctrl.parentAnswerText = null;
                }
            })
            .catch(() => {
                toast.error('There was an error loading the assessment.');
            })
            .finally(() => {
                ctrl.loading = false;
                // Check the history for a scroll position on this page.
                angular.element(document).ready(() => {
                    let scroll;
                    if ($window.history.state !== null && $window.history.state.parentQuestionId) {
                        scroll = angular
                            .element(`#question-${$window.history.state.parentQuestionId}`)
                            .prop('offsetTop');
                    } else {
                        scroll = angular.element('.main-container').prop('offsetTop');
                    }
                    angular.element('html,body').animate(
                        {
                            scrollTop: scroll,
                        },
                        0
                    );
                });
            });
    }

    function loadChildren(parentAnswerId, parentQuestionId) {
        // Set the scroll position so that when the back button is pressed the window will scroll to the parent.
        $window.history.replaceState(
            { path: $window.location.href, parentQuestionId },
            'Therigy | Improving therapy, enhancing lives'
        );

        $state.go('app.assessmentChildQuestions', {
            categoryId: ctrl.groupId,
            parentAnswerId,
            parentQuestionId,
            pollId: ctrl.pollId,
        });
    }

    function loadQuestions() {
        ctrl.loading = true;
        // Always create the default group. It needs to exist to allow new questions to be added.
        ctrl.questionGroups = [{ groupId: 0, questions: {} }];
        const promises = {
            links: assessmentQuestionService.getQuestionLinks(ctrl.pollId),
            questions: assessmentQuestionService.getQuestions(ctrl.pollId, ctrl.parentAnswerId),
        };
        let lastGroup = 0;
        let index = 0;
        return $q
            .all(promises)
            .then((res) => {
                _.each(res.questions, (question, id) => {
                    // Populate the answers for each question
                    if (question.answers) {
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line no-shadow
                        _.each(question.answers, (answer, id) => {
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line no-param-reassign
                            answer.id = id;
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line no-param-reassign
                            answer.position = _.toInteger(answer.position);
                        });
                        const answers = _.sortBy(question.answers, ['position']);
                        const sortedAnswers = {};
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line no-plusplus
                        for (let i = 0, len = answers.length; i < len; i++) {
                            sortedAnswers[answers[i].id] = answers[i];
                        }
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line no-param-reassign
                        question.answers = sortedAnswers;
                    }
                    // For some questions group id is in string so converting it to number
                    if (question.attributes.group_id && !Number.isNaN(question.attributes.group_id)) {
                        // eslint-disable-next-line no-param-reassign
                        question.attributes.group_id = parseInt(question.attributes.group_id, 10);
                    }
                    // Skip header questions, they cannot be edited from this component.
                    if (ctrl.headerQuestions.indexOf(question.name) === -1) {
                        if (question.official_question_id) {
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line no-param-reassign
                            question.link = res.links[question.official_question_id];
                        } else {
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line no-param-reassign
                            question.link = res.links[question.question_id];
                        }

                        // Set link type to default to none.
                        if (!question.link) {
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line no-param-reassign
                            question.link = { read_tag_selected: 0 };
                        }
                        // changing read_tag_selected to string
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line no-param-reassign
                        question.link.read_tag_selected = _.toString(question.link.read_tag_selected);

                        const groupId = !question.group ? 0 : question.group.id;
                        if (groupId !== lastGroup) {
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line no-plusplus
                            index++;
                            lastGroup = groupId;
                        }
                        if (!ctrl.questionGroups[index]) {
                            ctrl.questionGroups[index] = {
                                name: !question.group ? null : question.group.name,
                                groupId,
                                questions: {},
                            };
                        }
                        ctrl.questionGroups[index].questions[id] = question;
                        ctrl.questionGroups[index].questions = _(ctrl.questionGroups[index].questions)
                            .toPairs()
                            .sortBy(([, value]) => parseInt(value.attributes.position, 10))
                            .fromPairs()
                            .value();
                    }
                });
            })
            .finally(() => {
                ctrl.loading = false;
            });
    }

    function presentQuestionDeleteWarningDialog(numberOfQuestionsImpacted) {
        // Present the warning modal to the user and return the modal instance
        return $uibModal.open({
            component: 'questionDeleteWarningModal',
            size: 'md',
            backdrop: 'static',
            resolve: {
                modalData() {
                    return {
                        numberOfQuestionsToRemove: numberOfQuestionsImpacted,
                    };
                },
            },
        });
    }

    function addAnswer(groupIndex, questionId) {
        return idService.createId().then((newId) => {
            if (typeof ctrl.questionGroups[groupIndex].questions[questionId].answers !== 'object') {
                ctrl.questionGroups[groupIndex].questions[questionId].answers = {};
            }
            const maxPosition = _.maxBy(
                _.values(ctrl.questionGroups[groupIndex].questions[questionId].answers),
                'position'
            );
            const position = maxPosition ? _.toInteger(maxPosition.position) + 1 : 1;
            ctrl.questionGroups[groupIndex].questions[questionId].answers[newId] = {
                new: true,
                position,
            };
        });
    }

    function addQuestion(type) {
        const ids = {
            answerId: idService.createId(),
            questionId: idService.createId(),
        };
        let parentQuestionId = null;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-shadow
        return $q.all(ids).then((ids) => {
            const question = {};
            if (ctrl.parentQuestion) {
                parentQuestionId = ctrl.parentQuestion.question_id;
                if (ctrl.parentQuestion.official_question_id) {
                    parentQuestionId = ctrl.parentQuestion.official_question_id;
                }
            } else {
                parentQuestionId = null;
            }

            const answers = {};
            if (ctrl.multipleChoiceQuestions.indexOf(type) === -1) {
                // Non-multiple choice questions get a placeholder answer.
                answers[ids.answerId] = {
                    id: ids.answerId,
                    option: 'textbox',
                    new: true,
                };
            }

            const maxPosition = _.maxBy(_.values(ctrl.questionGroups[0].questions), 'attributes.position');
            const defaultPosition = maxPosition ? _.toInteger(maxPosition.attributes.position) + 1 : 1;

            question[ids.questionId] = {
                answers,
                attributes: {
                    position: defaultPosition,
                    is_mobile: false,
                },
                can_delete: true,
                group: null,
                link: { read_tag_selected: '0' },
                new: true,
                parent_answer_id: ctrl.parentAnswerId,
                parent_question_id: parentQuestionId,
                question_id: ids.questionId,
                type,
            };
            ctrl.questionGroups[0].questions = _.extend(question, ctrl.questionGroups[0].questions);
        });
    }

    function addRisk() {
        ctrl.riskToEdit = {
            is_new: true,
            ranges: [{}],
        };
    }

    function addRiskRange() {
        ctrl.riskToEdit.ranges.push({});
    }

    function deleteAnswer(groupIndex, questionId, answerId) {
        if (ctrl.questionGroups[groupIndex].questions[questionId].answers[answerId].new) {
            delete ctrl.questionGroups[groupIndex].questions[questionId].answers[answerId];
        } else if (
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-restricted-globals,no-alert
            confirm(
                'Are you sure you want to delete this answer?\r\nUser responses will be unlinked and sub-questions will also be deleted.'
            )
        ) {
            delete ctrl.questionGroups[groupIndex].questions[questionId].answers[answerId];
        }
    }

    function deleteQuestion(groupIndex, questionId) {
        if (
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-restricted-globals,no-alert
            confirm(
                'Are you sure you want to delete this question?\r\nUser responses will be unlinked and sub-questions will also be deleted.'
            )
        ) {
            ctrl.questionGroups[groupIndex].questions[questionId].deleted = true;
        }
    }

    function deleteRisk(riskId) {
        ctrl.loading = true;
        return assessmentService
            .deleteRisk(ctrl.pollId, riskId)
            .then(() => {
                ctrl.riskToEdit = null;
                assessmentService
                    .getRiskOptions(ctrl.pollId)
                    .then((res) => {
                        ctrl.riskOptions = res;
                    })
                    .finally(() => {
                        ctrl.loading = false;
                    });
            })
            .finally(() => {
                ctrl.loading = false;
            });
    }

    function deleteRiskRange(index) {
        ctrl.riskToEdit.ranges.splice(index, 1);
    }

    function editRisk(riskId) {
        ctrl.loading = true;
        return assessmentService
            .getRiskDetails(ctrl.pollId, riskId)
            .then((res) => {
                ctrl.riskToEdit = res;
            })
            .finally(() => {
                ctrl.loading = false;
            });
    }

    function getQuestionCanBeMobile(question) {
        // Determines if the given question is allowed to be made a mobile question
        const isMobileQuestionType = ctrl.mobileQuestionTypes.indexOf(question.type) > -1;
        if (ctrl.parentQuestion) {
            // If there is a parent question, the parent question must be mobile
            return questionIsMobile(ctrl.parentQuestion) && isMobileQuestionType;
        }
        return isMobileQuestionType;
    }

    function questionIsMobile(question) {
        return question.attributes.is_mobile === true;
    }

    function saveRisk() {
        ctrl.loading = true;
        ctrl.questionGroups = [];
        return assessmentService
            .saveRisk(ctrl.pollId, ctrl.riskToEdit)
            .then(() => {
                ctrl.riskToEdit = null;
                assessmentService.getRiskOptions(ctrl.pollId).then((res) => {
                    ctrl.riskOptions = res;
                });
            })
            .finally(() => {
                ctrl.loadQuestions();
            });
    }

    function setQuestionHiddenStatus(question) {
        // Tie the hidden status to the required status.
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        question.attributes.hidden = !question.attributes.required && question.attributes.hidden;
    }

    function setQuestionRequiredStatus(question) {
        // Tie the hidden status to the required status.
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-param-reassign
        question.attributes.required = !question.attributes.hidden && question.attributes.required;
    }

    function toggleQuestionScoring(question, scoringEnabled) {
        if (scoringEnabled) {
            if (question.type === 'number') {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                question.attributes.numericRiskType = 'enteredValue';
            }
        } else {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            delete question.attributes.risk_config;
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            delete question.attributes.risk_weight;
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            delete question.attributes.numericRiskType;
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            delete question.attributes.numericRiskRanges;
        }
    }

    function toggleNumericScoringRanges(attributes) {
        if (attributes.numericRiskType === 'ranges') {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            attributes.numericRiskRanges = [{}];
        } else {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            delete attributes.numericRiskRanges;
        }
    }

    function saveAssessment() {
        ctrl.loading = true;

        const questions = {};
        const errQuestions = [];
        _.each(ctrl.questionGroups, (group) => {
            _.each(group.questions, (question, questionId) => {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                delete question.error;
                if (question.deleted || ctrl.validateQuestion(question) === true) {
                    questions[questionId] = question;
                } else {
                    errQuestions.push(questionId);
                }
            });
        });

        // Focus on errors
        if (_.size(errQuestions)) {
            toast.error('There were errors validating your questions.');
            angular.element('html,body').animate(
                {
                    scrollTop: angular.element(`#question_${errQuestions[0]}`).prop('offsetTop'),
                },
                500
            );
            ctrl.loading = false;
            return;
        }
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line consistent-return
        return amsService.getAssessment(ctrl.pollId).then((res) => {
            if (ctrl.clinicalAdmin && moment.utc(res.publishedOn).unix() > ctrl.loadedAt) {
                toast.error(
                    'This assessment has been published since you loaded the page. ' +
                        'You will need to reload the assessment before making further changes.'
                );
                ctrl.loading = false;
                return $q.when();
            }
            // Submit changes
            return assessmentQuestionService
                .saveQuestions(ctrl.pollId, questions)
                .then(() => {
                    toast.success('Assessment updated successfully.');
                    if (ctrl.parentQuestion) {
                        $state.go('app.assessmentChildQuestions', {
                            categoryId: ctrl.groupId,
                            parentAnswerId: ctrl.parentQuestion.parent_answer_id,
                            parentQuestionId: ctrl.parentQuestion.parent_question_id,
                            pollId: ctrl.pollId,
                        });
                    } else if ($state.current && $state.current.name === 'app.assessmentQuestions') {
                        // If we are already at the parent state just reload questions after saving.
                        ctrl.loadQuestions();
                    } else {
                        $state.go('app.assessmentQuestions', {
                            categoryId: ctrl.groupId,
                            pollId: ctrl.pollId,
                        });
                    }
                })
                .catch((err) => {
                    const errorMessage =
                        err.status === 403 && err.headers()['content-type'] === 'text/html' ? (
                            <div>
                                You’ve encountered a security block. Please see{' '}
                                <a
                                    href="https://tkn-cdn.therigy.com/therigystm/gettingstarted/resources/TherigySTM_Resource_403_Errors.pdf"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    403 Resource Document
                                </a>{' '}
                                for troubleshooting.
                            </div>
                        ) : (
                            err.data.message
                        );
                    toast.error(errorMessage);
                })
                .finally(() => {
                    ctrl.loading = false;
                });
        });
    }

    function updateLink(link) {
        if (link.read_tag_selected === '2' && link.read_tag === '') {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            link.read_tag = link.write_tag;
        } else if (link.read_tag_selected !== '2') {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            link.read_tag = '';
        }
    }

    function updateChildrenForMobileQuestion(question) {
        // If the question is transitioning from a mobile question to a non-mobile question, and it has a "created_on"
        //  value indicating that it has been saved, check if the change will impact any child questions.
        if (!questionIsMobile(question) && question.created_on) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            question.loadingMobileChildren = true;
            const { children } = question;
            if (children) {
                const childPromises = _.map(children, (value, key) => {
                    return assessmentQuestionService.getQuestions(ctrl.pollId, key);
                });
                $q.all(childPromises)
                    .then((childObjects) => {
                        // Check if any children are mobile-enabled
                        return _.find(_.flatMapDeep(childObjects, _.values), (childQuestion) => {
                            return questionIsMobile(childQuestion);
                        });
                    })
                    .then((mobileChild) => {
                        // If there are any mobile children, calculate the total number of mobile children and
                        //    present the user with a dialog.
                        if (mobileChild) {
                            // Recursively retrieve the child questions to determine how many are mobile-enabled
                            retrieveChildQuestions(question).then((childQuestions) => {
                                const mobileChildren = _.filter(
                                    _.without(_.flattenDeep(childQuestions), []),
                                    (childQuestion) => {
                                        return questionIsMobile(childQuestion);
                                    }
                                );
                                const modalInstance = ctrl.presentQuestionDeleteWarningDialog(mobileChildren.length);
                                modalInstance.result.then((shouldDisableChildren) => {
                                    if (shouldDisableChildren) {
                                        // Add the parent question to the array of questions to save so that its
                                        // "is_mobile" field will be updated as well.
                                        const updatedQuestions = _.map(
                                            _.concat(mobileChildren, question),
                                            (questionToUpdate) => {
                                                // TODO: Fix this the next time the file is edited.
                                                // eslint-disable-next-line no-param-reassign
                                                questionToUpdate.attributes.is_mobile = false;
                                                return questionToUpdate;
                                            }
                                        );
                                        // Save the updated questions
                                        assessmentQuestionService
                                            .saveQuestions(ctrl.pollId, updatedQuestions)
                                            .then(() => {
                                                toast.success('Sub-level questions updated successfully.');
                                            })
                                            .catch((err) => {
                                                toast.error(err.data.message);
                                            })
                                            .finally(() => {
                                                // TODO: Fix this the next time the file is edited.
                                                // eslint-disable-next-line no-param-reassign
                                                question.loadingMobileChildren = false;
                                            });
                                    } else {
                                        // Reset the is_mobile value for the given question to true
                                        // TODO: Fix this the next time the file is edited.
                                        // eslint-disable-next-line no-param-reassign
                                        question.attributes.is_mobile = true;
                                        // TODO: Fix this the next time the file is edited.
                                        // eslint-disable-next-line no-param-reassign
                                        question.loadingMobileChildren = false;
                                    }
                                });
                            });
                        } else {
                            // TODO: Fix this the next time the file is edited.
                            // eslint-disable-next-line no-param-reassign
                            question.loadingMobileChildren = false;
                        }
                    });
            }
        }
    }

    function retrieveChildQuestions(question) {
        // Recursively retrieve the child questions for the parent question provided
        const childPromises = _.map(question.children, (value, key) => {
            return assessmentQuestionService.getQuestions(ctrl.pollId, key);
        });
        return $q.all(childPromises).then((rawChildQuestions) => {
            const childQuestions = _.flatMapDeep(rawChildQuestions, _.values);
            const recursivePromises = _.map(childQuestions, (child) => {
                return retrieveChildQuestions(child);
            });
            return $q.all(recursivePromises).then((aggregatedResult) => {
                return _.concat(aggregatedResult, childQuestions);
            });
        });
    }

    function validateNumericScoringRanges(question) {
        if (question.attributes.numericRiskRanges) {
            _.each(question.attributes.numericRiskRanges, (range, index) => {
                if (!_.isNumber(range.start) || !_.isNumber(range.end) || !_.isNumber(range.value)) {
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line no-param-reassign
                    question.error = 'All scoring range values must be numeric.';
                }
                if (range.start > range.end) {
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line no-param-reassign
                    question.error = 'Numeric scoring range start value must be less than or equal the end value.';
                }

                const overlap = _.find(question.attributes.numericRiskRanges, (checkRange, checkIndex) => {
                    if (checkIndex === index) {
                        return false;
                    }
                    return (
                        (range.start >= checkRange.start && range.start <= checkRange.end) ||
                        (range.end >= checkRange.start && range.end <= checkRange.end)
                    );
                });

                if (overlap) {
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line no-param-reassign
                    question.error = 'Numeric scoring ranges cannot overlap.';
                }
            });
        }
        if (question.error) {
            return false;
        }
        return true;
    }

    /**
     * Don't allow All days to be checked. At least one day must be available.
     * @param blockedDays
     * @returns {boolean}
     */
    function validateBlockedDays(blockedDays) {
        return !R.allPass([
            R.prop('Sunday'),
            R.prop('Monday'),
            R.prop('Tuesday'),
            R.prop('Wednesday'),
            R.prop('Thursday'),
            R.prop('Friday'),
            R.prop('Saturday'),
        ])(blockedDays);
    }

    function validateQuestion(question) {
        if (question.attributes.numericRiskRanges) {
            if (!validateNumericScoringRanges(question)) {
                return false;
            }
        }

        if (question.attributes.blockedDays) {
            if (!validateBlockedDays(question.attributes.blockedDays)) {
                // eslint-disable-next-line no-param-reassign
                question.error = 'Cannot block all days. You must allow for at least one day to be selected.';
                return false;
            }
        }

        if (ctrl.riskQuestions[question.officialQuestionId || question.question_id]) {
            if (!question.attributes.risk_config) {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                question.error = 'You must select a scoring type if "Question Scoring" is enabled for this question.';
                return false;
            }
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-restricted-globals
            if (isNaN(question.attributes.risk_weight) || !_.isNumber(parseFloat(question.attributes.risk_weight))) {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                question.error = '"Scoring Weight" must be a numeric value.';
                return false;
            }
        }

        if (ctrl.multipleChoiceQuestions.indexOf(question.type) !== -1) {
            // Require all answers to have options and, if mobile, mobile options
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line consistent-return
            _.each(question.answers, (answer) => {
                if (_.isEmpty(answer.option)) {
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line no-param-reassign
                    question.error = `${_.startCase(question.type)} questions must have answer options.`;
                    return false;
                }
                if (questionIsMobile(question) && _.isEmpty(answer.mobile_option)) {
                    if (!ctrl.clinicalAdmin && !question.can_delete) {
                        return true;
                    }
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line no-param-reassign
                    question.error = 'Please provide mobile answer options.';
                    return false;
                }
            });
            // Check if any errors were generated when checking the answer options
            if (!_.isEmpty(question.error)) {
                return false;
            }
            if (!question.answers || _.size(question.answers) === 0) {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line no-param-reassign
                question.error = `${_.startCase(question.type)} questions must have answer options.`;
                return false;
            }
        }
        if (_.isEmpty(question.question)) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            question.error = 'Please provide question text.';
            return false;
        }
        if (questionIsMobile(question) && _.isEmpty(question.mobile_question)) {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-param-reassign
            question.error = 'Please provide mobile question text.';
            return false;
        }
        return true;
    }
}

export default QuestionManagerController;
