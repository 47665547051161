import { useQuery } from 'react-query';
import { getAppealDocuments } from 'App/services/RMSService';

function useFetchAppealDocumentsQuery(patientId, referralId, activityId) {
    return useQuery(
        ['patient', patientId, 'referral', referralId, 'activity', activityId, 'appealDocuments'],
        () => {
            return getAppealDocuments(patientId, referralId, activityId);
        },
        {
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
}

export default useFetchAppealDocumentsQuery;
