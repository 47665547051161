import React, { useState } from 'react';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import styled from '@emotion/styled';
import Col from 'react-bootstrap/lib/Col';
import * as R from 'ramda';
import { max } from 'lodash';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';

const propTypes = {
    isDisabled: PropTypes.bool,
    handleSelectOptions: PropTypes.func,
    selectOption: PropTypes.string,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    insuranceKeys: PropTypes.array,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    insuranceToAttach: PropTypes.object,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    tabList: PropTypes.object,
    setTabList: PropTypes.func,
};
const defaultProps = {
    isDisabled: false,
    handleSelectOptions: () => {},
    selectOption: '',
    insuranceKeys: [],
    insuranceToAttach: {},
    tabList: {},
    setTabList: () => {},
};

const PaddedCol = styled(Col)`
    padding-bottom: 20px;
`;

function AttachInsurance(props) {
    const { isDisabled, handleSelectOptions, selectOption, insuranceKeys, insuranceToAttach, setTabList, tabList } =
        props;
    const [count, setCount] = useState(1);
    const [selectValue, setSelectValue] = useState(selectOption);
    const insuranceToAttachKeys = insuranceToAttach ? Object.keys(insuranceToAttach).map(Number) : [];

    function handleChange(value) {
        setSelectValue('Select');
        const insuranceAllKeys = R.concat(insuranceKeys, insuranceToAttachKeys);
        if (value.toLowerCase() === '+add new plan') {
            const getAllKey = insuranceAllKeys.length ? max(insuranceAllKeys) + count + 1 : count;
            setTabList({ tabList: [...tabList.tabList, getAllKey], activeState: getAllKey });
            setCount(count + 1);
        } else {
            const finalItem = insuranceToAttachKeys.filter((item) => value === insuranceToAttach[item].name);
            setTabList({ tabList: [finalItem[0]], activeState: finalItem[0] });
        }
    }

    return (
        <Row>
            <PaddedCol lg={3} md={4} sm={12}>
                <FormGroup>
                    <ControlLabel htmlFor="attachInsurance">Attach Insurance Plan(s)</ControlLabel>
                    <select
                        className="form-control insurance-field"
                        id="attachInsurance"
                        name="attachInsurance"
                        disabled={isDisabled}
                        onChange={(event) => {
                            const selected = event.target.value;
                            handleSelectOptions(selected);
                            handleChange(selected);
                        }}
                        value={selectValue}
                    >
                        <option id="select">Select</option>
                        <option data-testid="Add New Plan" id="+Add New Plan" value="+Add New Plan">
                            +Add New Plan
                        </option>
                        {insuranceToAttachKeys.length &&
                            insuranceToAttachKeys.map((item) => {
                                return (
                                    <option
                                        key={insuranceToAttach[item].id}
                                        id={insuranceToAttach[item].id}
                                        value={insuranceToAttach[item].name}
                                    >
                                        {insuranceToAttach[item].name}
                                    </option>
                                );
                            })}
                    </select>
                </FormGroup>
            </PaddedCol>
        </Row>
    );
}

AttachInsurance.propTypes = propTypes;
AttachInsurance.defaultProps = defaultProps;

export default AttachInsurance;
