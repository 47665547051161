import * as R from 'ramda';
import React, { useMemo } from 'react';
import Page from 'App/components/Page';
import { PERMISSION_TYPES } from 'App/utils/isPermitted';
import useFetchPatient from 'App/hooks/useFetchPatient';
import { useParams } from 'react-router-dom';
import useFetchAppealQuery from 'App/hooks/useFetchAppealQuery';
import useFetchAppealDocumentsQuery from 'App/hooks/useFetchAppealDocumentsQuery';
import useFetchPriorAuthsAndRenewals from './useFetchPriorAuthsAndRenewals';
import Appeal from './Appeal';
import {
    formatInoundAppealActivity,
    formatPriorAuthForAppealOptions,
} from 'App/features/ReferralManager/Referral/referral.utils';

const propTypes = {};

const defaultProps = {};

function AppealPage() {
    const { patientId, referralId, activityId } = useParams();

    const { isLoading: isLoadingPatient, isError, data: patient } = useFetchPatient(patientId);

    const { isLoading: isLoadingActivity, data: activityData } = useFetchAppealQuery(patientId, referralId, activityId);

    const { isLoading: isLoadingAppealDocs, data: appealDocs } = useFetchAppealDocumentsQuery(
        patientId,
        referralId,
        activityId
    );

    const { isLoading: isLoadingPAActivites, data: paActivities } = useFetchPriorAuthsAndRenewals(
        patientId,
        referralId
    );

    const priorAuthsOptions = useMemo(() => {
        return formatPriorAuthForAppealOptions(paActivities);
    }, [paActivities]);

    const activity = useMemo(() => {
        return formatInoundAppealActivity(activityData, appealDocs, priorAuthsOptions);
    }, [activityData, appealDocs, priorAuthsOptions]);

    //prettier-ignore
    const isLoading = R.allPass([
        R.anyPass([
            R.always(isLoadingPatient),
            R.always(isLoadingActivity),
            R.always(isLoadingAppealDocs),
            R.always(isLoadingPAActivites),
            R.always(!patient?.id),
        ]),
        R.always(!isError),
    ])();

    return (
        <Page
            title="Appeal"
            showPageTitle
            permissionType={PERMISSION_TYPES.ENABLE_REACT_REFERRAL_APPEAL}
            showNotFound={isError}
            isLoading={isLoading}
        >
            {isLoading || isError ? null : (
                <Appeal
                    patient={patient}
                    activity={activity}
                    referralId={referralId}
                    priorAuthsOptions={priorAuthsOptions}
                />
            )}
        </Page>
    );
}

AppealPage.propTypes = propTypes;
AppealPage.defaultProps = defaultProps;

export default AppealPage;
