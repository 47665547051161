import * as R from 'ramda';
import { viewModes } from 'App/components/Assessments/constants';

/**
 * Gets the view mobile options.
 * @param {boolean} [isPreview]
 * @returns {Object[]}
 */
function getViewModeOptions(isPreview = false) {
    return R.compose(
        R.map(
            R.applySpec({
                value: R.prop(0),
                label: R.prop(1),
            })
        ),
        R.toPairs,
        R.ifElse(R.always(isPreview), R.identity, R.omit(['VIEW_MOBILE']))
    )(viewModes);
}

export default getViewModeOptions;
