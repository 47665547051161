import * as R from 'ramda';

/** @ngInject */
function ReferralAppealActivityConfig($stateProvider) {
    $stateProvider.state({
        name: 'app.referralActivityAppeal',
        url: '/patient/:patientId/referral/:referralId/appeal/:activityId/type/:typeId',
        views: {
            '': 'referralActivityAppeal',
            'pageTitle@^': {
                template: 'Appeal Activity',
            },
        },
        resolve: {
            activity($transition$, rmsService) {
                const params = $transition$.params();
                return rmsService
                    .getActivityByType(params.patientId, params.referralId, params.activityId, params.typeId)
                    .catch(() => {
                        return false;
                    });
            },
            activities($transition$, rmsService) {
                const params = $transition$.params();

                return Promise.all([
                    rmsService.getActivities(params.patientId, params.referralId, {
                        type: '1', // Prior Authorization
                    }),
                    rmsService.getActivities(params.patientId, params.referralId, {
                        type: '4', // Prior Authorization Renewal
                    }),
                ]).then(([pa, renewals]) => {
                    return R.compose(
                        R.filter(
                            //prettier-ignore
                            R.anyPass([
                                R.propSatisfies(R.includes('Appealed'), 'status'),
                                R.propEq('Denied', 'status')
                            ])
                        ),
                        R.concat(pa)
                    )(renewals);
                });
            },
            appealDocuments($transition$, rmsService) {
                const params = $transition$.params();
                return rmsService.getAppealDocuments(params.patientId, params.referralId, params.activityId);
            },
            patient($transition$, patientService) {
                const params = $transition$.params();
                return patientService.get(params.patientId).catch(() => {
                    return false;
                });
            },
        },
    });
}

export default ReferralAppealActivityConfig;
