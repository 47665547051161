export const STATUS_TYPE = {
    new: 'New',
    pendingSubmission: 'Pending Submission',
    submitted: 'Submitted',
    partiallyApproved: 'Partially Approved',
    approved: 'Approved',
    deleted: 'Deleted',
    denied: 'Denied',
};

export const BOOLEAN_TYPE = {
    yes: 'Yes',
    no: 'No',
};

export const disableActivityStatusList = [
    STATUS_TYPE.approved,
    STATUS_TYPE.partiallyApproved,
    STATUS_TYPE.denied,
    STATUS_TYPE.deleted,
];

export const FOLLOW_UP_SCHEDULE_OPTION = {
    daysPrior: '14 Days Prior to Effective End Date',
    daysAfter: '30 Days After Completion Date',
    specificDate: 'Specific Date',
};

export const followUpScheduleOption = [
    FOLLOW_UP_SCHEDULE_OPTION.daysPrior,
    FOLLOW_UP_SCHEDULE_OPTION.daysAfter,
    FOLLOW_UP_SCHEDULE_OPTION.specificDate,
];

export const booleanOptions = [BOOLEAN_TYPE.no, BOOLEAN_TYPE.yes];

export const faSourceOptions = [
    'Clinical Trial Programs (HIV, HEP-C, Oncology)',
    'PAN Foundation (Patient Access Network)',
    'Johnson & Johnson Foundation – Copay Assistance',
    'Novartis Patient Assistance Foundation - Assistance',
    '(PAF) Patient Advocate Foundation – Copay Assistance',
    'Other',
];

export const denialReasonOptions = ['Insufficient information submitted', 'Eligibility criteria not met'];
