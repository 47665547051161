import CompanyService from '../../../services/CompanyService';
import { errorHandler } from '../../../utils';
import toast from 'Lib/toast';

/** @ngInject */
function AssessmentEditController($scope, $state, assessmentService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.updateAssessment = updateAssessment;
    ctrl.selectEhrNote = selectEhrNote;
    ctrl.isLoading = true;

    function $onChanges(changes) {
        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
        }
        if (changes.categories && ctrl.categories) {
            ctrl.categories = angular.copy(ctrl.categories);
        }
    }

    function $onInit() {
        const { categoryId } = $state.params;
        const { pollId } = $state.params;

        if (!pollId) {
            $state.go('app.assessmentManager.create', {
                categoryId,
            });
        }

        return Promise.all([
            CompanyService.getTherapyCategories().then((categories) => {
                ctrl.categories = categories;
            }),
            CompanyService.getBranchSettings('ehrType', '', false).then((ehrProvider) => {
                ctrl.ehrProvider = ehrProvider;
            }),
            assessmentService
                .getAssessment(categoryId, pollId)
                .then((res) => {
                    res.category_id = categoryId;
                    res.poll_id = pollId;
                    ctrl.assessment = res;
                    return res;
                })
                .catch((err) => {
                    let error = 'The assessment was not found or you do not have permission to view it.';
                    if ((err.status === 403 || err.status === 404) && err.data && err.data.message) {
                        error = err.data.message;
                    }
                    return error;
                }),
        ])
            .catch((error) => {
                errorHandler(error);
            })
            .finally(() => {
                ctrl.isLoading = false;
            });
    }

    function updateAssessment(categoryId, pollId, assessment) {
        ctrl.busy = true;

        return assessmentService
            .updateAssessment(categoryId, pollId, assessment)
            .then((res) => {
                toast.success(res.data.message);

                $scope.$emit('refreshAssessments', {
                    categoryId,
                });

                $state.go('app.assessmentManager', {
                    categoryId,
                    refreshAssessments: true,
                });

                return res;
            })
            .catch((err) => {
                toast.error(err.data.message);
                return err.data;
            })
            .finally(() => {
                ctrl.busy = false;
            });
    }

    function selectEhrNote() {
        if (ctrl.assessment.ehr_encounter_note === true) {
            ctrl.assessment.enhanced_summary_note = true;
            ctrl.assessment.require_medication_association = true;
        }
    }
}

export default AssessmentEditController;
