import styled from '@emotion/styled';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import SelectField from 'Lib/form/SelectField';
import { TextInput } from 'Lib/form/TextField';

const StyledTypeaheadTextInput = styled(TextInput)`
    border: 0;
    padding: 4px;
    height: auto;

    &&& {
        box-shadow: none;
    }
`;

const TypeaheadTextInput = ({ id, value, onChange, onBlur, onFocus, selectProps: { placeholder } }) => {
    return (
        <StyledTypeaheadTextInput
            name={id}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            placeholder={placeholder}
        />
    );
};
TypeaheadTextInput.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    selectProps: PropTypes.shape({
        placeholder: PropTypes.string.isRequired,
    }).isRequired,
};

const PatientFormTypeaheadField = ({ fieldName, options, isLoading, ...rest }) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const { values, setFieldValue } = useFormikContext();
    const fieldValue = get(values, fieldName);
    const optionsHasLength = options.length > 0;

    return (
        <SelectField
            {...rest}
            fieldName={fieldName}
            value={{
                label: fieldValue,
                value: fieldValue,
            }}
            inputValue={fieldValue}
            isLoading={isLoading}
            options={options}
            menuIsOpen={menuIsOpen && optionsHasLength}
            components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                Input: TypeaheadTextInput,
            }}
            onChange={(newValue) => {
                setFieldValue(fieldName, newValue.value);
            }}
            onInputChange={(newInputValue, actionMeta) => {
                if (actionMeta.action === 'input-change') {
                    setMenuIsOpen(true);
                    setFieldValue(fieldName, newInputValue);
                }
                if (actionMeta.action === 'input-blur') {
                    setMenuIsOpen(false);
                }
                if (actionMeta.action === 'menu-close') {
                    setMenuIsOpen(false);
                }
            }}
        />
    );
};

PatientFormTypeaheadField.propTypes = {
    fieldName: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default PatientFormTypeaheadField;
