import PatientInsuranceFormField from './PatientInsuranceFormField';
import * as R from 'ramda';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import MoneyInput from './MoneyInput';
import PhoneInput from './PhoneInput';
import Form from 'react-bootstrap/lib/Form';
import React from 'react';
import { useInsuranceContext } from '../../contexts/InsuranceContext';
import PropTypes from 'prop-types';
import { DatePickerInput } from 'Lib/form/DatePickerField';
import dayjs from 'dayjs';
import { cx } from '@emotion/css';

const propTypes = {
    item: PropTypes.number,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    patientInsurance: PropTypes.object,
    onChangeText: PropTypes.func,
    editPatientInsurance: PropTypes.bool,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    validationInsuranceErrors: PropTypes.object,
    validateInsurancePlan: PropTypes.func,
    disablePlan: PropTypes.func,
    isBiDisabled: PropTypes.bool,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    requiredFields: PropTypes.array,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/forbid-prop-types
    disabledFields: PropTypes.array,
};

const defaultProps = {
    item: 0,
    patientInsurance: {},
    onChangeText: () => {},
    editPatientInsurance: false,
    validationInsuranceErrors: {},
    validateInsurancePlan: () => {},
    disablePlan: () => {},
    isBiDisabled: false,
    requiredFields: [],
    disabledFields: [],
};

function PatientInsuranceMain(props) {
    const {
        item,
        patientInsurance,
        onChangeText,
        editPatientInsurance,
        validationInsuranceErrors,
        validateInsurancePlan,
        disablePlan,
        isBiDisabled,
        requiredFields,
        disabledFields,
    } = props;

    const isRequired = (field) => {
        if (requiredFields && Array.isArray(requiredFields)) {
            return requiredFields.indexOf(field) > -1;
        }
    };

    const isDisabled = (field) => {
        if (disabledFields && Array.isArray(disabledFields)) {
            return disabledFields.indexOf(field) > -1;
        }
    };
    const { values, setValues } = useInsuranceContext();

    if (patientInsurance[item]?.coverageEndDate) {
        patientInsurance[item].coverageEndDate = dayjs(patientInsurance[item].coverageEndDate)
            .utc()
            .format('YYYY-MM-DD');
    }

    if (patientInsurance[item]?.coverageEffectiveDate) {
        patientInsurance[item].coverageEffectiveDate = dayjs(patientInsurance[item].coverageEffectiveDate)
            .utc()
            .format('YYYY-MM-DD');
    }

    return (
        <div className="insurance-container">
            <div className="grid-container">
                <div className="grid-item">
                    <PatientInsuranceFormField
                        required
                        disabled={isBiDisabled || isDisabled('plan_name')}
                        id="name"
                        name="name"
                        type="text"
                        label="Plan Name"
                        className="field-required"
                        invalid={R.pathOr('', [item, 'name'], validationInsuranceErrors)}
                        errorMessage={R.pathOr('', [item, 'name'], validationInsuranceErrors)}
                        onChange={(event) => {
                            validateInsurancePlan(item, event.target.id, event.target.value);
                            onChangeText(item, event.target.id, event.target.value);
                        }}
                        value={R.pathOr('', [item, 'name'], patientInsurance)}
                    />
                </div>
                <div className="grid-item">
                    <PatientInsuranceFormField
                        required
                        disabled={isBiDisabled || isDisabled('patient_id')}
                        id="planPatientId"
                        name="planPatientId"
                        type="text"
                        label="Patient ID"
                        className="field-required"
                        invalid={R.pathOr('', [item, 'planPatientId'], validationInsuranceErrors)}
                        errorMessage={R.pathOr('', [item, 'planPatientId'], validationInsuranceErrors)}
                        onChange={(event) => {
                            validateInsurancePlan(item, event.target.id, event.target.value);
                            onChangeText(item, event.target.id, event.target.value);
                        }}
                        value={R.pathOr('', [item, 'planPatientId'], patientInsurance)}
                    />
                </div>
                <div className="grid-item">
                    <FormGroup>
                        <ControlLabel
                            htmlFor="coverageType"
                            className={
                                R.pathOr('', [item, 'coverageType'], validationInsuranceErrors)
                                    ? 'invalid-insurance-field field-required'
                                    : 'field-required'
                            }
                        >
                            Benefit Coverage Type
                        </ControlLabel>
                        <select
                            required
                            data-testid="benefitCoverageType"
                            className="form-control"
                            id="coverageType"
                            disabled={isBiDisabled || isDisabled('benefit_coverage_type')}
                            name="coverageType"
                            value={R.pathOr('', [item, 'coverageType'], patientInsurance)}
                            onChange={(event) => {
                                validateInsurancePlan(item, event.target.id, event.target.value);
                                onChangeText(item, event.target.id, event.target.value);
                            }}
                        >
                            <option value="Select">Select</option>
                            <option value="Buy and Bill">Buy and Bill</option>
                            <option value="Cash">Cash</option>
                            <option value="Financial Assistance">Financial Assistance</option>
                            <option value="Foundation Assistance">Foundation Assistance</option>
                            <option value="Medical">Medical</option>
                            <option value="None">None</option>
                            <option value="Pharmacy">Pharmacy</option>
                        </select>
                        {R.pathOr('', [item, 'coverageType'], validationInsuranceErrors) ? (
                            <span className="error-message-insurance">
                                {R.pathOr('', [item, 'coverageType'], validationInsuranceErrors)}{' '}
                            </span>
                        ) : (
                            <span className="error-message-insurance" />
                        )}{' '}
                    </FormGroup>
                </div>
                <div className="grid-item">
                    <FormGroup>
                        <ControlLabel
                            htmlFor="payerSegment"
                            className={
                                R.pathOr('', [item, 'payerSegment'], validationInsuranceErrors)
                                    ? 'invalid-insurance-field field-required'
                                    : 'field-required'
                            }
                        >
                            Payor Type
                        </ControlLabel>
                        <select
                            required
                            className="form-control insurance-field"
                            id="payerSegment"
                            name="payerSegment"
                            disabled={isBiDisabled || isDisabled('payor_type')}
                            onChange={(event) => {
                                validateInsurancePlan(item, event.target.id, event.target.value);
                                onChangeText(item, event.target.id, event.target.value);
                            }}
                            value={R.pathOr('', [item, 'payerSegment'], patientInsurance)}
                        >
                            <option value="Select">Select</option>
                            <option value="Auto Insurance">Auto Insurance</option>
                            <option value="Charity/Indigent Program">Charity/Indigent Program</option>
                            <option value="Government">Government</option>
                            <option value="HMO/Commercial">HMO/Commercial</option>
                            <option value="Integrated PBM">Integrated PBM</option>
                            <option value="Managed Medicaid">Managed Medicaid</option>
                            <option value="Medicaid">Medicaid</option>
                            <option value="Medicare">Medicare</option>
                            <option value="Medicare A">Medicare A</option>
                            <option value="Medicare Advantage (Part C)">Medicare Advantage (Part C)</option>
                            <option value="Medicare B">Medicare B</option>
                            <option value="Medicare D">Medicare D</option>
                            <option value="None">None</option>
                            <option value="Other">Other</option>
                            <option value="PPO/Commercial">PPO/Commercial</option>
                            <option value="Prisoners">Prisoners</option>
                            <option value="Self-Pay">Self-Pay</option>
                            <option value="Tri-Care">Tri-Care</option>
                            <option value="Unknown">Unknown</option>
                            <option value="Workers Comp">Workers Comp</option>
                        </select>
                        {R.pathOr('', [item, 'payerSegment'], validationInsuranceErrors) ? (
                            <span className="error-message-insurance">
                                {R.pathOr('', [item, 'payerSegment'], validationInsuranceErrors)}{' '}
                            </span>
                        ) : (
                            <span className="error-message-insurance" />
                        )}{' '}
                    </FormGroup>
                </div>
                <div className="grid-item">
                    <PatientInsuranceFormField
                        required={isRequired('bin')}
                        className={isRequired('bin') && 'field-required'}
                        invalid={R.pathOr('', [item, 'pharmacyInsuranceBin'], validationInsuranceErrors)}
                        errorMessage={R.pathOr('', [item, 'pharmacyInsuranceBin'], validationInsuranceErrors)}
                        disabled={isBiDisabled || isDisabled('bin')}
                        id="pharmacyInsuranceBin"
                        name="pharmacyInsuranceBin"
                        label="BIN"
                        type="text"
                        value={R.pathOr('', [item, 'pharmacyInsuranceBin'], patientInsurance)}
                        onChange={(event) => {
                            validateInsurancePlan(item, event.target.id, event.target.value);
                            onChangeText(item, event.target.id, event.target.value);
                        }}
                    />
                </div>
                <div className="grid-item">
                    <PatientInsuranceFormField
                        required={isRequired('pcn')}
                        className={isRequired('pcn') && 'field-required'}
                        invalid={R.pathOr('', [item, 'pharmacyInsurancePcn'], validationInsuranceErrors)}
                        errorMessage={R.pathOr('', [item, 'pharmacyInsurancePcn'], validationInsuranceErrors)}
                        disabled={isBiDisabled || isDisabled('pcn')}
                        id="pharmacyInsurancePcn"
                        name="pharmacyInsurancePcn"
                        label="PCN"
                        type="text"
                        value={R.pathOr('', [item, 'pharmacyInsurancePcn'], patientInsurance)}
                        onChange={(event) => {
                            validateInsurancePlan(item, event.target.id, event.target.value);
                            onChangeText(item, event.target.id, event.target.value);
                        }}
                    />
                </div>
                <div className="grid-item">
                    <PatientInsuranceFormField
                        required={isRequired('rx_group_number')}
                        className={isRequired('rx_group_number') && 'field-required'}
                        invalid={R.pathOr('', [item, 'pharmacyInsuranceGroupId'], validationInsuranceErrors)}
                        errorMessage={R.pathOr('', [item, 'pharmacyInsuranceGroupId'], validationInsuranceErrors)}
                        disabled={isBiDisabled || isDisabled('rx_group_number')}
                        id="pharmacyInsuranceGroupId"
                        name="pharmacyInsuranceGroupId"
                        label="RX Group Number"
                        type="text"
                        value={R.pathOr('', [item, 'pharmacyInsuranceGroupId'], patientInsurance)}
                        onChange={(event) => {
                            validateInsurancePlan(item, event.target.id, event.target.value);
                            onChangeText(item, event.target.id, event.target.value);
                        }}
                    />
                </div>
                <div className="grid-item">
                    <PatientInsuranceFormField
                        required={isRequired('person_code')}
                        className={isRequired('person_code') && 'field-required'}
                        invalid={R.pathOr('', [item, 'pharmacyInsurancePersonCode'], validationInsuranceErrors)}
                        errorMessage={R.pathOr('', [item, 'pharmacyInsurancePersonCode'], validationInsuranceErrors)}
                        id="pharmacyInsurancePersonCode"
                        name="pharmacyInsurancePersonCode"
                        label="Person Code"
                        type="text"
                        disabled={isBiDisabled || isDisabled('person_code')}
                        onChange={(event) => {
                            validateInsurancePlan(item, event.target.id, event.target.value);
                            onChangeText(item, event.target.id, event.target.value);
                        }}
                        value={R.pathOr('', [item, 'pharmacyInsurancePersonCode'], patientInsurance)}
                    />
                </div>
                <div className="grid-item">
                    <FormGroup>
                        <ControlLabel
                            htmlFor="isIntegratedHighDeductable"
                            className={
                                // eslint-disable-next-line no-nested-ternary
                                isRequired('high_deductible_plan') &&
                                R.pathOr('', [item, 'isIntegratedHighDeductable'], validationInsuranceErrors)
                                    ? 'invalid-insurance-field field-required'
                                    : isRequired('high_deductible_plan')
                                    ? 'field-required'
                                    : ''
                            }
                        >
                            High Deductible Plan
                        </ControlLabel>
                        <select
                            required={isRequired('high_deductible_plan')}
                            className={
                                isRequired('high_deductible_plan')
                                    ? 'form-control field-required insurance-field'
                                    : 'form-control'
                            }
                            data-testid="isIntegratedHighDeductable"
                            id="isIntegratedHighDeductable"
                            disabled={isBiDisabled || isDisabled('high_deductible_plan')}
                            name="isIntegratedHighDeductable"
                            value={R.pathOr('', [item, 'isIntegratedHighDeductable'], patientInsurance)}
                            onChange={(event) => {
                                validateInsurancePlan(item, event.target.id, event.target.value);
                                onChangeText(item, event.target.id, event.target.value);
                            }}
                        >
                            <option value="">Select</option>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                        </select>
                        {R.pathOr('', [item, 'isIntegratedHighDeductable'], validationInsuranceErrors) ? (
                            <span className="error-message-insurance">
                                {R.pathOr('', [item, 'isIntegratedHighDeductable'], validationInsuranceErrors)}{' '}
                            </span>
                        ) : (
                            <span className="error-message-insurance" />
                        )}{' '}
                    </FormGroup>
                </div>
                <div className="grid-item">
                    <FormGroup>
                        <ControlLabel
                            htmlFor="coverageEffectiveDate"
                            className={
                                // eslint-disable-next-line no-nested-ternary
                                isRequired('coverage_effective_date') &&
                                R.pathOr('', [item, 'coverageEffectiveDate'], validationInsuranceErrors)
                                    ? 'invalid-insurance-field field-required'
                                    : isRequired('coverage_effective_date')
                                    ? 'field-required'
                                    : ''
                            }
                        >
                            Coverage Effective Date
                        </ControlLabel>
                        <DatePickerInput
                            required={isRequired('coverage_effective_date')}
                            name="coverageEffectiveDate"
                            disabled={isBiDisabled || isDisabled('coverage_effective_date')}
                            placeholder="Coverage Effective Date"
                            dateFormat="yyyy/MM/dd"
                            value={
                                patientInsurance[item]?.coverageEffectiveDate
                                    ? dayjs(patientInsurance[item].coverageEffectiveDate).utc().toDate()
                                    : null
                            }
                            onChange={(date) => {
                                setValues({
                                    ...values,
                                    coverageEffectiveDate: date,
                                });
                                onChangeText(item, 'coverageEffectiveDate', date);
                                validateInsurancePlan(item, 'coverageEffectiveDate', date);
                            }}
                            onBlur={() => {}}
                        />
                        {R.pathOr('', [item, 'coverageEffectiveDate'], validationInsuranceErrors) ? (
                            <span className="error-message-insurance">
                                {R.pathOr('', [item, 'coverageEffectiveDate'], validationInsuranceErrors)}{' '}
                            </span>
                        ) : (
                            <span className="error-message-insurance" />
                        )}{' '}
                    </FormGroup>
                </div>
                <div className="grid-item">
                    <FormGroup>
                        <ControlLabel
                            htmlFor="coverageEndDate"
                            className={
                                // eslint-disable-next-line no-nested-ternary
                                isRequired('coverage_end_date') &&
                                R.pathOr('', [item, 'coverageEndDate'], validationInsuranceErrors)
                                    ? 'invalid-insurance-field field-required'
                                    : isRequired('coverage_end_date')
                                    ? 'field-required'
                                    : ''
                            }
                        >
                            Coverage End Date
                        </ControlLabel>
                        <DatePickerInput
                            required={isRequired('coverage_end_date')}
                            name="coverageEndDate"
                            disabled={isBiDisabled || isDisabled('coverage_end_date')}
                            placeholder="Coverage End Date"
                            value={
                                patientInsurance[item]?.coverageEndDate
                                    ? dayjs(patientInsurance[item].coverageEndDate).utc().toDate()
                                    : null
                            }
                            dateFormat="yyyy/MM/dd"
                            onChange={(date) => {
                                setValues({
                                    ...values,
                                    coverageEndDate: date,
                                });
                                onChangeText(item, 'coverageEndDate', date);
                                validateInsurancePlan(item, 'coverageEndDate', date);
                            }}
                            onBlur={() => {}}
                        />
                        {R.pathOr('', [item, 'coverageEndDate'], validationInsuranceErrors) ? (
                            <span className="error-message-insurance">
                                {R.pathOr('', [item, 'coverageEndDate'], validationInsuranceErrors)}{' '}
                            </span>
                        ) : (
                            <span className="error-message-insurance" />
                        )}{' '}
                    </FormGroup>
                </div>
                <div className="grid-item">
                    <PatientInsuranceFormField
                        required={isRequired('medical_group_id')}
                        className={isRequired('medical_group_id') && 'field-required'}
                        invalid={R.pathOr('', [item, 'groupNumber'], validationInsuranceErrors)}
                        errorMessage={R.pathOr('', [item, 'groupNumber'], validationInsuranceErrors)}
                        id="groupNumber"
                        name="groupNumber"
                        label="Medical Group ID"
                        type="text"
                        disabled={isBiDisabled || isDisabled('medical_group_id')}
                        onChange={(event) => {
                            validateInsurancePlan(item, event.target.id, event.target.value);
                            onChangeText(item, event.target.id, event.target.value);
                        }}
                        value={R.pathOr('', [item, 'groupNumber'], patientInsurance)}
                    />
                </div>
                <div className="grid-item">
                    <FormGroup>
                        <MoneyInput
                            required={isRequired('deductible_amount')}
                            className={isRequired('deductible_amount') && 'field-required'}
                            invalid={R.pathOr('', [item, 'deductableAmount'], validationInsuranceErrors)}
                            errorMessage={R.pathOr('', [item, 'deductableAmount'], validationInsuranceErrors)}
                            id="deductableAmount"
                            name="deductableAmount"
                            disabled={isBiDisabled || isDisabled('deductible_amount')}
                            placeholder="Deductible Amount"
                            label="Deductible Amount"
                            onChange={(event) => {
                                setValues({
                                    ...values,
                                    deductableAmount: event.target.value,
                                });
                                validateInsurancePlan(item, 'deductableAmount', event.target.value);
                                onChangeText(item, 'deductableAmount', event.target.value);
                            }}
                            value={R.pathOr('', [item, 'deductableAmount'], patientInsurance)}
                        />
                    </FormGroup>
                </div>
                <div className="grid-item">
                    <FormGroup>
                        <MoneyInput
                            required={isRequired('deductible_amount_paid_to_date')}
                            className={isRequired('deductible_amount_paid_to_date') && 'field-required'}
                            invalid={R.pathOr('', [item, 'deductableAmountPaidToDate'], validationInsuranceErrors)}
                            errorMessage={R.pathOr('', [item, 'deductableAmountPaidToDate'], validationInsuranceErrors)}
                            id="deductableAmountPaidToDate"
                            name="deductableAmountPaidToDate"
                            disabled={isBiDisabled || isDisabled('deductible_amount_paid_to_date')}
                            placeholder="Deductible Amount Paid To Date"
                            label="Deductible Amount Paid To Date"
                            onChange={(event) => {
                                setValues({
                                    ...values,
                                    deductableAmountPaidToDate: event.target.value,
                                });
                                validateInsurancePlan(item, 'deductableAmountPaidToDate', event.target.value);
                                onChangeText(item, 'deductableAmountPaidToDate', event.target.value);
                            }}
                            value={R.pathOr('', [item, 'deductableAmountPaidToDate'], patientInsurance)}
                        />
                    </FormGroup>
                </div>
                <div className="grid-item">
                    <FormGroup>
                        <MoneyInput
                            required={isRequired('out_of_pocket_maximum')}
                            className={isRequired('out_of_pocket_maximum') && 'field-required'}
                            invalid={R.pathOr('', [item, 'pharmacyPlanOopMax'], validationInsuranceErrors)}
                            errorMessage={R.pathOr('', [item, 'pharmacyPlanOopMax'], validationInsuranceErrors)}
                            id="pharmacyPlanOopMax"
                            name="pharmacyPlanOopMax"
                            disabled={isBiDisabled || isDisabled('out_of_pocket_maximum')}
                            placeholder="Out Of Pocket Maximum"
                            label="Out Of Pocket Maximum"
                            onChange={(event) => {
                                setValues({
                                    ...values,
                                    pharmacyPlanOopMax: event.target.value,
                                });
                                validateInsurancePlan(item, 'pharmacyPlanOopMax', event.target.value);
                                onChangeText(item, 'pharmacyPlanOopMax', event.target.value);
                            }}
                            value={R.pathOr('', [item, 'pharmacyPlanOopMax'], patientInsurance)}
                        />
                    </FormGroup>
                </div>
                <div className="grid-item">
                    <FormGroup>
                        <MoneyInput
                            required={isRequired('out_of_pocket_maximum_paid_to_date')}
                            className={isRequired('out_of_pocket_maximum_paid_to_date') && 'field-required'}
                            invalid={R.pathOr('', [item, 'pharmacyPlanOopMaxPaidToDate'], validationInsuranceErrors)}
                            errorMessage={R.pathOr(
                                '',
                                [item, 'pharmacyPlanOopMaxPaidToDate'],
                                validationInsuranceErrors
                            )}
                            id="pharmacyPlanOopMaxPaidToDate"
                            name="pharmacyPlanOopMaxPaidToDate"
                            disabled={isBiDisabled || isDisabled('out_of_pocket_maximum_paid_to_date')}
                            placeholder="Out Of Pocket Maximum Paid To Date"
                            label="Out Of Pocket Maximum Paid To Date"
                            onChange={(event) => {
                                setValues({
                                    ...values,
                                    pharmacyPlanOopMaxPaidToDate: event.target.value,
                                });
                                validateInsurancePlan(item, 'pharmacyPlanOopMaxPaidToDate', event.target.value);
                                onChangeText(item, 'pharmacyPlanOopMaxPaidToDate', event.target.value);
                            }}
                            value={R.pathOr('', [item, 'pharmacyPlanOopMaxPaidToDate'], patientInsurance)}
                        />
                    </FormGroup>
                </div>
                <div className="grid-item">
                    <FormGroup>
                        <ControlLabel
                            htmlFor="isPharmacyBenefitsCarveOutFromMedical"
                            className={
                                // eslint-disable-next-line no-nested-ternary
                                isRequired('coverage_carve_out') &&
                                R.pathOr('', [item, 'isPharmacyBenefitsCarveOutFromMedical'], validationInsuranceErrors)
                                    ? 'invalid-insurance-field field-required'
                                    : isRequired('coverage_carve_out')
                                    ? 'field-required'
                                    : ''
                            }
                        >
                            Coverage Carve-Out
                        </ControlLabel>
                        <select
                            required={isRequired('coverage_carve_out')}
                            className={
                                isRequired('coverage_carve_out')
                                    ? 'form-control field-required insurance-field'
                                    : 'form-control'
                            }
                            data-testid="coverageIsCarveOutFromMedical"
                            id="isPharmacyBenefitsCarveOutFromMedical"
                            disabled={isBiDisabled || isDisabled('coverage_carve_out')}
                            name="isPharmacyBenefitsCarveOutFromMedical"
                            value={R.pathOr('', [item, 'isPharmacyBenefitsCarveOutFromMedical'], patientInsurance)}
                            onChange={(event) => {
                                validateInsurancePlan(item, event.target.id, event.target.value);
                                onChangeText(item, event.target.id, event.target.value);
                            }}
                        >
                            <option value="">Select</option>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                        </select>
                        {R.pathOr('', [item, 'isPharmacyBenefitsCarveOutFromMedical'], validationInsuranceErrors) ? (
                            <span className="error-message-insurance">
                                {R.pathOr(
                                    '',
                                    [item, 'isPharmacyBenefitsCarveOutFromMedical'],
                                    validationInsuranceErrors
                                )}{' '}
                            </span>
                        ) : (
                            <span className="error-message-insurance" />
                        )}{' '}
                    </FormGroup>
                </div>
                <div className="grid-item">
                    <PhoneInput
                        required={isRequired('help_desk_phone')}
                        className={cx({ 'field-required': isRequired('help_desk_phone') })}
                        invalid={R.pathOr('', [item, 'helpDeskPhone'], validationInsuranceErrors)}
                        errorMessage={R.pathOr('', [item, 'helpDeskPhone'], validationInsuranceErrors)}
                        id="helpDeskPhone"
                        name="helpDeskPhone"
                        label="Help Desk Phone"
                        disabled={isBiDisabled || isDisabled('help_desk_phone')}
                        placeholderText="(###) ###-####"
                        type="text"
                        onChange={(event) => {
                            setValues({
                                ...values,
                                helpDeskPhone: event.target.value,
                            });
                            onChangeText(item, event.target.id, event.target.value);
                            validateInsurancePlan(item, event.target.id, event.target.value);
                        }}
                        value={R.pathOr('', [item, 'helpDeskPhone'], patientInsurance)}
                    />
                </div>
            </div>
            {editPatientInsurance && R.pathOr(null, [item, 'id'], patientInsurance) !== null ? (
                <Form>
                    <ControlLabel htmlFor="disablePlan" className="disable-insurance">
                        <input
                            type="checkbox"
                            onChange={(event) => disablePlan(item, event.target.checked)}
                            style={{ marginRight: '5px' }}
                            id="disablePlan"
                            label="Disable Insurance Plan"
                        />
                        Disable Insurance Plan
                    </ControlLabel>
                </Form>
            ) : null}{' '}
        </div>
    );
}

PatientInsuranceMain.propTypes = propTypes;
PatientInsuranceMain.defaultProps = defaultProps;

export default PatientInsuranceMain;
