import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import _ from 'lodash';
import * as R from 'ramda';
import { Form, Formik } from 'formik';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import { errorHandler } from 'App/utils';
import CancelButton from 'Lib/form/CancelButton';
import SelectField from 'Lib/form/SelectField';
import SubmitButton from 'Lib/form/SubmitButton';
import { patientPropType } from 'Lib/types';
import TherigyInfoMessage from 'App/components/TherigyInfoMessage/TherigyInfoMessage';
import { assignUnassignedMedication } from 'App/services/TherapyService';

const propTypes = {
    onHide: PropTypes.func.isRequired,
    patient: patientPropType.isRequired,
    unassignedMedications: PropTypes.shape({
        unassignedMedications: PropTypes.arrayOf(
            PropTypes.shape({
                indications: PropTypes.arrayOf(
                    PropTypes.shape({
                        Code: PropTypes.string,
                        Description: PropTypes.string,
                    })
                ),
            })
        ),
        diagnosis: PropTypes.shape({
            icd9: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string.isRequired,
                })
            ),
            icd10: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string.isRequired,
                })
            ),
            secondary: PropTypes.arrayOf(
                PropTypes.shape({
                    text: PropTypes.string,
                    name: PropTypes.string,
                })
            ),
        }),
    }),
    size: PropTypes.string,
};

const defaultProps = {
    size: undefined,
    unassignedMedications: {
        unassignedMedications: [],
    },
};

function UnassignedMedicationsModal({ onHide, patient, unassignedMedications, size }) {
    const allMedsHaveIndications = useMemo(() => {
        let medicationsWithIndications;
        unassignedMedications.unassignedMedications.forEach((med) => {
            if (_.filter(med.Indications, 'Code').length !== 0) {
                medicationsWithIndications += 1;
            }
        });
        return medicationsWithIndications === unassignedMedications?.unassignedMedications?.length;
    }, [unassignedMedications.unassignedMedications]);

    return (
        <Modal show onHide={onHide} backdrop="static" className="unassignedMedicationsModal" bsSize={size}>
            <Modal.Header closeButton>
                <Modal.Title>Assign Therapeutic Category</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={{
                    selectedTherapy: {},
                }}
                onSubmit={(values) => {
                    const uniqueTcRoutes = {};
                    if (values.selectedTherapy !== {}) {
                        for (const [medName, therapy] of Object.entries(values.selectedTherapy)) {
                            const therapyId = therapy.id;
                            const indicatedMed = unassignedMedications.unassignedMedications.find(
                                (med) =>
                                    med.medicationName.toLowerCase() === medName.toLowerCase() ||
                                    med.displayName.toLowerCase() === medName.toLowerCase()
                            );
                            if (!uniqueTcRoutes[therapyId]) {
                                uniqueTcRoutes[therapyId] = {
                                    medications: [
                                        {
                                            medName,
                                            activityDueDate: indicatedMed?.activityDueDate || null,
                                            isAppointmentReferralActivityMedication:
                                                indicatedMed?.isAppointmentReferralActivityMedication,
                                        },
                                    ],
                                    diagnoses: indicatedMed?.indications || [],
                                };
                            } else {
                                uniqueTcRoutes[therapyId].medications.push({
                                    medName,
                                    activityDueDate: indicatedMed.activityDueDate || null,
                                    isAppointmentReferralActivityMedication:
                                        indicatedMed?.isAppointmentReferralActivityMedication,
                                });
                                uniqueTcRoutes[therapyId].diagnoses.concat(indicatedMed);
                            }
                        }
                        if (Object.keys(uniqueTcRoutes).length > 0) {
                            const promises = [];
                            for (const [id, value] of Object.entries(uniqueTcRoutes)) {
                                promises.push(assignUnassignedMedication(patient.id, id, value));
                            }
                            Promise.all(promises)
                                .then(() => {
                                    onHide(true);
                                })
                                .catch(errorHandler)
                                .finally(() => {
                                    onHide();
                                });
                        }
                    }
                }}
            >
                {({ handleSubmit }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Modal.Body>
                                <Row>
                                    <Col md={12}>
                                        <h4>
                                            {patient.firstName} {patient.lastName}
                                        </h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>Gender: {patient.gender?.toUpperCase().slice(0, 1)}</Col>
                                    <Col md={6}>Birth Date: {patient.birthDate?.replace(/T.*/, '')}</Col>
                                </Row>
                                <Row>
                                    {patient.homePhone ? <Col md={6}>Home Phone: {patient.homePhone}</Col> : null}
                                    {patient.mobilePhone ? <Col md={6}>Mobile Phone: {patient.mobilePhone}</Col> : null}
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        Address: {patient.addressLine1} {patient.addressLine2} {patient.city},{' '}
                                        {patient.state} {patient.zip}
                                    </Col>
                                    {patient.languageSpoken ? (
                                        <Col md={6}>Preferred Language: {patient.languageSpoken}</Col>
                                    ) : null}
                                </Row>
                                {unassignedMedications.diagnosis?.icd9?.length ? (
                                    <Row>
                                        <Col md={6}>
                                            ICD9:{' '}
                                            {unassignedMedications.diagnosis?.icd9
                                                ?.map((icd9) => icd9?.name)
                                                .join(', ')}
                                        </Col>
                                    </Row>
                                ) : null}
                                {unassignedMedications.diagnosis?.icd10?.length ? (
                                    <Col md={6}>
                                        ICD10: ICD9:{' '}
                                        {unassignedMedications.diagnosis?.icd10?.map((icd10) => icd10?.name).join(', ')}
                                    </Col>
                                ) : null}
                                {!allMedsHaveIndications ? (
                                    <Row>
                                        <Col md={6}>
                                            Secondary:{' '}
                                            {unassignedMedications.diagnosis?.secondary
                                                ?.map((icd10) => icd10.text || icd10.name)
                                                .join(', ')}
                                        </Col>
                                    </Row>
                                ) : null}
                                <TherigyInfoMessage message="Please select a Therapeutic Category for each medication." />
                                <br />
                                <Row>
                                    {unassignedMedications?.unassignedMedications.map((medList) => {
                                        const displayName = medList.medicationName || medList.displayName;
                                        return (
                                            <Col md={6} key={displayName}>
                                                <SelectField
                                                    fieldName={`selectedTherapy.${displayName}`}
                                                    label={
                                                        <>
                                                            <div>{medList.displayName || medList.medicationName}</div>
                                                            {allMedsHaveIndications ? (
                                                                <>
                                                                    <div>Indications:</div>
                                                                    <ul>
                                                                        {medList.indications.map((indication) => (
                                                                            <li key={indication.id}>
                                                                                {indication.code}{' '}
                                                                                {indication.description}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </>
                                                            ) : null}
                                                        </>
                                                    }
                                                    options={medList?.therapies}
                                                    getOptionValue={R.prop('id')}
                                                    getOptionLabel={R.prop('name')}
                                                    isClearable
                                                />
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <FormButtonGroup>
                                    <CancelButton onClick={() => onHide()} />
                                    <SubmitButton>Save</SubmitButton>
                                </FormButtonGroup>
                            </Modal.Footer>
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );
}

UnassignedMedicationsModal.propTypes = propTypes;
UnassignedMedicationsModal.defaultProps = defaultProps;

export default UnassignedMedicationsModal;
