import { useMutation } from 'react-query';
import { createReferralActivityNote } from 'App/services/RMSService';

function useReferralActivityNoteMutation(activityId) {
    return useMutation(
        (payload) => {
            return createReferralActivityNote(activityId, payload);
        },
        {
            onSuccess() {
                /**
                 * No success is used because the useGetActivityNotes doesn't bother to read or update it initially.
                 * Any referral activity seems to simply include the notes in that payload.
                 */
            },
        }
    );
}

export default useReferralActivityNoteMutation;
