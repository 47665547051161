import UserService from '../../services/UserService';
import PESService from '../../services/PESService';
import toast from 'Lib/toast';

/** @ngInject */
function ActivePatientFieldsController(_, $q, $scope) {
    const ctrl = this;

    ctrl.$onChanges = $onChanges;
    ctrl.fieldActive = fieldActive;
    ctrl.setDisabledFields = setDisabledFields;
    ctrl.selectFields = selectFields;

    ctrl.defaultCareTendFields = [
        'first_name',
        'last_name',
        'middle_name',
        'suffix',
        'address_line_1',
        'address_line_2',
        'city',
        'state',
        'zip',
        'country',
        'height',
        'weight',
        'birth_date',
        'gender',
        'pregnancy_status',
        'lactating',
        'patient_status',
        'deceased_date',
        'marital_status',
        'ssn_last_four',
        'preferred_contact_method',
        'language_spoken',
        'email',
        'mobile_phone',
        'home_phone',
        'work_phone',
        'pharmacy',
        'external_id',
        'high_risk',
        'high_risk_reasons',
        'add_therapeutic_category',
        'icd_9_code',
        'icd_10_code',
        'care_opt_in',
        'care_opt_out',
        'medication_start_date',
        'medication_end_date',
        'add_rx_number',
        'rx_number',
        'rx_strength',
        'rx_prescriber',
        'rx_start_date',
        'rx_end_date',
        'other_diagnoses',
        'allergies',
        'medical_plan',
        'remote_medical_plan_id',
        'other_text',
        'other_route_of_administration',
        'other_strength',
        'other_frequency',
        'add_other_medications',
        'remove_other_medications',
        'ec_first_name',
        'ec_last_name',
        'ec_telephone',
        'ec_mobile_phone',
        'ec_relationship',
    ];

    ctrl.defaultMcKessonFields = [
        'address_line_1',
        'address_line_2',
        'add_other_medications',
        'allergies',
        'birth_date',
        'city',
        'country',
        'email',
        'external_id',
        'first_name',
        'gender',
        'height',
        'home_phone',
        'lactating',
        'language_spoken',
        'last_name',
        'middle_name',
        'mobile_phone',
        'other_diagnoses',
        'other_frequency',
        'other_text',
        'other_route_of_administration',
        'other_strength',
        'preferred_contact_method',
        'pregnancy_status',
        'remove_other_medications',
        'smoker_status',
        'suffix',
        'state',
        'terminally_ill',
        'weight',
        'work_phone',
        'zip',
    ];
    // This order follows the patient form
    ctrl.disabledFields = [];
    ctrl.fields = {
        'Patient Demographics': {
            'First Name': 'first_name',
            'Last Name': 'last_name',
            'Middle Name': 'middle_name',
            Suffix: 'suffix',
            'Address 1': 'address_line_1',
            'Address 2': 'address_line_2',
            City: 'city',
            State: 'state',
            Zip: 'zip',
            Country: 'country',
            Height: 'height',
            Weight: 'weight',
            'Terminally Ill': 'terminally_ill',
            Smoker: 'smoker_status',
            Birthday: 'birth_date',
            Gender: 'gender',
            'Pregnancy Status': 'pregnancy_status',
            Lactating: 'lactating',
            Ethnicity: 'ethnicity',
            'Patient Status': 'patient_status',
            'Deceased Date': 'deceased_date',
            'Marital Status': 'marital_status',
            'SSN Last Four': 'ssn_last_four',
            'Preferred Contact Method': 'preferred_contact_method',
            'Preferred Language': 'language_spoken',
            Email: 'email',
            'Mobile Phone': 'mobile_phone',
            'Home Phone': 'home_phone',
            'Work Phone': 'work_phone',
            Pharmacy: 'pharmacy',
            'External Id': 'external_id',
            'High-Risk': 'high_risk',
            'High-Risk Reasons': 'high_risk_reasons',
        },
        'Therapeutic Category and Medication': {
            'Add Therapeutic Category': 'add_therapeutic_category',
            'ICD-9 Code': 'icd_9_code',
            'ICD-10 Code': 'icd_10_code',
            'Care Opt-in': 'care_opt_in',
            'Care Opt-out': 'care_opt_out',
            'Medication Start Date': 'medication_start_date',
            'Medication End Date': 'medication_end_date',
            'Add Rx Number': 'add_rx_number',
            'Rx Number': 'rx_number',
            'Rx Strength': 'rx_strength',
            'Rx Prescriber': 'rx_prescriber',
            'Rx Start Date': 'rx_start_date',
            'Rx End Date': 'rx_end_date',
        },
        'Other Diagnoses': {
            'Other Diagnoses': 'other_diagnoses',
        },
        Allergies: {
            Allergies: 'allergies',
        },
        'Medical Plan': {
            'Medical Plan': 'medical_plan',
            'Medical Plan ID': 'remote_medical_plan_id',
            PBM: 'pbm',
            'PBM ID': 'remote_pbm_id',
        },
        'Other Medications': {
            Name: 'other_text',
            'Route of Administration': 'other_route_of_administration',
            Strength: 'other_strength',
            Frequency: 'other_frequency',
            'Add Other Medications': 'add_other_medications',
            'Remove Other Medications': 'remove_other_medications',
        },
        'Emergency Contact Information': {
            'First Name': 'ec_first_name',
            'Last Name': 'ec_last_name',
            'Home Phone': 'ec_telephone',
            'Mobile Phone': 'ec_mobile_phone',
            Relationship: 'ec_relationship',
        },
        'Email Alerts': {
            'Email Alerts': 'email_alerts',
        },
        'Welcome Packet': {
            'Welcome Packet': 'welcome_packet',
        },
        'Nursing Coordination': {
            'Nursing Coordination': 'nursing_coordination',
        },
    };

    ctrl.showGroup = {};

    /**
     * Gets available disabled patient fields for current company
     */
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line consistent-return
    function $onChanges(changes) {
        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
            return PESService.getDisabledFields(ctrl.user.active_company.ID).then((res) => {
                const formattedField = Object.values(res).map((record) => record.field);
                angular.forEach(_.values(ctrl.fields), (fields) => {
                    angular.forEach(_.values(fields), (f) => {
                        ctrl.disabledFields[f] = formattedField.indexOf(f) === -1;
                    });
                });
            });
        }
    }

    function fieldActive(field) {
        return ctrl.disabledFields.indexOf(field) === -1;
    }

    function selectFields(fields) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const groupName in ctrl.fields) {
            ctrl.showGroup[groupName] = true;
        }
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-restricted-syntax
        for (const field in ctrl.disabledFields) {
            if (fields.includes(field)) {
                ctrl.disabledFields[field] = false;
            } else {
                ctrl.disabledFields[field] = true;
            }
        }
    }

    function setDisabledFields() {
        ctrl.loading = true;
        let disabledFields = _.pickBy(ctrl.disabledFields, (f) => {
            return f === false;
        });
        disabledFields = _.keys(disabledFields);

        return UserService.setDisabledFields(ctrl.user.active_company.ID, {
            fields: disabledFields,
        })
            .then(() => {
                toast.success('All fields have been updated successfully.');
                $scope.$emit('updateDisabledFields', {
                    disabledFields,
                });
            })
            .finally(() => {
                ctrl.loading = false;
            });
    }
}

export default ActivePatientFieldsController;
