import isExpired from 'App/utils/isExpired';
import * as R from 'ramda';
import React from 'react';
import { unmountComponentAtNode } from 'react-dom';
import convertKeysToSnakeCase from 'App/utils/keyConversion/convertKeysToSnakeCase';
import mountReactComponent from 'App/utils/mountReactComponent';
import AppContextForAngular from 'App/components/AppContextForAngular';
import ViewPatientPrescription from 'App/components/PatientPrescription/ViewPatientPrescription';

const isNotExpired = R.complement(isExpired);

/** @ngInject */
function PatientProfileTherapiesController(_, $uibModal, userService) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.openTherapyHistoryModal = openTherapyHistoryModal;
    ctrl.openMedicationHistoryModal = openMedicationHistoryModal;
    ctrl.openRxNotesModal = openRxNotesModal;
    ctrl.openAddRxNotesModal = openAddRxNotesModal;
    ctrl.isNotExpired = isNotExpired;
    ctrl.$onDestroy = $onDestroy;
    ctrl.$onInit = $onInit;

    function $onInit() {}

    function patientPrescriptionMount() {
        if (ctrl.medications) {
            ctrl.medications.forEach((medication) => {
                if (medication.is_treatment_history) {
                    return;
                }
                const nextMed = R.set(R.lensPath(['medication', 'ended']), medication.ended, medication);
                mountReactComponent(
                    `#profile-rx-number-${nextMed.id}`,
                    <AppContextForAngular>
                        <ViewPatientPrescription
                            handleAddRxNotes={handleAddRxNotes}
                            handleViewRxNotes={handleViewRxNotes}
                            medication={nextMed.medication}
                            patientId={nextMed.patient_id}
                            patientPrescriptions={nextMed.patientPrescriptions}
                            showEndedMedications={ctrl.showEndedMedications}
                            viewProfile
                            viewRxNumber
                        />
                    </AppContextForAngular>
                );
            });
        }
    }

    function $onChanges(changes) {
        if (changes.medications && ctrl.medications) {
            ctrl.groupedMedications = angular.copy(_.groupBy(ctrl.medications, 'medication.group_id'));
        }

        if (ctrl.showEndedMedications !== null) {
            ctrl.showEndedMedications = angular.copy(ctrl.showEndedMedications);
        } else {
            ctrl.showEndedMedications = false;
        }

        if (changes.therapies && ctrl.therapies) {
            ctrl.therapies = angular.copy(ctrl.therapies);
        }

        patientPrescriptionMount();

        userService.get().then((res) => {
            ctrl.tmsEndpoint = res.company_permissions.TmsEndpoint;
        });
    }

    function handleViewRxNotes(patientId, prescription, rxIndex, medication) {
        openRxNotesModal(patientId, prescription[medication.id].rxNumbers[rxIndex].id.toString(), medication.id);
    }

    function handleAddRxNotes(patientId, prescription) {
        // eslint-disable-next-line no-param-reassign
        prescription.medication_id = prescription.medication.id;
        // eslint-disable-next-line no-param-reassign
        prescription.id = prescription.id.toString();
        openAddRxNotesModal(patientId, convertKeysToSnakeCase(prescription));
    }

    function openTherapyHistoryModal(therapy) {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'therapeuticCategoryHistory',
            size: 'lg',
            resolve: {
                therapy() {
                    return therapy;
                },
            },
        });

        modalInstance.result
            .then(() => {})
            .catch((err) => {
                return err.data;
            });
    }

    function openMedicationHistoryModal(patientId, medication) {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'medicationHistoryModal',
            size: 'lg',
            resolve: {
                medication() {
                    return medication;
                },
                patientId() {
                    return patientId;
                },
            },
        });

        modalInstance.result
            .then(() => {})
            .catch((err) => {
                return err.data;
            });
    }

    function openRxNotesModal(patientId, prescriptionId, medicationId) {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'prescriptionNotes',
            size: 'lg',
            resolve: {
                patientId() {
                    return patientId;
                },
                medicationId() {
                    return medicationId;
                },
                prescriptionId() {
                    return prescriptionId;
                },
            },
        });

        modalInstance.result
            .then(() => {})
            .catch((err) => {
                return err.data;
            });
    }

    function openAddRxNotesModal(patientId, prescription) {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'prescriptionAddNote',
            size: 'md',
            resolve: {
                prescription() {
                    return prescription;
                },
                patientId() {
                    return patientId;
                },
            },
        });

        modalInstance.result
            .then(() => {})
            .catch((err) => {
                return err.data;
            });
    }

    function $onDestroy(medication) {
        if (medication) {
            const dom = document.getElementById(`view-rx-number-${medication.id}`);
            if (dom) {
                unmountComponentAtNode(dom);
            }
        }
    }
}

export default PatientProfileTherapiesController;
