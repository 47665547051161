/** @ngInject */
function ReferralMedicationsController(_, NgTableParams) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.getIcd10 = getIcd10;

    function $onChanges(changes) {
        if (changes.user && ctrl.user) {
            ctrl.user = angular.copy(ctrl.user);
        }

        if (changes.medications && ctrl.medications) {
            ctrl.medications = angular.copy(ctrl.medications);

            // API provided status overrides STM
            if (ctrl.user.company_permissions.TmsEndpoint) {
                _.each(ctrl.medications, (medication) => {
                    if (_.has(medication, ['rx', 'tms', 'data', 'RxStatus'])) {
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line no-param-reassign
                        medication.status = medication.rx.tms.data.RxStatus;
                    } else if (!medication.status) {
                        // TODO: Fix this the next time the file is edited.
                        // eslint-disable-next-line no-param-reassign
                        medication.status = null;
                    }
                });
            }

            ctrl.tableParams = new NgTableParams(
                {
                    count: 5,
                    sorting: {
                        name: 'asc',
                    },
                },
                {
                    counts: [],
                    dataset: ctrl.medications,
                }
            );

            if (ctrl.tableParams.total() > ctrl.tableParams.count()) {
                ctrl.tableParams.settings({ counts: [5, 10, 25] });
            }
        }

        if (changes.statusReasons && ctrl.statusReasons) {
            ctrl.statusReasons = angular.copy(ctrl.statusReasons);
        }
    }
    function getIcd10(row) {
        const icd10Details = row.medication?.icd10?.icd10Details;
        if (icd10Details?.DxCode) {
            return `${icd10Details.DxCode} ${icd10Details.DxDescShort}`;
        }
        return row.icd_10 || '--';
    }
}

export default ReferralMedicationsController;
