import PESService from 'App/services/PESService';
import toast from 'Lib/toast';
import { useMutation, useQueryClient } from 'react-query';
import { errorHandler } from 'App/utils';

function useDeleteWorkQueueFilterMutation() {
    const queryClient = useQueryClient();

    return useMutation((reqBody) => PESService.deleteFilter(reqBody), {
        onError: errorHandler,
        onSuccess: async (data) => {
            toast.success('Filter has been deleted successfully.');
            const formattedFilters = data.map((it) => ({
                ...it,
                filter: JSON.parse(it.filter),
            }));
            await queryClient.setQueryData(['workQueue', 'savedFilters'], formattedFilters);
        },
    });
}

export default useDeleteWorkQueueFilterMutation;
