import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';

const propTypes = {};

const defaultProps = {};

function PreviousEnrollmentMessage() {
    return (
        <>
            <Row className="patienteducation-message">
                <Col sm={12}>
                    <span>
                        <FaInfoCircle className="info-icon" /> Please update the patient phone number to send a new
                        welcome text message. A welcome text message cannot be resent to the same phone number once the
                        patient is enrolled.
                    </span>
                </Col>
            </Row>
            <Row className="patienteducation-message">
                <Col sm={12}>
                    If the patient is unenrolled, the same phone number cannot be re-enrolled through the platform.
                    <strong>The patient must re-enroll by texting &quot;SUBSCRIBE&quot; to 833-354-1731.</strong>
                </Col>
            </Row>
        </>
    );
}

PreviousEnrollmentMessage.propTypes = propTypes;
PreviousEnrollmentMessage.defaultProps = defaultProps;

export default PreviousEnrollmentMessage;
