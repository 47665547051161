import React, { useCallback, useEffect, useState } from 'react';
import ManualPageTable from 'App/components/ManualPageTable/ManualPageTable';
import hasLength from 'App/utils/hasLength';
import { useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import EditActivityDueDateModal from 'App/components/PatientProfile/components/PatientActivities/components/EditActivityDueDateModal';
import ActivityNotesModal from 'App/components/PatientProfile/components/PatientActivities/components/ActivityNotesModal';
import { queryClient } from 'Lib/queryClient';
import { defaultActivityDateModalState, defaultActivityNoteModalState } from '../../defaultWorkQueueState';
import useFetchActivityCount from 'App/features/WorkQueue/hooks/useFetchActivityCount';
import { setWorkQueueFilterGenerator, updateWorkQueueFilters } from '../../workQueue.utils';
import { useGetWorkQueueColumns } from '../../hooks/useGetWorkQueueColumns';
import { useGetHeaderMapping } from 'App/features/WorkQueue/hooks/useGetHeaderMapping';

export const WorkQueueTable = () => {
    const {
        values: {
            result: { workQueueActivityCount, workQueueResult },
            filterParams,
            modalState: { activityDateModal: activityDateModalState, activityNoteModal: activityNoteModalState },
        },
        setFieldValue,
        isSubmitting,
    } = useFormikContext();
    const setWorkQueueFilter = setWorkQueueFilterGenerator(setFieldValue);
    const headerMapping = useGetHeaderMapping();
    const { page, count, search } = filterParams;
    const [searchState, setSearchState] = useState(search || {});
    const onFilterChange = useCallback(
        (e) => {
            const prevSearch = { ...searchState };
            if (e.target.value === '') {
                delete prevSearch[e.target.name];
            } else {
                prevSearch[e.target.name] = e.target.value;
            }
            setSearchState(prevSearch);
        },
        [searchState]
    );
    const sortKey = Object.keys(filterParams?.sorting || {})[0] ?? 'patient_name';
    const sortOrder = filterParams?.sorting ? filterParams?.sorting[sortKey] : 'asc';

    const { refetch: refetchActivityCount } = useFetchActivityCount();

    const refetchWorkQueueResult = () => {
        queryClient.refetchQueries(['work_queue', JSON.stringify(updateWorkQueueFilters(filterParams))]);
        refetchActivityCount();
    };

    const onHideActivityDueDateModal = (refetch) => {
        if (refetch) {
            refetchWorkQueueResult();
        }
        setFieldValue('modalState.activityDateModal', defaultActivityDateModalState);
    };

    const onHideActivityNoteStatus = (refetch) => {
        if (refetch) {
            refetchWorkQueueResult();
        }
        setFieldValue('modalState.activityNoteModal', defaultActivityNoteModalState);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setWorkQueueFilter(filterParams, 'search', searchState);
        }, 800);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchState]);

    useEffect(() => {
        if (!isEqual(searchState, search)) {
            setSearchState(search);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const columns = useGetWorkQueueColumns({ headerMapping, onFilterChange, searchState });

    const canSort = hasLength(workQueueResult);

    const onPageChange = ({ pageIndex, pageSize }) => {
        setFieldValue('filterParams.page', pageIndex);
        setFieldValue('filterParams.count', pageSize);
    };

    const onSortChange = (sort) => {
        const sortField = sort.sortKey;
        setWorkQueueFilter(filterParams, 'sorting', {
            [sortField]: sort.sortOrder,
        });
    };

    return (
        <>
            <div>
                <h3 className="header-work-queue">Work Queue</h3>
            </div>
            <ManualPageTable
                data={workQueueResult}
                totalRows={workQueueActivityCount}
                placeholderText="There are no activities that match your current search criteria."
                columns={columns}
                canSort={canSort}
                isLoading={isSubmitting}
                onPageChange={onPageChange}
                onSortChange={onSortChange}
                pageSizeOptions={[10, 25, 50]}
                currentPageIndex={count}
                currentPage={page}
                initialSortKey={sortKey}
                initialSortOrder={sortOrder}
            />
            {activityDateModalState.show && (
                <EditActivityDueDateModal onHide={onHideActivityDueDateModal} {...activityDateModalState} />
            )}
            {activityNoteModalState.show && (
                <ActivityNotesModal onHide={onHideActivityNoteStatus} {...activityNoteModalState} />
            )}
        </>
    );
};
