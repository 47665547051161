import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Form, FormControl, FormGroup, InputGroup } from 'react-bootstrap';

import UrlBuilderService from '../../services/UrlBuilderService';
import { useUserContext } from 'App/contexts/UserContext';
import isPermitted, { PERMISSION_TYPES } from 'App/utils/isPermitted';

const SearchBar = () => {
    const history = useHistory();
    const location = useLocation();
    const [searchValue, setSearchValue] = useState('');
    const user = useUserContext();

    window.rHistory = history;
    const onSearchValue = (event) => {
        if (event && event.target) {
            const { value } = event.target;
            setSearchValue(value);
        }
    };

    const searchPatient = (event) => {
        // Call event.preventDefault() here to prevent event from bubbling up
        event.preventDefault();
        if (searchValue) {
            const queryObj = {};
            const searchName = searchValue.trim();
            const names = searchName.split(/[ ,]+/); // Spilt by any number of white space or commas

            if (searchName.indexOf(',') !== -1) {
                const namesSplitByComma = searchName.split(',');
                queryObj.firstName = namesSplitByComma[1].trim();
                queryObj.lastName = namesSplitByComma[0].trim();
            } else if (names.length === 1) {
                queryObj.lastName = searchName;
            } else {
                // to handle the name in <first last last> format
                const { groups } = searchName.match(/^(?<prefix>Dr\.? )?(?:(?<firstName>\w+) )?(?<lastName>.*)$/i);
                queryObj.firstName = groups.firstName || '';
                queryObj.lastName = (groups.prefix || '') + groups.lastName;
            }

            const queryParamString = UrlBuilderService.getQueryParamsFromObj(queryObj);

            const basePath = isPermitted(PERMISSION_TYPES.PDX_FIND_PATIENT, user) ? '/pdxFindPatient' : '/findPatient';

            if (location.pathname === basePath) {
                history.replace(`${basePath}${queryParamString}`);
            } else {
                history.push(`${basePath}${queryParamString}`);
            }
            setSearchValue('');
        }
    };

    return (
        <Form className="input-group" inline onSubmit={searchPatient}>
            <FormGroup controlId="search-bar" bsSize="small">
                <InputGroup>
                    <FormControl
                        type="text"
                        value={searchValue}
                        placeholder="Find patients by last name"
                        onChange={onSearchValue}
                        aria-label="Search by last name"
                    />
                    <InputGroup.Button>
                        <Button bsStyle="primary" bsSize="small" disabled={!searchValue} type="submit">
                            Search
                        </Button>
                    </InputGroup.Button>
                </InputGroup>
            </FormGroup>
        </Form>
    );
};

export default SearchBar;
