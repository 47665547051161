import toast from 'Lib/toast';

/** @ngInject */
function PatientNotesController(pesService, $filter) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.cancelEdit = cancelEdit;
    ctrl.savePatientNotes = savePatientNotes;
    ctrl.toggleEdit = toggleEdit;

    function $onChanges(changes) {
        if (changes.patient && ctrl.patient) {
            ctrl.patient = angular.copy(ctrl.patient);
            ctrl.notes = $filter('unescapeHtml')(ctrl.patient.notes);
        }
    }

    function $onInit() {
        ctrl.editable = false;
    }

    function cancelEdit() {
        ctrl.editable = false;
        ctrl.notes = $filter('unescapeHtml')(ctrl.patient.notes);
    }

    function savePatientNotes(notes) {
        ctrl.busy = true;
        ctrl.editable = false;
        return pesService
            .update(ctrl.patient.id, {
                notes,
            })
            .then((res) => {
                ctrl.patient.notes = res.notes;
                ctrl.notes = $filter('unescapeHtml')(res.notes);
                toast.success('Patient notes updated successfully.');
                return res;
            })
            .catch((err) => {
                ctrl.editable = true;
                toast.error('We are unable to save patient notes at this time. Please try again.');
                return err;
            })
            .finally(() => {
                ctrl.busy = false;
                return ctrl.editable;
            });
    }

    function toggleEdit() {
        if (ctrl.busy) {
            return;
        }
        if (!ctrl.editable) {
            ctrl.editable = !ctrl.editable;
        }
    }
}

export default PatientNotesController;
