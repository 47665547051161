import React from 'react';
import { formatPhone, hasLength } from 'App/utils';
import BiInsurance from '../../PatientInsurance/BiInsurance';
import {
    addReferralActivityBiPlan,
    deleteReferralActivityBiPlan,
    getReferralActivityBiPlan,
} from 'App/services/RMSService';
import PESService from '../../../services/PESService';
import { keyBy } from 'lodash';
import * as yup from 'yup';
import mountReactComponent from '../../../utils/mountReactComponent';
import { setPatientIdentifiers } from 'App/utils/setPatientIdentifiers';
import * as R from 'ramda';
import { errorHandler } from '../../../utils/';
import delay from 'App/utils/delay';
import toast from 'Lib/toast';

/** @ngInject */
function ReferralActivityBenefitsInvestigationController(
    $q,
    $state,
    $timeout,
    $uibModal,
    NgTableParams,
    _,
    rmsService,
    __env
) {
    const ctrl = this;
    ctrl.$onChanges = $onChanges;
    ctrl.$onInit = $onInit;
    ctrl.addInsurancePlan = addInsurancePlan;
    ctrl.getInsuranceDifferences = getInsuranceDifferences;
    ctrl.deleteActivity = deleteActivity;
    ctrl.reset = reset;
    ctrl.save = save;
    ctrl.patientInsurance = {};
    ctrl.patientInsuranceUuids = [];
    ctrl.getBiInsurances = {};
    ctrl.getPatientInsurances = {};
    ctrl.selectOption = '';
    ctrl.tempPIUuids = [];
    ctrl.tempBiUuids = [];
    ctrl.diffInsurance = {};
    ctrl.selectDetach = '';
    ctrl.validationInsuranceErrors = {};
    ctrl.validationOfInsurance = [];
    ctrl.selectedInsuranceAll = {};
    ctrl.removeIndexPlan = [];
    ctrl.patientInsuranceAll = {};
    ctrl.getPatientInsurancesTemp = {};
    ctrl.isLoading = true;
    ctrl.isBiInsuranceLoading = false;
    const todaysDate = new Date().toISOString();

    function $onChanges(changes) {
        if (changes.activity && ctrl.activity) {
            ctrl.activity = angular.copy(ctrl.activity);
            ctrl.disableInputs = ctrl.activity.status === 'Deleted';
            ctrl.original = angular.copy(ctrl.activity);

            ctrl.activity.status = ctrl.activity.status ? ctrl.activity.status : 'Pending';

            ctrl.tableParams = new NgTableParams(
                {
                    count: 5,
                    sorting: {
                        medication_name: 'asc',
                    },
                },
                {
                    counts: [],
                    dataset: ctrl.activity.medications,
                }
            );

            if (ctrl.tableParams.total() > ctrl.tableParams.count()) {
                ctrl.tableParams.settings({
                    counts: [5, 10, 25],
                });
            }
        }

        if (changes.insurancePlans && ctrl.insurancePlans) {
            ctrl.insurancePlans = angular.copy(ctrl.insurancePlans);

            if (ctrl.active === undefined) {
                ctrl.active = 0;
            } else {
                $timeout(() => {
                    ctrl.active = ctrl.insurancePlans.length - 1;
                });
            }
        }
    }

    function handleSelectOptions(value) {
        ctrl.selectOption = value;
        const insuranceBiKeys = ctrl.diffInsurance && Object.keys(ctrl.diffInsurance).map(Number);
        const position = insuranceBiKeys.length
            ? insuranceBiKeys.filter((item) => value === ctrl.diffInsurance[item].name)
            : null;
        const selectedObj = {};
        selectedObj[position] = ctrl.diffInsurance[position];
        ctrl.selectedInsuranceAll = JSON.parse(
            JSON.stringify({
                ...ctrl.patientInsurance,
                ...selectedObj,
            })
        );
        if (ctrl.selectOption) {
            for (const item of insuranceBiKeys) {
                if (ctrl.diffInsurance[item].name === ctrl.selectOption) {
                    addReferralActivityBiPlan(
                        ctrl.patientId,
                        [ctrl.diffInsurance[item].uuid],
                        parseInt(ctrl.referralId, 10),
                        parseInt(ctrl.activityId, 10)
                    ).then((res) => {
                        ctrl.isBiInsuranceLoading = true;
                        ctrl.getInsuranceDifferences();
                        mountIt();
                        return res;
                    });
                }
            }
            mountIt();
        }
    }

    function validateInsurancePlan(item, id, value) {
        ctrl.validationInsuranceErrors[item] = ctrl.validationInsuranceErrors[item] || {};
        ctrl.validationInsuranceErrors[item][id] = '';
        if (!value || value === 'Select') {
            if (id === 'name') {
                ctrl.validationInsuranceErrors[item][id] = 'Please enter a Plan Name';
            }
            if (id === 'planPatientId') {
                ctrl.validationInsuranceErrors[item][id] = 'Please enter a Patient ID';
            }
            if (id === 'coverageType') {
                ctrl.validationInsuranceErrors[item][id] = 'Please enter a Benefit Coverage Type';
            }
            if (id === 'payerSegment') {
                ctrl.validationInsuranceErrors[item][id] = 'Please enter a Payor Type';
            }
        }
        mountIt();
    }

    function handlePropChange(item, id, value) {
        ctrl.patientInsurance[item] = ctrl.patientInsurance[item] || {};
        ctrl.patientInsurance[item][id] = value;
        mountIt();
    }

    function removeInsurancePlan(index) {
        ctrl.removeIndexPlan.push(index);
        delete ctrl.patientInsurance[index];
        mountIt();
    }

    function handleDetach(value) {
        ctrl.selectDetach = value;
        mountIt();
    }

    /**
     * Mount react
     */
    mountIt();

    function mountIt() {
        mountReactComponent(
            '#insurance-plans',
            <BiInsurance
                patientInsurance={ctrl.patientInsurance || []}
                handleChangeProps={handlePropChange}
                removeInsurancePlan={removeInsurancePlan}
                handleSelectOptions={handleSelectOptions}
                selectOption={ctrl.selectOption}
                selectedInsuranceAll={ctrl.selectedInsuranceAll}
                insuranceToAttach={ctrl.diffInsurance}
                handleDetach={handleDetach}
                selectDetach={ctrl.selectDetach}
                validationInsuranceErrors={ctrl.validationInsuranceErrors || []}
                validateInsurancePlan={validateInsurancePlan}
                biInsurance
                isBiInsuranceLoading={ctrl.isBiInsuranceLoading}
                isLoading={ctrl.isLoading}
                isDisabled={ctrl.original?.status === 'Completed'}
            />
        );
    }

    function validateInsuranceOnSubmit() {
        ctrl.insuranceKeys = Object.keys(ctrl.patientInsurance);
        const schema = yup.object().shape({
            name: yup.string().nullable().required('Please enter a Plan Name').label('Plan Name'),
            planPatientId: yup.string().nullable().required('Please enter a Patient ID').label('Patient ID'),
            coverageType: yup
                .string()
                .nullable()
                .matches(/\b(?!Select\b)\w+/, 'Please enter a Benefit Coverage Type')
                .required('Please enter a Benefit Coverage Type')
                .label('Benefit Coverage Type'),
            payerSegment: yup
                .string()
                .nullable()
                .matches(/\b(?!Select\b)\w+/, 'Please enter a Payor Type')
                .required('Please enter a Payor Type')
                .label('Payor Type'),
        });

        for (const key of ctrl.insuranceKeys) {
            ctrl.validationInsuranceErrors[key] = ctrl.validationInsuranceErrors[key] || {};
            try {
                schema.validateSync(ctrl.patientInsurance[key], { abortEarly: false });
            } catch (e) {
                if (e.name === 'ValidationError') {
                    for (const error of e.inner) {
                        ctrl.validationInsuranceErrors[key][error.path] = error.errors[0];
                    }
                }
            }

            ctrl.validationOfInsurance = [
                ...new Set(ctrl.validationOfInsurance.concat(Object.values(ctrl.validationInsuranceErrors[key]))),
            ];
        }
        ctrl.validationOfInsurance = ctrl.validationOfInsurance.map((item) => item.replace('Please enter a ', ''));
        return ctrl.validationInsuranceErrors;
    }

    function $onInit() {
        ctrl._ = _;
        ctrl.activityId = $state.params.activityId;
        ctrl.patientId = $state.params.patientId;
        ctrl.referralId = $state.params.referralId;
        ctrl.supportEmail = __env.supportEmail;
        mountIt();
        ctrl.getInsuranceDifferences();
        return setPatientIdentifiers(ctrl).catch((err) => {
            ctrl.displayedId = '';
            return err;
        });
    }

    function addInsurancePlan() {
        const modalInstance = $uibModal.open({
            backdrop: 'static',
            size: 'lg',
            component: 'referralInsuranceAddModal',
            keyboard: false,
            windowClass: 'referral-insurance-add-modal',
            resolve: {
                activityId() {
                    return ctrl.activityId;
                },
                patientId() {
                    return ctrl.patientId;
                },
                referralId() {
                    return ctrl.referralId;
                },
            },
        });

        modalInstance.result
            .then((res) => {
                if (res) {
                    return rmsService
                        .getInsurancePlans(ctrl.patientId, ctrl.referralId, ctrl.activityId)
                        .then((response) => {
                            ctrl.insurancePlans = angular.copy(response);

                            if (ctrl.active === undefined) {
                                ctrl.active = 0;
                            } else {
                                $timeout(() => {
                                    ctrl.active = ctrl.insurancePlans.length - 1;
                                });
                            }

                            return response;
                        });
                }
                return res;
            })
            .catch((err) => {
                return err;
            });
    }

    function deleteActivity() {
        ctrl.disableInputs = true;

        const modalInstance = $uibModal.open({
            backdrop: 'static',
            component: 'confirmationModal',
            size: 'md',
            windowClass: 'delete-confirmation-modal',
            resolve: {
                message() {
                    return 'Are you sure you want to delete this activity?';
                },
                title() {
                    return 'Delete Activity';
                },
            },
        });

        return modalInstance.result
            .then(() => {
                return rmsService
                    .deleteActivity(ctrl.patientId, ctrl.referralId, ctrl.activityId, ctrl.activity)
                    .then(() => {
                        return $state.go('app.referral', {
                            patientId: ctrl.patientId,
                            referralId: ctrl.referralId,
                        });
                    });
            })
            .catch((err) => {
                ctrl.disableInputs = false;
                toast.error(err.data.message ? err.data.message : 'An error occurred while deleting this activity.');
                return err;
            });
    }

    function reset() {
        ctrl.activity = angular.copy(ctrl.original);
    }

    function updateActivity() {
        return rmsService.updateActivity(ctrl.patientId, ctrl.referralId, ctrl.activityId, ctrl.activity);
    }

    function updateMedication() {
        const medications = ctrl.tableParams.settings().dataset;

        return Promise.all(
            medications.map((medication, idx) => {
                const next = {
                    medication_id: medication.medication_id,
                    data: medications[idx].data || {},
                };

                if (medication.rx_number_id) {
                    next.rx_number_id = medication.rx_number_id;
                }

                return rmsService.updateActivityMedication(
                    ctrl.patientId,
                    ctrl.referralId,
                    ctrl.activityId,
                    medication.id,
                    next
                );
            })
        );
    }

    function updateInsurance() {
        if (ctrl.insurancePlans.length) {
            return Promise.all(
                ctrl.insurancePlans.map((insurance) => {
                    return rmsService.updateInsurancePlan(
                        ctrl.patientId,
                        ctrl.referralId,
                        ctrl.activityId,
                        insurance.id,
                        insurance
                    );
                })
            );
        }
    }

    function updateActivityNotes() {
        return rmsService.createReferralActivityNotes(ctrl.activityId, {
            note: null,
            status: ctrl.activity.status,
        });
    }

    function save() {
        ctrl.disableInputs = true;
        ctrl.saving = true;

        ctrl.validationInsuranceErrors = {};
        ctrl.validationOfInsurance = [];

        validateInsuranceOnSubmit();
        if (Object.keys(ctrl.validationInsuranceErrors).length) {
            mountIt();
        }
        if (ctrl.validationOfInsurance.length) {
            ctrl.saving = false;
            ctrl.disableInputs = false;
            return false;
        }

        /**
         * Order matters here. Protocols run when we save the activity, and only when the status or status reason
         * changes. There are filters available in the protocol that look at some of these insurance fields. Therefore,
         * if we save the BI while both making a status/reason change, and one of the filters in a protocol, the
         * protocol needs to run _after_ all of those changes, which means, we need to save the activity last.
         */
        return updateMedication()
            .then(updateActivityNotes)
            .then(updateInsurance)
            .then(submitPatientInsurance)
            .then(removeBIInsurance)
            .then(() => delay(1000))
            .then(updateActivity)
            .then((res) => {
                toast.success('Activity information has been updated successfully.');
                $state.go('app.referral', {
                    patientId: ctrl.patientId,
                    referralId: ctrl.referralId,
                });
                return res;
            })
            .catch(() => {
                toast.error('Activity information did not update successfully. Please try again.');
            })
            .finally(() => {
                ctrl.disableInputs = false;
                ctrl.saving = false;
            });
    }

    function convertToNumber(value) {
        if (typeof value === 'string') {
            return parseFloat(value.replace(/,/g, ''));
        }
        return value;
    }

    function submitPatientInsurance() {
        return new Promise((resolve) => {
            const insuranceIds = Object.keys(ctrl.patientInsurance).map(Number);
            const insurancePromises = [];
            if (hasLength(insuranceIds)) {
                let newTabCounter = 0;
                let counter = 0;
                _.forEach(insuranceIds, (insuranceId) => {
                    ctrl.patientInsurance[insuranceId].deductableAmount = convertToNumber(
                        ctrl.patientInsurance[insuranceId].deductableAmount
                    );

                    ctrl.patientInsurance[insuranceId].deductableAmountPaidToDate = convertToNumber(
                        ctrl.patientInsurance[insuranceId].deductableAmountPaidToDate
                    );

                    ctrl.patientInsurance[insuranceId].pharmacyPlanOopMax = convertToNumber(
                        ctrl.patientInsurance[insuranceId].pharmacyPlanOopMax
                    );

                    ctrl.patientInsurance[insuranceId].pharmacyPlanOopMaxPaidToDate = convertToNumber(
                        ctrl.patientInsurance[insuranceId].pharmacyPlanOopMaxPaidToDate
                    );
                    ctrl.patientInsurance[insuranceId].helpDeskPhone = formatPhone(
                        ctrl.patientInsurance[insuranceId].helpDeskPhone
                    );
                    if (ctrl.patientInsurance[insuranceId].id) {
                        if (ctrl.patientInsurance[insuranceId].patientInsuranceUuid) {
                            const getPatientInsuranceID = ctrl.getPatientInsurancesTemp.filter(
                                (insurance) =>
                                    insurance.uuid === ctrl.patientInsurance[insuranceId].patientInsuranceUuid
                            );
                            ctrl.patientInsurance[insuranceId].id = getPatientInsuranceID[0].id;

                            delete ctrl.patientInsurance[insuranceId].referralId;
                            delete ctrl.patientInsurance[insuranceId].activityId;
                            delete ctrl.patientInsurance[insuranceId].answerId;
                            delete ctrl.patientInsurance[insuranceId].dateTime;
                            delete ctrl.patientInsurance[insuranceId].patientInsuranceUuid;

                            insurancePromises.push(
                                PESService.updatePatientInsurance(
                                    ctrl.patientId,
                                    ctrl.patientInsurance[insuranceId].id,
                                    ctrl.patientInsurance[insuranceId]
                                )
                            );
                        } else {
                            let BIInsuranceSnakeCase = {};
                            const patientInsuranceSnakeCase = Object.assign(
                                {},
                                ...Object.keys(ctrl.patientInsurance[insuranceId]).map((k) => ({
                                    [_.snakeCase(k)]: ctrl.patientInsurance[insuranceId][k],
                                }))
                            );
                            const getPatientInsurancesTempPosition = R.clone(ctrl.getPatientInsurancesTemp);
                            const patientInsurancePosition = getPatientInsurancesTempPosition.map((item) =>
                                R.omit(
                                    [
                                        'disabledOn',
                                        'disabledBy',
                                        'disabledByUser',
                                        'uuid',
                                        'id',
                                        'updatedOn',
                                        'updatedBy',
                                        'createdOn',
                                        'createdBy',
                                        'coverageEffectiveDate',
                                        'coverageEndDate',
                                    ],
                                    item
                                )
                            );
                            insurancePromises.push(
                                rmsService
                                    .updateInsurancePlan(
                                        ctrl.patientId,
                                        ctrl.referralId,
                                        ctrl.activityId,
                                        patientInsuranceSnakeCase.id,
                                        patientInsuranceSnakeCase
                                    )
                                    .then(() => {
                                        ctrl.patientInsurance[insuranceId] = R.omit(
                                            [
                                                'referralId',
                                                'activityId',
                                                'answerId',
                                                'dateTime',
                                                'patientInsuranceUuid',
                                                'id',
                                                'createdOn',
                                                'coverageEffectiveDate',
                                                'coverageEndDate',
                                            ],
                                            ctrl.patientInsurance[insuranceId]
                                        );
                                        ctrl.patientInsuranceArray = ctrl.patientInsurance[insuranceId];
                                        if (
                                            Object.keys(patientInsurancePosition).length &&
                                            !_.some(patientInsurancePosition, ctrl.patientInsurance[insuranceId])
                                        ) {
                                            ctrl.patientInsurance[insuranceId].position =
                                                _.maxBy(patientInsurancePosition, 'position').position + counter + 1;
                                            counter += 1;
                                        }
                                        ctrl.patientInsuranceObject = {
                                            insurance: [ctrl.patientInsurance[insuranceId]],
                                        };
                                        return PESService.addPatientInsurance(
                                            ctrl.patientId,
                                            ctrl.patientInsuranceObject
                                        );
                                    })
                                    .then((res) => {
                                        const updatedInsurance = Object.values(res);
                                        updatedInsurance[0].id = patientInsuranceSnakeCase.id;
                                        updatedInsurance[0].patient_insurance_uuid = updatedInsurance[0].uuid;
                                        delete updatedInsurance[0].uuid;
                                        BIInsuranceSnakeCase = Object.assign(
                                            {},
                                            ...Object.keys(updatedInsurance[0]).map((k) => ({
                                                [_.snakeCase(k)]: updatedInsurance[0][k],
                                            }))
                                        );
                                    })
                                    .then(() => {
                                        return rmsService.updateInsurancePlan(
                                            ctrl.patientId,
                                            ctrl.referralId,
                                            ctrl.activityId,
                                            BIInsuranceSnakeCase.id,
                                            BIInsuranceSnakeCase
                                        );
                                    })
                                    .catch(errorHandler)
                            );
                        }
                    } else {
                        const getBiInsuranceKeys = Object.keys(ctrl.getBiInsurances)
                            .filter((item) => item !== 'null')
                            .map(Number);
                        const concatBiProfile = [...new Set([...insuranceIds, ...getBiInsuranceKeys])].map(Number);
                        ctrl.patientInsuranceArray = {
                            ...ctrl.patientInsurance[insuranceId],
                            position:
                                getBiInsuranceKeys?.length || concatBiProfile?.length
                                    ? Math.max(...concatBiProfile) + newTabCounter + 1
                                    : newTabCounter + 1,
                            disabledOn:
                                ctrl.patientInsurance[insuranceId]?.coverageEndDate <= todaysDate ? new Date() : null,
                            disabledBy:
                                ctrl.patientInsurance[insuranceId]?.coverageEndDate <= todaysDate ? ctrl.user.id : null,
                        };

                        ctrl.patientInsuranceObject = {
                            insurance: [ctrl.patientInsuranceArray],
                        };

                        newTabCounter += 1;
                        insurancePromises.push(
                            PESService.addPatientInsurance(ctrl.patientId, ctrl.patientInsuranceObject)
                                .then((res) => {
                                    const insuranceKeys = Object.keys(res);
                                    ctrl.patientInsuranceUuids = insuranceKeys.map((item) => {
                                        return res[item].uuid;
                                    });
                                })
                                .then(() => {
                                    return addReferralActivityBiPlan(
                                        ctrl.patientId,
                                        ctrl.patientInsuranceUuids,
                                        parseInt(ctrl.referralId, 10),
                                        parseInt(ctrl.activityId, 10)
                                    );
                                })
                        );
                    }
                });
            }
            resolve(Promise.all(insurancePromises));
        });
    }

    function getInsuranceDifferences() {
        let max = 0;
        const getAllInsurances = [];
        getAllInsurances.push(
            PESService.getPatientInsurance(ctrl.patientId).then((res) => {
                ctrl.getPatientInsurances = _.values(res);
                ctrl.getPatientInsurancesTemp = ctrl.getPatientInsurances && R.clone(ctrl.getPatientInsurances);
            })
        );
        getAllInsurances.push(
            getReferralActivityBiPlan(
                ctrl.patientId,
                parseInt(ctrl.referralId, 10),
                parseInt(ctrl.activityId, 10)
            ).then((res) => {
                ctrl.getBiInsurancesArray = res;
                ctrl.getBiInsurances = R.clone(res || []);
            })
        );
        return $q.all(getAllInsurances).then(() => {
            ctrl.tempPIUuids = ctrl.getPatientInsurances
                .filter(
                    (item) =>
                        item.disabledOn === null && (item.coverageEndDate === null || item.coverageEndDate > todaysDate)
                )
                .map((item) => {
                    return R.omit(
                        [
                            'disabledOn',
                            'disabledBy',
                            'disabledByUser',
                            'id',
                            'updatedOn',
                            'updatedBy',
                            'createdOn',
                            'createdBy',
                            'coverageEffectiveDate',
                            'coverageEndDate',
                        ],
                        item
                    );
                });

            for (const insurance of ctrl.getBiInsurances) {
                if (insurance.position > max) {
                    max = insurance.position;
                }
            }
            for (const insurance of ctrl.getBiInsurances) {
                if (insurance.position === 0) {
                    max += 1;
                    insurance.position = max;
                }
            }
            ctrl.getBiInsurances = ctrl.getBiInsurances.filter(
                (insurance) =>
                    insurance && (insurance.coverageEndDate === null || insurance.coverageEndDate > todaysDate)
            );
            ctrl.patientInsurance = keyBy(ctrl.getBiInsurances, 'position');
            ctrl.patientInsuranceAll = R.clone(ctrl.patientInsurance);
            ctrl.tempBiUuids =
                ctrl.getBiInsurancesArray.length &&
                ctrl.getBiInsurancesArray.map((item) => {
                    const insuranceItem = {
                        ...item,
                        uuid: item.patientInsuranceUuid,
                    };

                    return R.omit(
                        [
                            'id',
                            'referralId',
                            'activityId',
                            'dateTime',
                            'answerId',
                            'patientInsuranceUuid',
                            'createdOn',
                            'coverageEffectiveDate',
                            'coverageEndDate',
                        ],
                        insuranceItem
                    );
                });
            const diffInsuranceArray = _.differenceWith(ctrl.tempPIUuids, ctrl.tempBiUuids, _.isEqual);
            const diffInsuranceUuids = diffInsuranceArray.map((item) => item.uuid);
            ctrl.diffInsurance = keyBy(
                ctrl.getPatientInsurancesTemp.length &&
                    ctrl.getPatientInsurancesTemp.filter((item) => diffInsuranceUuids.includes(item.uuid)),
                'position'
            );
            ctrl.isLoading = false;
            ctrl.isBiInsuranceLoading = false;
            mountIt();
        });
    }

    function removeBIInsurance() {
        const referralActivityBiPlanPromises = [];
        if (ctrl.removeIndexPlan.length) {
            for (const item of ctrl.removeIndexPlan) {
                if (ctrl.patientInsuranceAll[item]?.id) {
                    referralActivityBiPlanPromises.push(
                        deleteReferralActivityBiPlan(
                            ctrl.patientId,
                            parseInt(ctrl.referralId, 10),
                            parseInt(ctrl.activityId, 10),
                            ctrl.patientInsuranceAll[item].patientInsuranceUuid
                        ).catch(errorHandler)
                    );
                }
            }
            return Promise.all(referralActivityBiPlanPromises);
        }
    }
}

export default ReferralActivityBenefitsInvestigationController;
