import PropTypes from 'prop-types';
import React from 'react';

import useFetchPatientMedicationRxNumber from 'App/hooks/useFetchPatientMedicationRxNumber';
import dateOrDash from 'App/utils/dateOrDash';
import findRelevantRxFill from 'App/utils/findRelevantRxFill';
import pathOrDash from 'App/utils/pathOrDash';

const propTypes = {
    patientId: PropTypes.string.isRequired,
    medication: PropTypes.shape({
        id: PropTypes.string,
    }).isRequired,
};

const defaultProps = {};

function RxFillNumberSideBar({ patientId, medication }) {
    const { data: prescription } = useFetchPatientMedicationRxNumber(patientId, medication.id);

    return (
        <div className="patient-rx-fill">
            {prescription?.map((rx) => {
                const fill = findRelevantRxFill(rx.rxFill);
                return (
                    <div key={rx.id}>
                        <span className="rx-fill-label">Rx# </span>
                        <span>{pathOrDash(['rxNumber'], rx)}</span> <br />
                        <span className="rx-fill-label">Rx Strength </span>
                        <span>{pathOrDash(['strength'], rx)}</span>
                        <br />
                        <span className="rx-fill-label">Rx Written </span>
                        <span>{dateOrDash(['start'], rx)}</span> <br />
                        <span className="rx-fill-label">Rx Exp. </span>
                        <span>{dateOrDash(['fillExpiration'], rx)}</span> <br />
                        <span className="rx-fill-label">Prescriber </span>
                        <span>
                            {rx.physician?.firstName || rx.physician?.lastName
                                ? `${rx.physician.firstName} ${rx.physician.lastName}`
                                : '--'}
                        </span>
                        <br />
                        <span className="rx-fill-label">Refills Allowed </span>
                        <span>{pathOrDash(['refillsAuthorized'], rx)}</span>
                        <br />
                        {fill && (
                            <div className="patient-rx-fill">
                                <div>
                                    <span className="rx-fill-label">Fill# </span>
                                    <span>
                                        {fill?.fillNumber === 0 ? (
                                            <span>{fill.fillNumber}</span>
                                        ) : (
                                            <span>{pathOrDash(['fillNumber'], fill)}</span>
                                        )}
                                    </span>
                                </div>
                                <div>
                                    <span className="rx-fill-label">Fill Date </span>
                                    <span>{dateOrDash(['fillDate'], fill)}</span>
                                </div>
                                <div>
                                    <span className="rx-fill-label">Dispensed Qty </span>
                                    <span>{pathOrDash(['dispensedQuantity'], fill)}</span>
                                </div>
                                <div>
                                    <span className="rx-fill-label">Days Supply </span>
                                    <span>{pathOrDash(['daysSupply'], fill)}</span>
                                </div>
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
}

RxFillNumberSideBar.propTypes = propTypes;
RxFillNumberSideBar.defaultProps = defaultProps;

export default RxFillNumberSideBar;
