import styled from '@emotion/styled';
import React, { useState } from 'react';

import { generatePdf } from 'App/services/AMSService';
import { patientPropType } from 'Lib/types';
import TherigyLoadingSpinner from '../TherigyLoadingSpinner';
import { cdnURL } from '../config/config';
import { activityPropType } from './types';

const PdfButtonImage = styled.img`
    height: 45px;
    cursor: pointer;
`;

const AssessmentGeneratePDF = ({ activity, patient }) => {
    const [isPdfLoading, setIsPdfLoading] = useState(false);
    const isPreview = !patient.id;

    const handleGeneratePdf = async () => {
        setIsPdfLoading(true);

        const base = document.createElement('base');
        base.href = window.location.origin;
        document.head.insertBefore(base, document.head.firstChild);

        const styles = [...document.querySelectorAll('style[data-emotion]')]
            .flatMap(({ sheet }) => [...sheet.cssRules].map((rules) => rules.cssText))
            .join('\n');
        const styleElement = document.createElement('style');
        styleElement.textContent = styles;
        document.head.appendChild(styleElement);

        const htmlString = document.documentElement.outerHTML;

        try {
            await generatePdf(htmlString, isPreview ? 'preview' : 'activity', activity.name, activity.id);
        } finally {
            setIsPdfLoading(false);
        }
    };

    return (
        <div className="assessment-generate-pdf-container">
            {isPdfLoading ? (
                <TherigyLoadingSpinner />
            ) : (
                <PdfButtonImage
                    src={`${cdnURL}/images/PDF-231x300.png`}
                    alt="Generate PDF"
                    onClick={handleGeneratePdf}
                />
            )}
        </div>
    );
};

AssessmentGeneratePDF.propTypes = {
    activity: activityPropType.isRequired,
    patient: patientPropType.isRequired,
};

export default AssessmentGeneratePDF;
