import React from 'react';
import PropTypes from 'prop-types';
import { generatePath, Link } from 'react-router-dom';
import { patientRoute, referralDetailRoute } from 'App/common/routeLookup';

const propTypes = {
    patientId: PropTypes.string.isRequired,
    referralId: PropTypes.oneOfType([
        /**
         * These are usually always a number, but when they come from the URL, they are a string.
         */
        PropTypes.number,
        PropTypes.string,
    ]).isRequired,
};

const defaultProps = {};

function BackToLinks({ patientId, referralId }) {
    return (
        <nav className="profile-actions">
            <ul className="list-inline">
                <li>
                    <Link to={generatePath(patientRoute, { patientId })}>Back to Patient Profile</Link>
                </li>
                <li>
                    <Link to={generatePath(referralDetailRoute, { patientId, referralId })}>Back to Referral</Link>
                </li>
            </ul>
        </nav>
    );
}

BackToLinks.propTypes = propTypes;
BackToLinks.defaultProps = defaultProps;

export default BackToLinks;
