import React, { useEffect, useMemo } from 'react';
import * as R from 'ramda';
import { DatePickerField } from 'Lib/form/DatePickerField';
import { useFormikContext } from 'formik';
import { Col, Row } from 'react-bootstrap';
import SelectField from 'Lib/form/SelectField';
import MoneyInput from 'App/components/PatientInsurance/MoneyInput';
import { BI_STATUS_TYPE } from '../../PriorAuthorization/constant';
import CustomMenuField from 'Lib/form/CustomMenu';
import { TextareaField } from 'Lib/form/TextareaField';

const InformationForm = () => {
    const { initialValues, values, setFieldValue } = useFormikContext();
    const { disableActivity, disableInputs } = values;
    const isStatusDeleted = useMemo(() => values?.status?.id === BI_STATUS_TYPE.DELETED, [values?.status]);
    const statusOptions = useMemo(() => {
        const defaultStatusOptions = Object.values(BI_STATUS_TYPE).map((it) => ({
            id: it,
            name: it,
        }));
        if (!isStatusDeleted) {
            return defaultStatusOptions.filter((it) => it !== BI_STATUS_TYPE.DELETED);
        }
        return defaultStatusOptions;
    }, [isStatusDeleted]);

    useEffect(() => {
        const computedDisableInputs = initialValues?.status?.id === BI_STATUS_TYPE.DELETED;
        const computeDisableActivity = computedDisableInputs || initialValues?.status?.id === BI_STATUS_TYPE.COMPLETED;
        setFieldValue('disableActivity', computeDisableActivity);
        setFieldValue('disableInputs', computedDisableInputs);
    }, [initialValues?.status, setFieldValue]);

    return (
        <>
            <Row>
                <Col md={3}>
                    <DatePickerField
                        fieldName="createdOn"
                        label="Start Date"
                        disabled={disableActivity || disableInputs}
                        placeholder="YYYY/MM/DD"
                    />
                </Col>
                <Col md={3}>
                    <DatePickerField
                        id="activity-due-on"
                        fieldName="dueOn"
                        label="Due Date"
                        disabled={disableActivity || disableInputs}
                        placeholder="YYYY/MM/DD"
                    />
                </Col>
                <Col md={3}>
                    <DatePickerField
                        fieldName="details.completeDate"
                        label="Complete Date"
                        disabled={disableActivity || disableInputs}
                        placeholder="YYYY/MM/DD"
                    />
                </Col>
                <Col md={3}>
                    <MoneyInput
                        name="patientTotalResponsibilityAmount"
                        id="patientTotalResponsibilityAmount"
                        disabled={disableActivity || disableInputs}
                        placeholder="Patient Total Responsibility"
                        label="Patient Total Responsibility"
                        onChange={(event) => {
                            setFieldValue('details.patientTotalResponsibilityAmount', event.target.value);
                        }}
                        value={values.details?.patientTotalResponsibilityAmount || ''}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <SelectField
                        id="status"
                        options={statusOptions}
                        label="Status"
                        fieldName="status"
                        isDisabled={disableActivity || disableInputs}
                        getOptionValue={R.prop('id')}
                        getOptionLabel={R.prop('name')}
                    />
                </Col>
                <Col md={3}>
                    <CustomMenuField
                        fieldName="details.reasonCode"
                        isDisabled={disableActivity || disableInputs}
                        label="Status Reason"
                        menuName="referral_BI_Activity_Reason"
                    />
                </Col>
                <Col md={3}>
                    <CustomMenuField
                        fieldName="details.initialMedSiteOfCare"
                        isDisabled={disableActivity || disableInputs}
                        label="Initial Medication Site Of Care"
                        menuName="referral_BI_Initial_Site_of_Care"
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <TextareaField
                        fieldName="details.notes"
                        label="Notes"
                        placeholder="Notes"
                        disabled={disableActivity || disableInputs}
                        maxLength={255}
                        rows={3}
                    />
                </Col>
            </Row>
        </>
    );
};
export default InformationForm;
