import React, { useMemo, useState } from 'react';
import PatientHeader from 'App/components/PatientHeader';
import { Button, Col, Row } from 'react-bootstrap';
import BackToLinks from 'App/features/ReferralManager/Referral/BackToLinks';
import { Formik } from 'formik';
import SelectField from 'Lib/form/SelectField';
import { CheckboxMultiField } from 'Lib/form/CheckboxMultiField';
import { DatePickerField } from 'Lib/form/DatePickerField';
import { TextareaField } from 'Lib/form/TextareaField';
import FormButtonGroup from 'App/components/styles/FormButtonGroup';
import SubmitButton from 'Lib/form/SubmitButton';
import * as R from 'ramda';
import { patientPropType } from 'Lib/types';
import PropTypes from 'prop-types';
import { appealDocumentOptions, statuses } from './constants';
import OverturnedForm from './OverturnedForm';
import * as Yup from 'yup';
import dayjs from 'Lib/dayjs';
import useUpdateReferralActivityMutation from 'App/hooks/useUpdateReferralActivityMutation';
import convertKeysToSnakeCase from 'App/utils/keyConversion/convertKeysToSnakeCase';
import { convertAppealToSave } from 'App/features/ReferralManager/Referral/referral.utils';
import { useHistory } from 'react-router-dom';
import useCreateAppealDocumentMutation from 'App/hooks/useCreateAppealDocumentMutation';
import toast from 'Lib/toast';
import useUpdateReferralMedicationMutation from 'App/hooks/useUpdateReferralMedicationMutation';
import useCreateReferralActivityNoteMutation from 'App/hooks/useCreateReferralActivityNoteMutation';
import UpheldForm from './UpheldForm';
import useCreateReferralActivityMutation from 'App/hooks/useCreateReferralActivityMutation';
import errorHandler from 'App/utils/errorHandler';
import useDeleteReferralActivity from 'App/hooks/useDeleteReferralActivity';
import DeleteModal from 'App/components/DeleteModal';

const propTypes = {
    patient: patientPropType.isRequired,
    referralId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    activity: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
    priorAuthsOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const defaultProps = {};

const validationSchema = Yup.object().shape({
    status: Yup.object().nullable().required().label('Status'),
    details: Yup.object().when('status', {
        is: R.compose(R.equals('Overturned'), R.prop('value')),
        then: Yup.object({
            paActivityId: Yup.object().nullable().required().label('Prior Authorization to Appeal'),
            paNumber: Yup.string().label('PA Number').required(),
            followUpPhone: Yup.number()
                .test((d) => {
                    return String(d).length === 10;
                })
                .label('PA Follow Up Phone Number')
                .required(),
            effectiveStartDate: Yup.mixed()
                .test((val) => {
                    return dayjs(val).isValid();
                })
                .label('Effective Start Date')
                .required(),
            effectiveEndDate: Yup.mixed()
                .test((val) => {
                    return dayjs(val).isValid();
                })
                .label('Effective End Date')
                .required(),
            notes: Yup.string().max(255).label('Notes'),
        }),
        otherwise: Yup.object({
            paActivityId: Yup.object().nullable().required().label('Prior Authorization to Appeal'),
            paNumber: Yup.mixed().label('PA Number').notRequired(),
            followUpPhone: Yup.mixed().label('PA Follow Up Phone Number').notRequired(),
            effectiveStartDate: Yup.mixed().label('Effective Start Date').notRequired(),
            effectiveEndDate: Yup.mixed().label('Effective End Date').notRequired(),
            notes: Yup.string().max(255).label('Notes'),
        }).nullable(),
    }),
});

function setInitialStatus(statusNames) {
    return R.compose(
        R.map(
            R.applySpec({
                value: R.identity,
                label: R.identity,
                disabled: R.compose(R.ifElse(R.equals(statuses.NEW), R.T, R.F)),
            })
        ),
        R.values,
        R.pick(statusNames)
    )(statuses);
}

function Appeal({ patient, referralId, activity, priorAuthsOptions }) {
    const history = useHistory();

    const originalStatus = R.path(['status', 'value'], activity);

    const statusOptions = useMemo(() => {
        if (originalStatus === statuses.NEW) {
            return setInitialStatus(['NEW', 'PENDING_SUBMISSION', 'SUBMITTED']);
        }

        if (originalStatus === statuses.PENDING_SUBMISSION) {
            return setInitialStatus(['PENDING_SUBMISSION', 'SUBMITTED']);
        }

        if (originalStatus === statuses.SUBMITTED) {
            return setInitialStatus(['PENDING_SUBMISSION', 'SUBMITTED', 'OVERTURNED', 'UPHELD']);
        }

        return R.compose(
            R.map(R.applySpec({ value: R.identity, label: R.identity, disabled: R.F })),
            R.values
        )(statuses);
    }, [originalStatus]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const updateActivityMutation = useUpdateReferralActivityMutation();
    const createAppealDocumentMutation = useCreateAppealDocumentMutation(patient.id, referralId, activity.id);
    const updateMedicationMutation = useUpdateReferralMedicationMutation(patient.id, referralId);
    const createReferralActivityNoteMutation = useCreateReferralActivityNoteMutation(activity.id);
    const createReferralActivityMutation = useCreateReferralActivityMutation();
    const deleteReferralActivityMutation = useDeleteReferralActivity();

    const backToReferral = () => {
        history.push(`/patient/${patient.id}/referral/${referralId}`);
    };

    const deleteActivity = () => {
        return deleteReferralActivityMutation
            .mutateAsync({
                patientId: patient.id,
                referralId,
                activityId: activity.id,
            })
            .then(backToReferral);
    };

    return (
        <Row>
            <Col md={12} mdOffset={0}>
                {/*Header*/}
                <Row>
                    <Col md={12}>
                        <PatientHeader patient={patient} showEhrStatus={false} />
                    </Col>
                </Row>

                {/*Links*/}
                <Row>
                    <Col md={12}>
                        <BackToLinks patientId={patient.id} referralId={referralId} />
                    </Col>
                </Row>

                {/*Form*/}
                <Row>
                    <Col md={12}>
                        <Formik
                            initialValues={activity}
                            onSubmit={(values) => {
                                const data = R.compose(
                                    R.compose(
                                        R.assoc('patientId', patient.id),
                                        R.assoc('referralId', referralId),
                                        R.assoc('activityId', activity.id)
                                    ),
                                    R.chain(R.assoc('data'), R.compose(convertKeysToSnakeCase, convertAppealToSave))
                                )(values);

                                const promises = [
                                    // update activity
                                    updateActivityMutation
                                        .mutateAsync(data)
                                        .then((appeal) => {
                                            // update appeal docs
                                            return Promise.all(
                                                values.appealDocs.map(
                                                    R.compose(
                                                        createAppealDocumentMutation.mutateAsync,
                                                        R.applySpec({
                                                            answer_id: R.always(appeal.answerId),
                                                            name: R.identity,
                                                        })
                                                    )
                                                )
                                            );
                                        })
                                        .then(() => {
                                            /**
                                             * Create activity notes
                                             * Original work from Angular was explicitly setting note to null for some reason.
                                             * This seems to work, so that's how I left it.
                                             */
                                            return createReferralActivityNoteMutation.mutateAsync({
                                                note: null,
                                                status: values.status.value,
                                            });
                                        }),
                                ];

                                if (values.details.scheduleFollowupAppeal?.value === 'Yes') {
                                    promises.push(
                                        // Create follow up appeal, if requested.
                                        createReferralActivityMutation.mutateAsync({
                                            patientId: patient.id,
                                            referralId,
                                            body: {
                                                /**
                                                 * The original API call is using a string here instead of a number.
                                                 * Why? Who knows? ¯\_(ツ)_/¯
                                                 */
                                                type: '3',
                                                details: { pa_activity_id: values.details.paActivityId.id },
                                                medication: values.medications.map(
                                                    R.applySpec({
                                                        medication_id: R.prop('medicationId'),
                                                    })
                                                ),
                                            },
                                        })
                                    );
                                }

                                return Promise.all(promises)
                                    .then(() => {
                                        // update medications
                                        return Promise.all([
                                            ...R.compose(
                                                R.map(
                                                    R.compose(
                                                        updateMedicationMutation.mutateAsync,
                                                        R.applySpec({
                                                            id: R.prop('id'),
                                                            activity_id: R.prop('activityId'),
                                                            medication_id: R.prop('medicationId'),
                                                            rx_number_id: R.prop('id'),
                                                            data: {
                                                                enabled: R.pathOr(null, ['data', 'enabled']),
                                                                authorized_quantity: R.pathOr(null, [
                                                                    'data',
                                                                    'authorizedQuantity',
                                                                ]),
                                                                authorized_uom: R.pathOr(null, [
                                                                    'data',
                                                                    'authorizedUom',
                                                                    'value',
                                                                ]),
                                                                ndc_specific_approval: R.compose(
                                                                    R.cond([
                                                                        [R.equals('Yes'), R.always('1')],
                                                                        [R.equals('No'), R.always('2')],
                                                                        [R.T, R.always('')],
                                                                    ]),
                                                                    R.path(['data', 'ndcSpecificApproval', 'value'])
                                                                ),
                                                                approved_ndc: R.pathOr(null, ['data', 'approvedNdc']),
                                                                rx_number: R.pathOr(null, ['data', 'rxNumber']),
                                                            },
                                                        })
                                                    )
                                                ),
                                                R.filter(R.prop('data'))
                                            )(values.medications),
                                        ]);
                                    })
                                    .then(() => toast.success('Activity information has been updated successfully.'))
                                    .then(backToReferral)
                                    .catch(errorHandler);
                            }}
                            validationSchema={validationSchema}
                        >
                            {({ handleSubmit, values, setValues, setTouched, touched, isSubmitting }) => {
                                const currentStatus = R.path(['status', 'value'], values);

                                const isFormReadOnly = R.anyPass([
                                    R.equals(statuses.UPHELD),
                                    R.equals(statuses.OVERTURNED),
                                    R.equals(statuses.DELETED),
                                    R.always(isSubmitting),
                                ])(originalStatus);

                                return (
                                    <form onSubmit={handleSubmit}>
                                        {/*Status*/}
                                        <Row>
                                            <Col md={4}>
                                                <SelectField
                                                    required
                                                    isDisabled={isFormReadOnly}
                                                    label="Status"
                                                    fieldName="status"
                                                    isOptionDisabled={R.prop('disabled')}
                                                    options={statusOptions}
                                                    onChange={(option) => {
                                                        let nextValues = values;
                                                        let nextTouched = touched;

                                                        /**
                                                         * Reset form values when status changes. This is to prevent silly things like
                                                         * Filling out the over turned form, then changing the status back and saving
                                                         * and creating a follow-up accidentally.
                                                         */
                                                        if (statuses.OVERTURNED !== option?.value) {
                                                            nextValues = R.compose(
                                                                R.set(R.lensPath(['details', 'paNumber']), null),
                                                                R.set(R.lensPath(['details', 'followUpPhone']), null),
                                                                R.set(
                                                                    R.lensPath(['details', 'effectiveStartDate']),
                                                                    null
                                                                ),
                                                                R.set(
                                                                    R.lensPath(['details', 'effectiveEndDate']),
                                                                    null
                                                                ),
                                                                R.set(
                                                                    R.lensPath(['details', 'methodApprovalReceived']),
                                                                    null
                                                                ),
                                                                R.set(
                                                                    R.lensPath([
                                                                        'details',
                                                                        'benefitRequiresReVerification',
                                                                    ]),
                                                                    null
                                                                )
                                                            )(nextValues);

                                                            nextTouched = R.compose(
                                                                R.set(R.lensPath(['details', 'paNumber']), false),
                                                                R.set(R.lensPath(['details', 'followUpPhone']), false),
                                                                R.set(
                                                                    R.lensPath(['details', 'effectiveStartDate']),
                                                                    false
                                                                ),
                                                                R.set(
                                                                    R.lensPath(['details', 'effectiveEndDate']),
                                                                    false
                                                                ),
                                                                R.set(
                                                                    R.lensPath(['details', 'methodApprovalReceived']),
                                                                    false
                                                                ),
                                                                R.set(
                                                                    R.lensPath([
                                                                        'details',
                                                                        'benefitRequiresReVerification',
                                                                    ]),
                                                                    false
                                                                )
                                                            )(touched);
                                                        }

                                                        if (statuses.UPHELD !== option?.value) {
                                                            nextValues = R.compose(
                                                                R.set(
                                                                    R.lensPath(['details', 'scheduleFollowupAppeal']),
                                                                    null
                                                                )
                                                            )(nextValues);

                                                            nextTouched = R.compose(
                                                                R.set(
                                                                    R.lensPath(['details', 'scheduleFollowupAppeal']),
                                                                    false
                                                                )
                                                            )(nextTouched);
                                                        }

                                                        R.compose(
                                                            setValues,
                                                            R.set(R.lensProp('status'), option)
                                                        )(nextValues);

                                                        setTouched(nextTouched);
                                                    }}
                                                />
                                            </Col>
                                        </Row>

                                        {/*Prior Auth*/}
                                        <Row>
                                            <Col md={4}>
                                                <SelectField
                                                    required
                                                    isDisabled={isFormReadOnly}
                                                    label="Prior Authorization to Appeal"
                                                    fieldName="details.paActivityId"
                                                    getOptionValue={R.prop('id')}
                                                    options={priorAuthsOptions}
                                                />
                                            </Col>
                                        </Row>

                                        {/*Appeal Docs*/}
                                        <Row>
                                            <Col md={4}>
                                                <fieldset>
                                                    <legend> Select documents for appeals submission</legend>
                                                    <CheckboxMultiField
                                                        style={{ marginLeft: '3rem', marginBottom: '3rem' }}
                                                        disabled={isFormReadOnly}
                                                        id="appealDocs"
                                                        fieldName="appealDocs"
                                                        options={appealDocumentOptions}
                                                    />
                                                </fieldset>
                                            </Col>
                                        </Row>

                                        {/*Submission Date*/}
                                        <Row>
                                            <Col md={4}>
                                                <DatePickerField
                                                    disabled={isFormReadOnly}
                                                    label="Appeal Submission Date"
                                                    fieldName="details.appealDate"
                                                    placeholder="YYYY-MM-DD"
                                                />
                                            </Col>
                                        </Row>

                                        {/*If Overturned*/}

                                        {R.cond([
                                            [
                                                R.anyPass([
                                                    R.propEq(statuses.OVERTURNED, 'originalStatus'),
                                                    R.propEq(statuses.OVERTURNED, 'currentStatus'),
                                                ]),
                                                () => (
                                                    <Row>
                                                        <Col md={12}>
                                                            <OverturnedForm isFormDisabled={isFormReadOnly} />
                                                        </Col>
                                                    </Row>
                                                ),
                                            ],
                                            [
                                                R.anyPass([
                                                    R.propEq(statuses.UPHELD, 'originalStatus'),
                                                    R.propEq(statuses.UPHELD, 'currentStatus'),
                                                ]),
                                                () => (
                                                    <Row>
                                                        <Col md={12}>
                                                            <UpheldForm isFormDisabled={isFormReadOnly} />
                                                        </Col>
                                                    </Row>
                                                ),
                                            ],
                                        ])({ originalStatus, currentStatus })}

                                        {/*Notes*/}
                                        <Row>
                                            <Col md={values.status.value === statuses.OVERTURNED ? 8 : 4}>
                                                <TextareaField
                                                    disabled={isFormReadOnly}
                                                    fieldName="details.notes"
                                                    label="Notes"
                                                    placeholder="Notes"
                                                />
                                            </Col>
                                        </Row>

                                        {/*CTA*/}
                                        <Row>
                                            <Col md={values.status.value === statuses.OVERTURNED ? 8 : 4}>
                                                <FormButtonGroup>
                                                    <Button
                                                        bsStyle="danger"
                                                        disabled={isFormReadOnly}
                                                        onClick={() => setShowDeleteModal(true)}
                                                    >
                                                        Delete
                                                    </Button>
                                                    <Button onClick={backToReferral}>Cancel</Button>
                                                    <SubmitButton disabled={isFormReadOnly}>Save Activity</SubmitButton>
                                                </FormButtonGroup>
                                            </Col>
                                        </Row>
                                    </form>
                                );
                            }}
                        </Formik>
                    </Col>
                </Row>
            </Col>
            {showDeleteModal ? (
                <DeleteModal
                    onHide={() => setShowDeleteModal(false)}
                    show
                    title="Delete Activity"
                    onDelete={deleteActivity}
                />
            ) : null}
        </Row>
    );
}

Appeal.propTypes = propTypes;
Appeal.defaultProps = defaultProps;

export default Appeal;
