import React from 'react';
import PropTypes from 'prop-types';
import ClickLink from 'App/components/styles/ClickLink';

const propTypes = {
    showMessagingModal: PropTypes.func.isRequired,
    showHistoryModal: PropTypes.func.isRequired,
    showUnenrollmentModal: PropTypes.func.isRequired,
};

const defaultProps = {};

function Subscribed({ showMessagingModal, showHistoryModal, showUnenrollmentModal }) {
    return (
        <>
            <div style={{ flexGrow: 1, flexBasis: '50%' }}>
                <i className="fas fa-fw fa-check text-success" />
                <span>
                    Patient Messaging: <span id="engage-patient-messaging-enrollment-status">Enrolled</span>
                    {' | '}
                </span>
                <ClickLink onClick={showMessagingModal}>Edit</ClickLink>
            </div>
            <div>
                <ClickLink onClick={showHistoryModal}>
                    <i className="fa fa-fw fa-history" id="view-engage-patient-messaging-history-link" /> View Patient
                    Messaging History
                </ClickLink>
            </div>
            <div style={{ flexGrow: 1, flexBasis: '50%' }}>
                <ClickLink onClick={showUnenrollmentModal}>Unenroll Patient</ClickLink>
            </div>
        </>
    );
}

Subscribed.propTypes = propTypes;
Subscribed.defaultProps = defaultProps;

export default Subscribed;
