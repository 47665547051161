import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { getDefaultWorkQueueState } from './defaultWorkQueueState';
import SessionStorageService from 'App/services/SessionStorageService';
import { toDate } from 'App/services/DateService';
import { transformLsFilterToReact } from './workQueue.utils';
import dayjs from 'dayjs';

const propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
};

const defaultProps = {};
const defaultWorkQueueState = getDefaultWorkQueueState();

const validationSchema = Yup.object().shape({
    filterParams: Yup.object().shape({
        dateStart: Yup.date()
            .nullable()
            .test('dateStart-before-dateEnd', function test(value) {
                const { dateEnd, type } = this.parent;
                if (type !== 'custom') {
                    return true;
                }
                if (!value) {
                    return this.createError({
                        path: 'filterParams.dateStart',
                        message: 'The date range start is required',
                    });
                }
                if (dateEnd && dayjs(value).isAfter(dayjs(dateEnd))) {
                    return this.createError({
                        path: 'filterParams.dateStart',
                        message: `The date range start cannot be past ${dayjs(dateEnd).format('YYYY-MM-DD')}`,
                    });
                }
                return true;
            }),
        dateEnd: Yup.date()
            .nullable()
            .test('dateEnd-after-dateStart', function test(value) {
                const { dateStart, type } = this.parent;
                if (type !== 'custom') {
                    return true;
                }
                if (!value) {
                    return this.createError({
                        path: 'filterParams.dateEnd',
                        message: 'The date range end is required',
                    });
                }
                if (!dateStart || dayjs(value).isBefore(dayjs(dateStart))) {
                    return this.createError({
                        path: 'filterParams.dateEnd',
                        message: `The date range end cannot be before ${dayjs(dateStart).format('YYYY-MM-DD')}`,
                    });
                }
                return true;
            }),
    }),
});

function WorkQueueProvider({ children }) {
    const getInitialValues = useCallback(() => {
        let storedFilter = SessionStorageService.getOnUser(`workQueue_selectedFilter`, true);
        storedFilter = transformLsFilterToReact(storedFilter);
        if (storedFilter) {
            storedFilter = {
                ...storedFilter,
                dateEnd: toDate(storedFilter.dateEnd),
                dateStart: toDate(storedFilter.dateStart),
            };
        }
        const filter = storedFilter || defaultWorkQueueState.filterParams;
        return {
            ...defaultWorkQueueState,
            filterParams: {
                ...defaultWorkQueueState.filterParams,
                ...filter,
            },
        };
    }, []);

    return (
        <Formik initialValues={getInitialValues()} validationSchema={validationSchema}>
            {({ handleSubmit, resetForm }) => {
                return (
                    <Form onSubmit={handleSubmit} onReset={resetForm} className="work-queue-form">
                        {children}
                    </Form>
                );
            }}
        </Formik>
    );
}
WorkQueueProvider.propTypes = propTypes;
WorkQueueProvider.defaultProps = defaultProps;

export { WorkQueueProvider };
